import { FC, useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useLocales from '#/hooks/useLocales';
import useAuth from '#/hooks/useAuth';
import MobileMegaMenuItem from '#/components/shared/ui/navbar/MobileMegaMenuItem';
import { Item } from '#/components/shared/ui/navbar/navConfig';
import Iconify from '#/components/shared/ui/Iconify';
import useResponsive from '#/hooks/useResponsive';

interface NavItemProps {
  url: string;
  title: string;
  type?: string;
  title1?: string;
  url1?: string;
  items?: Item[];
}

const NavItem: FC<NavItemProps> = ({
  url,
  title,
  type,
  title1,
  url1,
  items,
}) => {
  const [mobileCollapse, setMobileCollapse] = useState<boolean>(false);

  const { isAuthenticated } = useAuth();
  const { translate, currentLang } = useLocales();
  const { pathname } = useLocation();
  const active = getActive(url, pathname);
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  const isBlog = title === 'global.services.blog';

  const handleMobileCollapse = (close?: boolean) => {
    if (isDesktop) return;
    if (close) {
      setMobileCollapse(false);
      return;
    }
    setMobileCollapse(!mobileCollapse);
  };

  useEffect(() => {
    if (pathname === url) setMobileCollapse(true);
    else setMobileCollapse(false);
  }, [pathname, url]);

  // const truncateText = (text: string, limit: number) =>
  //   text.length > limit ? `${text.substring(0, limit)}...` : text;

  return (
    <Box>
      <Stack
        component={Link}
        to={
          // eslint-disable-next-line no-unsafe-optional-chaining, no-nested-ternary
          url1 && isAuthenticated
            ? url1
            : isBlog
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              url + currentLang?.value
            : url
        }
        direction="row"
        alignItems="center"
        sx={{
          px: 2,
          py: 1,
          color: 'text.primary',
          textDecoration: 'none !important',
          opacity: type === 'button' ? 1 : 0.5,
          ...(active &&
            type !== 'button' && {
              color:
                title === 'global.services.pricing' ? 'black' : 'primary.main',
              backgroundColor:
                title === 'global.services.pricing'
                  ? 'transparent'
                  : theme.palette.action.hover,
              borderRadius: '0.5rem',
              opacity: title === 'global.services.pricing' ? 0.5 : 1,
            }),
          ...(mobileCollapse &&
            title === 'global.services.forIndividuals' && {
              opacity: 1,
              backgroundColor: theme.palette.action.hover,
            }),
        }}
        state={
          title === 'global.services.pricing' && pathname !== '/main'
            ? { pricing: true }
            : null
        }
        onClick={() => {
          handleMobileCollapse();
        }}
      >
        {type && type === 'button' ? (
          <Link
            to={isAuthenticated ? (url1 as string) : (url as string)}
            state={{
              registration: true,
            }}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Button variant="contained" aria-label={translate(title)}>
              <Typography noWrap>
                {translate(title1 && isAuthenticated ? title1 : title)}
              </Typography>
            </Button>
          </Link>
        ) : (
          <>
            <Typography noWrap>{translate(title)}</Typography>
            {(title === 'global.services.forIndividuals' ||
              title === 'global.services.forCompanies') &&
              (mobileCollapse ? (
                <Iconify icon="eva:arrow-ios-downward-fill" />
              ) : (
                <Iconify icon="eva:arrow-ios-upward-fill" />
              ))}
          </>
        )}
      </Stack>
      {items && items.length > 0 && (
        <MobileMegaMenuItem mobileCollapse={mobileCollapse} items={items} />
      )}
    </Box>
  );
};

export function getActive(path: string, pathname: string) {
  return path ? !!matchPath({ path, end: false }, pathname) : false;
}

export default NavItem;
