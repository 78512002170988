const lsLandingMains = {
  heirat: {
    bannerTitle: 'Marriage means taking responsibility. And planning ahead.',
    bannerDescription:
      'Getting married means taking responsibility. For yourself, your spouse, and your family. We help you plan for difficult times during good times.',
    sectionHeadline: 'Recommended precautions for marriage',
  },
  'geburt-eines-kindes': {
    bannerTitle: 'A child means love. And responsibility for the future.',
    bannerDescription:
      'The birth of a child is a wonderful event that turns life upside down. Now is the perfect time to secure the beautiful moments and think long-term. Together, we ensure that your child/children and loved ones are always well taken care of – no matter what life brings.',
    sectionHeadline: 'Recommended precautions for the birth of a child',
  },
  immobilienkauf: {
    bannerTitle: 'A home creates security. Make sure it stays that way.',
    bannerDescription:
      "Buying a home is a big step into the future. To ensure this security remains even in difficult times, it's important to plan ahead. We help you adapt your precautionary plans to the new life situation so you can focus entirely on your new home.",
    sectionHeadline: 'Recommended precautions when buying real estate',
  },
  pensionierung: {
    bannerTitle: 'Relax into retirement. Plan ahead to enjoy peace of mind.',
    bannerDescription:
      'Entering retirement marks the beginning of a new phase of life. Now is particularly important to think about the future. We help you get everything on the right track so you can enjoy your retirement without worry.',
    sectionHeadline: 'Recommended precautions when entering retirement',
  },
  'tod-verwandter': {
    bannerTitle:
      'Planning creates peace and space for mourning in case of death.',
    bannerDescription:
      'The death of a relative is a difficult time when many things need to be arranged. Besides grief, there are often questions about digital legacy and material values of the deceased. With a clear asset and property register and regulations for digital legacy, you create order and help your survivors manage everything according to your wishes.',
    sectionHeadline: 'Recommended precautions in case of death',
  },
  unternehmensgruendung: {
    bannerTitle: 'Start your own business. And stay well protected.',
    bannerDescription:
      "By founding a company, you put your vision into action and create something of lasting value. But especially during this exciting time, it's important to secure yourself – both personally and professionally. With a precautionary mandate and a will, you ensure that your company and family are protected even when life takes unexpected turns.",
    sectionHeadline: 'Recommended precautions when founding a company',
  },
  scheidung: {
    bannerTitle:
      'Divorce brings changes. Make sure your future remains secure.',
    bannerDescription:
      "A divorce can be a challenging time. It's important that you are well informed during this phase and make the right decisions. We help you make all necessary arrangements to ensure that you and your family are well protected and can make a fresh start.",
    sectionHeadline: 'Recommended precautions for divorce',
  },
  krankheit: {
    bannerTitle:
      'A serious diagnosis brings uncertainty. We help you plan ahead.',
    bannerDescription:
      "A serious illness changes everything. During this difficult time, it's even more important to focus on what matters – your health and the people you care about. With a living will and testament, you ensure that your wishes are respected and your loved ones are protected when you can no longer manage things yourself.",
    sectionHeadline: 'Recommended precautions for illness diagnosis',
  },
};

export default lsLandingMains;
