const lsLandingMains = {
  heirat: {
    bannerTitle:
      'Le mariage signifie prendre des responsabilités. Et planifier.',
    bannerDescription:
      'Se marier signifie prendre des responsabilités. Pour vous-même, votre conjoint et votre famille. Nous vous aidons à planifier les moments difficiles pendant les bons moments.',
    sectionHeadline: 'Précautions recommandées pour le mariage',
  },
  'geburt-eines-kindes': {
    bannerTitle:
      "Un enfant signifie l'amour. Et la responsabilité pour l'avenir.",
    bannerDescription:
      "La naissance d'un enfant est un événement merveilleux qui bouleverse la vie. C'est le moment idéal pour sécuriser les beaux moments et penser à long terme. Ensemble, nous veillons à ce que votre/vos enfant(s) et vos proches soient toujours bien pris en charge, quoi que la vie apporte.",
    sectionHeadline: "Précautions recommandées pour la naissance d'un enfant",
  },
  immobilienkauf: {
    bannerTitle:
      'Une maison crée la sécurité. Assurez-vous que cela reste ainsi.',
    bannerDescription:
      "L'achat d'une maison est un grand pas vers l'avenir. Pour que cette sécurité perdure même dans les moments difficiles, il est important de planifier à l'avance. Nous vous aidons à adapter vos plans de précaution à la nouvelle situation de vie afin que vous puissiez vous concentrer entièrement sur votre nouveau foyer.",
    sectionHeadline: "Précautions recommandées lors de l'achat immobilier",
  },
  pensionierung: {
    bannerTitle:
      "Détendez-vous à la retraite. Planifiez pour profiter l'esprit tranquille.",
    bannerDescription:
      "L'entrée dans la retraite marque le début d'une nouvelle phase de vie. Il est particulièrement important de penser à l'avenir maintenant. Nous vous aidons à mettre tout sur la bonne voie pour que vous puissiez profiter de votre retraite sans souci.",
    sectionHeadline: 'Précautions recommandées lors du départ à la retraite',
  },
  'tod-verwandter': {
    bannerTitle:
      "La planification crée la paix et l'espace pour le deuil en cas de décès.",
    bannerDescription:
      "Le décès d'un proche est une période difficile où beaucoup de choses doivent être arrangées. Outre le deuil, il y a souvent des questions sur l'héritage numérique et les valeurs matérielles du défunt. Avec un registre clair des actifs et des biens et des règlements pour l'héritage numérique, vous créez de l'ordre et aidez vos survivants à tout gérer selon vos souhaits.",
    sectionHeadline: 'Précautions recommandées en cas de décès',
  },
  unternehmensgruendung: {
    bannerTitle: 'Lancez votre entreprise. Et restez bien protégé.',
    bannerDescription:
      'En fondant une entreprise, vous mettez votre vision en action et créez quelque chose de durable. Mais particulièrement pendant cette période excitante, il est important de vous sécuriser - tant personnellement que professionnellement. Avec un mandat de précaution et un testament, vous vous assurez que votre entreprise et votre famille sont protégées même lorsque la vie prend des tournants inattendus.',
    sectionHeadline:
      "Précautions recommandées lors de la création d'entreprise",
  },
  scheidung: {
    bannerTitle:
      'Le divorce apporte des changements. Assurez-vous que votre avenir reste sécurisé.',
    bannerDescription:
      'Un divorce peut être une période difficile. Il est important que vous soyez bien informé pendant cette phase et que vous preniez les bonnes décisions. Nous vous aidons à prendre toutes les dispositions nécessaires pour que vous et votre famille soyez bien protégés et puissiez prendre un nouveau départ.',
    sectionHeadline: 'Précautions recommandées pour le divorce',
  },
  krankheit: {
    bannerTitle:
      "Un diagnostic grave apporte l'incertitude. Nous vous aidons à planifier.",
    bannerDescription:
      "Une maladie grave change tout. Pendant cette période difficile, il est encore plus important de se concentrer sur l'essentiel - votre santé et les personnes qui vous sont chères. Avec des directives anticipées et un testament, vous vous assurez que vos souhaits sont respectés et que vos proches sont protégés lorsque vous ne pouvez plus gérer les choses vous-même.",
    sectionHeadline: 'Précautions recommandées pour le diagnostic de maladie',
  },
};

export default lsLandingMains;
