import { IQuestion } from '#/types/globalTypes';

export const financialInformationQuestions: IQuestion[] = [
  {
    question: 'Bank documents',
  },
  {
    question: 'Insurance policies',
  },
  {
    question: 'State pension (AHV)',
  },
  {
    question: 'Pension fund',
  },
  {
    question: '3a',
  },
];

export const trustedPersonQuestions: IQuestion[] = [
  {
    question: 'Executor',
  },
  {
    question: 'Notary/trustee',
  },
];
