import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type LivingWillSituationKeys =
  | 'heirat'
  | 'geburt-eines-kindes'
  | 'immobilienkauf'
  | 'pensionierung'
  | 'krankheit'
  | 'tod-verwandter'
  | 'unternehmensgruendung'
  | 'scheidung';

export const LIVING_WILL_COMMONS = {
  service: 'patientenverfügung',
  actions: defaultActions,
  learnMoreLink: '/patientenverfuegung',
  startProcessLink: '/dashboard/patientenverfuegung',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-lw.webp',
  },
};

export const LIVING_WILL_SITUATION: Record<LivingWillSituationKeys, any> = {
  heirat: {
    content: {
      main: {
        title: 'livingWillLanding.situations.heirat.content.main.title',
        description:
          'livingWillLanding.situations.heirat.content.main.description',
      },
      columns: [
        {
          title: 'livingWillLanding.situations.heirat.content.columns.0.title',
          description: [
            'livingWillLanding.situations.heirat.content.columns.0.description.0',
          ],
        },
        {
          title: 'livingWillLanding.situations.heirat.content.columns.1.title',
          description: [
            'livingWillLanding.situations.heirat.content.columns.1.description.0',
            'livingWillLanding.situations.heirat.content.columns.1.description.1',
            'livingWillLanding.situations.heirat.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  'geburt-eines-kindes': {
    content: {
      main: {
        title:
          'livingWillLanding.situations.geburt-eines-kindes.content.main.title',
        description:
          'livingWillLanding.situations.geburt-eines-kindes.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.geburt-eines-kindes.content.columns.0.title',
          description: [
            'livingWillLanding.situations.geburt-eines-kindes.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.geburt-eines-kindes.content.columns.1.title',
          description: [
            'livingWillLanding.situations.geburt-eines-kindes.content.columns.1.description.0',
            'livingWillLanding.situations.geburt-eines-kindes.content.columns.1.description.1',
            'livingWillLanding.situations.geburt-eines-kindes.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  immobilienkauf: {
    content: {
      main: {
        title: 'livingWillLanding.situations.immobilienkauf.content.main.title',
        description:
          'livingWillLanding.situations.immobilienkauf.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.immobilienkauf.content.columns.0.title',
          description: [
            'livingWillLanding.situations.immobilienkauf.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.immobilienkauf.content.columns.1.title',
          description: [
            'livingWillLanding.situations.immobilienkauf.content.columns.1.description.0',
            'livingWillLanding.situations.immobilienkauf.content.columns.1.description.1',
            'livingWillLanding.situations.immobilienkauf.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  pensionierung: {
    content: {
      main: {
        title: 'livingWillLanding.situations.pensionierung.content.main.title',
        description:
          'livingWillLanding.situations.pensionierung.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.pensionierung.content.columns.0.title',
          description: [
            'livingWillLanding.situations.pensionierung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.pensionierung.content.columns.1.title',
          description: [
            'livingWillLanding.situations.pensionierung.content.columns.1.description.0',
            'livingWillLanding.situations.pensionierung.content.columns.1.description.1',
            'livingWillLanding.situations.pensionierung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  krankheit: {
    content: {
      main: {
        title: 'livingWillLanding.situations.krankheit.content.main.title',
        description:
          'livingWillLanding.situations.krankheit.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.krankheit.content.columns.0.title',
          description: [
            'livingWillLanding.situations.krankheit.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.krankheit.content.columns.1.title',
          description: [
            'livingWillLanding.situations.krankheit.content.columns.1.description.0',
            'livingWillLanding.situations.krankheit.content.columns.1.description.1',
            'livingWillLanding.situations.krankheit.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  'tod-verwandter': {
    content: {
      main: {
        title: 'livingWillLanding.situations.tod-verwandter.content.main.title',
        description:
          'livingWillLanding.situations.tod-verwandter.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.tod-verwandter.content.columns.0.title',
          description: [
            'livingWillLanding.situations.tod-verwandter.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.tod-verwandter.content.columns.1.title',
          description: [
            'livingWillLanding.situations.tod-verwandter.content.columns.1.description.0',
            'livingWillLanding.situations.tod-verwandter.content.columns.1.description.1',
            'livingWillLanding.situations.tod-verwandter.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  unternehmensgruendung: {
    content: {
      main: {
        title:
          'livingWillLanding.situations.unternehmensgruendung.content.main.title',
        description:
          'livingWillLanding.situations.unternehmensgruendung.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.unternehmensgruendung.content.columns.0.title',
          description: [
            'livingWillLanding.situations.unternehmensgruendung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.unternehmensgruendung.content.columns.1.title',
          description: [
            'livingWillLanding.situations.unternehmensgruendung.content.columns.1.description.0',
            'livingWillLanding.situations.unternehmensgruendung.content.columns.1.description.1',
            'livingWillLanding.situations.unternehmensgruendung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  scheidung: {
    content: {
      main: {
        title: 'livingWillLanding.situations.scheidung.content.main.title',
        description:
          'livingWillLanding.situations.scheidung.content.main.description',
      },
      columns: [
        {
          title:
            'livingWillLanding.situations.scheidung.content.columns.0.title',
          description: [
            'livingWillLanding.situations.scheidung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'livingWillLanding.situations.scheidung.content.columns.1.title',
          description: [
            'livingWillLanding.situations.scheidung.content.columns.1.description.0',
            'livingWillLanding.situations.scheidung.content.columns.1.description.1',
            'livingWillLanding.situations.scheidung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getLivingWillSituation = (situation: LivingWillSituationKeys) => ({
  ...LIVING_WILL_SITUATION[situation],
  ...LIVING_WILL_COMMONS,
});
