import {
  financialInformationQuestions,
  trustedPersonQuestions,
} from './questions';

const importantInformation = {
  mainTitle: 'Fourniture simple d\'informations importantes',
  mainDescription:
    'Informez vos proches ou exécuteurs testamentaires où trouver des informations importantes en période difficile.',

  insuranceTypes: {
    life_insurance: 'Assurance vie',
    private_liability_insurance: 'Assurance responsabilité civile privée',
    household_insurance: 'Assurance ménage',
    vehicle_insurance: 'Assurance véhicule',
    building_insurance: 'Assurance bâtiment',
    legal_expenses_insurance: 'Assurance protection juridique',
    occupational_disability_insurance: 'Assurance invalidité professionnelle',
    health_insurance: 'Assurance maladie',
    supplementary_health_insurance: 'Assurance maladie complémentaire',
    accident_insurance: 'Assurance accident',
  },

  threeATypes: {
    policy: 'Police',
    account: 'Compte',
    fund: 'Fonds',
  },

  stepperInfo: [
    {
      label: 'Documents d\'identification',
      title: '',
    },
    {
      label: 'Informations financières',
      title: '',
    },
    {
      label: 'Personnes de confiance',
      title: '',
    },
  ],

  stepTwo: {
    questions: financialInformationQuestions,
  },

  delete3Amodal: {
    title:
      'Voulez-vous vraiment supprimer {{financialInstitution}} de la liste ?',
    description: 'Vous pouvez le rajouter à tout moment plus tard',
    onClose: 'Fermer',
    onConfirm: 'Supprimer',
  },

  deleteInsurancePolicyModal: {
    title: 'Voulez-vous vraiment supprimer la {{policy}} ?',
    description: 'Vous pouvez le rajouter à tout moment plus tard',
    onClose: 'Fermer',
    onConfirm: 'Supprimer',
  },

  stepThree: {
    questions: trustedPersonQuestions,
    addAnotherTrustedPerson: 'Ajouter un autre exécuteur testamentaire',
    addNotary: 'Ajouter un notaire ou un fiduciaire',
  },
};

export default importantInformation;