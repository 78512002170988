import { Button, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  openEnoughNow: boolean;
  handleCloseEnoughNow: () => void;
};

const EnoughForNowModal = ({ openEnoughNow, handleCloseEnoughNow }: Props) => {
  const { translate } = useLocales();

  const navigate = useNavigate();

  return (
    <DialogAnimate open={openEnoughNow} onClose={handleCloseEnoughNow}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {translate('global.areYouSureYouWantToLeave')}
        </Typography>
        <Typography variant="body2">
          {translate('global.enoughForNowDscp')}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            onClick={handleCloseEnoughNow}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body1" noWrap>
              {translate('global.enoughForNowContinue')}
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate('/dashboard/home');
            }}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body1" noWrap>
              {translate('global.enoughForNowCancel')}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};

export default EnoughForNowModal;
