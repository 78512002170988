import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type DigitalFootprintSituationKeys =
  | 'pensionierung'
  | 'tod-verwandter'
  | 'unternehmensgruendung';

export const DIGITAL_FOOTPRINT_COMMONS = {
  service: 'digitaler-nachlass',
  actions: defaultActions,
  learnMoreLink: '/digitaler-nachlass',
  startProcessLink: '/dashboard/digitaler-nachlass',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-df.webp',
  },
};

export const DIGITAL_FOOTPRINT_SITUATION: Record<
  DigitalFootprintSituationKeys,
  any
> = {
  'tod-verwandter': {
    content: {
      main: {
        title:
          'digitalFootprintLanding.situations.tod-verwandter.content.main.title',
        description:
          'digitalFootprintLanding.situations.tod-verwandter.content.main.description',
      },
      columns: [
        {
          title:
            'digitalFootprintLanding.situations.tod-verwandter.content.columns.0.title',
          description: [
            'digitalFootprintLanding.situations.tod-verwandter.content.columns.0.description.0',
          ],
        },
        {
          title:
            'digitalFootprintLanding.situations.tod-verwandter.content.columns.1.title',
          description: [
            'digitalFootprintLanding.situations.tod-verwandter.content.columns.1.description.0',
            'digitalFootprintLanding.situations.tod-verwandter.content.columns.1.description.1',
            'digitalFootprintLanding.situations.tod-verwandter.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  pensionierung: {
    content: {
      main: {
        title:
          'digitalFootprintLanding.situations.pensionierung.content.main.title',
        description:
          'digitalFootprintLanding.situations.pensionierung.content.main.description',
      },
      columns: [
        {
          title:
            'digitalFootprintLanding.situations.pensionierung.content.columns.0.title',
          description: [
            'digitalFootprintLanding.situations.pensionierung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'digitalFootprintLanding.situations.pensionierung.content.columns.1.title',
          description: [
            'digitalFootprintLanding.situations.pensionierung.content.columns.1.description.0',
            'digitalFootprintLanding.situations.pensionierung.content.columns.1.description.1',
            'digitalFootprintLanding.situations.pensionierung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  unternehmensgruendung: {
    content: {
      main: {
        title:
          'digitalFootprintLanding.situations.unternehmensgruendung.content.main.title',
        description:
          'digitalFootprintLanding.situations.unternehmensgruendung.content.main.description',
      },
      columns: [
        {
          title:
            'digitalFootprintLanding.situations.unternehmensgruendung.content.columns.0.title',
          description: [
            'digitalFootprintLanding.situations.unternehmensgruendung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'digitalFootprintLanding.situations.unternehmensgruendung.content.columns.1.title',
          description: [
            'digitalFootprintLanding.situations.unternehmensgruendung.content.columns.1.description.0',
            'digitalFootprintLanding.situations.unternehmensgruendung.content.columns.1.description.1',
            'digitalFootprintLanding.situations.unternehmensgruendung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getDigitalFootprintSituation = (
  situation: DigitalFootprintSituationKeys
) => ({
  ...DIGITAL_FOOTPRINT_SITUATION[situation],
  ...DIGITAL_FOOTPRINT_COMMONS,
});
