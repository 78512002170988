const financialPlanning = {
  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Advice on Financial Situation',
          description:
            'In retirement, the financial situation often changes. Our advice helps you to plan your finances optimally so that you are financially secure in old age.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'Good financial planning gives you security in retirement and ensures that you can enjoy your new phase of life in a relaxed way.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Optimal use of your pension income',
              'Tax advantages',
              'Long-term financial security',
            ],
          },
        ],
      },
    },

    scheidung: {
      content: {
        main: {
          title: 'Financial Planning',
          description:
            'Comprehensive financial planning helps you to reorganize your financial situation after a divorce and secure it in the long term. This way you keep control over your finances.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'After a divorce, financial priorities often need to be reset. Professional financial planning gives you clarity and security for the coming years.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Reorganization of your financial situation',
              'Planning for long-term financial stability',
              'Tax optimization after the divorce',
            ],
          },
        ],
      },
    },
  },
};

export default financialPlanning;
