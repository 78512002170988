const aboutUs = {
  heroTitle: 'gut geregelt – a Swiss company from Baselland',
  heroSubtitle:
    'gut geregelt AG is a startup based in Sissach near Basel. We are digitalizing and democratizing access to retirement planning.',
  heroLabel: 'About us',
  heroButton: 'Contact',
  section1: {
    title: 'We are gut geregelt',
    subtitle:
      'gut geregelt is a young and dynamic company with deep roots in Switzerland and the Basel area. Our team consists of experts in law, finance, insurance, and software development. Our ambition is to digitalize the retirement planning process, making it faster, more affordable, and accessible.',
  },
  section2: {
    title: 'Chairman: {{chairMan}}',
    subtitle:
      '{{chairMan}} is a Swiss serial entrepreneur from Sissach. He is the Chairman of the Board and the driving force at gut geregelt. Together with the gut geregelt team, Alain leads iterative software development, presents our solutions to banks and pension funds, and is a sought-after speaker at various events in the banking and startup sectors.',
  },
  ourGoal: {
    label: 'Our goal',
    title: 'Making retirement planning accessible',
    subtitle:
      'At gut geregelt, our mission is to digitize the entire retirement planning process and integrate it onto our platform. We have quickly and efficiently developed a comprehensive solution that continues to expand. gut geregelt makes retirement planning easier, more understandable, and more accessible.',
  },
  section3: {
    title: 'Part of the betascale group',
    subtitle:
      'gut geregelt AG is a company of the Swiss Betascale Group, based in Sissach near Basel. With the motto "Building the ventures of tomorrow, today," Betascale has successfully tested and positioned gut geregelt in the market. In a short time, we have established ourselves as a reliable partner in retirement planning and have launched our software to the market.',
  },
  partners: {
    label: 'Our partners',
    title: 'We rely on strong partners',
    subtitle:
      'Strong partnerships are a key component of our success. To offer our clients optimal solutions, we work with leading companies and institutions in Switzerland.',
  },
};

export default aboutUs;
