import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type FinancialPlanningSituationKeys = 'pensionierung' | 'scheidung';

export const FINANCIAL_PLANNING_COMMONS = {
  service: 'finanzplanung',
  actions: defaultActions,
  startProcessLink:
    'https://finfinder.ch/?openModal=1&utm_source=gutgeregelt&utm_medium=app&utm_campaign=promo',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-finances2.webp',
  },
};

export const FINANCIAL_PLANNING_SITUATION: Record<
  FinancialPlanningSituationKeys,
  any
> = {
  pensionierung: {
    content: {
      main: {
        title: 'financialPlanning.situations.pensionierung.title',
        description: 'financialPlanning.situations.pensionierung.description',
      },
      columns: [
        {
          title:
            'financialPlanning.situations.pensionierung.content.columns.0.title',
          description: [
            'financialPlanning.situations.pensionierung.columns.content.0.description.0',
          ],
        },
        {
          title:
            'financialPlanning.situations.pensionierung.content.columns.1.title',
          description: [
            'financialPlanning.situations.pensionierung.content.columns.1.description.0',
            'financialPlanning.situations.pensionierung.content.columns.1.description.1',
            'financialPlanning.situations.pensionierung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  scheidung: {
    content: {
      main: {
        title: 'financialPlanning.situations.scheidung.content.main.title',
        description:
          'financialPlanning.situations.scheidung.content.main.description',
      },
      columns: [
        {
          title:
            'financialPlanning.situations.scheidung.content.columns.0.title',
          description: [
            'financialPlanning.situations.scheidung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'financialPlanning.situations.scheidung.content.columns.1.title',
          description: [
            'financialPlanning.situations.scheidung.content.columns.1.description.0',
            'financialPlanning.situations.scheidung.content.columns.1.description.1',
            'financialPlanning.situations.scheidung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getFinancialPlanningSituation = (
  situation: FinancialPlanningSituationKeys
) => ({
  ...FINANCIAL_PLANNING_SITUATION[situation],
  ...FINANCIAL_PLANNING_COMMONS,
});
