import { IQuestion } from '#/types/globalTypes';

export const inheritorsMarriedVersion: IQuestion[] = [
  {
    question:
      'Hai indicato di essere sposato. Puoi fornirci i dettagli del tuo coniuge?',
  },
  {
    question:
      'Hai stipulato un contratto matrimoniale o un contratto immobiliare?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Hai una proprietà/appartamento che desideri lasciare in eredità al tuo partner?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Hai un procedimento di divorzio in corso?',
  //   options: [
  //     {
  //       label: 'Sì',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
  {
    question: 'Hai figli da un matrimonio precedente o attuale?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Vuoi beneficiare altre persone o istituzioni?',
  //   options: [
  //     {
  //       label: 'Sì',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const inheritorsSingleVersion: IQuestion[] = [
  {
    question: 'Attualmente vivi in una relazione stabile?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Hai stipulato un contratto immobiliare (partenariato registrato)?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Hai una proprietà/appartamento che desideri lasciare in eredità al tuo partner?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Hai figli?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Vuoi beneficiare altre persone o istituzioni?',
  //   options: [
  //     {
  //       label: 'Sì',
  //     },
  //
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const inheritorsDivorcedVersion: IQuestion[] = [
  {
    question: 'Attualmente vivi in una relazione stabile?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Hai indicato di essere divorziato. Hai figli?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Vuoi beneficiare altre persone o istituzioni?',
  //   options: [
  //     {
  //       label: 'Sì',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const distributionQuestions: IQuestion[] = [
  {
    question:
      'Nella misura consentita dalla legge, desidero che il più possibile della mia eredità sia distribuito a:',
    options: [
      {
        label: 'Coniuge/partner registrato/partner di vita',
      },
      {
        label: 'Figli',
      },
      {
        label: 'Un\'istituzione a mia scelta',
      },
    ],
  },
  {
    question:
      'Vuoi donare oggetti specifici o una somma di denaro a un membro della famiglia, a una persona specifica, anche al di fuori della tua famiglia, o a un\'istituzione?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question:
  //     'Hai diverse eredità che desideri donare a persone diverse?',
  //   options: [
  //     {
  //       label: 'Sì',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const governanceQuestions: IQuestion[] = [
  {
    question: 'Chi dovrebbe essere l\'esecutore testamentario?',
  },
];