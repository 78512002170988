import {
  contractParties,
  estateQuestions,
  marriageQuestions,
} from './questions';
import outputDoc from './outputDoc';

const marriageContract = {
  mainHeaders: {
    maximiseBenefits: 'Maximize the benefits for your spouse',
    effectiveMarriageContract: 'Your effective marriage contract',
  },

  stepperInfo: [
    {
      label: 'Spouse',
      title: 'Contract parties',
    },
    {
      label: 'Marriage',
      title: 'Marriage',
    },
    {
      label: 'Assets',
      title: 'Assets',
    },
    {
      label: 'Your contract',
      title: 'Submit your marriage contract',
    },
  ],

  propertyTypes: {
    apartment: 'Apartment',
    house: 'House',
    chalet: 'Chalet',
    rustico: 'Rustico',
    building_plot: 'Building plot',
    multi_family_house: 'Multi-family house',
    commercial_building: 'Commercial building',
    residential_building: 'Residential building',
    commercial_property: 'Commercial property',
  },

  stepOne: {
    questions: contractParties,
  },
  stepTwo: {
    questions: marriageQuestions,
    confirmAssetDelete: {
      title: 'Are you sure you want to delete this asset?',
      description: 'You can add it again at any time later.',
      cancel: 'Cancel',
      delete: 'Delete',
    },
  },
  stepThree: {
    questions: estateQuestions,
    tooltips: {
      id2: 'If you choose "Yes," the contract will include a clause confirming that the entire remainder of your assets was jointly acquired, known as the marital property. This means the surviving partner automatically owns 50% of the assets. This offers many benefits for your spouse.',
      id4: 'This condition must be met for our form. If you have other wishes, please contact a lawyer. We are happy to help you find a suitable lawyer.',
      id6: 'The greater value at the time of death must be considered here.',
      id7:
        'If you want to authorize a third person to enforce your estate, add them here. \n' +
        'If you do not add a third person, the surviving spouse is automatically authorized.\n' +
        'Please note that a third person generally has the right to refuse the task.\n' +
        'If the person accepts the task, they are entitled to a compensation. The legal provisions at the time of death apply.',
    },
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Well done!',
    nextStepsTitle: 'The next steps:',
    nextStep1: 'Print the marriage contract and read it carefully',
    nextStep2:
      'Contact a notary to have your contract notarized (click here to find one)',
    highlightedText: 'click here',
    nextStep3: 'Upload the notarized marriage contract below',
  },
};

export default marriageContract;
