const adminDashboard = {
  main: {
    title: 'Cruscotto',
    description:
      'Vedi quanti dei tuoi clienti visitano Gut geregelt, creano un login e poi acquistano un pacchetto.',
  },
  analytics: {
    cards: {
      visitors: 'Visitatori',
      conversion_rate_login: 'Tasso di conversione al login',
      loginsCreated: 'Logins creati',
      conversion_rate_pay: 'Tasso di conversione ai clienti paganti',
      paying_customers: 'Clienti paganti',
    },
  },
  charts: {
    visitors: 'Visitatori',
    conversionRateToLogin: 'Tasso di conversione al login',
    loginsCreated: 'Logins creati',
    conversionRateToPaying: 'Tasso di conversione ai clienti paganti',
    subscriptionsCreated: 'Clienti paganti',
  },
  historicalData: 'Dati storici',
  historicalDataSubheader:
    '({{subscription_growth_percentage}}%) rispetto all\'anno scorso',
  demographics: 'Demografia',
  women: 'Donne',
  man: 'Uomini',
  visits: 'visite',

  widgetsOptions: {
    lifeExpectancy: 'Aspettativa di vita',
    ageAppropriate: 'Investimento 3a adeguato all\'età',
    ahvCalculator: 'Calcolatore AHV',
  },

  clientOverview: 'Panoramica clienti',
  generalOverview: 'Cruscotto',
  legalAdvisors: 'Consulenti legali',
  financialAdvisors: 'Consulenti finanziari',
  platform: 'Piattaforma',
  user: 'Utente',
  mainInfoModal: {
    title: 'Per favore, completa le informazioni mancanti',
    description:
      'Per favore, aggiungi il tuo nome e cognome per completare il login.',
  },

  consulting: {
    unlockWidget: {
      title: 'Sblocca widget',
      description:
        'Richiedi un\'offerta e attiva i widget per te e i tuoi consulenti per offrire una consulenza pensionistica semplificata ai clienti durante le sessioni di consulenza.',
    },
  },
  teamTable: {
    tableHeads: {
      name: 'Nome',
      email: 'Email',
      role: 'Ruolo',
      last_login: 'Ultimo login',
    },
  },
  consultantTable: {
    defaultSwitchTooltip:
      'Passando alla lista standard, verranno mostrati solo i consulenti forniti da Gut geregelt.',
    tableHeads: {
      name: 'Nome',
      email: 'Email',
      location: 'Località',
      added: 'Aggiunto',
    },
  },
  usersTable: {
    toolbarButtons: {
      columns: 'Colonne',
      export: 'Esporta',
      filter: 'Filtro',
      transferSelectedUsers:
        'Trasferisci utenti selezionati ({{selectedUsers}})',
      removeFromConsulting: 'Rimuovi dalla consulenza',
    },
    tableHeads: {
      category: 'Categoria',
      name: 'Nome',
      email: 'Email',
      owned_by: 'Consulente',
      last_login: 'Ultimo login',
      pension_provision: 'Previdenza',
      living_will: 'Testamento biologico',
      power_of_attorney: 'Procura',
      last_will: 'Testamento',
    },
  },
  deleteUser: 'Elimina utente',
  deleteConsultant: {
    title:
      'Stai per rimuovere {{consultant}} dalla lista. Sei sicuro?',
    description: 'Per favore, conferma l\'eliminazione.',
    onConfirm: 'Elimina',
    onClose: 'Annulla',
  },
  deleteTeamMember: {
    title: 'Stai per eliminare la lista. Sei sicuro?',
    description: 'Per favore, conferma l\'eliminazione.',
    onConfirm: 'Elimina',
    onClose: 'Annulla',
  },
  cancelInvitation: {
    title: 'Stai per annullare l\'invito. Sei sicuro?',
    description:
      'Anche dopo l\'annullamento, puoi invitare nuovamente il membro.',
    onConfirm: 'Elimina',
    onClose: 'Annulla',
  },
  deleteUserModal: {
    title:
      'Stai per rimuovere {{user}} dalla lista. Sei sicuro?',
    description: 'Per favore, conferma l\'eliminazione.',
    onConfirm: 'Elimina',
    onClose: 'Annulla',
  },

  userTransferModal: {
    title:
      'Hai selezionato gli utenti elencati di seguito per trasferirli a un altro consulente',
    description:
      'Seleziona dalla lista sottostante il consulente a cui trasferire',
  },

  confirmUnassignment: {
    title:
      'Confermi di voler rimuovere l\'assegnazione del consulente per gli utenti selezionati?',
    description: 'Puoi assegnarli a un altro consulente in seguito.',
    onConfirm: 'Conferma',
    onClose: 'Annulla',
  },
};

export default adminDashboard;