import { Stack, Typography, Box } from '@mui/material';
import { pricings } from '#/components/pages/Landing/landingData';
import PricingCard from './Cards/PricingCard';
import useLocales from '#/hooks/useLocales';

const Pricing = () => {
  const { translate } = useLocales();

  return (
    <Stack
      spacing={4}
      sx={{
        py: 10,
        px: {
          xs: 2,
          md: 0,
        },
        backgroundColor: '#F4F6F8',
      }}
      id="pricing"
    >
      <Typography variant="h2" textAlign="center">
        {String(translate('provisionLanding.pricing.title'))}
      </Typography>
      <Typography textAlign="center">
        {String(translate('provisionLanding.pricing.subtitle'))}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          justifyContent: 'center',
          // gridTemplateColumns: {
          //   xs: '1fr',
          //   md: '1fr 1fr 1fr',
          // },
          gap: 10,
        }}
      >
        {pricings.map((pricing, i) => (
          <PricingCard {...pricing} key={i} />
        ))}
      </Box>
    </Stack>
  );
};

export default Pricing;
