const outputDoc = {
  title: 'Marriage contract',
  from: 'from ',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  civil_status: 'Civil status: {{civil_status}}',
  address: 'Address: {{address}}',
  male: 'Husband',
  female: 'Wife',
  and: 'and',
  spousePresentation:
    '{{spouseLastName}} {{spouseName}}, {{relation}}, born on {{spouseBirthDate}}, from {{spousePlaceOfCitizenship}}, {{spouseCivilStatus}}, residing at {{spouseAddress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, {{relation}}, born on {{birthDate}}, from {{placeOfCitizenship}}, {{civilStatus}}, residing at {{address}}.',
  determinations: {
    title: 'Provisions',
    section1: {
      firstParagraphWithChildren:
        'The parties were married on {{weddingDate}} in {{weddingLocation}}.',
      oneCommonChildParagraph:
        'From their marriage, there is one common child: ',
      multipleCommonChildrenParagraph:
        'From their marriage, there are {{numberOfChildren}} common children: ',
      childBirthday: 'born on {{birthday}}',
      firstParagraphWithoutChildren:
        'The parties were married on {{weddingDate}} in {{weddingLocation}}. No common children have resulted from their marriage.',
      noChildrenOutsideMarriage: 'The parties have no non-common children.',
      oneChildOutsideMarriage: 'The parties have one non-common child: ',
      multipleChildrenOutsideMarriage:
        'The parties have {{numberOfChildren}} non-common children: ',
    },
    section2: {
      noMarriageContract:
        'To date, the parties have never concluded a marriage contract. Neither judicial nor statutory separation of property has occurred.',
    },
    section3: {
      participationAgreement:
        'The participation in acquisitions includes the acquisitions and personal property of each spouse. Within the legal limits, each spouse manages and uses their acquisitions and personal property and disposes of them.',
    },
    section4: {
      personalAssets:
        'In addition to items that serve each spouse exclusively for personal use, the parties own the following personal assets:',
      pointA: 'a.) Husband: ',
      pointB: 'b.) Wife: ',
    },
    section5: {
      otherAssets: 'All other assets',
      evenEstates: 'including ',
      otherAssetsContinued:
        'have been jointly acquired by the parties after marriage; these constitute acquisitions within the meaning of Art. 197 ZGB.',
      apartment: 'the apartment',
      house: 'the house',
      chalet: 'the chalet',
      rustico: 'the rustico',
      building_plot: 'the building plot',
      multi_family_house: 'the multi-family house',
      commercial_building: 'the commercial building',
      residential_building: 'the residential building',
      commercial_property: 'the commercial property',
      nonAcquiredAfterMarriage:
        'The following {{assetsQuantity}} have been jointly acquired by the parties after marriage; these constitute acquisitions within the meaning of Art. 197 ZGB: ',
      plural: 'properties',
      singular: 'property',
    },
  },
  marriageContractAgreements: {
    title: 'Marriage contract agreements',
    section1: {
      firstParagraph:
        'The parties expressly agree to maintain the ordinary marital property regime of participation in acquisitions.',
    },
    section2: {
      firstParagraph:
        'Based on Art. 216 para. 1 ZGB and in modification of the participation in proposals provided for in Art. 215 ZGB, the parties agree that upon dissolution of the marriage by the death of a spouse, the surviving spouse shall receive the total sum of the proposals of both spouses as unencumbered property.',
    },
    section3: {
      firstParagraph:
        'In application of Art. 206 para. 3 ZGB, the spouses exclude mutual participation in the increase in value upon dissolution of the marriage in the event of death.',
    },
  },
  jointProvisions: {
    title: 'Joint provisions',
    section1: {
      firstParagraph:
        'By establishing this contract, the parties revoke all previous dispositions of property upon death. Excluded from this are any declarations of beneficiary in favor of insurance and pension institutions.',
    },
    section2: {
      firstParagraph:
        'As a division rule, it is determined that the surviving spouse can freely choose to take over those tangible and capital assets that appeal to them, in credit to the marital and inheritance claims. This division rule explicitly also applies to real estate, which is to be entered in the land register as the sole property of the surviving spouse. The official market value (tax value) applies as the credit value; as of the date of death of the first deceased.',
      secondParagraph:
        'The surviving spouse is also entitled to settle the inheritance claims of the other heirs at their discretion in cash or by assigning other assets. The entire household belongs to the surviving spouse as unrestricted property without credit to the inheritance share.',
    },
    section3: {
      firstParagraph:
        'If the surviving spouse remarries, they must pay the descendants the amount they would have received at the death of the first deceased spouse in a marital and inheritance settlement according to the legal provisions and without this contract. This takes into account what the descendants have already received from the estate of the first deceased spouse despite this contract and what has been given to them by the surviving spouse as a gift since then. The tax inventory of the estate, which was recorded by the cantonal authorities or the heirs themselves at the death of the first deceased parent/spouse, is decisive for the existence of the estate assets. The claims of the descendants become due on the day the condition occurs and are neither to be interest-bearing nor secured until that day. The surviving parent/spouse is free to decide in what form the payment is made.',
    },
    section4: {
      firstParagraph:
        'This marriage contract is subject to Swiss law, subject to any mandatory legal provisions to the contrary. As far as permissible, the Swiss authorities are also responsible for the marital and inheritance relationships of the parties.',
    },
    section5: {
      firstParagraph:
        'This marriage contract becomes invalid if, at the death of the first deceased spouse, the marriage of the parties is legally divorced, judicially or factually separated within the meaning of Art. 117 ZGB, or if a divorce or marital protection request is pending.',
    },
  },
  contractualAgreements: {
    title: 'Inheritance contractual agreements',
    section1: {
      firstParagraph:
        'Upon the death of the first deceased spouse, the survivor retains their personal property and, based on the above marriage contract agreement, receives the proposals of both spouses as property.',
    },
    section2: {
      firstParagraph:
        'Furthermore, the parties also benefit each other in terms of inheritance, as far as legally permissible.',
      nonCommonChildren:
        'The surviving spouse can thus choose from the remaining estate between:',
      pointA:
        'a.) the highest possible inheritance claim (currently 5/8) as property and allocation of the compulsory portion to the descendants',
      pointB:
        'b.) the free quota (currently 1/4) as property and from the remaining estate due to the descendants, the free usufruct according to Art. 473 ZGB, without having to provide security for this.',
    },
    section4: {
      firstParagraph:
        'The spouses grant each other the right to freely dispose of the greater amount from CHF {{disposal_fixed_amount}} or {{disposal_percentage}}% of their net estate by will.',
    },
  },
  signatures: {
    title: 'Signature {{gender}}',
    female: 'Wife',
    male: 'Husband',
  },
  notarization: {
    title: 'Notarization',
    firstParagraph: 'The undersigned notary hereby certifies that',
    firstCondition:
      'he has read the present document to the parties to the deed,',
    secondCondition:
      'the document corresponds to the will communicated to him by the parties to the deed,',
    thirdCondition:
      'the document has been personally signed by the parties to the deed in his presence,',
    noterTitle: 'Notary signature',
  },
};

export default outputDoc;
