const provisionLanding = {
  mainBanner: {
    intro: 'Secure and organise your future for your loved ones',
    title:
      'Create your advance healthcare directive, power of attorney, and will online',
    subtitle:
      'In emergencies, your family members may face intense emotions. Save them the extra stress of unresolved formalities.',
    buttonLabel: 'organise your future now',
  },
  sale: '-43% / per person',
  addMore: 'Add {{membersNo}} more',
  youtubeTutorial: 'Why is planning for your future so important?',
  aboutCards: {
    remainInControl: {
      title: 'Stay in control',
      description:
        "If you don't speak for yourself, others will—perhaps not in your best interest.",
    },
    protectYourFamily: {
      title: 'Protect your family',
      description:
        'Take proactive steps to clarify how your family should manage things if you become incapacitated or pass away.',
    },
    relieveRelatives: {
      title: 'Ease the burden on relatives',
      description:
        "Consider those who will have a lot to manage if you're no longer able to. Plan to lighten their load.",
    },
  },
  whyWithUs: {
    title: 'Why choose gut geregelt?',
    cards: {
      card1: {
        title: 'Clear and understandable',
        description:
          "Are you often asked about things you don't understand at all? Not here. We speak your language.",
      },
      card2: {
        title: 'Quick to complete',
        description:
          'Instead of answering dozens of questions in a lengthy process, get what you need in 10 minutes—guaranteed.',
      },
      card3: {
        title: 'Cost-effective',
        description:
          'Gut geregelt allows you to make comprehensive, legally compliant plans at an unbeatable price.',
      },
    },
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: "My husband and I have been discussing this topic for years. Our daughter introduced us to gut geregelt, and we were amazed at how simple and quick it was to do something we'd been putting off for so long.",
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: "My father was an old-school businessman, always busy and with little documentation. When he passed, my sister and I had to work hard to bring clarity and order. Even though I'm not of that age, I don't want to take risks, and I want to spare my family from a similar burden. Gut geregelt enabled me to do just that—from the comfort of my couch.",
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "Two years ago, I created a will with my lawyer. Thanks to gut geregelt, I now also have an advance healthcare directive and a power of attorney. The process was straightforward, and above all, the language was easy to understand. While I hope I'll never need them, I'm glad I took the step to create these documents as well.",
    },
  },
  subBanner: {
    title: 'Legally compliant planning made easy',
    subtitle:
      'gut geregelt provides access to legal advice if needed and helps you create legally compliant documents, securely store them, and share them in emergencies with those who need them.',
    buttonLabel: 'Get started now',
  },
  pricing: {
    title: 'Choose the right package for you',
    subtitle:
      'We have something for everyone. Choose the package that best meets your needs.',
    oneTime: 'one-time payment',
    perYear: 'year',
    additionalMember: 'additional member',
    purchaseButton: 'Secure your future now',
    manageButton: 'Manage',
    starter: {
      title: 'Starter',
      finfinderSale: 'Save 200 CHF with FinFinder',
      features: [
        'All provision documents',
        'Digital document management',
        'Asset and property register',
        'Digital legacy',
        'Tax folder',
        'Support with organizing',
        'Guest access for representatives',
        'Access to legal and financial advice',
      ],
    },
    standard: {
      title: 'Standard',
      finfinderSale: 'Save 400 CHF with FinFinder',
      features: [
        'All provision documents',
        'Digital document management',
        'Asset and property register',
        'Digital legacy',
        'Tax folder',
        'Support with organizing',
        'Guest access for representatives',
        'Reminders (complete documents, legal updates and other changes)',
        'Access to legal and financial advice',
      ],
    },
  },
  provisionBanner: {
    title:
      "Even if it's difficult, it's important to start thinking about it now",
    subtitle:
      "Many of us tend to put off retirement planning. After all, no one enjoys thinking about long term scenarios. However, remember that ignoring it isn't doing yourself or your loved ones any favors. The sooner you start, the better. We'll help you find the right offering for you.",
    buttonLabel: 'Secure your future now',
  },
  newsletter: {
    title: 'Become "gut geregelt"',
    buttonLabel: 'Create your documents',
  },

  eightReasons: {
    title: '8 reasons for gut geregelt',
    cards: {
      card1: {
        title: 'Easy and understandable',
        description: {
          provisionLanding:
            'We speak your language and make complex terms easy to understand.',
          patientenverfuegung:
            'We speak your language and make complex terms easy to understand.',
          vorsorgeauftrag:
            'We speak your language and make complex terms easy to understand.',
          testament:
            'We speak your language and make complex terms easy to understand.',
          ehevertrag:
            'We speak your language and make complex terms easy to understand.',
          'digitaler-nachlass':
            'We speak your language and make complex terms easy to understand.',
          konkubinatsvertrag:
            'We speak your language and make complex terms easy to understand.',
        },
      },
      card2: {
        title: 'Completed in 30 minutes',
        description: {
          provisionLanding:
            'Your final document in just 14 minutes on average, thanks to our optimized process.',
          patientenverfuegung:
            'Your personal healthcare directive in just 14 minutes on average, thanks to our optimized process.',
          vorsorgeauftrag:
            'Your final power of attorney in just 14 minutes on average, thanks to our optimized process.',
          testament:
            'Your final will in just 14 minutes on average, thanks to our optimized process.',
          ehevertrag:
            'Your final marriage contract in just 14 minutes on average, thanks to our optimized process.',
          'digitaler-nachlass':
            'Your final digital legacy document in just 14 minutes on average, thanks to our optimized process.',
          konkubinatsvertrag:
            'Your final cohabitation agreement in just 14 minutes on average, thanks to our optimized process.',
        },
      },
      card3: {
        title: 'Convenient from home',
        description: {
          provisionLanding:
            'Create your documents from the comfort of your home, anytime without appointments.',
          patientenverfuegung:
            'Create your personal healthcare directive from the comfort of your home, anytime without appointments.',
          vorsorgeauftrag:
            'Create your power of attorney from the comfort of your home, anytime without appointments.',
          testament:
            'Create your will from the comfort of your home, anytime without appointments.',
          ehevertrag:
            'Create your marriage contract from the comfort of your home, anytime without appointments.',
          'digitaler-nachlass':
            'Create your digital legacy document from the comfort of your home, anytime without appointments.',
          konkubinatsvertrag:
            'Create your cohabitation agreement from the comfort of your home, anytime without appointments.',
        },
      },
      card4: {
        title: 'Can be revised or changed anytime',
        description: {
          provisionLanding:
            'Easily adjust your documents to new life circumstances anytime.',
          patientenverfuegung:
            'Easily adjust your personal healthcare directive to new life circumstances anytime.',
          vorsorgeauftrag:
            'Easily adjust your power of attorney to new life circumstances anytime.',
          testament:
            'Easily adjust your will to new life circumstances anytime.',
          ehevertrag:
            'Easily adjust your marriage contract to new life circumstances anytime.',
          'digitaler-nachlass':
            'Easily adjust your digital legacy document to new life circumstances anytime.',
          konkubinatsvertrag:
            'Easily adjust your cohabitation agreement to new life circumstances anytime.',
        },
      },
      card5: {
        title: 'Legally compliant',
        description: {
          provisionLanding:
            'Create legally compliant documents, reviewed by experts and always up-to-date.',
          patientenverfuegung:
            'Create legally compliant personal healthcare directive, reviewed by experts and always up-to-date.',
          vorsorgeauftrag:
            'Create legally compliant powers of attorney, reviewed by experts and always up-to-date.',
          testament:
            'Create legally compliant wills, reviewed by experts and always up-to-date.',
          ehevertrag:
            'Create legally compliant marriage contracts, reviewed by experts and always up-to-date.',
          'digitaler-nachlass':
            'Create legally compliant digital legacy documents, reviewed by experts and always up-to-date.',
          konkubinatsvertrag:
            'Create legally compliant cohabitation agreements, reviewed by experts and always up-to-date.',
        },
      },
      card6: {
        title: 'Secure storage',
        description: {
          provisionLanding: 'Secure storage of all documents.',
          patientenverfuegung: 'Secure storage of living will.',
          vorsorgeauftrag: 'Secure storage of power of attorney.',
          testament: 'Secure storage of will.',
          ehevertrag: 'Secure storage of marriage contract.',
          'digitaler-nachlass': 'Secure storage of digital inheritance.',
          konkubinatsvertrag: 'Secure storage of cohabitation agreement.',
        },
      },
      card7: {
        title: 'Access for key individuals',
        description: {
          provisionLanding:
            'Notification and access to relevant documents for relatives, doctors, or executors at the right moment.',
          patientenverfuegung:
            'Notification and access to relevant living will for relatives, doctors, or executors at the right moment.',
          vorsorgeauftrag:
            'Notification and access to relevant power of attorney for relatives, doctors, or executors at the right moment.',
          testament:
            'Notification and access to relevant will for relatives, doctors, or executors at the right moment.',
          ehevertrag:
            'Notification and access to relevant marriage contract for relatives, doctors, or executors at the right moment.',
          'digitaler-nachlass':
            'Notification and access to relevant digital inheritance for relatives, doctors, or executors at the right moment.',
          konkubinatsvertrag:
            'Notification and access to relevant cohabitation agreement for relatives, doctors, or executors at the right moment.',
        },
      },
      card8: {
        title: 'Affordable',
        description: {
          provisionLanding:
            'Comprehensive and legally compliant provision at a fraction of the cost of a lawyer or notary.',
          patientenverfuegung:
            'Comprehensive and legally compliant living will at a fraction of the cost of a lawyer or notary.',
          vorsorgeauftrag:
            'Comprehensive and legally compliant power of attorney at a fraction of the cost of a lawyer or notary.',
          testament:
            'Comprehensive and legally compliant will at a fraction of the cost of a lawyer or notary.',
          ehevertrag:
            'Comprehensive and legally compliant marriage contract at a fraction of the cost of a lawyer or notary.',
          'digitaler-nachlass':
            'Comprehensive and legally compliant digital inheritance at a fraction of the cost of a lawyer or notary.',
          konkubinatsvertrag:
            'Comprehensive and legally compliant cohabitation agreement at a fraction of the cost of a lawyer or notary.',
        },
      },
    },
  },
  betterRegulatedLife: {
    title: 'A well-regulated life',
    provisionCheck: {
      title: 'Retirement check',
      subtitle:
        'We understand your life situation. Find out in two minutes what you need to organise your legal affairs pre retirement. We meet you where you are.',
      buttonLabel: 'Start provision check',
    },
    lifeSituationCheck: {
      title: 'Life situation check',
      subtitle:
        'Is a new life chapter approaching, or have you experienced a significant event and are thinking about how it might affect your long term planning?',
      buttonLabel: 'Start life situation check',
    },
  },
};
export default provisionLanding;
