import React, { memo, useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Stack, Typography, FormHelperText } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '#/components/shared/hook-form/styles/styled-radio-group';

type IProps = {
  name: string;
  question: {
    questionKey: string;
    question: string;
    answers?: { value: string; label: string }[];
  };
  disableOption?: (value: string) => boolean;
};

const RHFStyledRadioGroup = memo(function RHFStyledRadioGroup({
  name,
  question,
  disableOption,
}: IProps) {
  const { control, setValue } = useFormContext();
  const { translate } = useLocales();

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setValue(name, value, { shouldValidate: true });
    },
    [setValue, name]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledRadioGroup
          {...field}
          onChange={(event) => {
            handleOnChange(event);
            field.onChange(event);
          }}
          sx={{
            ...(error && {
              '& .MuiFormControlLabel-root': {
                borderColor: 'error.main',
              },
            }),
          }}
        >
          <Stack spacing={1}>
            <Typography variant="subtitle1" fontWeight="normal">
              {question.question}
            </Typography>

            {question.answers && (
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                spacing={2}
              >
                {question.answers.map((option) => (
                  <StyledFormControlLabel
                    key={`step-${question.questionKey}-${option.value}`}
                    value={option.value}
                    control={<StyledRadio />}
                    label={String(option.label)}
                    disabled={disableOption?.(option.value) || false}
                  />
                ))}
              </Stack>
            )}
          </Stack>

          {!!error && (
            <FormHelperText error sx={{ mx: 0 }}>
              {translate(error.message as string)}
            </FormHelperText>
          )}
        </StyledRadioGroup>
      )}
    />
  );
});

export default RHFStyledRadioGroup;
