import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useRef } from 'react';
import useLocales from '#/hooks/useLocales';
import { marriageQuestions } from '#/components/pages/MarriageContract/stepsData';
import {
  StyledRadioGroup,
  StyledFormControlLabel,
  StyledRadio,
} from '#/components/pages/PensionProvision/styles';
import { IMarriageContract } from '#/types/marriageContract';

export default function Marriage() {
  const { translate } = useLocales();

  const { watch, setValue, control } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const handleDefaultValue = (question: string) => {
    const value = watch(`${question}` as keyof IMarriageContract);
    if (value === null) return null;
    return value === true ? 'yes' : 'no';
  };

  const handleOnChange = (
    questionKey: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const isTrue = value === 'yes';
    if (!isTrue) {
      setValue(`${questionKey}`, false, { shouldValidate: true });
    } else setValue(questionKey, true, { shouldValidate: true });
  };

  const showChildren = (question: string) => {
    const value = watch(`${question}` as keyof IMarriageContract);
    return value === true;
  };

  const translatedQuestions = marriageQuestions.map((question, i) => ({
    ...question,
    question: String(
      translate(`marriageContract.stepTwo.questions.${i}.question`)
    ),
    answers: question.answers?.map((answer, j) => ({
      ...answer,
      label: String(
        translate(`marriageContract.stepTwo.questions.${i}.options.${j}.label`)
      ),
    })),
  }));

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Stack spacing={5}>
        {translatedQuestions.map((question, index) => (
          <div
            key={question.questionKey}
            ref={(ref) => {
              refs.current[`${question.questionKey}`] = ref;
            }}
          >
            <Controller
              name={question.questionKey}
              control={control}
              render={({ fieldState: { error } }) => (
                <StyledRadioGroup
                  name={question.questionKey}
                  value={handleDefaultValue(question.questionKey)}
                  onChange={(e) => {
                    handleOnChange(question.questionKey, e);
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                  >
                    {String(question.question)}
                  </Typography>
                  {question.answers && (
                    <Stack
                      direction={{
                        xs: 'column',
                        md: 'row',
                      }}
                      flexDirection="row"
                      spacing={2}
                    >
                      {question?.answers?.map((option) => (
                        <StyledFormControlLabel
                          key={`step-${question.id}-${option.value}`}
                          value={option.value}
                          control={<StyledRadio />}
                          label={String(option.label)}
                        />
                      ))}
                    </Stack>
                  )}
                  {!!error && (
                    <FormHelperText error={!!error} sx={{ mx: 0 }}>
                      {error && translate(error?.message as string)}
                    </FormHelperText>
                  )}
                </StyledRadioGroup>
              )}
            />
            <Stack sx={{ mt: 2 }}>
              {!question.answers && question.children && (
                <>{question.children.content()}</>
              )}
              {question.children && showChildren(`${question.questionKey}`) && (
                <>{question.children.content()}</>
              )}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}
