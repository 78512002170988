import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: '{{partnersGender}}',
  },
  {
    question: 'Vous',
  },
];

export const lifeSituation: IQuestion[] = [
  {
    question: 'En quelle année vous êtes-vous rencontrés ?',
  },
  {
    question: 'Depuis quand vivez-vous ensemble ?',
  },
  {
    question: 'Quelle situation décrit le mieux votre situation ?',
    options: [
      {
        label: 'Les deux travaillent, pas d\'enfants',
      },
      {
        label: 'L\'un travaille à plein temps, l\'autre à temps partiel; pas d\'enfants',
      },
      {
        label:
          'L\'un travaille à plein temps, l\'autre s\'occupe du ménage; avec enfants',
      },
    ],
  },
  {
    question: 'À quel nom est le contrat de location actuel ?',
    options: [
      {
        label: 'Vous',
      },
      {
        label: 'Partenaire',
      },
      {
        label: 'Les deux noms',
      },
    ],
  },
  {
    question: 'Y a-t-il des plans pour une modification du contrat ?',
    options: [
      {
        label: 'Oui, nous avons l\'intention de conclure un contrat de sous-location',
      },
      {
        label: 'Oui, nous prévoyons de créer un contrat aux deux noms',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Dans quelle banque avez-vous votre compte commun de ménage ?',
  // },
];
