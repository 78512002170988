import { Card, Typography } from '@mui/material';
import React from 'react';
import useLocales from '#/hooks/useLocales';
import SuccessorForm from '#/components/pages/User/successors/successor-form';

type Props = {
  closeModal?: () => void;
};
export default function AccountSuccessors({ closeModal }: Props) {
  const { translate } = useLocales();

  return (
    <Card
      sx={{ p: 3, display: 'flex', flexDirection: 'column', rowGap: '30px' }}
    >
      <Typography variant="subtitle1">
        {String(translate('global.userSettings.successor.title'))}
      </Typography>
      <Typography variant="subtitle1" fontWeight="normal">
        {String(translate('global.userSettings.successor.description'))}
      </Typography>

      <SuccessorForm closeModal={closeModal} />
    </Card>
  );
}
