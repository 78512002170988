import useLocales from '#/hooks/useLocales';
import Page from '#/components/shared/ui/Page';
import ConsultingMain from '#/components/pages/AdminConsulting/ConsultingMain';

export default function AdminConsulting() {
  const { translate } = useLocales();

  return (
    <Page title={translate('global.services.consulting')}>
      <ConsultingMain />
    </Page>
  );
}
