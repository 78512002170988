import {
  distributionQuestions,
  governanceQuestions,
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
} from './questions';
import outputDoc from './outputDoc';

const lastWill = {
  mainHeaders: {
    shortPainFree: 'Votre testament simple et efficace',
    madeIt: 'Votre testament efficace',
  },

  yes: 'Oui',
  no: 'Non',

  addAnotherChildren: "Ajouter d'autres enfants",
  areYouSureYouWantToDeleteChild:
    "Êtes-vous sûr de vouloir supprimer l'enfant ?",
  deleteInformerChild:
    'Si vous supprimez un enfant de la liste, toutes ses données seront supprimées',
  cancelBtn: 'Annuler',
  deleteBtn: 'Supprimer',
  childBirthDate: 'Date de naissance',

  noChildrenModal: {
    title: "Êtes-vous sûr de vouloir réinitialiser le nombre d'enfants ?",
    description:
      "Si vous réinitialisez le nombre d'enfants, toutes les informations sur vos enfants seront supprimées.",
    resetNumberBtn: 'Réinitialiser le nombre',
    cancelBtn: 'Annuler',
  },

  stepperInfo: [
    {
      label: 'Bénéficiaires',
      title: "De quoi s'agit-il et pourquoi est-ce si important ?",
    },

    {
      label: 'Distribution',
      title: 'Définir la distribution',
    },

    {
      label: 'Gouvernance',
      title: 'Définir la gouvernance',
    },

    {
      label: 'Votre dernier souhait',
      title: 'Déposez votre testament',
    },
  ],

  stepOne: {
    introParagraph:
      "Un testament vous offre la possibilité de donner à vos descendants des instructions claires sur vos souhaits en période difficile. Il vous permet également de vous assurer, dans le cadre des dispositions légales, que vos souhaits concernant la gestion de votre succession sont pris en compte. De plus, le testament vous permet d'éviter des discussions difficiles et émotionnelles au sein du système familial en nommant un exécuteur testamentaire.",
    helperParagraph:
      'En même temps, rédiger un testament n\'est pas une tâche facile pour vous personnellement, alors prenez votre temps et ne vous sentez pas stressé. Avec notre service de rappel, vous pouvez également faire une pause à tout moment et reprendre plus tard. Cliquez simplement sur le bouton "J\'ai terminé".',
    questions: {
      inheritorsMarriedVersion,
      inheritorsSingleVersion,
      inheritorsDivorcedVersion,
    },
    addAnotherBenefiter: 'Ajouter un bénéficiaire',
    isChildFromCommonPreviousMarriage:
      'Cet enfant est issu du mariage précédent',
    isChildFromCommonMarriage: 'Cet enfant est issu du mariage commun',
    isChildFromCommonRelationship: 'Cet enfant est issu du partenariat commun',
  },

  stepTwo: {
    introParagraph:
      'Que souhaitez-vous principalement accomplir avec ce testament ?',
    mainly: 'principalement',
    yesIHaveProperty:
      "Oui, j'ai des biens ou un/des appartement(s) que je souhaite donner à :",
    followingDistribution:
      "Oui, je souhaite que les biens de la succession suivants / la somme d'argent mentionnée ci-dessus soient transférés à la personne mentionnée ci-dessus :",
    questions: distributionQuestions,
  },
  stepThree: {
    questions: governanceQuestions,
    orAddSomeoneNew: "ou ajouter quelqu'un de nouveau",
    addAnotherExecutor: 'Exécuteur testamentaire',
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Félicitations ! Vous avez réussi.',
    helperParagraph:
      "En Suisse, l'exigence de forme écrite s'applique, ce qui signifie que pour rendre votre testament juridiquement valable, vous avez le choix :",
    helperParagraph2:
      'Il est également conseillé de déposer le testament chez un avocat et de le faire enregistrer au registre officiel des testaments suisses (https://www.ztr.ch/notariatsinfo/).',
    contactForm: 'Formulaire de contact',
    helperParagraph3:
      'Si vous souhaitez que nous vous aidions, veuillez utiliser notre formulaire de contact.',
    obligation1:
      'Une copie manuscrite du testament avec lieu, date et votre signature',
    obligation2:
      "Un testament public (procédure de lecture personnelle, art. 500 ss. CC ou procédure de lecture, art. 502 CC), pour cela vous avez besoin d'un notaire et de deux témoins",
    nextStepTitle: 'Les prochaines étapes :',
    nextStep1:
      'Soit recopier entièrement le testament à la main, le dater et le signer, soit le faire authentifier par un notaire et deux témoins (selon la procédure de lecture personnelle, art. 500 ss. CC ou la procédure de lecture, art. 502 CC) et enfin le télécharger ici',
    nextStep2:
      "Assurez-vous que des personnes de confiance ont un accès invité à gut geregelt, afin qu'elles puissent accéder au testament en cas d'urgence (cliquez ici)",
    highlightText: 'cliquez ici',
    nextStep3: 'Discutez éventuellement du testament avec la famille',
    highlightedText: {
      noterizeWithLawyer: 'faire authentifier par un notaire et deux témoins',
      linkToSettings: 'https://gutgeregelt.ch/user/account',
    },
    stopProcess: {
      title:
        'Si vous avez conclu un contrat de mariage, nous ne pouvons pas poursuivre ce processus.',
      title1:
        'Si vous choisissez "Non" ici, nous ne pouvons pas poursuivre ce processus.',
      description:
        'Nous vous recommandons de consulter un avocat pour un testament qui prend en compte un contrat de mariage. Vous trouverez ici un avocat pour vous aider',
      description1:
        'Si vous souhaitez choisir "Non", nous vous recommandons de rédiger un testament qui prend en compte le contrat de mariage. \n' +
        'Pour cela, vous devriez vous adresser directement à un avocat.\n' +
        'Nous vous orienterons volontiers vers un spécialiste.',
      changeAnswerBtn: 'Modifier la réponse',
      continueBtn: 'Terminer le processus et continuer avec un avocat',
    },
  },
  updateCivilStatusModal: {
    title: 'Souhaitez-vous mettre à jour votre état civil ?',
    description:
      'Modifier votre réponse mettra à jour votre état civil et réinitialisera votre document. Êtes-vous sûr de vouloir continuer ?',
    updateButton: 'Mettre à jour',
    cancelButton: 'Annuler',
  },

  checkChildrenRelationship: {
    title:
      'Étant donné que vous avez modifié votre statut relationnel actuel, les enfants associés seront supprimés',
    description:
      "Si vous avez des enfants issus d'une relation antérieure, veuillez les ajouter à nouveau",
    updateButton: 'Mettre à jour les informations sur les enfants',
    cancelButton: 'Annuler',
  },

  alternativeGoverner:
    "Si la personne que vous avez choisie ne peut pas agir en tant qu'exécuteur testamentaire, la personne suivante sera nommée :",
};

export default lastWill;
