import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: 'Your spouse',
  },
  {
    question: 'You',
  },
];

export const marriageQuestions: IQuestion[] = [
  {
    question: 'When did you get married?',
  },
  {
    question: 'Do you have children?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'How many?',
  // },
];

export const estateQuestions: IQuestion[] = [
  {
    question:
      'What assets did you and your spouse already own before the marriage (e.g., inheritances, items with high emotional value, etc.)?',
  },
  {
    question:
      'Is it correct that you acquired all other assets (not listed) after the marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Have you acquired real estate property after the marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Do you agree to maintain the ordinary marital property regime of participation in acquisitions?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'The spouses confirm that the surviving spouse receives their personal property (e.g., a car acquired before marriage or a gift from an aunt) and has the legally best possible choice regarding the remaining estate.',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'I want my spouse to have the right to freely dispose of a certain part of the estate.',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question:
  //     'Who should be the executor in the event of the simultaneous death of both spouses or the death of the last surviving spouse?',
  // },
  // {
  //   question:
  //     'If the person you chose cannot act as executor, the following person will be appointed:',
  // },
];