import { Box, Card, Container, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useLocales from '#/hooks/useLocales';
import InformativeCard from '#/components/shared/informative-card';
import { PROVISION_TOPICS } from '#/components/pages/PensionProvision/onboarding/data';
import useAuth from '#/hooks/useAuth';
import IntroHead from '#/components/shared/IntroHead';
import { invalidateProfileQueries, useUpdateProfile } from '#/api/userQueries';

export default function SecondStep() {
  const [selectedService, setSelectedService] = useState<string[] | null>(null);

  const { translate } = useLocales();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, refetch } = useAuth();
  const { mutateAsync: updateProfile, isLoading } = useUpdateProfile();

  const getTopicsForCivilStatus = () => {
    const baseTopics = PROVISION_TOPICS.filter((topic) =>
      [
        'vorsorgeauftrag',
        'patientenverfuegung',
        'testament',
        'digitaler-nachlass',
        'vermoegens-und-sachregister',
      ].includes(topic.value)
    );

    switch (user?.civil_status) {
      case 'married':
        return [
          ...baseTopics,
          PROVISION_TOPICS.find((topic) => topic.value === 'ehevertrag')!,
        ];
      default:
        return [
          ...baseTopics,
          PROVISION_TOPICS.find(
            (topic) => topic.value === 'konkubinatsvertrag'
          )!,
        ];
    }
  };

  const availableTopics = getTopicsForCivilStatus();

  const handleServiceSelect = (service: string) => {
    setSelectedService((prev) => {
      if (prev?.includes(service)) {
        return prev.filter((item) => item !== service);
      }
      return [...(prev || []), service];
    });
  };

  const checkIfServiceSelected = (service: string) =>
    selectedService?.includes(service);

  const isForMyself = user?.provision_for === 'self';
  const first_name = user?.first_name;
  const last_name = user?.last_name;

  const isDisabled = selectedService === null;

  const handleUpdateProfile = async () => {
    try {
      // @ts-ignore
      await updateProfile({ provision_check_done: selectedService });
      invalidateProfileQueries.getProfile();
      await refetch();
      navigate('/vorsorge/schritt=3');
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  if (user?.provision_check_done !== null) {
    return <Navigate to="/vorsorge/schritt=3" />;
  }

  return (
    <Container maxWidth="lg" sx={{ marginY: 12 }}>
      <IntroHead
        title={String(translate('pensionProvision.onboarding.stepTwo.title'))}
        description={String(
          translate('pensionProvision.onboarding.stepTwo.description')
        )}
      />
      <Card sx={{ p: 3, mt: 4 }}>
        <Box
          sx={{
            width: '100%',
            '& > div': {
              marginTop: 2,
            },
          }}
        >
          <Typography variant="subtitle1">
            {String(
              translate(
                `pensionProvision.onboarding.stepTwo.${
                  isForMyself ? 'subtitlePersonal' : 'subtitleOther'
                }`,
                {
                  first_name,
                  last_name,
                  genderFormal:
                    user?.gender === 'M'
                      ? translate(
                          'pensionProvision.onboarding.stepTwo.maleGenderBased'
                        )
                      : translate(
                          'pensionProvision.onboarding.stepTwo.femaleGenderBased'
                        ),
                }
              )
            )}
          </Typography>

          <Typography variant="subtitle1">
            {String(
              translate(
                `pensionProvision.onboarding.stepTwo.selectOnlyCompleted`
              )
            )}
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr 1fr',
                md: 'repeat(4, 1fr)',
              },
              gap: 2,
            }}
          >
            {availableTopics.map((topic) => (
              <InformativeCard
                cardTitle={String(translate(topic.title))}
                key={topic.value}
                onServiceSelect={() => handleServiceSelect(topic.value)}
                selected={checkIfServiceSelected(topic.value)}
              />
            ))}
            <InformativeCard
              cardTitle={String(
                translate(
                  'pensionProvision.onboarding.stepTwo.noCompletedService'
                )
              )}
              onServiceSelect={() => setSelectedService([])}
              inverse={selectedService?.length === 0}
              sx={{
                backgroundColor:
                  selectedService?.length === 0
                    ? theme.palette.primary.main
                    : theme.palette.background.paper,
                color: '#fff',
              }}
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              onClick={handleUpdateProfile}
              loading={isLoading}
              disabled={isDisabled}
            >
              <Typography>
                {String(
                  translate('pensionProvision.onboarding.stepTwo.seeResults')
                )}
              </Typography>
            </LoadingButton>
          </Stack>
        </Box>
      </Card>
    </Container>
  );
}
