import { treatmentQuestions, detailedQuestions } from './questions';
import outputDoc from './outputDoc';

const livingWill = {
  mainHeaders: {
    shortPainFree: 'Ihre einfache & effektive Patientenverfügung',
    madeIt: 'Ihre wirksame Patientenverfügung',
    detailedLivingWill: 'Toll gemacht, Sie haben es geschafft',
  },

  stepperInfo: [
    {
      label: 'Grundlagen',
      title: 'Was ist das Thema?',
    },
    {
      label: 'Vertrauensperson',
      title: 'Die Menschen, die sich um mich kümmern',
    },
    {
      label: 'Behandlungen',
      title: 'Wann soll es wirksam werden?',
    },
    // {
    //   label: '',
    //   title: 'Lassen Sie uns die Details klären',
    // },
    {
      label: 'Ihre Patientenverfügung',
      title: 'Hinterlegen Sie Ihre Patientenverfügung',
    },
  ],

  stepOne: {
    introParagraph:
      'Mit einer Patientenverfügung bestimmen Sie, wie Sie medizinisch behandelt und gepflegt werden wollen, falls Sie urteilsunfähig werden.\n' +
      '\n' +
      'Jeder urteilsfähige Mensch kann eine Patientenverfügung erstellen, ändern oder widerrufen. In der Patientenverfügung steht auch, wer sie im Ernstfall durchsetzen wird. Sie können eine Vertretungsperson bestimmen, die befugt ist, Entscheidungen für Sie zu treffen und die dem medizinischen Personal als Ansprechperson dient. Idealerweise setzen Sie eine Hauptperson sowie eine Ersatzperson ein',
    helperParagraph: 'Um zu beginnen, füllen Sie bitte folgendes aus:',
  },

  stepTwo: {
    introParagraph: 'Vertretungsperson',
    helperParagraph: 'Meine Vertrauensperson ist:',
    optionalRepresentative:
      'Falls die oben genannte Person verhindert ist, benenne ich die folgende Ersatzperson:',

    contactConfirmation:
      'Ich bestätige, dass ich die Vertretungsperson(en) über meine Wünsche informiert habe.',
  },

  stepThree: {
    introParagraph:
      'Um sicherzustellen, dass die Patientenverfügung Ihren Wünschen entspricht, müssen Sie nun einige anspruchsvolle und persönliche Fragen beantworten.',
    helperParagraph:
      'Sie müssen sich damit auseinandersetzen, wie Sie Lebensqualität definieren und in welchen Situationen Sie auf medizinische Eingriffe verzichten würden. Nehmen Sie sich dafür alle Zeit der Welt. Mit unserem Erinnerungsdienst können Sie jederzeit unterbrechen und zwischenspeichern.',
    questions: treatmentQuestions,
  },

  stepFour: {
    introParagraph:
      'Bitte lesen Sie die folgenden Szenarien sorgfältig durch und kreuzen Sie für jede Erkrankung das an, was am besten auf Sie zutrifft. Für jeden Gesundheitszustand möchten wir wissen, welche Behandlung Sie wünschen, um Ihr Leben zu verlängern und/oder Ihr Leiden zu lindern.',
    questions: detailedQuestions,
    highlightedText: {
      coma: 'Koma',
      dementia: 'Demenz',
      emergency: 'Notfall',
      goalOnSufferingRelief: 'Ziel der Leidenslinderung',
      prolongedIncapacity: 'verlängerte Urteilsunfähigkeit',
      permanentIncapacity: 'dauerhafte Urteilsunfähigkeit',
      suddenlyIncacitated: 'plötzlich urteilsunfähig',
      sufferingRelief: 'Leidenslinderung',
      suddenIncapacitation: 'Plötzliche Entmündigung',
      treatmentGoalForLifeExtension:
        'Behandlungsziel für die Lebensverlängerung',
      treatmentGoalsForLifeExtension:
        'Behandlungsziele für die Lebensverlängerung',
      treatmentGoalForSufferingRelief:
        'Behandlungsziel für die Linderung von Leiden',
      vegetativeState: 'vegetativer Zustand',
    },
  },

  stepFive: {
    introParagraph: 'Gut gemacht, Sie sind jetzt gut geregelt!',
    helperParagraph:
      'Der schwierigste Schritt ist nun geschafft. Jetzt müssen Sie nur noch die Patientenverfügung ausdrucken, datieren und unterschreiben und eine Kopie an Ihre Vertretungsperson/en (am besten persönlich) und Ihrem Hausarzt übergeben. Bitte besprechen Sie die Patientenverfügung zu gegebener Zeit auch mit Ihrer Familie.',
    here: 'hier',
    eightMinutes: '8 Minuten',
    helperParagraph2:
      'Wenn Sie eine ausführlichere Version der Vorsorgeauftrag Testament wünschen, können Sie hier fortfahren, der Zeitaufwand beträgt etwa 8 Minuten.',
    document: outputDoc,
    nextStepsTitle: 'Die nächsten Schritte:',
    nextStep1:
      'Patientenverfügung ausdrucken, datieren, unterschreiben und hier hochladen',
    nextStep2:
      'Kopie an Hausarzt übermitteln und sorgen Sie dafür, dass Vertrauenspersonen einen Gastzugang für gut geregelt haben, damit diese im Notfall auf die Vollmacht zugreifen können (hier klicken)',
    highlightedText: 'hier klicken',
    nextStep3: 'Patientenverfügung mit der Familie besprechen',
  },
};

export default livingWill;
