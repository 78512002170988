import { IQuestion } from '#/types/globalTypes';

export const financialInformationQuestions: IQuestion[] = [
  {
    question: 'Documents bancaires',
  },
  {
    question: 'Polices d\'assurance',
  },
  {
    question: 'Pension d\'État (AVS)',
  },
  {
    question: 'Caisse de pension',
  },
  {
    question: '3a',
  },
];

export const trustedPersonQuestions: IQuestion[] = [
  {
    question: 'Exécuteur testamentaire',
  },
  {
    question: 'Notaire/Fiduciaire',
  },
];
