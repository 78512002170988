const cohabitationAgreementLanding = {
  mainBanner: {
    intro: '',
    title: 'Régulez votre relation légalement avec un contrat de concubinage',
    subtitle:
      'De nombreux aspects de la sécurité sociale et juridique dans les unions libres ne sont pas réglementés par la loi. Avec un contrat de concubinage, vous bénéficiez des avantages fiscaux et clarifiez de nombreuses questions non résolues.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  youtubeTutorial: 'Qu\'est-ce qu\'un contrat de concubinage ?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Mesure proactive',
      description:
        'Protégez-vous et votre partenaire contre les problèmes et sujets non résolus dans l\'union libre.',
    },
    defineWishes: {
      title: 'Définissez vos souhaits',
      description:
        'Profitez de la marge de manœuvre en cas de séparation et de décès.',
    },
    benefitMaximally: {
      title: 'Profitez au maximum',
      description:
        'Bénéficiez des avantages d\'une cohabitation sécurisée - personnellement, financièrement et fiscalement.',
    },
  },
  subBanner: {
    title: 'Bien organisé avec un contrat de concubinage conforme à la loi',
    subtitle:
      'Avec gut geregelt, vous pouvez rapidement rédiger votre contrat de concubinage, l\'adapter et l\'inventaire associé à tout moment et ainsi clarifier légalement les questions ou problèmes sensibles afin de vous protéger mutuellement et de bénéficier de manière optimale de la cohabitation.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  newsletter: {
    title: 'Devenez bien organisé avec votre contrat de concubinage',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  faq: {
    questions: {
      question1: 'Qu\'est-ce qu\'un contrat de concubinage ?',
      question2: 'Quels avantages le concubinage offre-t-il par rapport au mariage ?',
      question3: 'Où le concubinage ne protège-t-il pas autant que le mariage ?',
      question4: 'Comment le concubinage protège-t-il la succession ou mon héritage ?',
      question5:
        'Ai-je droit aux prestations sociales de mon partenaire de vie ?',
      question6: 'Un contrat de concubinage nécessite-t-il une certification notariale ?',
      question7:
        'Pour quels aspects la loi prévoit-elle des protections avec le concubinage ?',
      question8:
        'Peut-on modifier ou résilier un contrat de concubinage à tout moment ?',
      question9: 'Que se passe-t-il avec le contrat de concubinage si l\'on se marie ?',
      question10: 'Comment régler la pension alimentaire en cas de séparation ?',
    },
    answers: {
      answer1:
        'Le contrat de concubinage est un contrat de droit privé entre deux personnes vivant en union libre, sans être mariées. Le contrat régit les droits et obligations des partenaires pendant la vie commune et en cas de séparation.',
      answer2: '',
      answer3: '',
      answer4:
        'Le concubinage n\'offre pas de protection légale de l\'héritage pour le partenaire. La succession doit être réglée par un testament ou un contrat de succession pour sécuriser le partenaire de vie. Sinon, le partenaire de concubinage n\'a pas droit à l\'héritage.',
      answer5:
        'Non, dans le concubinage, il n\'y a généralement pas de droit aux prestations sociales du partenaire de vie, comme par exemple la pension de veuve ou de veuf. Les prestations sociales comme l\'assurance maladie ou les droits à la retraite ne sont assurés que dans le cadre d\'un mariage, sauf s\'il existe des accords contractuels spécifiques. Certaines institutions de prévoyance offrent cependant la possibilité de déclarer le partenaire de vie et de faire valoir des droits en cas de décès.',
      answer6:
        'Un contrat de concubinage ne doit pas nécessairement être certifié par un notaire. Pour des règlements plus complexes, notamment concernant les biens immobiliers ou les questions de succession, une certification notariale peut être judicieuse. Pour quels aspects la loi prévoit-elle des protections avec le concubinage ?',
      answer7:
        'Oui, un contrat de concubinage peut être modifié ou résilié à tout moment d\'un commun accord entre les deux parties. Cela doit être consigné par écrit pour éviter des litiges ultérieurs.',
      answer8:
        'Avec le mariage, le contrat de concubinage devient caduc, car le mariage est entièrement réglementé par le Code civil suisse (CCS). Toutes les dispositions du contrat de concubinage sont remplacées par le droit matrimonial.',
      answer9:
        'Étant donné qu\'il n\'y a pas de droit légal à la pension alimentaire dans le concubinage, les partenaires doivent le régler eux-mêmes. Il est conseillé de consigner les accords correspondants dans le contrat de concubinage. Si aucun règlement n\'a été pris, une séparation peut entraîner des litiges juridiques où la pratique contractuelle générale s\'applique.',
    },
    lists: {
      answer1: {
        title:
          'Différence entre partenaire de vie et conjoint (Concubinage versus couple marié)',
        items: [
          {
            description:
              'Statut juridique : Les conjoints sont protégés légalement par le Code civil suisse (CCS), tandis que les partenaires de vie en concubinage n\'ont pas de droits et obligations légaux spécifiques.',
          },
          {
            description:
              'Droit à la pension alimentaire : Les conjoints ont des droits réciproques à la pension alimentaire, tandis que les partenaires de vie en concubinage ne l\'ont pas automatiquement.',
          },
          {
            description:
              'Droit de succession : Les conjoints ont un droit légal à l\'héritage, tandis que les partenaires de vie ne l\'ont pas, sauf disposition contraire par testament ou contrat de succession.',
          },
          {
            description:
              'Droit fiscal : Les conjoints sont imposés conjointement, tandis que les partenaires de vie continuent d\'être imposés séparément (ce qui entraîne un avantage fiscal pour les partenaires de concubinage).',
          },
          {
            description:
              'Assurances sociales : Les conjoints ont droit à certaines prestations sociales comme la pension de veuve ou de veuf ; les partenaires de concubinage n\'ont pas ce droit ou pas automatiquement. Selon l\'institution de prévoyance, les droits peuvent cependant être assurés sur déclaration du partenaire de vie. De plus, la rente AVS sera plus élevée pour les partenaires de concubinage que pour les conjoints.',
          },
        ],
      },
      answer2: {
        title: '',
        items: [
          {
            description:
              'Flexibilité : Les partenaires de concubinage peuvent organiser leur relation et leur mode de vie de manière plus libre et flexible, sans les exigences formelles d\'un mariage.',
          },
          {
            description:
              'Pas de divorce : Comme le concubinage n\'est pas un mariage juridiquement contraignant, il n\'y a pas de procédures de divorce complexes en cas de séparation.',
          },
          {
            description:
              'Impôts : Dans certains cas, le concubinage peut offrir des avantages fiscaux, car les partenaires sont imposés séparément.',
          },
        ],
      },
      answer3: {
        title: '',
        items: [
          {
            description:
              'Droit de succession : Les partenaires de vie n\'ont pas de droit légal à l\'héritage dans le concubinage.',
          },
          {
            description:
              'Pension alimentaire : Il n\'y a pas de droit légal à la pension alimentaire après une séparation.',
          },
          {
            description:
              'Assurances sociales : Les partenaires de vie ne sont pas automatiquement assurés socialement, par exemple en ce qui concerne la pension de veuve ou de veuf.',
          },
          {
            description:
              'Répartition des biens : Il n\'y a pas de règlement légal pour la répartition des biens en cas de séparation.',
          },
        ],
      },
      answer6: {
        title:
          'Le droit suisse prévoit des effets pour les domaines suivants :',
        items: [
          {
            description: 'Tutelle',
          },
          {
            description: 'Pension alimentaire',
          },
          {
            description: 'Représentation vis-à-vis des tiers',
          },
          {
            description: 'Biens',
          },
          {
            description: 'Relations avec les enfants',
          },
        ],
      },
    },
  },
};

export default cohabitationAgreementLanding;