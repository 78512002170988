const aboutUs = {
  heroTitle: 'gut geregelt – une entreprise suisse du Baselland',
  heroSubtitle:
    'gut geregelt AG est une startup de Sissach près de Bâle. Nous numérisons et démocratisons l\'accès à la prévoyance.',
  heroLabel: 'À propos de nous',
  heroButton: 'Contact',
  section1: {
    title: 'Nous sommes gut geregelt',
    subtitle:
      'gut geregelt est une entreprise jeune et dynamique avec des racines profondes en Suisse et dans le Baselland. Notre équipe est composée d\'experts dans les domaines du droit, de la finance, des assurances et du développement logiciel. Nous numérisons le processus de prévoyance et le rendons plus rapide, moins cher et plus accessible.',
  },
  section2: {
    title: 'Président : {{chairMan}}',
    subtitle:
      '{{chairMan}} est un entrepreneur en série suisse de Sissach. Il est président du conseil d\'administration et force motrice chez gut geregelt. Avec l\'équipe gut geregelt, Alain fait avancer le développement logiciel itératif, présente nos solutions aux banques et caisses de pension et est un conférencier recherché lors de divers événements dans le domaine bancaire et des startups.',
  },
  ourGoal: {
    label: 'Notre objectif',
    title: 'Rendre la prévoyance accessible',
    subtitle:
      'Chez gut geregelt, nous poursuivons la mission de numériser l\'ensemble du processus de prévoyance et de le représenter sur notre plateforme. En peu de temps, nous avons développé une solution complète qui est continuellement étendue. gut geregelt rend la prévoyance plus simple, plus compréhensible et plus accessible.',
  },
  section3: {
    title: 'Partie du groupe Betascale',
    subtitle:
      'gut geregelt AG est une entreprise du groupe suisse Betascale, basée à Sissach près de Bâle. Sous la devise "Building the ventures of tomorrow, today", Betascale a testé avec succès gut geregelt et l\'a positionné sur le marché. En peu de temps, nous nous sommes établis comme un partenaire fiable pour la prévoyance et avons lancé notre logiciel sur le marché.',
  },
  partners: {
    label: 'Nos partenaires',
    title: 'Nous comptons sur des partenaires solides',
    subtitle:
      'Des partenariats solides sont un élément essentiel de notre succès. Pour offrir à nos clients des solutions optimales, nous comptons sur la collaboration avec des entreprises et institutions leaders en Suisse.',
  },
};

export default aboutUs;