import { useForm } from 'react-hook-form';
import { Box, Button, Chip, MenuItem, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { FormProvider, RHFSelect } from '#/components/shared/hook-form';
import RHFAutocompleteTwo from '#/components/shared/hook-form/RHFAutocomplete2';
import useLocales from '#/hooks/useLocales';
import {
  invalidatePartnerQueries,
  useGetConsultantsList,
  useTransferUsers,
} from '#/api/partnerQueries';

type Props = {
  selectedUsers:
    | {
        id: number;
        first_name: string;
        last_name: string;
      }[]
    | undefined;
  onClose: () => void;
};

export default function UsersTransferForm({ selectedUsers, onClose }: Props) {
  const defaultValues = useMemo(
    () => ({
      ids: selectedUsers?.map((user) => String(user.id)) || [],
      destination_consultant: '',
    }),
    [selectedUsers]
  );

  const { data: consultants } = useGetConsultantsList();
  const methods = useForm({ defaultValues });
  const { translate } = useLocales();
  const { handleSubmit, reset } = methods;
  const { mutateAsync: transferUsers, isLoading: transferring } =
    useTransferUsers();

  const handleOnClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data: any) => {
    try {
      await transferUsers(data).then(() => {
        toast.success(translate('toast_notifications.success.userTransfer'));
        handleOnClose();
        invalidatePartnerQueries.consultantUserList();
      });
    } catch {
      toast.error(translate('toast_notifications.error.userTransfer'));
    }
  };

  const getUserFullName = (id: string) => {
    const user = selectedUsers?.find((user) => user.id === Number(id));
    return `${user?.first_name} ${user?.last_name}`;
  };

  useEffect(() => {
    if (selectedUsers) {
      reset(defaultValues);
    }
  }, [selectedUsers]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 1,
        }}
      >
        <RHFAutocompleteTwo
          name="ids"
          label={translate('global.formLabels.plusUser')}
          placeholder={translate('global.formLabels.plusUser')}
          multiple
          freeSolo
          options={selectedUsers?.map((option) => String(option.id)) || []}
          getOptionLabel={(option) => String(option)}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option}>
              {getUserFullName(option)}
            </MenuItem>
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={getUserFullName(option)}
                size="small"
                color="primary"
                variant="filled"
              />
            ))
          }
        />
        <RHFSelect
          name="destination_consultant"
          sx={{ mt: 2 }}
          label={translate('global.formLabels.newConsultant')}
        >
          {consultants?.map((consultant) => (
            <MenuItem key={consultant.user_id} value={consultant.user_id}>
              {consultant.first_name} {consultant.last_name}
            </MenuItem>
          ))}
        </RHFSelect>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
          alignSelf="stretch"
        >
          <Button variant="contained" color="inherit" onClick={handleOnClose}>
            <Typography>{translate('global.cancel')}</Typography>
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={transferring}
          >
            <Typography>{translate('global.save')}</Typography>
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
