const privacyPolicy = {
  title: 'Privacy policy',

  childrenUnderAge: {
    title: 'Children under 18',
    content: {
      p1: 'Our website is not intended for children under 18 years of age. Persons under 18 are not allowed to provide any information on or through the website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use this website or provide any information on this website or through its features. If we learn that we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at support@gutgeregelt.ch.',
    },
  },
  informationWeCollect: {
    title: 'Information we collect and how we collect it',
    content: {
      p1: 'We collect several types of information from users of our website, including information:',
      p2: '• By which you may be personally identified, such as your name or email address or any other identifier by which you may be contacted online or offline ("personal data");',
      p3: '• That is about you but individually does not identify you; and/or',
      p4: '• About your internet connection, the equipment you use to access our website, and usage details.',
      p5: 'We collect this information:',
      p6: '• Directly from you when you provide it to us.',
      p7: '• Automatically as you navigate through the website. Automatically collected information may include usage details, IP addresses, and information collected through cookies.',
      p8: '• From third parties, for example, our business partners.',
    },
  },
  informationYouProvide: {
    title: 'Information you provide',
    content: {
      p1: 'The information we collect on or through our website may include:',
      p2: '• Information that you provide by filling in forms on our website. This includes information provided at the time of registering to use our website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us and when you report a problem with our website.',
      p3: '• Records and copies of your correspondence (including email addresses), if you contact us.',
      p4: '• Your responses to surveys that we might ask you to complete for research purposes.',
      p5: '• Your search queries on the website.',
      p6: 'You may also provide information to be published or displayed (hereinafter, "posted") on public areas of the website, or transmitted to other users of the website or third parties (collectively, "User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.',
    },
  },
  informationWeCollectThroughAutomaticData: {
    title:
      'Information We Collect Through Automatic Data Collection Technologies',
    content: {
      p1: 'As you navigate through and interact with our website, we may use automatic data collection technologies to gather certain information about your devices, browsing actions, and patterns, including:',
      p2: '• Details of your visits to our website, including traffic data, location data, logs, and other communication data, as well as the resources that you access and use on the website.',
      p3: '• Information about your computer and internet connection, including your IP address, operating system, and browser type.',
      p4: 'The information we collect automatically may include personal data. We collect it to improve our website and deliver a better and more personalized service, for example by:',
      p5: '• Estimating our audience size and usage patterns.',
      p6: '• Storing information about your preferences, allowing us to customize our website according to your individual interests.',
      p7: '• Speeding up your searches.',
      p8: '• Recognizing you when you return to our website.',
      p9: 'The technologies we use for this automatic data collection may include:',
      p10: '• Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You can refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting, you may be unable to access certain parts of our website. Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue cookies when you direct your browser to our website.',
      p11: '• Flash cookies. Certain features of our website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our website. Flash cookies are not managed by the same browser settings as are used for browser cookies.',
    },
  },
  informationVoluntarily: {
    title: 'Information you voluntarily provide for the use of services',
    content: {
      p1: 'We collect information that you voluntarily provide to us to fulfill a request, such as when you register to use our services and website, request support from our support team, contact us via email, fill out a contact form, wish to use our services, or otherwise communicate with us. This information includes your first and last name, your company name, physical address, email address, and certain types of business information about your company, such as customer payment information, revenue, website analytics information, customer churn, customer lifetime value, and other metrics and data, as well as any other information you provide to us regarding your company.',
      p2: 'Especially after registering to use our services as a start-up, if you wish to display data from your accounts with third-party tools and applications such as Stripe, Google Analytics, ChartMogul, Baremetrics, and ProfitWell on your information page for potential buyers by logging into your account on the authorization page of such third-party tools and applications or by providing us with your API keys or view IDs on such third-party tools and applications, you authorize us and grant us read access to specific information in your accounts with such third parties. Below are the types of information we may collect from such third parties with your authorization:',
      p3: '• Stripe – Read-only access to customer revenue data for the last 12 months',
      p4: '• Google Analytics – Read-only access to website traffic data for the last 12 months',
    },
  },
  useOfCookies: {
    title: 'Use of cookies by third parties',
    content: {
      p1: 'Some content or applications on the website are provided by third parties, such as content providers and application providers like Google Analytics and Hubspot, as mentioned above. These third parties use cookies to collect information about you when you use our website. The information they collect may be linked to your personal information, or they may collect information, including personal information, about your online activities over time and across different websites and other online services.',
    },
  },
  howWeUseYourInformation: {
    title: 'How we use your information',
    content: {
      p1: 'We use information that we collect about you or that you provide to us, including any personal information:',
      p2: '• To present our website and its contents to you.',
      p3: '• To facilitate the listing of your buyer, seller, or advisor profile on the website.',
      p4: '• To provide you with information, products, or services that you request from us, including but not limited to (for startup users) the display of authorized business information of startups for certain potential buyers.',
      p5: '• To fulfill any other purpose for which you provide it.',
      p6: '• To send you notices about your account, including expiration and renewal notices.',
      p7: '• To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.',
      p8: '• To notify you about changes to our website or any products or services we offer or provide through it.',
      p9: '• To allow you to participate in interactive features on our website.',
      p10: '• In any other way we may describe when you provide the information.',
      p11: '• For any other purpose with your consent.',
      p12: 'With your consent, we will use your information to inform you about our own goods and services as well as those of third parties that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see "Your Rights and Controls Regarding Information" below.',
    },
  },
  saleOfPersonalInformation: {
    title: 'Sale of personal information',
    content: {
      p1: 'Please note that we do not sell your personal information to third parties.',
    },
  },

  disclosureOfYourInformation: {
    title: 'Disclosure of your information',
    content: {
      p1: 'We may disclose aggregated information about our users, as well as information that does not identify any individual, without restriction.',
      p2: 'We may disclose personal information that we collect or you provide as described in this privacy policy:',
      p3: '• To our subsidiaries and affiliates to facilitate the provision of our services.',
      p4: '• To contractors, service providers, and other third parties we use to support our business and who are contractually obligated to keep personal information confidential and use it only for the purposes for which we disclose it to them.',
      p5: "• To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the company's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by the company about our website users is among the assets transferred.",
      p6: '• To fulfill the purpose for which you provide it.',
      p7: '• For any other purpose disclosed by us when you provide the information.',
      p8: '• With your consent.',
      p9: 'We may also disclose your personal information:',
      p10: '• To comply with any court order, law, or legal process, including responding to any government or regulatory request.',
      p11: '• To enforce or apply our terms of use and other agreements, including for billing and collection purposes.',
      p12: '• If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the company, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.',
    },
  },
  informationRights: {
    title: 'Your information rights and controls',
    content: {
      p1: 'The General Data Protection Regulation ("GDPR") grants individuals rights over their personal information. While the following rights are specific to members of the European Economic Area (EEA), we strive to provide all our users with the same rights as EEA members under the GDPR whenever possible.',
      p2: 'Please familiarize yourself with your rights and their descriptions in the following table.',
      p3: 'You have the right to:',
      p4: 'Access: Be informed about the personal data we process about you and request access to it.',
      p5: 'Rectification: Request that we update or correct inaccurate or incomplete data about you.',
      p6: 'Erasure: Request that we delete your data.',
      p7: 'Restriction of Processing: Ask us to restrict the processing of your personal data.',
      p8: 'Data Portability: Request that we provide you or a third party with a copy of your personal data in a structured, commonly used, and machine-readable format.',
      p9: 'Objection: Object to the processing of your personal data.',
      p10: 'Objection to Marketing: Object to the processing of your personal data for marketing purposes.',
      p11: 'No Automated Decision-Making: Not be subject to a decision based solely on automated decision-making (decisions without human involvement), including profiling, if the decision would have legal effects on you or similarly significant effects on you.',
    },
  },
  exerciseRights: {
    title: 'How to exercise your rights with us',
    content: {
      p1: 'To access: Make a verifiable request for information about the personal data we have collected about you, please send an email to support@gutgeregelt.ch. Please note that nearly all the information we collect from you is contained in your account profile on the platform.',
      p2: 'For rectification: You can edit your personal information in your account profile on our platform.',
      p3: 'For erasure: You can exercise this right by sending an email to support@gutgeregelt.ch. Please note that we will delete your personal data:',
      p4: '• We may retain your personal data as long as it is necessary for our legitimate business interests, such as preventing money laundering, detecting and preventing fraud, and enhancing security. For example, if we suspend a gut geregelt account for fraud or security reasons, we may retain information from that gut geregelt account to prevent the person from opening a new gut geregelt account in the future.',
      p5: '• We may retain and use your personal data as necessary to comply with our legal obligations. Gut geregelt may retain your information for tax, legal reporting, and auditing obligations or as a record of a dispute with you.',
      p6: '• Some copies of your information (e.g., emails) may remain in our database but are separated from personal identifiers and retained only for documentation purposes.',
      p7: 'For restriction: You can exercise this right by sending an email to support@gutgeregelt.ch or by ceasing to use the platform.',
      p8: 'For objection: You can exercise this right by sending an email to support@gutgeregelt.ch.',
      p9: 'For data portability: You can exercise this right by sending an email to support@gutgeregelt.ch.',
      p10: 'For automated decision-making: You can exercise this right by sending an email to support@gutgeregelt.ch. Please note, however, that we do not conduct automated decision-making. All decisions regarding your profile are made internally by our support staff.',
      p11: 'You also have the right to contact your local data protection authority with questions or concerns.',
    },
  },
  dataStorage: {
    title: 'Data storage and deletion',
    content: {
      p1: 'In accordance with the GDPR and other relevant regulations, we store your personal data only as long as necessary to provide the services available on the gut geregelt platform and for our legitimate and essential business purposes.',
    },
  },
  deletion: {
    title: 'Deletion',
    content: {
      p1: 'If you close your account or ask us to close it, we will delete, anonymize, or modify your personal data so that it no longer identifies you, unless we are legally required to retain it or need to use it for legally justified reasons.',
      p2: 'Here are some examples of situations where we are legally permitted or required to retain some of your personal data:',
      p3: '• If there is an unresolved issue related to your account, such as an outstanding payment or an unresolved complaint or dispute.',
      p4: '• For our legal, tax, audit, and accounting obligations.',
      p5: '• For our legitimate business interests, such as fraud prevention or maintaining security.',
      p6: 'For more information, see the section on deletion above.',
      p7: 'In some cases, we may anonymize or modify your personal data so that it is no longer identifiable, to prevent fraud or determine if you are a previous user when you re-register for the gut geregelt platform.',
      p8: 'We have implemented measures to protect your personal data from accidental loss and unauthorized access, use, alteration, and disclosure.',
      p9: 'The security of your information also depends on you. If we have given you (or if you have chosen) a password for access to certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Please be careful when sharing information in public areas of the website, such as forums. The information you share in public areas can be viewed by any user of the website.',
      p10: 'Unfortunately, the transmission of information over the internet is not completely secure. Although we do our best to protect your personal data, we cannot guarantee the security of your personal data transmitted to our website. Any transmission of personal data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the website.',
    },
  },
  changes: {
    title: 'Changes to our privacy policy',
    content: {
      p1: "It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal data, we will notify you through a notice on the homepage of the website. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our website and this privacy policy to check for any changes.",
    },
  },
  contantInformation: {
    title: 'Contact information',
    content: {
      p1: 'If you have any questions or comments about this privacy policy and our privacy practices, contact us and our Data Protection Officer at support@gutgeregelt.ch.',
      p2: 'To register a complaint or concern, please send an email to support@gutgeregelt.ch.',
    },
  },
};

export default privacyPolicy;
