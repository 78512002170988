import {
  Avatar,
  Badge,
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useCallback, useState } from 'react';
import Iconify from '#/components/shared/ui/Iconify';
import { IChatParticipant } from '#/types/chat';
import useBoolean from '#/lib/hooks/useBoolean';
import Scrollbar from '#/components/shared/Scrollbar';
import ChatRoomParticipantDialog from './chat-room-participant-dialog';

type Props = {
  participants: IChatParticipant[];
};

export default function ChatRoomGroup({ participants }: Props) {
  const [selected, setSelected] = useState<IChatParticipant | null>(null);

  const totalParticipants = participants.length;

  const collapse = useBoolean(true);

  const handleOpen = useCallback((participant: IChatParticipant) => {
    setSelected(participant);
  }, []);

  const handleClose = () => {
    setSelected(null);
  };

  const renderBtn = (
    <ListItemButton
      onClick={collapse.onToggle}
      sx={{
        pl: 2.5,
        pr: 1.5,
        height: 40,
        flexShrink: 0,
        flexGrow: 'unset',
        typography: 'overline',
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Box component="span" sx={{ flexGrow: 1 }}>
        In room ({totalParticipants})
      </Box>
      <Iconify
        width={16}
        icon={
          collapse.value
            ? 'eva:arrow-ios-downward-fill'
            : 'eva:arrow-ios-forward-fill'
        }
      />
    </ListItemButton>
  );

  const renderContent = (
    <Scrollbar sx={{ height: 56 * 4 }}>
      {participants.map((participant) => (
        <ListItemButton
          key={participant.id}
          onClick={() => handleOpen(participant)}
        >
          <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Avatar
              alt={`${participant.first_name} ${participant.last_name}`}
              src={participant.avatar_url}
            />
          </Badge>

          <ListItemText
            sx={{ ml: 2 }}
            primary={`${participant.first_name} ${participant.last_name}`}
            secondary={participant.role}
            primaryTypographyProps={{
              noWrap: true,
              typography: 'subtitle2',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              component: 'span',
              typography: 'caption',
            }}
          />
        </ListItemButton>
      ))}
    </Scrollbar>
  );

  return (
    <>
      {renderBtn}

      <div>
        <Collapse in={collapse.value}>{renderContent}</Collapse>
      </div>

      {selected && (
        <ChatRoomParticipantDialog
          participant={selected}
          open={!!selected}
          onClose={handleClose}
        />
      )}
    </>
  );
}
