import {
  Box,
  Button,
  Stack,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from '#/components/shared/hook-form';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  invalidateSuccessorQueries,
  useDeleteInvitation,
  useGetInvitationsList,
  useUpdateInvitation,
} from '#/api/successor';
import { Successor } from '#/types/user';
import AdvancedAccessModal from '#/components/pages/User/successors/advanced-access-modal';
import {
  AccessRightOptions,
  INITIAL_SPECIFIC_SERVICE_ACCESS,
} from '#/components/pages/User/successors/schema-validation';

type SuccessorInputProps = {
  index: number;
  remove: UseFieldArrayRemove;
  id: number;
  showDeleteButton: (email: string) => boolean | undefined;
};

export default function SuccessorInput({
  index,
  remove,
  id,
  showDeleteButton,
}: SuccessorInputProps) {
  const [openAccessModal, setOpenAccessModal] = useState<boolean>(false);

  const { translate } = useLocales();
  const { watch, setValue } = useFormContext();

  const handleOpenAccessModal = () => setOpenAccessModal(true);
  const handleCloseAccessModal = () => setOpenAccessModal(false);

  const { data: list } = useGetInvitationsList('SUCCESSOR');
  const { mutateAsync: deleteInvitation, isLoading } = useDeleteInvitation(id);

  const { mutateAsync: updateInvitation } = useUpdateInvitation(id);

  const handleDeleteInvitation = async () => {
    await deleteInvitation(id)
      .then(() => {
        remove(index);
        invalidateSuccessorQueries.getInvitationsList();
      })
      .catch(() => {
        remove(index);
      });
  };

  const handleUpdateInvitation = async () => {
    await updateInvitation({
      ...watch(`invitations[${index}]`),
    });
  };

  const hasSpecificAccess = watch(`invitations[${index}].has_specific_access`);
  const changedGeneralAccessRight = watch(
    `invitations[${index}].general_access_right`
  );

  useEffect(() => {
    if (hasSpecificAccess === false) {
      setValue(`invitations[${index}].specific_service_access`, [
        INITIAL_SPECIFIC_SERVICE_ACCESS,
      ]);
    }
    if (id && (hasSpecificAccess !== undefined || changedGeneralAccessRight)) {
      handleUpdateInvitation();
    }
  }, [hasSpecificAccess, id, changedGeneralAccessRight]);

  return (
    <Box
      sx={{
        marginBottom: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Stack
        spacing={2}
        alignItems="center"
        direction={{
          xs: 'column',
          sm: 'row',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: 544,
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <RHFTextField
              name={`invitations[${index}].first_name`}
              label={String(translate('global.formLabels.firstName'))}
              disabled={
                list?.some(
                  (invitation: Successor) =>
                    invitation?.first_name ===
                    watch(`invitations[${index}].first_name`)
                ) && true
              }
            />
            <RHFTextField
              name={`invitations[${index}].last_name`}
              label={String(translate('global.formLabels.lastName'))}
              disabled={
                list?.some(
                  (invitation: Successor) =>
                    invitation?.last_name ===
                    watch(`invitations[${index}].last_name`)
                ) && true
              }
            />
          </Stack>
        </Box>

        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          spacing={1}
          sx={{
            width: {
              xs: '100%',
              sm: 544,
            },
          }}
        >
          <RHFTextField
            name={`invitations[${index}].email`}
            label={String(translate('global.formLabels.emailAddress'))}
            disabled={
              list?.some(
                (invitation: Successor) =>
                  invitation?.email === watch(`invitations[${index}].email`)
              ) && true
            }
          />

          {showDeleteButton(watch(`invitations[${index}].email`) as string) && (
            <Button
              type="button"
              size="small"
              color="error"
              onClick={handleDeleteInvitation}
            >
              {isLoading ? (
                <Iconify
                  icon="line-md:loading-twotone-loop"
                  width={20}
                  height={20}
                />
              ) : (
                <Iconify
                  icon="material-symbols:delete-outline"
                  width={20}
                  height={20}
                />
              )}
            </Button>
          )}
        </Stack>
      </Stack>
      <RHFSelect
        name={`invitations[${index}].general_access_right`}
        label={translate('global.userSettings.successor.access.select_label')}
        sx={{ width: '49.5%' }}
      >
        {AccessRightOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Typography sx={{ textTransform: 'none' }}>
              {String(translate(option.label))}
            </Typography>
          </MenuItem>
        ))}
      </RHFSelect>
      <Stack direction="row" alignItems="center">
        <RHFSwitch
          label={translate('global.userSettings.successor.access.switch_label')}
          name={`invitations[${index}].has_specific_access`}
          labelPlacement="start"
        />
        <IconButton
          disabled={!watch(`invitations[${index}].has_specific_access`)}
          onClick={handleOpenAccessModal}
        >
          <Iconify
            icon="akar-icons:settings-horizontal"
            width={20}
            height={20}
            color={(theme) =>
              watch(`invitations[${index}].has_specific_access`)
                ? theme.palette.grey[900]
                : theme.palette.action.disabled
            }
          />
        </IconButton>
      </Stack>

      <AdvancedAccessModal
        open={openAccessModal}
        nestIndex={index}
        closeModal={handleCloseAccessModal}
      />
    </Box>
  );
}
