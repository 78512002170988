import { treatmentQuestions, detailedQuestions } from './questions';
import outputDoc from './outputDoc';

const livingWill = {
  mainHeaders: {
    shortPainFree: 'Votre directive anticipée simple et efficace',
    madeIt: 'Votre directive anticipée efficace',
    detailedLivingWill: 'Bien joué, vous avez réussi',
  },

  stepperInfo: [
    {
      label: 'Fondamentaux',
      title: 'Quel est le sujet ?',
    },
    {
      label: 'Exécuteur testamentaire',
      title: "Les personnes qui s'occupent de moi",
    },
    {
      label: 'Traitements',
      title: 'Quand doit-elle prendre effet ?',
    },
    // {
    //   label: '',
    //   title: 'Clarifions les détails',
    // },
    {
      label: 'Votre directive anticipée',
      title: 'Déposez votre directive anticipée',
    },
  ],

  stepOne: {
    introParagraph:
      'Avec une directive anticipée, vous déterminez comment vous souhaitez être traité et soigné médicalement si vous devenez incapable de discernement.\n' +
      '\n' +
      "Toute personne capable de discernement peut rédiger, modifier ou révoquer une directive anticipée. La directive anticipée précise également qui la fera respecter en cas d'urgence. Vous pouvez désigner une personne de confiance habilitée à prendre des décisions pour vous et à servir de contact pour le personnel médical. Idéalement, vous désignez une personne principale ainsi qu'une personne de remplacement.",
    helperParagraph: 'Pour commencer, veuillez remplir ce qui suit :',
  },

  stepTwo: {
    introParagraph: 'Personne de confiance',
    helperParagraph: 'Ma personne de confiance est :',
    optionalRepresentative:
      'Si la personne mentionnée ci-dessus est empêchée, je désigne la personne de remplacement suivante :',

    contactConfirmation:
      'Je confirme avoir informé la/les personne(s) de confiance de mes souhaits.',
  },

  stepThree: {
    introParagraph:
      'Pour vous assurer que la directive anticipée correspond à vos souhaits, vous devez maintenant répondre à quelques questions exigeantes et personnelles.',
    helperParagraph:
      'Vous devez réfléchir à la façon dont vous définissez la qualité de vie et dans quelles situations vous renonceriez à des interventions médicales. Prenez tout le temps nécessaire. Avec notre service de rappel, vous pouvez interrompre et sauvegarder à tout moment.',
    questions: treatmentQuestions,
  },

  stepFour: {
    introParagraph:
      'Veuillez lire attentivement les scénarios suivants et cochez pour chaque maladie ce qui vous correspond le mieux. Pour chaque état de santé, nous souhaitons savoir quel traitement vous souhaitez pour prolonger votre vie et/ou soulager vos souffrances.',
    questions: detailedQuestions,
    highlightedText: {
      coma: 'Coma',
      dementia: 'Démence',
      emergency: 'Urgence',
      goalOnSufferingRelief: 'Objectif de soulagement des souffrances',
      prolongedIncapacity: 'Incapacité prolongée',
      permanentIncapacity: 'Incapacité permanente',
      suddenlyIncacitated: 'Incapacité soudaine',
      sufferingRelief: 'Soulagement des souffrances',
      suddenIncapacitation: 'Incapacité soudaine',
      treatmentGoalForLifeExtension:
        'Objectif de traitement pour prolonger la vie',
      treatmentGoalsForLifeExtension:
        'Objectifs de traitement pour prolonger la vie',
      treatmentGoalForSufferingRelief:
        'Objectif de traitement pour soulager les souffrances',
      vegetativeState: 'État végétatif',
    },
  },

  stepFive: {
    introParagraph:
      'Bien joué, vous êtes maintenant bien organisé avec gut geregelt!',
    helperParagraph:
      "L'étape la plus difficile est maintenant terminée. Vous devez maintenant imprimer, dater et signer la directive anticipée et en remettre une copie à votre/vos personne(s) de confiance (de préférence en personne) et à votre médecin traitant. Veuillez également discuter de la directive anticipée avec votre famille en temps voulu.",
    here: 'ici',
    eightMinutes: '8 minutes',
    helperParagraph2:
      "Si vous souhaitez une version plus détaillée du mandat pour cause d'inaptitude, vous pouvez continuer ici, cela prend environ 8 minutes.",
    document: outputDoc,
    nextStepsTitle: 'Les prochaines étapes :',
    nextStep1:
      'Imprimer, dater, signer la directive anticipée et la télécharger ici',
    nextStep2:
      "Transmettre une copie au médecin traitant et s'assurer que les personnes de confiance ont un accès invité à gut geregelt, afin qu'elles puissent accéder au mandat en cas d'urgence (cliquer ici)",
    highlightedText: 'cliquer ici',
    nextStep3: 'Discuter de la directive anticipée avec la famille',
  },
};

export default livingWill;
