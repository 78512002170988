import { useFormContext, Controller } from 'react-hook-form';
import { Box, TextField, TextFieldProps, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO, format } from 'date-fns';
import useLocales from '#/hooks/useLocales';

type IProps = {
  name: string;
  label?: string;
  disableFuture?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
};

type Props = IProps & TextFieldProps;

export default function RHFDatePicker({
  name,
  label,
  disableFuture,
  ...props
}: Props) {
  const { control } = useFormContext();
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      // {...props}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: 1 }}>
          <DatePicker
            maxDate={new Date()}
            disableFuture={disableFuture}
            label={label || 'Date'}
            format="dd.MM.yyyy"
            value={field.value ? parseISO(field.value) : null}
            onChange={(date) => {
              const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
              field.onChange(formattedDate);
            }}
            sx={{
              width: '100%',
              '& .MuiInputLabel-root': {
                color: error ? 'red' : theme.palette.text.secondary,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error ? 'red' : theme.palette.divider,
                },
              },
            }}
            slots={{
              textField: TextField,
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error ? translate(error?.message as string) : '',
                ...props,
              },
            }}
          />
          {/* {error && (
            <FormHelperText error={!!error}>
              {String(translate(error.message || 'global.required'))}
            </FormHelperText>
          )} */}
        </Box>
      )}
    />
  );
}
