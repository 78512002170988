const outputDoc = {
  title: 'Testament',
  from: 'de',
  born: ' né le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié',
    widowed: 'Veuf',
    divorced: 'Divorcé',
    registered_partnership: 'Partenariat enregistré',
  },
  civil_status: 'État civil : {{civil_status}}',
  address: 'Adresse : {{address}}',
  declare: 'Je, {{first_name}} {{last_name}}, déclare ce qui suit :  ',

  paragraph1: 'Je révoque tous les testaments que j\'ai rédigés auparavant.',

  paragraph2:
    'Les dettes en cours et les frais de décès doivent être payés sur la succession.',

  paragraph3: '{{civil_status}}',

  receivesNothing: 'ne reçoit rien.',

  and: 'et',

  p4MarriedSpouse: {
    receives: 'reçoit',
    marriedStatus: {
      male: 'Mon épouse',
      female: 'Mon époux',
    },
    livingPartnershipStatus: {
      male: 'Ma partenaire enregistrée',
      female: 'Mon partenaire enregistré',
    },
    partnershipContract: {
      yes: 'juridiquement tout le bien commun et héréditairement tous les actifs dans le cadre de la part librement disponible',
      no: 'juridiquement tout le bénéfice et héréditairement tous les actifs dans le cadre de la part librement disponible',
    },
    quota: 'Quote-part',
    noContractButChildren: {
      main: 'seulement en usufruit la part légale qui revient à {{children}}',
      oneChild: 'l\'enfant',
      multipleChildren: 'les enfants',
      receive: ' revient.',
    },
    propertyContractAndChildren: {
      main: 'seulement en usufruit la part légale qui revient à {{children}}',
      oneChild: 'l\'enfant reçoit',
      multipleChildren: 'les enfants revient.',
    },
    born: ', né le {{birthday}}, ',
  },

  p4UnmarriedPartner: {
    receives:
      'reçoit tous les actifs dans le cadre de la part librement disponible.',
    receivesProperty: 'reçoit notamment aussi',
    theProperty: 'le bien immobilier',
    atTheAddress: 'à l\'adresse',
    assignedToProperty: 'attribué en propriété.',
    born: ', né le {{birthday}}, ',
    genderStatus: {
      male: 'Ma partenaire de vie',
      female: 'Mon partenaire de vie',
      male1: 'ma partenaire de vie',
      female1: 'mon partenaire de vie',
      registeredPartnerMale: 'Ma partenaire de vie',
      registeredPartnerFemale: 'Mon partenaire de vie',
    },
  },

  p5UnmarriedPartnerWithChildren: {
    commonPartnership: {
      oneChild: {
        main: 'Notre enfant commun, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Nos enfants communs, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou tous leurs descendants reçoivent chacun la part légale.',
      },
      and: 'et',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mon enfant non commun, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs, ',
        born: ', né le {{birthday}}, ',
        receive:
          'ou tous leurs descendants reçoivent chacun à parts égales la part légale.',
      },
    },
    noPartnership: {
      oneChild: {
        main: 'Mon enfant, ',
        born: ', né le {{birthday}}, ',
        receive: ' ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Mes enfants, ',
        born: ', né le {{birthday}}, ',
        receive: ' ou tous leurs descendants reçoivent chacun la part légale.',
      },
    },
    deathCase:
      'Si je décède en même temps que {{partner}}, alors {{childrenCase}}',
    born: ', né le {{birthday}}, ',
    childrenCase: {
      oneChild: {
        fromCommonMarriage: 'notre enfant commun reçoit, ',
        fromOtherMarriage: 'notre enfant non commun reçoit, ',
        fromUnknownMarriage: 'mon enfant reçoit,',
      },
      onesDescendants: 'ou ses descendants tous les actifs.',
      multipleChildren: {
        fromCommonMarriage: 'nos enfants communs reçoivent, ',
        fromOtherMarriage: 'nos enfants non communs reçoivent, ',
        fromUnknownMarriage: 'mes enfants reçoivent, ',
      },
      allDescendants: 'ou tous leurs descendants chacun tous les actifs.',
    },
  },

  p4Child: {
    commonMarriage: {
      oneChild: {
        main: 'Notre enfant commun, ',
        born: ', né le {{birthday}}, ',
        receive:
          'ou tous ses descendants reçoivent chacun à parts égales tous les actifs dans le cadre de la part librement disponible. ',
      },
      multipleChildren: {
        main: 'Nos enfants communs, ',
        born: ', né le {{birthday}}, ',
        receive:
          'ou tous leurs descendants reçoivent chacun à parts égales tous les actifs dans le cadre de la part librement disponible. ',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'Mon enfant non commun, ',
        born: ', né le {{birthday}}, ',
        receive:
          'ou tous ses descendants reçoivent chacun à parts égales tous les actifs dans le cadre de la part librement disponible. ',
      },
      multipleChildren: {
        main: 'Mes enfants non communs, ',
        born: ', né le {{birthday}}, ',
        receive:
          'ou tous leurs descendants reçoivent chacun à parts égales tous les actifs dans le cadre de la part librement disponible. ',
      },
      and: 'et',
    },
    partnerCase: {
      male: 'Mon épouse',
      female: 'Mon époux',
      born: ', né le {{birthday}}, ',
      receivesShare: 'reçoit la part légale.',
    },
  },

  unmarriedChildrenChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Notre enfant commun, ',
        born: ', né le {{birthday}}, ',
        receives:
          'ou tous ses descendants reçoivent chacun à parts égales tous les actifs',
      },
      multipleChildren: {
        main: 'Nos enfants communs, ',
        born: ', né le {{birthday}}, ',
        receives:
          'ou tous leurs descendants reçoivent chacun à parts égales tous les actifs',
      },
      quota: 'dans le cadre de la part librement disponible.',
      and: 'et',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mon enfant non commun, ',
        born: ', né le {{birthday}}, ',
        receives:
          'ou tous ses descendants reçoivent chacun à parts égales tous les actifs',
      },
      multipleChildren: {
        main: 'Mes enfants non communs, ',
        born: ', né le {{birthday}}, ',
        receives:
          'ou tous leurs descendants reçoivent chacun à parts égales tous les actifs',
      },
      quota: 'dans le cadre de la part librement disponible.',
      and: 'et',
    },
    noPartnership: {
      oneChild: {
        main: 'Mon enfant, ',
        born: ', né le {{birthday}}, ',
        receives: 'ou ses descendants reçoivent',
      },
      multipleChildren: {
        main: 'Mes enfants, ',
        born: ', né le {{birthday}}, ',
        receives: 'ou tous leurs descendants reçoivent chacun',
      },
      quota:
        'chacun à parts égales tous les actifs dans le cadre de la part librement disponible.',
      and: 'et',
    },
  },

  unmarriedInstitutionChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Notre enfant commun, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Nos enfants communs, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou tous leurs descendants reçoivent chacun la part légale.',
      },
      and: 'et',
    },
    otherPartnership: {
      oneChild: {
        main: 'Mon enfant non commun, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou tous leurs descendants reçoivent chacun la part légale.',
      },
      and: 'et',
    },
    noPartnership: {
      oneChild: {
        main: 'Mon enfant, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Mes enfants, ',
        born: ', né le {{birthday}}, ',
        receive: 'ou tous leurs descendants reçoivent chacun la part légale.',
      },
      and: 'et',
    },
  },

  p4Institution: {
    oneInstitution: {
      main: 'L\'institution',
      receives: 'reçoit',
    },
    multipleInstitutions: {
      main: 'Les institutions',
      receives: 'reçoivent',
    },
    finals:
      ' chacun à parts égales tous les actifs dans le cadre de la part librement disponible. ',
    final: ' tous les actifs dans le cadre de la part librement disponible. ',
    partnerReceives: 'reçoit la part légale.',
    born: ', né le {{birthday}}, ',
    and: ' et ',
  },

  p5MarriedSpouse: {
    marriedStatus: {
      male: 'Mon épouse',
      female: 'Mon époux',
    },
    livingPartnershipStatus: {
      male: 'Ma partenaire enregistrée',
      female: 'Mon partenaire enregistré',
    },
    particularReceives: 'reçoit notamment aussi',
    theProperty: 'le bien immobilier',
    atTheAddress: 'à l\'adresse',
    assignedToProperty: 'attribué en propriété.',
  },

  p5Institution: {
    commonMarriage: {
      oneChild: {
        main: 'Notre enfant commun, ',
        born: ' né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Nos enfants communs, ',
        born: ' né le {{birthday}}, ',
        receive: 'ou tous leurs descendants reçoivent chacun la part légale.',
      },
      and: 'et',
    },
    otherMarriage: {
      oneChild: {
        main: 'Mon enfant non commun, ',
        born: ' né le {{birthday}}, ',
        receive: 'ou ses descendants reçoivent la part légale.',
      },
      multipleChildren: {
        main: 'Mes enfants non communs, ',
        born: ' né le {{birthday}}, ',
        receive: 'ou tous leurs descendants reçoivent chacun la part légale.',
      },
      and: 'et',
    },
  },

  p6MarriedSpouse: {
    oneChild: {
      commonMarriage: 'Notre enfant commun, ',
      otherMarriage: 'Mon enfant non commun, ',
      born: ', né le {{birthday}}, ',
      receives: 'ou ses descendants reçoivent ',
    },
    multipleChildren: {
      commonMarriage: 'Nos enfants communs, ',
      otherMarriage: 'Mes enfants non communs, ',
      born: ', né le {{birthday}}, ',
      receives: 'ou tous leurs descendants reçoivent chacun',
    },
    final:
      'la part légale, grevée cependant de l\'usufruit mentionné au premier paragraphe. ',
  },

  willExecutor:
    'En tant qu\'exécuteur testamentaire pour l\'ensemble de ma succession, je désigne {{executor1}}. En tant qu\'exécuteur testamentaire de remplacement, je désigne {{executor2}}.',

  forFollowing: {
    main: 'Pour les actifs suivants, une disposition différente s\'applique :',
    asset: '{{asset}}, {{address}} va à : {{benefiter}}. ',
    devisionProvision:
      'Ceci est une disposition de partage en faveur de {{benefiter}}, pas un avantage supplémentaire.',
  },
};

export default outputDoc;