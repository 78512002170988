import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import DashboardContainer from '#/components/shared/dashboard-container';
import useLocales from '#/lib/hooks/useLocales';
import Filters from '../DFPhotosVideos/filters';
import useBoolean from '#/lib/hooks/useBoolean';
import { IFileFilters, IFileFilterValue } from '#/types/file';
import useTable from '#/lib/hooks/useTable';
import { isAfter } from '#/utils/formatTime';
import Iconify from '#/components/shared/ui/Iconify';
import TableView from '../DFPhotosVideos/table/table-view';
import GridView from '../DFPhotosVideos/grid/grid-view';
import { FilesAPI } from '#/types/digitalAssets';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import useAccountSwitch from '#/lib/hooks/useAccountSwitch';
import ReqTaxExpert from './req-tax-expert';
import useAuth from '#/lib/hooks/useAuth';

const defaultFilters: IFileFilters = {
  name: '',
  type: [],
  startDate: null,
  endDate: null,
};

export const FILE_TYPE_OPTIONS = [
  'folder',
  'txt',
  'zip',
  'audio',
  'image',
  'video',
  'word',
  'excel',
  'powerpoint',
  'pdf',
  'photoshop',
  'illustrator',
];

export default function TaxFolderClient() {
  const [view, setView] = useState<string>('photos');
  const [filters, setFilters] = useState(defaultFilters);
  const [guestOn] = useLocalStorage('guest_on', false);

  const { user } = useAuth();
  const { currentAccount } = useAccountSwitch();

  const hasPartnership = !!user?.partner;

  const { translate } = useLocales();
  const openDateRange = useBoolean();
  const navigate = useNavigate();
  const table = useTable({ defaultRowsPerPage: 10 });
  const handleFilters = useCallback(
    (name: string, value: IFileFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const dataFiltered: FilesAPI = {
    files: [],
    folders: [],
    current_path: [],
  };

  const dateError = isAfter(filters.startDate, filters.endDate);

  const handleBackToDashboard = () => {
    navigate('/dashboard/home');
  };

  const handleRedirectToChat = () => {
    navigate('/dashboard/chat?id=123123');
  };

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
      }
    },
    []
  );

  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <DashboardContainer
        introTitle={String(
          translate(
            guestOn ? 'global.guestTaxFolder.title' : 'global.taxFolder.title',
            {
              user: `${currentAccount?.first_name} ${currentAccount?.last_name}`,
            }
          )
        )}
        introDescription={
          guestOn
            ? String(translate('global.guestTaxFolder.description'))
            : String(translate('global.taxFolder.description'))
        }
      >
        {hasPartnership && (
          <Stack direction="row" justifyContent="center">
            <ReqTaxExpert />
          </Stack>
        )}
        {guestOn && (
          <Stack direction="row" justifyContent="center">
            <Button
              onClick={handleRedirectToChat}
              sx={{ width: 'fit-content' }}
            >
              <Typography>
                {String(translate('global.guestTaxFolder.messageClient'))}
              </Typography>
            </Button>
          </Stack>
        )}
        <Stack
          spacing={2.5}
          sx={{
            my: { xs: 3, md: 5 },
          }}
        >
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-end', md: 'center' }}
          >
            <Filters
              openDateRange={openDateRange.value}
              onCloseDateRange={openDateRange.onFalse}
              onOpenDateRange={openDateRange.onTrue}
              filters={filters}
              onFilters={handleFilters}
              typeOptions={FILE_TYPE_OPTIONS}
              dateError={dateError}
            />
            <ToggleButtonGroup
              size="small"
              value={view}
              exclusive
              onChange={handleChangeView}
            >
              <ToggleButton value="list">
                <Iconify icon="solar:list-bold" />
              </ToggleButton>

              <ToggleButton value="grid">
                <Iconify icon="mingcute:dot-grid-fill" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
        {view === 'list' ? (
          <TableView
            table={table}
            dataFiltered={dataFiltered}
            openFolder={() => console.info('OPEN FOLDER')}
            openFile={() => console.info('OPEN FILE')}
            onOpenConfirm={() => console.info('OPEN CONFIRM')}
            parentList={[]}
            notFound={false}
          />
        ) : (
          <GridView
            table={table}
            dataFiltered={dataFiltered}
            openFolder={() => console.info('OPEN FOLDER')}
            openFile={() => console.info('OPEN FILE')}
            parentList={[]}
            notFound={false}
          />
        )}
      </DashboardContainer>
    </>
  );
}
