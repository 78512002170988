import {
  financialInformationQuestions,
  trustedPersonQuestions,
} from './questions';

const importantInformation = {
  mainTitle: 'Simple provision of important information',
  mainDescription:
    'Let your relatives or executors know where to find important information in case of incapacitation or passing.',

  insuranceTypes: {
    life_insurance: 'Life insurance',
    private_liability_insurance: 'Private liability insurance',
    household_insurance: 'Household insurance',
    vehicle_insurance: 'Vehicle insurance',
    building_insurance: 'Building insurance',
    legal_expenses_insurance: 'Legal expenses insurance',
    occupational_disability_insurance: 'Occupational disability insurance',
    health_insurance: 'Health insurance',
    supplementary_health_insurance: 'Supplementary health insurance',
    accident_insurance: 'Accident insurance',
  },

  threeATypes: {
    policy: 'Policy',
    account: 'Account',
    fund: 'Fund',
  },

  stepperInfo: [
    {
      label: 'Identification documents',
      title: '',
    },
    {
      label: 'Financial information',
      title: '',
    },
    {
      label: 'Trusted persons',
      title: '',
    },
  ],

  stepTwo: {
    questions: financialInformationQuestions,
  },

  delete3Amodal: {
    title:
      'Are you sure you want to delete {{financialInstitution}} from the list?',
    description: 'You can add it again at any time later',
    onClose: 'Close',
    onConfirm: 'Delete',
  },

  deleteInsurancePolicyModal: {
    title: 'Are you sure you want to delete {{policy}}?',
    description: 'You can add it again at any time later',
    onClose: 'Close',
    onConfirm: 'Delete',
  },

  stepThree: {
    questions: trustedPersonQuestions,
    addAnotherTrustedPerson: 'Add another executor',
    addNotary: 'Add notary or trustee',
  },
};

export default importantInformation;
