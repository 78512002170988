import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { Button, Link, Stack, Typography } from '@mui/material';
import { PATH_AUTH } from '#/routes/paths';
import Iconify from '#/components/shared/ui/Iconify';
import Image from '#/components/shared/ui/Image';
import Page from '#/components/shared/ui/Page';
import NewPasswordForm from '../../components/pages/NewPassword/NewPasswordForm';
import { useConfirmResetPassword } from '#/api/userQueries';
import LoadingScreen from '#/components/shared/ui/LoadingScreen';
import useLocales from '#/hooks/useLocales';

export default function NewPasswordPage() {
  const { translate } = useLocales();
  const fromPartner = localStorage.getItem('gg_partner_key');

  const { uidb64, token } = useParams();
  const location = useLocation();
  const isPartnerOnboarding =
    location?.pathname?.includes('partner-onboarding');

  const {
    data: confirmResetPassword,
    isLoading,
    error,
  } = useConfirmResetPassword(uidb64, token, !!uidb64 && !!token, fromPartner);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return (
      <Stack
        sx={{
          maxWidth: 400,
          textAlign: 'center',
          m: 'auto',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h3" paragraph>
          {translate('global.loginInfo.invalidLink')}
        </Typography>
        <Link
          component={RouterLink}
          to="/auth/reset-passwort"
          color="inherit"
          variant="subtitle2"
          sx={{
            mt: 3,
            mx: 'auto',
            alignItems: 'center',
            display: 'inline-flex',
            textDecoration: 'none',
          }}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:chevron-right-fill" width={16} />}
          >
            <Typography variant="caption">
              {translate('global.loginInfo.returnToResetPassword')}
            </Typography>
          </Button>
        </Link>
      </Stack>
    );
  }

  return (
    <Page title="New Password">
      {confirmResetPassword?.success && uidb64 && token && (
        <Stack
          sx={{
            maxWidth: 400,
            textAlign: 'center',
            m: 'auto',
            minHeight: '100vh',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image src="/assets/icons/newPassword.svg" />
          </div>

          <Typography variant="h4" paragraph>
            {isPartnerOnboarding
              ? translate('global.loginInfo.welcomeOnboard')
              : translate('global.loginInfo.setNewPassword')}
          </Typography>
          {isPartnerOnboarding && (
            <Typography sx={{ mb: 2 }}>
              {translate('global.loginInfo.welcomeOnboardDescription')}
            </Typography>
          )}
          <NewPasswordForm uidb64={uidb64} token={token} />

          <Link
            component={RouterLink}
            to={PATH_AUTH.login}
            color="inherit"
            variant="subtitle2"
            sx={{
              mx: 'auto',
              my: 3,
              alignItems: 'center',
              display: 'inline-flex',
            }}
          >
            <Iconify icon="eva:chevron-left-fill" width={16} />
            {translate('global.loginInfo.returnToLogin')}
          </Link>
        </Stack>
      )}
    </Page>
  );
}
