import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import RecommendationCard from '#/components/pages/PensionProvision/onboarding/steps/profiling/recommendations/card';
import useAuth from '#/hooks/useAuth';
import { PROVISION_TOPICS } from '#/components/pages/PensionProvision/onboarding/data';
import InformativeCard from '#/components/shared/informative-card';

export default function YellowLightRecommendations() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLandingRedirect = (landingURL: string) => {
    navigate(landingURL);
  };

  const getExpectedTopicsForCivilStatus = () => {
    const baseTopics = PROVISION_TOPICS.filter((topic) =>
      [
        'vorsorgeauftrag',
        'patientenverfuegung',
        'testament',
        'digitaler-nachlass',
        'vermoegens-und-sachregister',
      ].includes(topic.value)
    );

    switch (user?.civil_status) {
      case 'married':
        return [
          ...baseTopics,
          PROVISION_TOPICS.find((topic) => topic.value === 'ehevertrag')!,
        ];
      default:
        return [
          ...baseTopics,
          PROVISION_TOPICS.find(
            (topic) => topic.value === 'konkubinatsvertrag'
          )!,
        ];
    }
  };

  const expectedTopics = getExpectedTopicsForCivilStatus();

  const completedProvisions = expectedTopics.filter(
    (topic) => user?.provision_check_done?.includes(topic?.value)
  );

  const missingProvisions = expectedTopics.filter(
    (topic) => !user?.provision_check_done?.includes(topic?.value)
  );

  return (
    <RecommendationCard
      title={String(
        translate(
          'pensionProvision.onboarding.stepThree.yellowLightRecommendation.title',
          {
            list: completedProvisions
              .map((topic) => String(translate(topic.title)))
              .reduce(
                (acc, curr, index) =>
                  acc +
                  (index === completedProvisions.length - 1
                    ? ` und ${curr}`
                    : `, ${curr}`)
              ),
          }
        )
      )}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            md: 'repeat(4, 1fr)',
          },
          gap: 2,
        }}
      >
        {missingProvisions.map((topic) => (
          <InformativeCard
            cardTitle={String(translate(topic.title))}
            key={topic.value}
            onServiceSelect={() =>
              handleLandingRedirect(topic.panelURL as string)
            }
          />
        ))}
      </Box>
    </RecommendationCard>
  );
}
