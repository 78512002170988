import { Stack, Typography } from '@mui/material';
import {
  SituationCardContent,
  SituationCardContentColumns,
} from '#/components/shared/ui/situation-card/styles';
import useLocales from '#/hooks/useLocales';

interface Column {
  title: string;
  description: string[];
}

type ContentColumnsProps = {
  columns: Column[];
};

const ContentColumns = ({ columns }: ContentColumnsProps) => {
  const { translate } = useLocales();

  return (
    <SituationCardContentColumns>
      {columns.map((column, index) => (
        <SituationCardContent key={index}>
          <Typography variant="h6">{translate(column.title)}</Typography>
          {column?.description?.map((desc, descIndex) => (
            <Stack
              key={descIndex}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {column.title === 'Ihre Vorteile' && <Typography>•</Typography>}
              <Typography>{translate(desc)}</Typography>
            </Stack>
          ))}
        </SituationCardContent>
      ))}
    </SituationCardContentColumns>
  );
};

export default ContentColumns;
