import {
  contractParties,
  estateQuestions,
  marriageQuestions,
} from './questions';
import outputDoc from './outputDoc';

const marriageContract = {
  mainHeaders: {
    maximiseBenefits: 'Maximisez les avantages pour votre conjoint',
    effectiveMarriageContract: 'Votre contrat de mariage efficace',
  },

  stepperInfo: [
    {
      label: 'Conjoints',
      title: 'Parties contractantes',
    },
    {
      label: 'Mariage',
      title: 'Mariage',
    },
    {
      label: 'Patrimoine',
      title: 'Patrimoine',
    },
    {
      label: 'Votre contrat',
      title: 'Déposez votre contrat de mariage',
    },
  ],

  propertyTypes: {
    apartment: 'Appartement',
    house: 'Maison',
    chalet: 'Chalet',
    rustico: 'Rustico',
    building_plot: 'Terrain à bâtir',
    multi_family_house: 'Maison multifamiliale',
    commercial_building: 'Bâtiment commercial',
    residential_building: 'Bâtiment résidentiel',
    commercial_property: 'Propriété commerciale',
  },

  stepOne: {
    questions: contractParties,
  },
  stepTwo: {
    questions: marriageQuestions,
    confirmAssetDelete: {
      title: 'Êtes-vous sûr de vouloir supprimer cet actif ?',
      description: 'Vous pouvez le rajouter à tout moment plus tard.',
      cancel: 'Annuler',
      delete: 'Supprimer',
    },
  },
  stepThree: {
    questions: estateQuestions,
    tooltips: {
      id2: 'Si vous choisissez "Oui", le contrat contient un paragraphe confirmant que le reste de votre patrimoine a été acquis conjointement, appelé "acquêts". Cela signifie que le conjoint survivant devient automatiquement propriétaire de 50% du patrimoine. Cela offre de nombreux avantages pour votre conjoint.',
      id4: "Cette condition doit être remplie pour notre formulaire. Si vous avez d'autres souhaits, veuillez contacter un avocat. Nous serons heureux de vous aider à trouver un avocat approprié.",
      id6: 'La valeur la plus élevée au moment du décès doit être prise en compte.',
      id7:
        'Si vous souhaitez autoriser une tierce personne à exécuter votre succession, ajoutez-la ici. \n' +
        "Si vous n'ajoutez pas de tierce personne, le conjoint survivant est automatiquement autorisé.\n" +
        "Veuillez noter qu'une tierce personne a généralement le droit de refuser la tâche.\n" +
        "Si la personne accepte la tâche, elle a droit à une indemnité. Les dispositions légales en vigueur au moment du décès s'appliquent.",
    },
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Bien joué !',
    nextStepsTitle: 'Les prochaines étapes :',
    nextStep1: 'Imprimez le contrat de mariage et lisez-le attentivement',
    nextStep2:
      'Contactez un notaire pour faire authentifier votre contrat (cliquez ici pour en trouver un)',
    highlightedText: 'cliquez ici',
    nextStep3: 'Téléchargez ci-dessous le contrat de mariage authentifié',
  },
};

export default marriageContract;
