const tt = {
  title: 'Conditions Générales',
  scope: {
    title: 'Portée',
    content:
      'Ces Conditions Générales (CG) régissent l\'utilisation des services de betascale AG (ci-après dénommée « gut geregelt »). gut geregelt met à disposition du client les services pour la durée du contrat dans leur version actuelle moyennant paiement. La relation contractuelle entre gut geregelt et le client est établie par l\'acceptation de l\'offre. En acceptant l\'offre, le client reconnaît expressément ces CG, même si elles contredisent totalement ou partiellement ses propres conditions générales. Le client est tenu de fournir à tout moment des informations correctes, complètes et à jour. Le non-respect de cette obligation constitue une violation des conditions d\'utilisation et peut entraîner la résiliation immédiate du contrat.',
  },
  userRights: {
    title: 'Droits de l\'utilisateur',
    content:
      'gut geregelt accorde au client un droit non exclusif et non transférable d\'utiliser les services conformément au contrat pour la durée de celui-ci. Le client n\'est pas autorisé à mettre les services à la disposition de tiers, que ce soit à titre onéreux ou gratuit. Toute forme de prestation de service à des tiers est expressément interdite. Le client doit s\'assurer que toutes les relations contractuelles avec des tiers excluent l\'utilisation gratuite du service.',
  },
  customerResponsibilities: {
    title: 'Responsabilités du client',
    content:
      'Au-delà des services fournis par gut geregelt, le client assume l\'entière responsabilité du fonctionnement, de la sécurité et de l\'état de ses médias électroniques et numériques sur lesquels les services de gut geregelt sont utilisés (matériel, logiciel, exploitation, sécurité, etc.). Tous les coûts associés à l\'accomplissement de ces obligations sont à la charge du client.',
  },
  systemAvailability: {
    title: 'Disponibilité du système',
    content:
      'Les services de gut geregelt reposent sur le principe du « meilleur effort ». gut geregelt prend des mesures appropriées pour assurer une utilisation aussi ininterrompue que possible des services. Le client est toutefois conscient qu\'il s\'agit d\'un système techniquement complexe, comprenant des composants de tiers dont la fonctionnalité échappe au contrôle de gut geregelt. Par conséquent, gut geregelt ne peut garantir une disponibilité constante et complète des composants de plus de 99,5 %. Les interruptions dues à des maintenances système, des mises à jour, etc., seront annoncées à l\'avance avec un préavis de deux jours ouvrables pour les travaux de maintenance planifiés. Les maintenances urgentes, qui entraînent une interruption de la disponibilité, peuvent être effectuées sans préavis pour résoudre immédiatement les problèmes ou prévenir les menaces potentielles pour le système. Si gut geregelt constate une menace pour le bon fonctionnement due à une action négligente ou intentionnelle de tiers, elle est autorisée à prendre immédiatement toutes les mesures nécessaires pour protéger sa propre infrastructure et ses logiciels contre les dommages.',
  },
  support: {
    title: 'Support',
    content:
      'Le support de gut geregelt est disponible pour le client par e-mail à support@gutgeregelt.ch du lundi au vendredi, de 8h00 à 12h00 et de 13h00 à 17h00, avec un temps de réponse moyen d\'une heure.',
  },
  renumerations: {
    title: 'Rémunérations',
    content:
      'Le montant de la rémunération pour les services convenus est fixé séparément dans les listes de prix respectives. Il s\'agit généralement de frais récurrents. Les frais récurrents peuvent être ajustés après notification préalable. Ils sont dus dans les 20 jours ou peuvent être prélevés par carte de crédit. Les services supplémentaires convenus sont facturés séparément.',
  },
  intellectualPropertyRights: {
    title: 'Droits de propriété intellectuelle',
    content:
      'Le client reconnaît les droits de propriété intellectuelle, notamment le droit d\'auteur, de gut geregelt sur les logiciels, les services et la documentation. Le client n\'est pas autorisé à mettre ce logiciel à la disposition de tiers, à le louer ou à l\'utiliser en dehors de la relation contractuelle avec gut geregelt, ni à contester les droits de gut geregelt de quelque manière que ce soit.',
  },
  dataProtection: {
    title: 'Protection des données et sécurité des données',
    content:
      'gut geregelt traitera les données du client avec le plus grand soin et les protégera contre les abus et les pertes. gut geregelt ne stocke pas de données personnelles de ses clients. Néanmoins, gut geregelt prend des mesures techniques et organisationnelles conformes aux exigences du RGPD. Dans le cadre des possibilités techniques, des accords spéciaux de stockage des données peuvent être conclus dans certains pays. Le client est responsable de la légalité du transfert de données ou de leur utilisation. Toutes les données client stockées et traitées par gut geregelt sont la propriété exclusive du client et sont utilisées par gut geregelt uniquement à des fins d\'exécution du contrat.',
  },
  disclaimerLiability: {
    title: 'Clause de non-responsabilité',
    content:
      'gut geregelt garantit que les services sont fonctionnels et opérationnels conformément aux dispositions légales de ce contrat. Le client s\'engage à dégager gut geregelt de toute réclamation de tiers en raison des données stockées et à rembourser gut geregelt pour tous les coûts résultant de violations légales éventuelles. gut geregelt est autorisée à bloquer immédiatement l\'espace de stockage si un soupçon fondé existe que les données stockées sont illégales et/ou violent les droits de tiers. Un soupçon fondé d\'illégalité et/ou de violation existe lorsque des tribunaux, autorités et/ou tiers informent gut geregelt à ce sujet. gut geregelt doit informer immédiatement le client de la suppression et des raisons de celle-ci. L\'interdiction doit être levée dès que le soupçon est complètement levé. gut geregelt exclut, dans le cadre des dispositions légales, toute responsabilité envers le client (ou des tiers), notamment pour l\'exécution de ses obligations contractuelles et extracontractuelles, ainsi que pour la perte de données et le manque à gagner (même en cas de négligence grave). Cette clause de non-responsabilité s\'applique également aux dommages causés directement ou indirectement par l\'utilisation de nos services. La responsabilité réciproque des parties, quel que soit le motif de la responsabilité, est dans tous les cas limitée au montant des frais de service mensuels des douze derniers mois précédant l\'événement dommageable.',
  },
  communication: {
    title: 'Communication',
    content:
      'gut geregelt est autorisée à nommer le client comme référence et à utiliser de manière appropriée des informations générales sur le contrat convenu à des fins de marketing et de vente.',
  },
  amendments: {
    title: 'Modifications',
    content:
      'gut geregelt se réserve le droit de modifier ou de remplacer ces Conditions Générales à tout moment. En cas de modification, gut geregelt s\'efforcera d\'informer le client au moins 30 jours avant l\'entrée en vigueur des nouvelles conditions.',
  },
  severabilityClause: {
    title: 'Clause de divisibilité',
    content:
      'En cas d\'invalidité totale ou partielle de certaines dispositions de ce contrat, les dispositions invalides ou inapplicables seront mises en œuvre ou complétées de manière à atteindre l\'objectif économique poursuivi par la disposition invalide. Il en va de même en cas de lacunes dans ce contrat.',
  },
  jurisdiction: {
    title: 'Juridiction',
    content:
      'Les parties conviennent que le droit suisse s\'applique à toutes les relations juridiques découlant de ce contrat. Le for exclusif pour tous les litiges découlant de l\'exécution de cette relation contractuelle est Liestal BL (Suisse).',
  },
};

export default tt;