const toast_notifications = {
  success: {
    register: 'Enregistré avec succès',
    login: 'Connexion réussie',
    logout: 'Déconnexion réussie',
    representative_add: 'Représentant ajouté avec succès',
    representative_update: 'Représentant mis à jour avec succès',
    representative_delete: 'Représentant supprimé avec succès',
    message_sent: 'Message envoyé avec succès',
    package_update: 'Forfait mis à jour avec succès',
    good_update: 'Bien mis à jour avec succès',
    good_create: 'Bien ajouté avec succès',
    profile_update: 'Profil mis à jour avec succès',
    password_update: 'Mot de passe mis à jour avec succès',
    password_reset:
      'Lien de réinitialisation du mot de passe envoyé à votre e-mail',
    asset_delete: 'Actif supprimé avec succès',
    property_update: 'Propriété mise à jour avec succès',
    property_create: 'Propriété ajoutée avec succès',
    beneficiary_update: 'Bénéficiaire mis à jour avec succès',
    beneficiary_create: 'Bénéficiaire ajouté avec succès',
    beneficiary_delete: 'Bénéficiaire supprimé avec succès',
    reminder_service: 'Service de rappel mis à jour avec succès',
    childAdd: 'Enfant ajouté avec succès',
    childDelete: 'Enfant supprimé avec succès',
    childEdit: 'Enfant mis à jour avec succès',
    partner_message: 'Message envoyé avec succès',
    invitation_sent: 'Invitation envoyée avec succès',
    document_submit: 'Document soumis avec succès',
    deathReport: 'Rapport de décès soumis avec succès',
    teamInvite: 'Membre de l\'équipe invité avec succès',
    addAdvisor: 'Conseiller ajouté avec succès',
    platform_update: 'Paramètres de la plateforme mis à jour avec succès',
    cancel_invitation: 'Invitation annulée avec succès',
    resend_invitation: 'Invitation renvoyée avec succès',
    delete_team_member: 'Membre de l\'équipe supprimé avec succès',
    joinWaitList: 'Rejoint la liste d\'attente avec succès',
    widgetRequest: 'Demande de widget envoyée avec succès',
    createUser: 'Utilisateur créé avec succès',
    deleteUser: 'Utilisateur supprimé avec succès',
    assignedNewPartner: 'Nouveau partenaire assigné avec succès',
    disconnectFromPartner: 'Déconnecté du partenaire avec succès',
    consulationRequest: 'Consultation demandée avec succès',
    addedAsset: 'Actif ajouté avec succès',
    updatedAsset: 'Actif mis à jour avec succès',
    deletedAsset: 'Actif supprimé avec succès',
    addedFinancialInstitution: 'Institution financière ajoutée avec succès',
    updatedFinancialInstitution: 'Institution financière mise à jour avec succès',
    deletedFinancialInstitution: 'Institution financière supprimée avec succès',
    userTransfer: 'Utilisateur transféré avec succès',
    addDigitalAsset: 'Actif numérique ajouté avec succès',
    editDigitalAsset: 'Actif numérique mis à jour avec succès',
    deleteDigitalAsset: 'Actif numérique supprimé avec succès',
    saving: 'Enregistré avec succès',
    otpCode: 'Code OTP envoyé avec succès',
  },
  error: {
    register: 'Échec de l\'enregistrement',
    login: 'Échec de la connexion, vérifiez vos identifiants',
    logout: 'Échec de la déconnexion',
    package_failed: 'Échec de la mise à jour du forfait',
    representative_add: 'Échec de l\'ajout du représentant',
    representative_update: 'Échec de la mise à jour du représentant',
    representative_delete: 'Échec de la suppression du représentant',
    good_update: 'Échec de la mise à jour du bien',
    good_create: 'Échec de l\'ajout du bien',
    profile_update: 'Échec de la mise à jour du profil',
    password_update: 'Échec de la mise à jour du mot de passe',
    password_reset:
      'Échec de l\'envoi du lien de réinitialisation du mot de passe',
    asset_delete: 'Échec de la suppression de l\'actif',
    property_update: 'Échec de la mise à jour de la propriété',
    property_create: 'Échec de l\'ajout de la propriété',
    beneficiary_update: 'Échec de la mise à jour du bénéficiaire',
    beneficiary_create: 'Échec de l\'ajout du bénéficiaire',
    beneficiary_delete: 'Échec de la suppression du bénéficiaire',
    reminder_service: 'Échec de la mise à jour du service de rappel',
    childAdd: 'Échec de l\'ajout de l\'enfant',
    childDelete: 'Échec de la suppression de l\'enfant',
    childEdit: 'Échec de la mise à jour de l\'enfant',
    partner_message: 'Échec de l\'envoi du message',
    invitation_sent: 'Échec de l\'envoi de l\'invitation',
    document_submit: 'Échec de la soumission du document',
    deathReport:
      'Échec de la soumission du rapport de décès, peut-être l\'avez-vous déjà soumis',
    teamInvite: 'Échec de l\'invitation du membre de l\'équipe',
    addAdvisor: 'Échec de l\'ajout du conseiller',
    platform_update: 'Échec de la mise à jour des paramètres de la plateforme',
    cancel_invitation: 'Échec de l\'annulation de l\'invitation',
    resend_invitation: 'Échec de l\'envoi de l\'invitation',
    delete_team_member: 'Échec de la suppression du membre de l\'équipe',
    joinWaitList: 'Échec de l\'adhésion à la liste d\'attente',
    widgetRequest: 'Échec de l\'envoi de la demande de widget',
    createUser: 'L\'utilisateur existe déjà',
    deleteUser: 'Échec de la suppression de l\'utilisateur',
    assignedNewPartner: 'Échec de l\'attribution du nouveau partenaire',
    disconnectFromPartner: 'Échec de la déconnexion du partenaire',
    consulationRequest: 'Échec de la demande de consultation',
    addedAsset: 'Échec de l\'ajout de l\'actif',
    updatedAsset: 'Échec de la mise à jour de l\'actif',
    deletedAsset: 'Échec de la suppression de l\'actif',
    addedFinancialInstitution: 'Échec de l\'ajout de l\'institution financière',
    updatedFinancialInstitution:
      'Échec de la mise à jour de l\'institution financière',
    deletedFinancialInstitution: 'Échec de la suppression de l\'institution financière',
    userTransfer: 'Échec du transfert de l\'utilisateur',
    addDigitalAsset: 'Échec de l\'ajout de l\'actif numérique',
    editDigitalAsset:
      'Échec de la mise à jour de l\'actif numérique',
    deleteDigitalAsset: 'Échec de la suppression de l\'actif numérique',
    saving: 'Échec de l\'enregistrement',
    otpCode: 'Échec de l\'envoi du code OTP',
  },
};

export default toast_notifications;