const aboutUs = {
  heroTitle: 'gut geregelt – un\'azienda svizzera del Basilea Campagna',
  heroSubtitle:
    'gut geregelt AG è una startup di Sissach vicino a Basilea. Digitalizziamo e democratizziamo l\'accesso alla previdenza.',
  heroLabel: 'Chi siamo',
  heroButton: 'Contatto',
  section1: {
    title: 'Siamo gut geregelt',
    subtitle:
      'gut geregelt è un\'azienda giovane e dinamica con radici profonde in Svizzera e nel Basilea Campagna. Il nostro team è composto da esperti nei settori del diritto, finanza, assicurazioni e sviluppo software. Digitalizziamo il processo di previdenza rendendolo più veloce, economico e accessibile.',
  },
  section2: {
    title: 'Presidente: {{chairMan}}',
    subtitle:
      '{{chairMan}} è un imprenditore seriale svizzero di Sissach. È presidente del consiglio di amministrazione e forza trainante di gut geregelt. Insieme al team di gut geregelt, Alain promuove lo sviluppo software iterativo, presenta le nostre soluzioni a banche e casse pensioni ed è un relatore richiesto in vari eventi nel settore bancario e delle startup.',
  },
  ourGoal: {
    label: 'Il nostro obiettivo',
    title: 'Rendere accessibile la previdenza per la vecchiaia',
    subtitle:
      'In gut geregelt perseguiamo la missione di digitalizzare l\'intero processo di previdenza e rappresentarlo sulla nostra piattaforma. In breve tempo abbiamo sviluppato una soluzione completa che viene continuamente ampliata. gut geregelt rende la previdenza più semplice, comprensibile e accessibile.',
  },
  section3: {
    title: 'Parte del gruppo Betascale',
    subtitle:
      'gut geregelt AG è un\'azienda del gruppo svizzero Betascale con sede a Sissach vicino a Basilea. Con il motto "Building the ventures of tomorrow, today", Betascale ha testato con successo gut geregelt e l\'ha posizionata sul mercato. In breve tempo ci siamo affermati come partner affidabile per la previdenza e abbiamo lanciato il nostro software sul mercato.',
  },
  partners: {
    label: 'I nostri partner',
    title: 'Puntiamo su partner forti',
    subtitle:
      'Partnership forti sono una componente essenziale del nostro successo. Per offrire soluzioni ottimali ai nostri clienti, collaboriamo con aziende e istituzioni leader in Svizzera.',
  },
};

export default aboutUs;