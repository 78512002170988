import { Container, Stack, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLocales from '#/lib/hooks/useLocales';
import IntroHead from '#/components/shared/IntroHead';

import FileContent from './file-content';

export default function DFPhotosVideos() {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/dashboard/digitaler-nachlass');
  };

  return (
    <Container>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <IntroHead
        title={String(translate('dfPhotosVideos.introHead'))}
        description={String(translate('dfPhotosVideos.introDescription'))}
      />

      <FileContent />
    </Container>
  );
}
