import {
  financialInformationQuestions,
  trustedPersonQuestions,
} from './questions';

const importantInformation = {
  mainTitle: 'Fornitura semplice di informazioni importanti',
  mainDescription:
    'Fai sapere ai tuoi cari o agli esecutori testamentari dove trovare informazioni importanti in momenti difficili.',

  insuranceTypes: {
    life_insurance: 'Assicurazione sulla vita',
    private_liability_insurance: 'Assicurazione di responsabilità civile privata',
    household_insurance: 'Assicurazione domestica',
    vehicle_insurance: 'Assicurazione veicoli',
    building_insurance: 'Assicurazione edifici',
    legal_expenses_insurance: 'Assicurazione spese legali',
    occupational_disability_insurance: 'Assicurazione invalidità professionale',
    health_insurance: 'Assicurazione sanitaria',
    supplementary_health_insurance: 'Assicurazione sanitaria supplementare',
    accident_insurance: 'Assicurazione infortuni',
  },

  threeATypes: {
    policy: 'Polizza',
    account: 'Conto',
    fund: 'Fondo',
  },

  stepperInfo: [
    {
      label: 'Documenti di identificazione',
      title: '',
    },
    {
      label: 'Informazioni finanziarie',
      title: '',
    },
    {
      label: 'Persone di fiducia',
      title: '',
    },
  ],

  stepTwo: {
    questions: financialInformationQuestions,
  },

  delete3Amodal: {
    title:
      'Vuoi davvero eliminare {{financialInstitution}} dall\'elenco?',
    description: 'Puoi sempre aggiungerlo di nuovo in seguito',
    onClose: 'Chiudi',
    onConfirm: 'Elimina',
  },

  deleteInsurancePolicyModal: {
    title: 'Vuoi davvero eliminare la {{policy}}?',
    description: 'Puoi sempre aggiungerlo di nuovo in seguito',
    onClose: 'Chiudi',
    onConfirm: 'Elimina',
  },

  stepThree: {
    questions: trustedPersonQuestions,
    addAnotherTrustedPerson: 'Aggiungi un altro esecutore testamentario',
    addNotary: 'Aggiungi notaio o fiduciario',
  },
};

export default importantInformation;