const validations = {
  user: {
    name: 'Le nom est requis',
    first_name: 'Le prénom est requis',
    last_name: 'Le nom de famille est requis',
    email: 'L\'e-mail est requis',
    invalidEmail: 'L\'e-mail est invalide',
    civil_status: 'L\'état civil est requis',
    gender: 'Veuillez sélectionner votre sexe',
    place_of_citinzenship: 'Le lieu d\'origine est requis',
    date_of_birth: {
      required: 'La date de naissance est requise',
      max: 'Le représentant doit avoir 18 ans ou plus',
    },
    mobile_phone: {
      required: 'Le numéro de téléphone portable est requis',
      min: 'Le numéro de téléphone portable est trop court',
      max: 'Le numéro de téléphone portable est trop long',
      matches: 'Le numéro de téléphone portable est invalide',
    },
    minPassword: 'Le mot de passe doit comporter au moins 6 caractères',
    password: 'Le mot de passe est requis',
    confirmPassword: 'La confirmation du mot de passe est requise',
    passwordMatch: 'Les mots de passe doivent correspondre',
  },
  adress: {
    street: 'La rue est requise',
    house_number: 'Le numéro de maison est requis',
    zip_code: 'Le code postal est requis',
    city: 'La ville est requise',
    country: 'Le pays est requis',
  },
  invalid_date: 'Date invalide',
  platform_logo: 'L\'image doit être d\'au moins 630 x 80 pixels',
  primary_representative: 'Le représentant principal est requis',
  secondary_representative: 'Le représentant secondaire est requis',
  rep_selection: 'Veuillez sélectionner le représentant principal et secondaire',
  rep_inform_date: 'Veuillez sélectionner la date d\'information',
  selectAnOption: 'Veuillez sélectionner une option',
  current_rental_agreement_name:
    'Le nom du contrat de location actuel est requis',
  description_good: 'La description est requise',
  storage_location: 'Le lieu de stockage est requis',
  next_benefiter: 'Le prochain bénéficiaire est requis',
  previous_owner: 'Le propriétaire précédent est requis',
  who_are_you: 'Veuillez me dire qui vous êtes',
  shouldNotBeEmpty: 'Ce champ ne doit pas être vide',
  default_message: 'Ce champ est requis',
  meet_year: 'L\'année de la rencontre est requise',
  must_be_number: 'Ce champ doit être un nombre',
  min_0: 'Ce champ doit être au moins 0',
  max_100: 'Ce champ doit être au maximum 100',
  payment_ratio_per_party: 'Le ratio de paiement par partie est requis',
  date_of_moving_in: 'La date d\'emménagement est requise',
  successors: {
    first_name: 'Le prénom est requis',
    last_name: 'Le nom de famille est requis',
    email: 'L\'e-mail est requis',
    invalidEmail: 'L\'e-mail est invalide',
    agree: 'Veuillez accepter que ces personnes aient un accès invité.',
    min: 'Veuillez sélectionner au moins deux personnes de confiance',
  },
  message: 'Le message est requis',
  role: 'Le rôle est requis',
  shouldBeNumber: 'Ce champ doit être un nombre',
  duplicateChild: 'Il y a un enfant avec le même nom',
  otpCode: 'Le code OTP est requis',
  otpMinimum: 'Le code OTP doit comporter au moins 6 caractères',
  marriage: {
    place_of_marriage: 'Le lieu du mariage est requis',
    date_of_marriage: 'La date du mariage est requise',
    have_children: 'Veuillez sélectionner une option',
    number_of_children: 'Le nombre d\'enfants est requis',
    is_less_than_children:
      'Le nombre d\'enfants doit correspondre au nombre de la liste',
    childrenAmount:
      'Le nombre d\'enfants doit correspondre au nombre de la liste',
    acquired_after_marriage: 'Veuillez sélectionner une option',
    acquired_property_after_marriage: 'Veuillez sélectionner une option',
    maintain_ordinary_property_regime: 'Veuillez sélectionner une option',
    spouse_disposal_right: 'Veuillez sélectionner une option',
    disposal_fixed_amount: 'Le montant fixe est requis',
    disposal_percentage: 'Le pourcentage est requis',
    executor: 'L\'exécuteur est requis',
    deputy: 'Le suppléant est requis',
    sameAsContractParties:
      'Les parties au contrat ne peuvent pas être sélectionnées comme exécuteur ou suppléant',
    assets: {
      description_good: 'La description est requise',
      approximate_value: 'La valeur approximative est requise',
      approximate_value_number: 'La valeur doit être un nombre',
      storage_location: 'Le lieu de stockage est requis',
      previous_owner: 'Le propriétaire précédent est requis',
    },
  },

  digitalAssets: {
    assets: {
      description: 'La description est requise',
      storage_location: 'Le lieu de stockage est requis',
      beneficiary_id: 'Le bénéficiaire est requis',
    },
    financial: {
      institution_name: 'Le nom de l\'institution est requis',
      type: 'Le type est requis',
    },
  },

  importantInformation: {
    passport_storage_location: 'Le lieu de stockage du passeport est requis',
    identity_card_storage_location:
      'Le lieu de stockage de la carte d\'identité est requis',
    residence_permit_storage_location:
      'Le lieu de stockage du permis de séjour est requis',
    family_book_storage_location: 'Le lieu de stockage du livret de famille est requis',
    birth_certificate_storage_location:
      'Le lieu de stockage de l\'acte de naissance est requis',
    bank_documents_storage_location:
      'Le lieu de stockage des documents bancaires est requis',
    ahv_fund: 'Le fonds AVS est requis',
    ahv_number: 'Le numéro AVS est requis',
    pension_fund_organization: 'L\'organisation de la caisse de pension est requise',
    trusted_person: 'L\'exécuteur testamentaire est requis',
    insurancePolicySchema: {
      insurance_type: 'Le type d\'assurance est requis',
      insurance_company: 'La compagnie d\'assurance est requise',
      policy_number: 'Le numéro de police est requis',
      storage_location: 'Le lieu de stockage est requis',
    },
    threeASchema: {
      pension_type: 'Le type de pension est requis',
      financial_institution: 'L\'institution financière est requise',
      storage_location: 'Le lieu de stockage est requis',
    },
    notarySchema: {
      name: 'Le nom est requis',
      email: 'L\'e-mail est requis',
      invalidEmail: 'L\'e-mail est invalide',
    },
  },

  acceptedFiles: {
    type: {
      image: 'Seules les images sont autorisées',
      png: 'Seuls les fichiers PNG sont autorisés',
      jpg: 'Seuls les fichiers JPG sont autorisés',
      txt: 'Seuls les fichiers TXT sont autorisés',
      docx: 'Seuls les fichiers DOCX sont autorisés',
      pdf: 'Seuls les documents PDF ou Word sont autorisés.',
    },
  },
};

export default validations;