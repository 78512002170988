import { Button, Stack, Typography, Box, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import ReactPlayer from 'react-player/youtube';
import Image from '#/components/shared/ui/Image';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import TrustLaurel from '#/components/pages/Landing/trust-laurel';
import {
  Save50,
  SpecialBanner,
} from '#/components/pages/Landing/grosseitern-magazine';
import { useJoinWaitList } from '#/api/userQueries';
import useResponsive from '#/hooks/useResponsive';

type Props = {
  intro?: string;
  heading: string;
  title: string;
  subHeading: string;
  buttonLabel?: string;
  image: string;
  imagePosition?: string | undefined;
  url?: string;
  heroBanner?: boolean;
  trustLaurel?: boolean;
  waitlist?: boolean;
  youtubePlayer?: any;
};

const Banner = ({
  heading,
  title,
  subHeading,
  buttonLabel,
  image,
  imagePosition = 'right',
  url,
  heroBanner,
  trustLaurel,
  intro,
  waitlist,
  youtubePlayer,
}: Props) => {
  const { translate, currentLang } = useLocales();

  const [email, setEmail] = useState('');
  const { mutateAsync, isLoading } = useJoinWaitList();

  const isMobile = useResponsive('down', 'md');

  const handleButtonClick = (url?: string) => {
    if (!url) return;
    if (url === '#partner-with-us') {
      scroller.scrollTo('partner-with-us', {
        smooth: true,
      });
    }
    if (url === '#join-waitlist') {
      scroller.scrollTo('join-waitlist', {
        smooth: true,
      });
    }
    if (url === '#vorsorgecheck') {
      scroller.scrollTo('vorsorgecheck', {
        smooth: true,
      });
    }
  };

  const onServiceSet = () => {
    localStorage.setItem('req-service', title);
  };

  const handleJoinWaitList = async () => {
    try {
      await mutateAsync({ email }).then(() => {
        setEmail('');
        toast.success(
          String(translate('toast_notifications.success.joinWaitList'))
        );
      });
    } catch (error) {
      toast.error(String(translate('toast_notifications.error.joinWaitList')));
    }
  };

  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 0,
        },
        maxWidth: '1264px',
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: {
          xs: 'column',
          md: imagePosition === 'left' ? 'row-reverse' : 'row',
        },
        gap: {
          xs: 3,
          md: 10,
        },
      }}
      id={waitlist ? 'join-waitlist' : undefined}
    >
      <Stack
        direction="column"
        spacing={1}
        data-aos="fade-right"
        sx={{
          width: {
            xs: '100%',
            md: '60%',
          },
        }}
      >
        {title === 'grosseltern-magazin' && <SpecialBanner />}
        <Stack
          direction="column"
          width={1}
          flexDirection={{
            xs: title === 'grosseltern-magazin' ? 'column-reverse' : 'column',
          }}
        >
          {trustLaurel && <TrustLaurel />}
          <Stack
            spacing={2}
            sx={{
              marginBottom: {
                xs: title === 'grosseltern-magazin' ? 3 : 0,
              },
            }}
          >
            <Stack>
              {intro && (
                <Typography component="h1" variant="h4">
                  {String(translate(intro))}
                </Typography>
              )}
              <Typography
                component={heroBanner ? 'h1' : 'h2'}
                variant="h2"
                sx={{
                  lineHeight: '1.1',
                }}
              >
                {String(translate(heading))}
              </Typography>
            </Stack>
            <Typography variant="body1">
              {String(translate(subHeading))}
            </Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              alignItems="center"
              spacing={2}
            >
              {buttonLabel &&
              (url === '#partner-with-us' ||
                url === '#join-waitlist' ||
                url === '#vorsorgecheck') ? (
                <Stack>
                  {waitlist ? (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                        flexDirection: {
                          xs: 'column',
                          md: 'row',
                        },
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        size="small"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <LoadingButton
                        loading={isLoading}
                        variant="outlined"
                        onClick={handleJoinWaitList}
                      >
                        <Typography>
                          {String(translate('global.joinWaitlist'))}
                        </Typography>
                      </LoadingButton>
                    </Box>
                  ) : (
                    <Button
                      aria-label={String(translate(buttonLabel))}
                      onClick={() => handleButtonClick(url)}
                      variant={
                        imagePosition === 'left' ? 'outlined' : 'contained'
                      }
                      color="primary"
                      sx={{
                        width: 'max-content',
                      }}
                      endIcon={
                        imagePosition === 'left' ? (
                          <Iconify icon="solar:arrow-right-outline" />
                        ) : null
                      }
                    >
                      <Typography variant="body1">
                        {String(translate(buttonLabel))}
                      </Typography>
                    </Button>
                  )}
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" spacing={1}>
                  {!waitlist && (
                    <Link
                      //  @ts-ignore
                      to={url}
                      style={{ textDecoration: 'none' }}
                      state={{
                        redirectLink:
                          title === 'main' ? null : `/dashboard/${title}`,
                        ...(title === 'grosseltern-magazin' && {
                          subscription_package: 'promo50',
                        }),
                        ...(title === 'main' && { registration: true }),
                      }}
                    >
                      <Button
                        aria-label={translate(buttonLabel as string)}
                        onClick={onServiceSet}
                        variant={
                          imagePosition === 'left' ? 'outlined' : 'contained'
                        }
                        color="primary"
                        sx={{
                          width: 'max-content',
                          ...(title === 'partner-werden' &&
                            !buttonLabel && { display: 'none' }),
                        }}
                        endIcon={
                          imagePosition === 'left' ? (
                            <Iconify icon="solar:arrow-right-outline" />
                          ) : null
                        }
                      >
                        <Typography variant="body1">
                          {translate(buttonLabel as string)}
                        </Typography>
                      </Button>
                    </Link>
                  )}
                </Stack>
              )}
              {title === 'grosseltern-magazin' && <Save50 />}
              {title === 'vorsorgecheck' && (
                <Typography sx={{ fontStyle: 'italic' }}>
                  {String(translate('preventiveCheckUp.allInfos'))}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {youtubePlayer ? (
        <ReactPlayer
          url={youtubePlayer.url[currentLang.value]}
          playing
          light={
            <Image
              className="react-player__preview"
              src={`https://storage.gutgeregelt.ch/assets/youtube/y_${title}.webp`}
              alt="youtube_introduction"
              sx={{
                borderRadius: 2,
                padding: 0,
              }}
            />
          }
          width={isMobile ? '100%' : 670}
          // height={isMobile ? 200 : 400}
        />
      ) : (
        <Image
          disabledEffect
          src={image}
          sx={{
            minWidth: {
              xs: '340px',
              md: '400px',
            },
            height: {
              xs: '245px',
              md: 1,
            },
            boxSizing: 'border-box',
          }}
          data-aos="fade-left"
          alt={`${heading} - ${subHeading}`}
        />
      )}
    </Box>
  );
};

export default Banner;
