const privacyPolicy = {
  title: 'Politique de confidentialité',

  childrenUnderAge: {
    title: 'Enfants de moins de 18 ans',
    content: {
      p1: 'Notre site Web ne s\'adresse pas aux enfants de moins de 18 ans. Les personnes de moins de 18 ans ne doivent pas fournir d\'informations sur ou via le site Web. Nous ne collectons pas sciemment d\'informations personnelles auprès d\'enfants de moins de 18 ans. Si vous avez moins de 18 ans, n\'utilisez pas ce site Web et ne fournissez aucune information sur ce site ou via ses fonctionnalités. Si nous découvrons que nous avons collecté ou reçu des informations personnelles d\'un enfant de moins de 18 ans sans vérification du consentement parental, nous supprimerons ces informations. Si vous pensez que nous pourrions avoir des informations provenant ou concernant un enfant de moins de 18 ans, veuillez nous contacter à support@gutgeregelt.ch.',
    },
  },
  informationWeCollect: {
    title: 'Informations que nous collectons et comment nous les collectons',
    content: {
      p1: 'Nous collectons différents types d\'informations auprès des utilisateurs de notre site Web, y compris des informations :',
      p2: '• Qui vous identifient personnellement, comme votre nom ou votre adresse e-mail ou un autre identifiant par lequel vous pouvez être contacté en ligne ou hors ligne ("données personnelles") ;',
      p3: '• Qui vous concernent, mais ne vous identifient pas individuellement ; et/ou',
      p4: '• Concernant votre connexion Internet, les appareils que vous utilisez pour accéder à notre site Web et les détails de votre utilisation.',
      p5: 'Nous collectons ces informations :',
      p6: '• Directement de vous lorsque vous nous les fournissez.',
      p7: '• Automatiquement lorsque vous naviguez sur le site. Les informations collectées automatiquement peuvent inclure des détails d\'utilisation, des adresses IP et des informations collectées via des cookies.',
      p8: '• De tiers, par exemple, de nos partenaires commerciaux.',
    },
  },
  informationYouProvide: {
    title: 'Informations que vous fournissez',
    content: {
      p1: 'Les informations que nous collectons sur ou via notre site Web peuvent inclure :',
      p2: '• Les informations que vous fournissez en remplissant des formulaires sur notre site Web. Cela inclut les informations fournies lors de l\'inscription pour utiliser notre site Web, l\'abonnement à notre service, la publication de matériel ou la demande de services supplémentaires. Nous pouvons également vous demander des informations lorsque vous participez à un concours ou à une promotion que nous parrainons et lorsque vous signalez un problème avec notre site Web.',
      p3: '• Les enregistrements et copies de votre correspondance (y compris les adresses e-mail), si vous nous contactez.',
      p4: '• Vos réponses aux enquêtes que nous pourrions mener à des fins de recherche.',
      p5: '• Vos recherches sur le site.',
      p6: 'Vous pouvez également fournir des informations à publier ou à afficher dans des zones publiques du site Web (ci-après "publiées") ou transmises à d\'autres utilisateurs du site Web ou à des tiers (collectivement, "contributions des utilisateurs"). Vos contributions d\'utilisateurs sont publiées et transmises à d\'autres à vos propres risques. Bien que nous limitions l\'accès à certaines pages, vous devez être conscient qu\'aucune mesure de sécurité n\'est parfaite ou impénétrable. De plus, nous ne pouvons pas contrôler les actions des autres utilisateurs du site Web avec lesquels vous pouvez choisir de partager vos contributions d\'utilisateurs. Par conséquent, nous ne pouvons pas garantir que vos contributions d\'utilisateurs ne seront pas vues par des personnes non autorisées.',
    },
  },
  informationWeCollectThroughAutomaticData: {
    title:
      'Informations que nous collectons par le biais de technologies de collecte de données automatiques',
    content: {
      p1: 'Lorsque vous naviguez sur notre site Web et interagissez avec lui, nous pouvons utiliser des technologies de collecte de données automatiques pour collecter certaines informations sur vos appareils, vos activités de navigation et vos habitudes, y compris :',
      p2: '• Les détails de vos visites sur notre site Web, y compris les données de trafic, les données de localisation, les journaux et autres données de communication ainsi que les ressources auxquelles vous accédez et que vous utilisez sur le site Web.',
      p3: '• Les informations sur votre ordinateur et votre connexion Internet, y compris votre adresse IP, votre système d\'exploitation et le type de navigateur.',
      p4: 'Les informations que nous collectons automatiquement peuvent inclure des données personnelles. Nous les collectons pour améliorer notre site Web et offrir un service meilleur et plus personnalisé, par exemple en :',
      p5: '• Estimant la taille de notre audience et les habitudes d\'utilisation.',
      p6: '• Stockant des informations sur vos préférences, nous permettant de personnaliser notre site Web en fonction de vos intérêts individuels.',
      p7: '• Accélérant vos recherches.',
      p8: '• Vous reconnaissant lorsque vous revenez sur notre site Web.',
      p9: 'Les technologies que nous utilisons pour cette collecte de données automatique peuvent inclure :',
      p10: '• Cookies (ou cookies de navigateur). Un cookie est un petit fichier placé sur le disque dur de votre ordinateur. Vous pouvez refuser d\'accepter les cookies de navigateur en activant le paramètre approprié sur votre navigateur. Cependant, si vous sélectionnez ce paramètre, vous pourriez ne pas être en mesure d\'accéder à certaines parties de notre site Web. À moins que vous n\'ayez ajusté les paramètres de votre navigateur pour qu\'il refuse les cookies, notre système émettra des cookies lorsque vous dirigerez votre navigateur vers notre site Web.',
      p11: '• Cookies Flash. Certaines fonctionnalités de notre site Web peuvent utiliser des objets de stockage local (ou cookies Flash) pour collecter et stocker des informations sur vos préférences et votre navigation vers, depuis et sur notre site Web. Les cookies Flash ne sont pas gérés par les mêmes paramètres de navigateur que ceux utilisés pour les cookies de navigateur.',
    },
  },

  informationVoluntarily: {
    title:
      'Informations que vous fournissez volontairement pour l\'utilisation des services',
    content: {
      p1: 'Nous collectons les informations que vous nous fournissez volontairement pour répondre à une demande, par exemple lorsque vous vous inscrivez pour utiliser nos services et notre site Web, demandez de l\'assistance à notre équipe de support, nous contactez par e-mail, remplissez un formulaire de contact, souhaitez utiliser nos services ou communiquez avec nous de toute autre manière. Ces informations incluent votre prénom et votre nom, le nom de votre entreprise, l\'adresse physique, l\'adresse e-mail et certains types d\'informations commerciales de votre entreprise, telles que les informations de paiement des clients, le chiffre d\'affaires, les informations d\'analyse de site Web, le taux de désabonnement des clients, la valeur client à vie et d\'autres métriques et données, ainsi que toutes les autres informations que vous nous fournissez concernant votre entreprise.',
      p2: 'En particulier après votre inscription pour utiliser nos services en tant que start-up, si vous souhaitez afficher des données de vos comptes avec des outils et applications tiers tels que Stripe, Google Analytics, ChartMogul, Baremetrics et ProfitWell sur votre page d\'informations pour les acheteurs potentiels, en vous connectant à votre compte sur ces outils et applications tiers sur la page d\'autorisation ou en nous fournissant vos clés API ou ID de vue sur ces outils et applications tiers, vous nous autorisez et nous accordez un accès en lecture à des informations spécifiques dans vos comptes chez ces tiers. Voici les informations que nous pouvons collecter avec votre autorisation de ces tiers :',
      p3: '• Stripe – Accès en lecture seule aux données de revenus des clients pour les 12 derniers mois',
      p4: '• Google Analytics – Accès en lecture seule aux données de trafic du site Web pour les 12 derniers mois',
    },
  },
  useOfCookies: {
    title: 'Utilisation des cookies par des tiers',
    content: {
      p1: 'Certains contenus ou applications sur le site Web sont fournis par des tiers, tels que des fournisseurs de contenu et des fournisseurs d\'applications comme Google Analytics et Hubspot, comme mentionné ci-dessus. Ces tiers utilisent des cookies pour collecter des informations vous concernant lorsque vous utilisez notre site Web. Les informations qu\'ils collectent peuvent être associées à vos informations personnelles ou ils peuvent collecter des informations, y compris des informations personnelles, sur vos activités en ligne au fil du temps et sur différents sites Web et autres services en ligne.',
    },
  },
  howWeUseYourInformation: {
    title: 'Comment nous utilisons vos informations',
    content: {
      p1: 'Nous utilisons les informations que nous collectons à votre sujet ou que vous nous fournissez, y compris toutes les informations personnelles :',
      p2: '• Pour vous présenter notre site Web et son contenu.',
      p3: '• Pour faciliter la liste de votre profil d\'acheteur, de vendeur ou de conseiller sur le site Web.',
      p4: '• Pour vous fournir des informations, des produits ou des services que vous nous demandez, y compris, mais sans s\'y limiter (pour les utilisateurs de start-up), l\'affichage d\'informations commerciales autorisées de start-ups pour certains acheteurs potentiels.',
      p5: '• Pour remplir tout autre objectif pour lequel vous les fournissez.',
      p6: '• Pour vous envoyer des notifications concernant votre compte, y compris des avis d\'expiration et de renouvellement.',
      p7: '• Pour exécuter nos obligations découlant de tout contrat conclu entre vous et nous et pour faire valoir nos droits, y compris à des fins de facturation et de recouvrement.',
      p8: '• Pour vous informer des changements apportés à notre site Web ou aux produits ou services que nous offrons.',
      p9: '• Pour vous permettre de participer à des fonctionnalités interactives sur notre site Web.',
      p10: '• De toute autre manière que nous pouvons décrire lorsque vous fournissez les informations.',
      p11: '• Pour tout autre objectif avec votre consentement.',
      p12: 'Avec votre consentement, nous utiliserons vos informations pour vous informer sur nos propres biens et services ainsi que sur les biens et services de tiers qui pourraient vous intéresser. Si vous ne souhaitez pas que nous utilisions vos informations de cette manière, veuillez ajuster vos préférences utilisateur dans votre profil de compte. Pour plus d\'informations, consultez la section "Vos droits et contrôles en matière d\'informations" ci-dessous.',
    },
  },
  saleOfPersonalInformation: {
    title: 'Vente d\'informations personnelles',
    content: {
      p1: 'Veuillez noter que nous ne vendons pas vos informations personnelles à des tiers.',
    },
  },

  disclosureOfYourInformation: {
    title: 'Divulgation de vos informations',
    content: {
      p1: 'Nous pouvons divulguer des informations agrégées sur nos utilisateurs ainsi que des informations qui ne permettent pas d\'identification individuelle ou qui ne relèvent pas autrement de la définition de "Informations personnelles", "Données personnelles" ou d\'une définition similaire en vertu d\'un règlement, sans restriction.',
      p2: 'Nous pouvons divulguer des informations personnelles que nous collectons ou que vous fournissez conformément à cette politique de confidentialité :',
      p3: '• À nos filiales et sociétés affiliées pour nous permettre de fournir nos services.',
      p4: '• Aux entrepreneurs, prestataires de services et autres tiers que nous utilisons pour soutenir notre entreprise et qui sont contractuellement tenus de garder les informations personnelles confidentielles et de ne les utiliser que pour les fins pour lesquelles nous les divulguons.',
      p5: '• À un acheteur ou à un autre successeur en cas de fusion, de cession, de restructuration, de réorganisation, de dissolution ou de toute autre vente ou transfert de tout ou partie des actifs de l\'entreprise, que ce soit dans le cadre d\'une opération en cours ou dans le cadre d\'une faillite, d\'une liquidation ou d\'une procédure similaire, dans laquelle les données personnelles détenues par l\'entreprise sur les utilisateurs de notre site Web font partie des actifs transférés.',
      p6: '• Pour remplir l\'objectif pour lequel vous fournissez les informations.',
      p7: '• Pour tout autre objectif que nous spécifions lorsque vous fournissez les informations.',
      p8: '• Avec votre consentement.',
      p9: 'Nous pouvons également divulguer vos informations personnelles :',
      p10: '• Pour se conformer à une ordonnance du tribunal, à une loi ou à une procédure judiciaire, y compris pour répondre à des demandes d\'autorités gouvernementales ou de régulateurs.',
      p11: '• Pour faire respecter nos conditions d\'utilisation et autres accords, y compris à des fins de facturation et de recouvrement.',
      p12: '• Si nous pensons que la divulgation est nécessaire ou appropriée pour protéger les droits, la propriété ou la sécurité de l\'entreprise, de nos clients ou d\'autres personnes. Cela inclut l\'échange d\'informations avec d\'autres entreprises et organisations à des fins de protection contre la fraude et de réduction des risques de crédit.',
    },
  },
  informationRights: {
    title: 'Vos droits et contrôles en matière d\'informations',
    content: {
      p1: 'Le Règlement général sur la protection des données ("RGPD") accorde aux individus des droits sur leurs informations personnelles. Bien que les droits suivants soient spécifiques aux membres de l\'Espace économique européen (EEE), nous nous efforçons d\'accorder à tous nos utilisateurs les mêmes droits que les membres de l\'EEE conformément au RGPD, dans la mesure du possible.',
      p2: 'Veuillez vous familiariser avec vos droits et leurs descriptions dans le tableau ci-dessous.',
      p3: 'Vous avez le droit :',
      p4: 'Accès : Informez-vous sur les données personnelles que nous traitons à votre sujet et demandez-en l\'accès.',
      p5: 'Rectification : Demandez-nous de mettre à jour ou de corriger des données inexactes ou incomplètes vous concernant.',
      p6: 'Effacement : Demandez-nous de supprimer vos données.',
      p7: 'Limitation du traitement : Demandez-nous de restreindre le traitement de vos données personnelles.',
      p8: 'Portabilité des données : Demandez-nous de vous fournir ou de fournir à un tiers une copie de vos données personnelles dans un format structuré, couramment utilisé et lisible par machine.',
      p9: 'Opposition : Opposez-vous au traitement de vos données personnelles.',
      p10: 'Opposition au marketing : Opposez-vous au traitement de vos données personnelles à des fins de marketing.',
      p11: 'Pas de prise de décision automatisée : Ne soyez pas soumis à une décision basée uniquement sur un traitement automatisé (décisions sans intervention humaine), y compris le profilage, si la décision a des effets juridiques sur vous ou vous affecte de manière significative de façon similaire.',
    },
  },
  exerciseRights: {
    title: 'Comment exercer vos droits chez nous',
    content: {
      p1: 'Accès : Pour faire une demande vérifiable d\'informations sur les données personnelles que nous avons collectées à votre sujet, veuillez envoyer un e-mail à support@gutgeregelt.ch. Veuillez noter que presque toutes les informations que nous collectons à votre sujet sont incluses dans votre profil de compte sur la plateforme.',
      p2: 'Rectification : Vous pouvez modifier vos informations personnelles dans votre profil de compte sur notre plateforme.',
      p3: 'Effacement : Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch. Veuillez noter que nous supprimerons vos données personnelles :',
      p4: '• Nous pouvons conserver vos données personnelles aussi longtemps que nécessaire pour nos intérêts commerciaux légitimes, tels que la prévention du blanchiment d\'argent, la détection et la prévention de la fraude et l\'amélioration de la sécurité. Par exemple, si nous suspendons un compte gut geregelt pour des raisons de fraude ou de sécurité, nous pouvons conserver des informations de ce compte gut geregelt pour empêcher la personne d\'ouvrir un nouveau compte gut geregelt à l\'avenir.',
      p5: '• Nous pouvons conserver et utiliser vos données personnelles dans la mesure nécessaire pour remplir nos obligations légales. gut geregelt peut conserver vos informations, par exemple, pour des obligations fiscales, de rapports légaux et d\'audit ou comme enregistrement d\'un litige avec vous.',
      p6: '• Certaines copies de vos informations (par exemple, les e-mails) peuvent rester dans notre base de données, mais elles seront séparées des identifiants personnels et conservées uniquement à des fins de documentation.',
      p7: 'Limitation : Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch ou en cessant d\'utiliser la plateforme.',
      p8: 'Opposition : Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch.',
      p9: 'Portabilité des données : Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch.',
      p10: 'Prise de décision automatisée : Vous pouvez exercer ce droit en envoyant un e-mail à support@gutgeregelt.ch. Veuillez noter cependant que nous ne procédons à aucune prise de décision automatisée. Toutes les décisions concernant votre profil sont prises en interne par notre personnel de support.',
      p11: 'Vous avez également le droit de contacter votre autorité locale de protection des données pour toute question ou préoccupation.',
    },
  },
  dataStorage: {
    title: 'Stockage et suppression des données',
    content: {
      p1: 'Conformément au RGPD et à d\'autres réglementations pertinentes, nous ne conservons vos données personnelles que le temps nécessaire pour fournir les services disponibles sur la plateforme gut geregelt et pour nos objectifs commerciaux légitimes et essentiels.',
    },
  },
  deletion: {
    title: 'Suppression',
    content: {
      p1: 'Si vous fermez votre compte ou nous demandez de le fermer, nous supprimerons, anonymiserons ou modifierons vos données personnelles de manière à ce qu\'elles ne vous identifient plus. Sauf si nous sommes légalement tenus de conserver quelque chose ou si nous devons continuer à l\'utiliser pour des raisons légalement justifiées.',
      p2: 'Voici quelques exemples de situations dans lesquelles nous sommes légalement autorisés ou tenus de conserver certaines de vos données personnelles :',
      p3: '• S\'il y a un problème non résolu lié à votre compte, tel qu\'un paiement en attente ou une plainte ou un litige non résolu.',
      p4: '• Pour nos obligations légales, fiscales, d\'audit et comptables.',
      p5: '• Pour nos intérêts commerciaux légitimes, tels que la prévention de la fraude ou le maintien de la sécurité.',
      p6: 'Pour plus d\'informations, consultez la section sur la suppression ci-dessus.',
      p7: 'Dans certains cas, nous pouvons anonymiser vos données personnelles ou les modifier de manière à ce qu\'elles ne soient plus identifiables, pour prévenir la fraude ou déterminer si vous êtes un ancien utilisateur lorsque vous vous réinscrivez sur la plateforme gut geregelt, afin de prévenir la fraude.',
      p8: 'Nous avons pris des mesures pour protéger vos données personnelles contre la perte accidentelle et l\'accès, l\'utilisation, la modification et la divulgation non autorisés.',
      p9: 'La sécurité de vos informations dépend également de vous. Si nous vous avons fourni (ou si vous avez choisi) un mot de passe pour accéder à certaines parties de notre site Web, vous êtes responsable de garder ce mot de passe confidentiel. Nous vous demandons de ne pas partager votre mot de passe avec d\'autres personnes. Veuillez faire preuve de prudence lorsque vous partagez des informations dans des zones publiques du site Web, telles que des forums. Les informations que vous partagez dans les zones publiques peuvent être vues par tout utilisateur du site Web.',
      p10: 'Malheureusement, la transmission d\'informations via Internet n\'est pas entièrement sécurisée. Bien que nous fassions de notre mieux pour protéger vos données personnelles, nous ne pouvons garantir la sécurité de vos données personnelles transmises à notre site Web. Toute transmission de données personnelles se fait à vos propres risques. Nous ne sommes pas responsables du contournement des paramètres de confidentialité ou des mesures de sécurité sur le site Web.',
    },
  },
  changes: {
    title: 'Modifications de notre politique de confidentialité',
    content: {
      p1: 'Il est de notre politique de publier toutes les modifications que nous apportons à notre politique de confidentialité sur cette page. Si nous apportons des modifications importantes à la manière dont nous traitons les données personnelles de nos utilisateurs, nous vous en informerons par une notification sur la page d\'accueil du site Web. La date de la dernière révision de la politique de confidentialité est indiquée en haut de la page. Vous êtes responsable de vous assurer que nous avons une adresse e-mail actuelle, active et livrable pour vous, et de visiter régulièrement notre site Web et cette politique de confidentialité pour vérifier les modifications.',
    },
  },
  contantInformation: {
    title: 'Informations de contact',
    content: {
      p1: 'Si vous avez des questions ou des commentaires concernant cette politique de confidentialité et nos pratiques de confidentialité, veuillez nous contacter ainsi que notre délégué à la protection des données à support@gutgeregelt.ch.',
      p2: 'Pour enregistrer une plainte ou une préoccupation, veuillez envoyer un e-mail à support@gutgeregelt.ch.',
    },
  },
};

export default privacyPolicy;