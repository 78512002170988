const outputDoc = {
  title: 'Cohabitation agreement',
  from: 'from ',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  civil_status: 'Civil status: {{civil_status}}',
  address: 'Address: {{address}}',
  male: 'Husband',
  female: 'Wife',
  and: 'and',
  between: 'Between',
  contractPartiesAgree: 'The contract parties hereby agree on the following: ',
  spousePresentation:
    '{{partnerSurname}} {{partnerName}}, born on {{partnerBirthday}}, from {{partnerCitizenship}}, {{partnerCivilStatus}}, residing at {{partnerAdress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, born on {{birthday}}, from {{citizenship}}, {{civilStatus}}, residing at {{adress}}.',
  preliminaryRemark: {
    title: 'Preliminary remarks',
    section1: {
      firstDefault:
        'We met in the year {{meet_year}} and have been living together at {{adress}} since {{date_of_moving_in}}.',
      secondDefault:
        'We intend to maintain our cohabitation relationship for an indefinite period.',
      bothEmployedNoChildren: 'We are both engaged in full-time employment.',
      oneWorksFullTime:
        '{{who_works_full_time}} is employed full-time, and {{who_works_part_time}} works part-time ({{workload_distribution}}%).',
      oneFullTimeOneHousehold:
        '{{who_works_full_time}} is employed full-time, while {{householder}} manages the household and cares for {{childrenNo}}.',
      singularChild: 'the child',
      plurarChildren: 'the children',
    },
  },
  ownership: {
    title: 'Ownership arrangements',
    section1: {
      text: 'An inventory of furniture and valuable items will be created and updated regularly. This inventory, signed by both parties, is an integral part of this agreement. Whoever claims ownership of a specific item not listed in this inventory must provide evidence of ownership. If this evidence cannot be provided, co-ownership by both partners is presumed.',
    },
  },
  costOfLiving: {
    title: 'Cost of living',
    section1: {
      firstText:
        'Each of us contributes to the joint living expenses within our respective financial means. For this purpose, we have opened a joint bank account at {{joint_household_account_bank}}.',
      bothEmployedNoChildren: {
        firstText:
          'Each month in advance, no later than the first day of the month, {{firstParty}} and {{secondParty}} deposit CHF {{equal_payment_amount}} {{equal_payment_frequency}}.',
        secondText: 'First payment due by {{date_of_first_payment}}.',
        thirdText:
          'The account balance will be checked {{compensation_payment_cadence}}. If the balance is insufficient, both partners will cover it equally.',
      },
      oneWorksFullTime: {
        firstText:
          'Each month in advance, no later than the first day, {{firstParty}} deposits CHF {{amount_paid_by_me}} and {{secondParty}} deposits CHF {{amount_paid_by_partner}} {{equal_payment_frequency}}.',
        secondText:
          '(= Proportion {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}) into the joint bank account.',
        thirdText: 'First payment due by {{date_of_first_payment}}.',
        fourthText:
          'The account balance will be checked {{compensation_payment_cadence}}. If the balance is insufficient, it will be covered by both partners in the ratio {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}.',
      },
      oneFullTimeOneHousehold: {
        firstText:
          'Each month in advance, no later than the first day, {{who_works_full_time}} deposits CHF {{equal_payment_amount}} {{equal_payment_frequency}} into the bank account.',
        secondText: 'First payment due by {{date_of_first_payment}}.',
        thirdText:
          'The account balance will be checked {{compensation_payment_cadence}}. If the balance is insufficient, {{who_works_full_time}} will cover it.',
      },
    },
    section2: {
      title: 'The following expenses will be covered from this account:',
      expensesOptions: {
        rent: 'Rent',
        additional:
          'Additional housing costs such as electricity, heating, water, waste disposal fees, etc.',
        radioTV: 'Radio and TV connection',
        groceries: 'Groceries',
        cleaning: 'Cleaning expenses',
      },
      bothEmployedNoChildren:
        'Household responsibilities are shared equally. Otherwise, each party manages and uses their income and assets independently. Personal expenses (clothing, education, transportation, etc.) are borne by each partner individually. Each partner is solely liable for their personal debts with all their personal assets. Upon dissolution, any remaining balance in the joint account will be split equally.',
      oneWorksFullTime:
        'Household responsibilities are primarily handled by {{who_works_part_time}}. The compensation from {{who_works_full_time}} to {{who_works_part_time}} is included in {{partnerGender}} contribution to the household account. Otherwise, each party manages and uses their income and assets independently. Personal expenses are borne individually, and each partner is solely liable for their personal debts with all their personal assets. Upon dissolution, any remaining balance in the joint account will be divided in the ratio {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}.',
      malePartner: 'his',
      femalePartner: 'her',
      oneFullTimeOneHousehold: {
        firstText:
          'Household management is primarily handled by {{household}}.',
        isFreeDisposal:
          '{{who_works_full_time}} pays {{household}} a monthly amount of CHF {{free_disposal_amount}}.',
        thirdText: "This amount is at {{household}}'s free disposal.",
        fourthText:
          'Otherwise, each partner manages and uses their income and assets independently. Personal expenses are borne individually, and each partner is solely liable for their personal debts with all their personal assets.',
        fifthText:
          'Upon dissolution, any remaining balance in the joint account will be divided equally.',
      },
    },
  },
  children: {
    title: '{{childrenNo}}',
    plural: 'Children',
    singular: 'Child',
    text: 'The maintenance for {{childrenNo}} is regulated by an agreement approved by the guardianship authority, which is an integral part of this agreement.',
    pluralText: 'of the child',
    singularText: 'of the children',
  },
  living: {
    title: 'Housing',
    onePartyNameAgreement:
      'The current lease is in the name of {{agreement_name}} only. We intend for both to be added as tenants, and we will agree with the landlord to amend the lease accordingly.',
    onePartyNameAgreementSublease:
      'The current lease is in the name of {{agreement_name}} only. The parties agree that {{agreement_name}} will sublet to {{non_agreement_name}}. This sublease is an integral part of this agreement.',
    onePartyNameAgreementNoPlans:
      'The current lease is in the name of {{agreement_name}} only.',
    bothPartiesNameAgreement: 'The lease is in both names.',
    rentBudget: 'Rent will be paid from the joint household account.',
    equalRights:
      'Both partners have the right to sign the lease as a tenant in case of a housing change.',
  },
  cirsumstancesChange: {
    title: 'Change of circumstances',
    noKids:
      'We commit to amending this agreement in case of changes in personal circumstances, particularly if expecting a child.',
    withKids:
      'We commit to amending this agreement in case of changes in personal circumstances, particularly if expecting another child.',
  },
  dissolution: {
    title: 'Dissolution of Cohabitation Relationship',
    firstText:
      'Upon dissolution of the cohabitation relationship, each partner retrieves their own assets that are in the possession of the other. Assets co-owned are to be divided fairly and equitably. Neither partner is entitled to a refund for gifts.',
    secondText:
      'This agreement becomes effective upon signing and is subject to Swiss law. The exclusive place of jurisdiction for disputes arising from this agreement is {{city}}.',
  },
  signature: {
    place: 'Place',
    date: 'Date',
  },
  inventory: {
    title: 'Inventory',
    agreementBetween: 'Supplement to the cohabitation agreement between',
    partnerPresentation:
      '{{partnerSurname}}, {{partnerName}}, born on {{partnerBirthday}}, from {{partnerCitizenship}}, {{partnerCivilStatus}}, residing at {{partnerAdress}}.',
    personalPresentation:
      '{{firstName}} {{lastName}}, born on {{birthday}}, from {{citizenship}}, {{civilStatus}}, residing at {{adress}}.',
    soleOwnership: 'The following items are solely owned by {{owner}}:',
    notListedItems:
      'Items not listed are solely owned by the party holding the corresponding receipt. In case of doubt, co-ownership is presumed.',
    joinedOwnership: 'The following items were purchased jointly:',
    otherPersonals:
      'Items of daily use, such as clothing and other personal belongings, are not included in this inventory.',
    ownershipBenefiter:
      'Upon separation, the following items will become the sole property of {{owner}}:',
  },
};

export default outputDoc;
