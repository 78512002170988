import { useForm } from 'react-hook-form';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import useAuth from '../../../lib/hooks/useAuth';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';
import { useUpdateProfile } from '#/api/userQueries';
import useLocales from '#/hooks/useLocales';
import PhoneInput from '#/components/shared/hook-form/PhoneInput';
import AccountPicture from '#/components/pages/User/AccountPicture';
import { invalidateServicesStatsQueries } from '#/api/servicesQueries';

type FormValuesProps = {
  first_name: string;
  last_name: string;
  email: string;
  mobile_phone: string;
};

export default function AccountUser() {
  const { user, refetch } = useAuth();

  const { translate } = useLocales();

  const { mutateAsync: updateProfile, isLoading } = useUpdateProfile();

  const defaultValues = {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    mobile_phone: user?.mobile_phone || '',
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = methods;

  const handlePhoneChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setValue('mobile_phone', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const disabled = !isDirty || isSubmitting;

  const onSubmit = async (data: FormValuesProps) => {
    if (JSON.stringify(data) === JSON.stringify(defaultValues)) {
      toast.loading('Nothing to update here...', {
        duration: 1500,
        icon: '🤔',
      });

      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updateProfile(data)
      .then(() => {
        toast.success(translate('toast_notifications.success.profile_update'));
      })
      .then(() => {
        refetch();
        invalidateServicesStatsQueries.getServicesStats();
      })
      .catch(() => {
        toast.error(translate('toast_notifications.error.profile_update'));
      });
  };

  useEffect(() => {
    if (user) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Typography variant="subtitle1" sx={{ px: 3 }}>
        {translate('adminDashboard.user')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <AccountPicture />
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <RHFTextField
                    name="first_name"
                    label={translate('global.formLabels.firstName')}
                  />
                  <RHFTextField
                    name="last_name"
                    label={translate('global.formLabels.lastName')}
                  />
                </Stack>

                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <RHFTextField
                    name="email"
                    label={translate('global.formLabels.emailAddress')}
                    disabled
                  />
                  <PhoneInput
                    name="mobile_phone"
                    label={translate('global.formLabels.mobilePhone')}
                    value={watch('mobile_phone')}
                    onChange={(e) => handlePhoneChange(e)}
                    sx={{ width: '100%' }}
                  />
                </Stack>

                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton
                    aria-label="save changes"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting || isLoading}
                    disabled={disabled}
                  >
                    <Typography>{translate('global.save')}</Typography>
                  </LoadingButton>
                </Stack>
              </Stack>
            </Card>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}
