const guest = {
  title: 'Provisions of {{first_name}} {{last_name}}',
  description:
    'As long as your host is alive, you will have limited access to the data and information.',
  seeWhatsIncluded: 'see what is included',
  includedTooltip:
    'With guest access, these individuals will only have access to the living will and the power of attorney.',
  reportDeath: 'Report death',
  reportDeathDescription:
    "To gain full access to all documents and information of {{first_name}}, you will understand that we need to first confirm the death and require the official death certificate as well as the executor's certificate (both can be obtained from the municipality).",
  alreadyReported: 'Already reported',
};

export default guest;
