import {
  Chip,
  Divider,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';
import RHFAutocompleteTwo from '#/components/shared/hook-form/RHFAutocomplete2';
import {
  invalidatePartnerQueries,
  useGetWidgetList,
  useUnlockWidget,
} from '#/api/partnerQueries';
import { IUnlockWidget } from '#/types/partner';

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function UnlockWidget({ open, onClose }: Props) {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(translate('adminDashboard.consulting.unlockWidget.title'))}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {String(
            translate('adminDashboard.consulting.unlockWidget.description')
          )}
        </Typography>

        <Stack spacing={2} sx={{ mt: 2 }}>
          <UnlockWidgetForm closeModal={onClose} />
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}

export const widgetsData = [
  {
    id: '3',
    name: 'adminDashboard.widgetsOptions.lifeExpectancy',
  },
  {
    id: '2',
    name: 'adminDashboard.widgetsOptions.ageAppropriate',
  },
  {
    id: '1',
    name: 'adminDashboard.widgetsOptions.ahvCalculator',
  },
];

type FormProps = {
  closeModal: () => void;
};

const UnlockWidgetForm = ({ closeModal }: FormProps) => {
  const { translate } = useLocales();

  const { data: widgets } = useGetWidgetList();
  const { mutateAsync: unlockWidget, isLoading } = useUnlockWidget();

  const defaultValues = {
    widgets: [],
  };

  const methods = useForm<IUnlockWidget>({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = async (data: IUnlockWidget) => {
    try {
      await unlockWidget(data).then(() => {
        toast.success(
          String(translate('toast_notifications.success.widgetRequest'))
        );
        invalidatePartnerQueries.widgetList();
        closeModal();
      });
    } catch (error) {
      toast.error(String(translate('toast_notifications.error.widgetRequest')));
    }
  };

  const getWidgetLabel = (id: string) => {
    const name = widgetsData.find((widget) => widget.id === id)?.name;
    return translate(name as string);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <RHFAutocompleteTwo
          name="widgets"
          label={String(translate('global.formLabels.addWidget'))}
          placeholder={String(translate('global.formLabels.plusWidget'))}
          multiple
          freeSolo
          options={widgetsData.map((option) => option.id)}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => {
            const isDisabled = widgets?.widgets?.some(
              (widget) => widget.id === Number(option) && widget.requested
            );
            return (
              <MenuItem {...props} key={option} disabled={isDisabled}>
                {getWidgetLabel(option)}
              </MenuItem>
            );
          }}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={getWidgetLabel(option)}
                size="small"
                color="primary"
                variant="filled"
              />
            ))
          }
        />
        <RHFTextField
          multiline
          name="message"
          label={String(translate('global.formLabels.message'))}
          rows={6}
        />
        <Divider />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            <Typography>
              {String(translate('global.formLabels.submit'))}
            </Typography>
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
