const partnerWithUs = {
  mainBanner: {
    title: 'Provide access to digital retirement solutions',
    subtitle:
      'Pension funds, banks, insurance companies, associations, and media partners collaborate with gut geregelt to offer people easy access to comprehensive retirement planning.',
    buttonLabel: 'Talk with us',
  },
  youtubeTutorial: 'Why partner with us?',
  aboutCards: {
    differentiate: {
      title: 'Achieve differentiation',
      description:
        'Generate more traffic for your platform with additional value add services and relevant content.',
    },
    efficiency: {
      title: 'Increase efficiency',
      description:
        'Generate more revenue through additional services while also reducing costs.',
    },
    simplyIntegrated: {
      title: 'Simple integration',
      description:
        'Ready to use with a standalone cloud solution that can be adapted to your corporate design.',
    },
  },
  subBanner: {
    title: 'Available as self-service or professional advisory solution',
    subtitle:
      'gut geregelt can be used as a whitelabel self-service solution for end customers or as an advisory tool to support your consulting process. API integration into an existing solution is also possible.',
  },
  talkWithUs: {
    title: 'Help people lead a well-organized life',
    description:
      'Expand your service offerings and provide meaningful retirement planning services for people who want to simplify their lives and make a positive impact on their families.',
    talkAboutPossibilities: "Let's discuss possibilities",
    iam: 'I am a',
    partnerOccupations: {
      bank: 'Bank',
      insurance: 'Insurance',
      pensionFund: 'Pension fund',
      association: 'Association',
      media: 'Media partner',
      trust_foundation: 'Foundation',
      other: 'Other',
    },
  },
  solutions: {
    pensionFund: {
      title: 'Pension fund',
      content:
        "We've been trying to increase traffic to our customer portal for some time, but aside from the annual statements and calculators, there's little appeal. With gut geregelt, we broaden retirement planning options while driving traffic through an innovative offering.",
    },
    bank: {
      title: 'Bank',
      content:
        'While banks have traditionally focused on financial provisioning, we believe banks should also support clients in ensuring their legal affairs are in order. A tool like gut geregelt makes this possible.',
    },
    fintechPlatform: {
      title: 'FinTech platform',
      content:
        "As a solution, we're already closely connected to clients' financial planning. Expanding our offerings with enhanced, legally secure retirement solutions is a logical next step to provide clients with comprehensive retirement planning.",
    },
  },
  faq: {
    questions: {
      question1: 'What value does gut geregelt offer my customers?',
      question2: 'Where is the data stored?',
      question3: 'Is gut geregelt compliant with GDPR and Swiss data laws?',
      question4: 'Can I use gut geregelt as an advisory tool?',
      question5: 'Is gut geregelt available as a whitelabel solution?',
      question6: 'How can I integrate gut geregelt into my offering?',
      question7: 'What languages are supported?',
      question8: 'Can I involve partner organizations?',
      question9: 'What benefits does gut geregelt offer me as a trustee?',
      question10: 'Can I request additional features?',
      question11: 'What is the commercial model?',
    },
    answers: {
      answer1:
        'With gut geregelt, people gain easy access to digital retirement services that are legally secure. We help them take the first steps toward a well-organized life, giving them and their families more control and less stress in critical situations.',
      answer2:
        'The data is stored in Switzerland. For partner organizations in Germany and Austria, we provide an EU-compliant zone.',
      answer3:
        'Yes, gut geregelt was designed with GDPR and Swiss data privacy requirements as guiding principles, forming the foundation of its product architecture.',
      answer4:
        'Yes, gut geregelt offers a self-service solution for end customers as well as an advisor dashboard. This allows your advisors to develop solutions collaboratively with clients, who can then make adjustments or additions independently at a later time.',
      answer5:
        'Yes, gut geregelt is available as a whitelabel solution for organizations such as banks, insurance companies, pension funds, other retirement institutions, financial institutions, and associations. Integration options are varied.',
      answer6:
        'Various integration scenarios are possible. The simplest integration is through a link to gut geregelt. We also offer integrations via SSO and APIs, as well as iFrame embedding.',
      answer7: 'gut geregelt supports German, English, French, and Italian.',
      answer8:
        'gut geregelt offers different partner models and allows you to include experts such as notaries, attorneys, financial advisors, and wealth managers.',
      answer9:
        'As a trustee, gut geregelt allows me to actively minimize liability by encouraging policyholders to plan for retirement, providing a tool to increase portal traffic, and creating greater transparency and efficiency.',
      answer10:
        'Yes, at gut geregelt, we listen carefully to the needs of our clients and partners. We evaluate all requests and prioritize features that offer the greatest value to most clients and partners.',
      answer11:
        'Partners pay an annual fee for the gut geregelt solution. For each completed transaction by end customers, the partner receives a kickback. Some partners choose to partially or fully cover the costs for their end customers, providing the service at a reduced rate.',
    },
  },
};

export default partnerWithUs;
