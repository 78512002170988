import {
  format,
  formatDistanceToNow,
  getTime,
  getYear,
  isSameDay,
  isSameMonth,
} from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';

type InputValue = Date | string | number | null;

export function fDate(
  date: InputValue,
  newFormat?: string,
  localeKey?: string
) {
  const fm = newFormat || 'dd MMM yyyy';

  const locales = { enUS, de, fr };

  const selectedLocale = locales[localeKey as keyof typeof locales] || enUS;

  if (!date) return '';

  const formattedDate = format(new Date(date), fm, {
    locale: selectedLocale,
  });

  const germanMonthWithDot = (inputDateString: string) => {
    const monthsWithDot = [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ];
    let resultDateString = inputDateString;
    monthsWithDot.forEach((month) => {
      const regex = new RegExp(`\\b${month}(\\.|\\b)`, 'g');
      resultDateString = resultDateString.replace(regex, `${month}.`);
    });
    return resultDateString;
  };

  return localeKey === 'de' ? germanMonthWithDot(formattedDate) : formattedDate;
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const fToDB = (dateInput: string | Date | null | undefined) => {
  if (!dateInput) return null;

  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  if (year < 1900) {
    return `${year}-${month}-${day}`;
  }

  return `${year}-${month}-${day}`;
};

export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'p';

  return date ? format(new Date(date), fm) : '';
}

export function isAfter(startDate: Date | null, endDate: Date | null) {
  const results =
    startDate && endDate
      ? new Date(startDate).getTime() > new Date(endDate).getTime()
      : false;

  return results;
}

export function shortDateLabel(startDate: Date | null, endDate: Date | null) {
  const getCurrentYear = new Date().getFullYear();

  const startDateYear = startDate ? getYear(startDate) : null;

  const endDateYear = endDate ? getYear(endDate) : null;

  const currentYear =
    getCurrentYear === startDateYear && getCurrentYear === endDateYear;

  const sameDay =
    startDate && endDate
      ? isSameDay(new Date(startDate), new Date(endDate))
      : false;

  const sameMonth =
    startDate && endDate
      ? isSameMonth(new Date(startDate), new Date(endDate))
      : false;

  if (currentYear) {
    if (sameMonth) {
      if (sameDay) {
        return fDate(endDate, 'dd MMM yy', 'de');
      }
      return `${fDate(startDate, 'dd', 'de')} - ${fDate(
        endDate,
        'dd MMM yy',
        'de'
      )}`;
    }
    return `${fDate(startDate, 'dd MMM', 'de')} - ${fDate(
      endDate,
      'dd MMM yyyy',
      'de'
    )}`;
  }

  return `${fDate(startDate, 'dd MMM yyyy', 'de')} - ${fDate(
    endDate,
    'dd MMM yyyy',
    'de'
  )}`;
}
