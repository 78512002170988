import powerOfTrustQuestions from './questions';
import outputDoc from './outputDoc';

const powerOfAttorney = {
  mainHeaders: {
    shortPainFree: 'Power of attorney and simple',
    madeIt: 'Your effective power of attorney',
  },

  stepperInfo: [
    {
      label: 'Power of trust',
      title: 'What is it about?',
    },
    {
      label: 'Your power of attorney',
      title: 'Submit your power of attorney',
    },
  ],

  stepOne: {
    introParagraph:
      'Unfortunately, there are situations that can lead to our incapacity to make decisions. Examples include serious accidents or illnesses such as dementia.',
    helperParagraph:
      'We therefore recommend that you organise your affairs in advance via a so-called living will to define who should take care of you in such a situation and also represent you legally. This relieves your relatives in an already difficult situation, as your wishes are clearly formulated.',
    helperParagraph2:
      'Let’s get started: This section only takes about 5 minutes. If you need a break, you can pause and save at any time.',
    questions: powerOfTrustQuestions,
  },

  stepTwo: {
    introParagraph: 'Congratulations! You did it.\n',
    helperParagraph:
      'The hardest step is now done. Download the document, print it out, and read it carefully to see if it really meets your wishes. If you are satisfied, we recommend discussing the power of attorney with a trusted person and your family.',
    helperParagraph2:
      'It is advisable to deposit the power of attorney with a lawyer or notary, who can register the storage location at your request with the Swiss Will Register (https://www.ztr.ch).',
    contactForm: 'Contact form',
    helperParagraph3:
      'If you would like us to help you, please use our contact form.',
    obligation1:
      'The power of attorney must be drawn up by hand or publicly notarized. The handwritten power of attorney must be written, dated, and signed by the person giving the order from beginning to end.',
    obligation2: 'If you are unsure, a lawyer or notary can help you.',
    document: outputDoc,
    nextStepsTitle: 'The next steps:',
    nextStep1:
      'Either write out the power of attorney completely by hand, date and sign it, or have it notarized by a lawyer and upload it here',
    nextStep2:
      'Ensure that trusted persons have guest access to gut geregelt so that they can access the power of attorney in an emergency (click here)',
    highlightText: 'click here',
    nextStep3: 'Discuss the power of attorney with the family',
    highlightedText: {
      noterizeWithLawyer: 'Have it notarized by a lawyer',
    },
  },
};

export default powerOfAttorney;
