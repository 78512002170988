import {
  Autocomplete,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';

type IProps = {
  name: string;
  options: any[];
};

type Props = IProps & TextFieldProps;

export default function RHFAutocomplete({
  name,
  helperText,
  options,
  ...other
}: Props) {
  const { control, setValue } = useFormContext();
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { value } = field;
        return (
          <Autocomplete
            freeSolo
            value={options.find((option) => option.value === value) || null}
            options={options}
            onChange={(e, data) => {
              setValue(name, data?.value);
            }}
            getOptionLabel={(option) => option.label}
            sx={{
              width: '100%',
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                {...other}
                error={!!error}
                helperText={
                  error ? translate(error?.message as string) : helperText
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.divider,
                    },
                  },
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
