const poaLanding = {
  mainBanner: {
    intro: 'Create your power of attorney online:',
    title: 'Legally secure, completed in 5 minutes',
    subtitle:
      'Without a power of attorney, even your spouse or family cannot make decisions on your behalf in an emergency. Decide now who will be legally authorized to act in your name.',
    buttonLabel: 'Create power of attorney',
  },
  youtubeTutorial: 'Why is a power of attorney important?',
  aboutCards: {
    guaranteeForPower: {
      title: 'Guarantee of authority',
      description:
        'Enable your loved ones to make decisions in your best interest if you cannot make them yourself.',
    },
    mutualProtection: {
      title: 'Mutual protection',
      description:
        'Protect the integrity of your assets by ensuring that important family decisions are only made by family members.',
    },
    clarificationForFamily: {
      title: 'Clarity for the family',
      description:
        'Eliminate unnecessary discussions and provide clarity for your loved ones.',
    },
  },
  subBanner: {
    title: 'Stay organized with a legally compliant power of attorney',
    subtitle:
      'With gut geregelt, you can quickly create your power of attorney, receive legal advice on sensitive topics or unresolved questions, and do a favor for your loved ones.',
    buttonLabel: 'Create now for free',
  },
  newsletter: {
    title: 'Get organized with your power of attorney',
    buttonLabel: 'Create now',
  },
  situations: {
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Power of Attorney',
          description:
            'A power of attorney determines who will make decisions for you and your family if you are no longer able to do so yourself. Protect yourself and your child with clear arrangements.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'With the birth of a child comes additional responsibility. A power of attorney ensures that your child will be well cared for even in an emergency.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Determine who will care for your child',
              'Legal security for your family',
              'Protection in case of illness or emergency',
            ],
          },
        ],
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Power of Attorney',
          description:
            'A power of attorney determines who will take care of your property and personal affairs if you are no longer able to do so yourself. This protects your home and your family.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'With the purchase of property comes increased responsibility. A power of attorney ensures that your property remains protected even in emergencies and your wishes are carried out.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Protection of your home in case of illness or accident',
              'Security for your family',
              'Legal clarity for emergencies',
            ],
          },
        ],
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Power of Attorney',
          description:
            'The power of attorney specifies who will handle your affairs if you are no longer able to do so yourself. This ensures that your life and assets are managed according to your wishes.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'With retirement age, it should also be determined who will take care of your financial and personal affairs in an emergency.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Clear arrangements for cases of incapacity',
              'Protection of assets and rights',
              'Relief for the family',
            ],
          },
        ],
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Power of Attorney',
          description:
            'A power of attorney determines who will take over your business and private affairs if you are no longer able to do so yourself. This ensures the continuity of your business and protects your family.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'As an entrepreneur, you carry great responsibility – not just for yourself, but also for your employees and business partners. A power of attorney ensures that your company remains operational even in times of crisis.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Protection of the business in case of illness or accident',
              'Continuation of business management in emergencies',
              'Relief for the family',
            ],
          },
        ],
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Power of Attorney',
          description:
            'A power of attorney specifies who will handle your personal and financial affairs if you are no longer able to do so. Especially after a divorce, it is important to redefine these arrangements.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'After a divorce, many legal frameworks often change. An updated power of attorney ensures that your wishes are considered in your new life situation.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'New arrangement of your personal affairs',
              'Protection in case of illness',
              'Clarity and security for the future',
            ],
          },
        ],
      },
    },
  },
  faq: {
    questions: {
      question1: 'What is a power of attorney?',
      question2: 'When does incapacity apply?',
      question3:
        'What happens if I become incapacitated and do not have a power of attorney?',
      question4: 'What must I include in the power of attorney?',
      question5: 'How do I create my power of attorney?',
      question6: 'How do I choose my representative?',
      question7: 'What is the KESB and what is its role?',
      question8: 'Can I adjust my power of attorney whenever I want?',
      question9: 'Can I revoke my power of attorney?',
      question10:
        'Can I include my medical treatment wishes for an emergency in the power of attorney?',
      question11:
        'Are there other documents I should create besides the power of attorney?',
      question12: 'How does gut geregelt assist with my power of attorney?',
    },
    answers: {
      answer1:
        'In a power of attorney, you specify who will represent your interests in personal and financial matters in the event of your incapacity. With a power of attorney, you ensure that your interests are upheld and your wishes are respected.',
      answer2:
        'Incapacity can occur, for instance, due to a serious illness such as dementia, after a stroke, or following a severe accident resulting in a coma.',
      answer3:
        'It depends on your marital status, married or in a registered partnership, or living in a cohabitation arrangement.',
      answer4:
        'A power of attorney includes three areas: care, financial care, and legal transactions. You can assign all areas to the same representative or divide them among multiple representatives.',
      answer5:
        "You can create your power of attorney independently; just don't forget to date and sign it. Alternatively, you can create it with a notary and have it publicly certified.",
      answer6:
        'You can choose one person for all three areas or appoint multiple people. It is crucial that you completely trust this person.',
      answer7:
        "The Child and Adult Protection Authority (KESB) ensures the protection of vulnerable children and adults. In the case of a power of attorney, the KESB becomes involved if they are notified of a person's incapacity.",
      answer8:
        'Yes, with the only requirement being that you are capable at the time of adjustment. A new power of attorney replaces the previous version.',
      answer9:
        'Yes, you can revoke your power of attorney at any time by destroying the document.',
      answer10:
        'Yes, you can. However, it may also make sense to create a separate living will.',
      answer11:
        'Yes, the {{lastWill}} and {{livingWill}} are two other important documents you should prepare in a timely manner.',
      answer12:
        'At gut geregelt, we assist you in creating a legally valid and seamless power of attorney.',
    },
    hyperlinks: {
      lastWill: 'Last will',
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
    },
  },
};

export default poaLanding;
