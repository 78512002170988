import { Button, Paper, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/lib/hooks/useLocales';
import ListToolbar from './list-toolbar';
import ListHead from './list-head';
import ListItem from './list-item';
import cityData from '../../Admin/consulting-legal-table/AddConsultant/cityData';

type Props = {
  open: boolean;
  onClose: () => void;
};

const defaultFilters = {
  advisor_name: '',
  location: [],
  search: '',
};

const locations = cityData.map((city) => city.label);

export default function ExpertsModal({ open, onClose }: Props) {
  const [filters, setFilters] = useState(defaultFilters);

  const { translate } = useLocales();

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );
  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Paper sx={{ py: 2 }}>
        <Stack sx={{ px: 2 }}>
          <Typography variant="subtitle1">
            {translate('global.taxFolder.findTaxExpert.title')}
          </Typography>
          <Typography variant="body2">
            {translate('global.taxFolder.findTaxExpert.description')}
          </Typography>
        </Stack>

        <Stack sx={{ px: 2 }}>
          <ListToolbar
            filters={filters}
            onFilters={handleFilters}
            locationsOptions={locations}
          />
        </Stack>
        <ListHead />
        <ListItem />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mt: 2, px: 2 }}
        >
          <Button variant="contained" onClick={onClose}>
            <Typography>{translate('global.cancel')}</Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}
