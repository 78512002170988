import { Button, Typography } from '@mui/material';
import useLocales from '#/lib/hooks/useLocales';
import useBoolean from '#/lib/hooks/useBoolean';
import ExpertsModal from './experts';

export default function ReqTaxExpert() {
  const openModal = useBoolean();

  const { translate } = useLocales();
  return (
    <>
      <Button variant="outlined" onClick={openModal.onTrue}>
        <Typography>
          {translate('global.taxFolder.requestTaxExpert')}
        </Typography>
      </Button>
      <ExpertsModal open={openModal.value} onClose={openModal.onFalse} />
    </>
  );
}
