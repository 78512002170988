import { treatmentQuestions, detailedQuestions } from './questions';
import outputDoc from './outputDoc';

const livingWill = {
  mainHeaders: {
    shortPainFree: 'La tua direttiva anticipata semplice ed efficace',
    madeIt: 'La tua direttiva anticipata efficace',
    detailedLivingWill: "Ben fatto, ce l'hai fatta",
  },

  stepperInfo: [
    {
      label: 'Fondamenti',
      title: "Qual è l'argomento?",
    },
    {
      label: 'Esecutore testamentario',
      title: 'Le persone che si prendono cura di me',
    },
    {
      label: 'Trattamenti',
      title: 'Quando dovrebbe diventare efficace?',
    },
    // {
    //   label: '',
    //   title: 'Chiarire i dettagli',
    // },
    {
      label: 'La tua direttiva anticipata',
      title: 'Deposita la tua direttiva anticipata',
    },
  ],

  stepOne: {
    introParagraph:
      'Con una direttiva anticipata, determini come desideri essere trattato e curato dal punto di vista medico nel caso in cui tu diventi incapace di intendere e di volere.\n' +
      '\n' +
      'Ogni persona capace di intendere e di volere può creare, modificare o revocare una direttiva anticipata. Nella direttiva anticipata è indicato anche chi la farà rispettare in caso di emergenza. Puoi designare una persona di fiducia autorizzata a prendere decisioni per te e a fungere da contatto per il personale medico. Idealmente, dovresti nominare una persona principale e una sostituta.',
    helperParagraph: 'Per iniziare, compila quanto segue:',
  },

  stepTwo: {
    introParagraph: 'Persona di fiducia',
    helperParagraph: 'La mia persona di fiducia è:',
    optionalRepresentative:
      'Se la persona sopra indicata è impossibilitata, nomino la seguente persona sostituta:',

    contactConfirmation:
      'Confermo di aver informato la/le persona/e di fiducia sui miei desideri.',
  },

  stepThree: {
    introParagraph:
      'Per garantire che la direttiva anticipata corrisponda ai tuoi desideri, ora devi rispondere ad alcune domande impegnative e personali.',
    helperParagraph:
      'Devi riflettere su come definisci la qualità della vita e in quali situazioni rinunceresti a interventi medici. Prenditi tutto il tempo necessario. Con il nostro servizio di promemoria, puoi interrompere e salvare in qualsiasi momento.',
    questions: treatmentQuestions,
  },

  stepFour: {
    introParagraph:
      'Leggi attentamente i seguenti scenari e seleziona per ogni condizione ciò che meglio si adatta a te. Per ogni stato di salute, vogliamo sapere quale trattamento desideri per prolungare la tua vita e/o alleviare la tua sofferenza.',
    questions: detailedQuestions,
    highlightedText: {
      coma: 'Coma',
      dementia: 'Demenza',
      emergency: 'Emergenza',
      goalOnSufferingRelief: 'Obiettivo di alleviare la sofferenza',
      prolongedIncapacity: 'Incapacità prolungata',
      permanentIncapacity: 'Incapacità permanente',
      suddenlyIncacitated: 'Improvvisamente incapace',
      sufferingRelief: 'Alleviamento della sofferenza',
      suddenIncapacitation: 'Incapacità improvvisa',
      treatmentGoalForLifeExtension:
        "Obiettivo del trattamento per l'estensione della vita",
      treatmentGoalsForLifeExtension:
        "Obiettivi del trattamento per l'estensione della vita",
      treatmentGoalForSufferingRelief:
        'Obiettivo del trattamento per alleviare la sofferenza',
      vegetativeState: 'Stato vegetativo',
    },
  },

  stepFive: {
    introParagraph: 'Ben fatto, ora sei ben organizzato!',
    helperParagraph:
      'Il passo più difficile è stato completato. Ora devi solo stampare, datare e firmare la direttiva anticipata e consegnarne una copia alla/e tua/e persona/e di fiducia (preferibilmente di persona) e al tuo medico di base. Discuti la direttiva anticipata con la tua famiglia a tempo debito.',
    here: 'qui',
    eightMinutes: '8 minuti',
    helperParagraph2:
      'Se desideri una versione più dettagliata del mandato precauzionale, puoi continuare qui, il tempo richiesto è di circa 8 minuti.',
    document: outputDoc,
    nextStepsTitle: 'I prossimi passi:',
    nextStep1: 'Stampa, data, firma la direttiva anticipata e caricala qui',
    nextStep2:
      'Trasmetti una copia al medico di base e assicurati che le persone di fiducia abbiano un accesso ospite per gut geregelt, in modo che possano accedere alla procura in caso di emergenza (clicca qui)',
    highlightedText: 'clicca qui',
    nextStep3: 'Discuti la direttiva anticipata con la famiglia',
  },
};

export default livingWill;
