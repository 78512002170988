import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type LastWillSituationKeys =
  | 'heirat'
  | 'geburt-eines-kindes'
  | 'pensionierung'
  | 'krankheit'
  | 'unternehmensgruendung'
  | 'scheidung';

export const LAST_WILL_COMMONS = {
  service: 'testament',
  actions: defaultActions,
  learnMoreLink: '/testament',
  startProcessLink: '/dashboard/testament',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-tes.webp',
  },
};

export const LAST_WILL_SITUATION: Record<LastWillSituationKeys, any> = {
  heirat: {
    content: {
      main: {
        title: 'lastWillLanding.situations.heirat.content.main.title',
        description:
          'lastWillLanding.situations.heirat.content.main.description',
      },
      columns: [
        {
          title: 'lastWillLanding.situations.heirat.content.columns.0.title',
          description: [
            'lastWillLanding.situations.heirat.content.columns.0.description.0',
          ],
        },
        {
          title: 'lastWillLanding.situations.heirat.content.columns.1.title',
          description: [
            'lastWillLanding.situations.heirat.content.columns.1.description.0',
            'lastWillLanding.situations.heirat.content.columns.1.description.1',
            'lastWillLanding.situations.heirat.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  'geburt-eines-kindes': {
    content: {
      main: {
        title:
          'lastWillLanding.situations.geburt-eines-kindes.content.main.title',
        description:
          'lastWillLanding.situations.geburt-eines-kindes.content.main.description',
      },
      columns: [
        {
          title:
            'lastWillLanding.situations.geburt-eines-kindes.content.columns.0.title',
          description: [
            'lastWillLanding.situations.geburt-eines-kindes.content.columns.0.description.0',
          ],
        },
        {
          title:
            'lastWillLanding.situations.geburt-eines-kindes.content.columns.1.title',
          description: [
            'lastWillLanding.situations.geburt-eines-kindes.content.columns.1.description.0',
            'lastWillLanding.situations.geburt-eines-kindes.content.columns.1.description.1',
            'lastWillLanding.situations.geburt-eines-kindes.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  pensionierung: {
    content: {
      main: {
        title: 'lastWillLanding.situations.pensionierung.content.main.title',
        description:
          'lastWillLanding.situations.pensionierung.content.main.description',
      },
      columns: [
        {
          title:
            'lastWillLanding.situations.pensionierung.content.columns.0.title',
          description: [
            'lastWillLanding.situations.pensionierung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'lastWillLanding.situations.pensionierung.content.columns.1.title',
          description: [
            'lastWillLanding.situations.pensionierung.content.columns.1.description.0',
            'lastWillLanding.situations.pensionierung.content.columns.1.description.1',
            'lastWillLanding.situations.pensionierung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  krankheit: {
    content: {
      main: {
        title: 'lastWillLanding.situations.krankheit.content.main.title',
        description:
          'lastWillLanding.situations.krankheit.content.main.description',
      },
      columns: [
        {
          title: 'lastWillLanding.situations.krankheit.content.columns.0.title',
          description: [
            'lastWillLanding.situations.krankheit.content.columns.0.description.0',
          ],
        },
        {
          title: 'lastWillLanding.situations.krankheit.content.columns.1.title',
          description: [
            'lastWillLanding.situations.krankheit.content.columns.1.description.0',
            'lastWillLanding.situations.krankheit.content.columns.1.description.1',
            'lastWillLanding.situations.krankheit.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  unternehmensgruendung: {
    content: {
      main: {
        title:
          'lastWillLanding.situations.unternehmensgruendung.content.main.title',
        description:
          'lastWillLanding.situations.unternehmensgruendung.content.main.description',
      },
      columns: [
        {
          title:
            'lastWillLanding.situations.unternehmensgruendung.content.columns.0.title',
          description: [
            'lastWillLanding.situations.unternehmensgruendung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'lastWillLanding.situations.unternehmensgruendung.content.columns.1.title',
          description: [
            'lastWillLanding.situations.unternehmensgruendung.content.columns.1.description.0',
            'lastWillLanding.situations.unternehmensgruendung.content.columns.1.description.1',
            'lastWillLanding.situations.unternehmensgruendung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  scheidung: {
    content: {
      main: {
        title: 'lastWillLanding.situations.scheidung.content.main.title',
        description:
          'lastWillLanding.situations.scheidung.content.main.description',
      },
      columns: [
        {
          title: 'lastWillLanding.situations.scheidung.content.columns.0.title',
          description: [
            'lastWillLanding.situations.scheidung.content.columns.0.description.0',
          ],
        },
        {
          title: 'lastWillLanding.situations.scheidung.content.columns.1.title',
          description: [
            'lastWillLanding.situations.scheidung.content.columns.1.description.0',
            'lastWillLanding.situations.scheidung.content.columns.1.description.1',
            'lastWillLanding.situations.scheidung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getLastWillSituation = (situation: LastWillSituationKeys) => ({
  ...LAST_WILL_SITUATION[situation],
  ...LAST_WILL_COMMONS,
});
