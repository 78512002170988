import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: 'Il tuo coniuge',
  },
  {
    question: 'Tu',
  },
];

export const marriageQuestions: IQuestion[] = [
  {
    question: 'Quando ti sei sposato?',
  },
  {
    question: 'Hai figli?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Quanti?',
  // },
];

export const estateQuestions: IQuestion[] = [
  {
    question:
      'Quali beni possedevi tu e il tuo coniuge già prima del matrimonio (ad es. eredità, oggetti di alto valore emotivo, ecc.)?',
  },
  {
    question:
      'È corretto che tutti gli altri beni (non elencati) siano stati acquisiti dopo il matrimonio?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Hai acquisito proprietà immobiliari dopo il matrimonio?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Confermi di mantenere il regime patrimoniale ordinario della partecipazione agli acquisti?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'I coniugi confermano che il coniuge superstite riceve i suoi beni propri (ad es. auto acquistata prima del matrimonio o donazione di una zia) e ha la migliore scelta legale possibile riguardo al patrimonio residuo.',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Desidero che il mio coniuge abbia il diritto di disporre liberamente di una parte specifica del patrimonio.',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question:
  //     'Chi dovrebbe essere l\'esecutore testamentario in caso di morte simultanea di entrambi i coniugi o della morte dell\'ultimo coniuge superstite?',
  // },
  // {
  //   question:
  //     'Se la persona da te scelta non può fungere da esecutore testamentario, verrà nominata la seguente persona:',
  // },
];