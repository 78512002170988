import { sub } from 'date-fns';
import { useRef, useMemo, useState, useCallback } from 'react';

import { IChatParticipant } from 'src/types/chat';
import { IconButton, InputBase, Stack } from '@mui/material';
import useAuth from '#/lib/hooks/useAuth';
import uuidv4 from '#/utils/uuidv4';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  recipients: IChatParticipant[];
  onAddRecipients: (recipients: IChatParticipant[]) => void;
  //
  disabled: boolean;
  selectedConversationId: string;
};

export default function ChatMessageInput({
  recipients,
  onAddRecipients,
  //
  disabled,
  selectedConversationId,
}: Props) {
  const { user } = useAuth();

  const fileRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');

  const myContact = useMemo(
    () => ({
      id: `${user?.id}`,
      role: `${user?.role}`,
      email: `${user?.email}`,
      address: `${user?.address}`,
      name: `${user?.first_name} ${user?.last_name}`,
      lastActivity: new Date(),
      avatarUrl: `${user?.profile_picture}`,
      phoneNumber: `${user?.mobile_phone}`,
      status: 'online' as 'online' | 'offline' | 'alway' | 'busy',
    }),
    [user]
  );

  const messageData = useMemo(
    () => ({
      id: uuidv4(),
      attachments: [],
      body: message,
      contentType: 'text',
      createdAt: sub(new Date(), { minutes: 1 }),
      senderId: myContact.id,
    }),
    [message, myContact.id]
  );

  const conversationData = useMemo(
    () => ({
      id: uuidv4(),
      messages: [messageData],
      participants: [...recipients, myContact],
      type: recipients.length > 1 ? 'GROUP' : 'ONE_TO_ONE',
      unreadCount: 0,
    }),
    [messageData, myContact, recipients]
  );

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleChangeMessage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMessage(event.target.value);
    },
    []
  );

  //   const handleSendMessage = useCallback(
  //     async (event: React.KeyboardEvent<HTMLInputElement>) => {
  //       try {
  //         if (event.key === 'Enter') {
  //           if (message) {
  //             if (selectedConversationId) {
  //               await sendMessage(selectedConversationId, messageData);
  //             } else {
  //               const res = await createConversation(conversationData);

  //               router.push(`${paths.dashboard.chat}?id=${res.conversation.id}`);

  //               onAddRecipients([]);
  //             }
  //           }
  //           setMessage('');
  //         }
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     },
  //     [
  //       conversationData,
  //       message,
  //       messageData,
  //       onAddRecipients,
  //       router,
  //       selectedConversationId,
  //     ]
  //   );

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={() => {}}
        onChange={handleChangeMessage}
        placeholder="Type a message"
        disabled={disabled}
        startAdornment={
          <IconButton>
            <Iconify icon="eva:smiling-face-fill" />
          </IconButton>
        }
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>
            <IconButton onClick={handleAttach}>
              <Iconify icon="solar:gallery-add-bold" />
            </IconButton>
            <IconButton onClick={handleAttach}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>
            <IconButton>
              <Iconify icon="solar:microphone-bold" />
            </IconButton>
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </>
  );
}
