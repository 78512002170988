const outputDoc = {
  title: 'Contratto Matrimoniale',
  from: 'da ',
  born: 'nato il ',
  civilStatus: {
    single: 'Celibe/Nubile',
    married: 'Sposato/a',
    widowed: 'Vedovo/a',
    divorced: 'Divorziato/a',
    registered_partnership: 'Unione registrata',
  },
  civil_status: 'Stato civile: {{civil_status}}',
  address: 'Indirizzo: {{address}}',
  male: 'Marito',
  female: 'Moglie',
  and: 'e',
  spousePresentation:
    '{{spouseLastName}} {{spouseName}}, {{relation}}, nato il {{spouseBirthDate}}, da {{spousePlaceOfCitizenship}}, {{spouseCivilStatus}}, residente a {{spouseAddress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, {{relation}}, nato il {{birthDate}}, da {{placeOfCitizenship}}, {{civilStatus}}, residente a {{address}}.',
  determinations: {
    title: 'Disposizioni',
    section1: {
      firstParagraphWithChildren:
        'Le parti contraenti si sono sposate il {{weddingDate}} a {{weddingLocation}}.',
      oneCommonChildParagraph: 'Dal loro matrimonio è nato un figlio comune: ',
      multipleCommonChildrenParagraph:
        'Dal loro matrimonio sono nati {{numberOfChildren}} figli comuni: ',
      childBirthday: 'nato il {{birthday}}',
      firstParagraphWithoutChildren:
        'Le parti contraenti si sono sposate il {{weddingDate}} a {{weddingLocation}}. Dal loro matrimonio non sono nati figli comuni.',
      noChildrenOutsideMarriage:
        'Le parti contraenti non hanno figli non comuni.',
      oneChildOutsideMarriage:
        'Le parti contraenti hanno un figlio non comune: ',
      multipleChildrenOutsideMarriage:
        'Le parti contraenti hanno {{numberOfChildren}} figli non comuni: ',
    },
    section2: {
      noMarriageContract:
        'Fino ad oggi, le parti contraenti non hanno mai stipulato un contratto matrimoniale. Non è avvenuta né una separazione giudiziaria né una separazione dei beni legale.',
    },
    section3: {
      participationAgreement:
        'La partecipazione agli acquisti comprende gli acquisti e i beni propri di ciascun coniuge. Entro i limiti legali, ciascun coniuge gestisce e utilizza i propri acquisti e beni propri e ne dispone.',
    },
    section4: {
      personalAssets:
        'Oltre agli oggetti che servono esclusivamente all\'uso personale di ciascun coniuge, le parti contraenti possiedono i seguenti beni propri:',
      pointA: 'a.) Marito: ',
      pointB: 'b.) Moglie: ',
    },
    section5: {
      otherAssets: 'Tutti gli altri beni patrimoniali',
      evenEstates: 'incl. ',
      otherAssetsContinued:
        'sono stati accumulati congiuntamente dalle parti contraenti dopo il matrimonio; questi costituiscono acquisti ai sensi dell\'art. 197 ZGB.',
      apartment: 'dell\'appartamento',
      house: 'della casa',
      chalet: 'dello chalet',
      rustico: 'del rustico',
      building_plot: 'del terreno edificabile',
      multi_family_house: 'della casa plurifamiliare',
      commercial_building: 'dell\'edificio commerciale',
      residential_building: 'dell\'edificio residenziale',
      commercial_property: 'della proprietà commerciale',
      nonAcquiredAfterMarriage:
        'I seguenti {{assetsQuantity}} sono stati accumulati congiuntamente dalle parti contraenti dopo il matrimonio; questi costituiscono acquisti ai sensi dell\'art. 197 ZGB: ',
      plural: 'immobili',
      singular: 'immobile',
    },
  },
  marriageContractAgreements: {
    title: 'Accordi Contrattuali Matrimoniali',
    section1: {
      firstParagraph:
        'Le parti contraenti concordano espressamente di mantenere il regime patrimoniale ordinario della partecipazione agli acquisti.',
    },
    section2: {
      firstParagraph:
        'In base all\'art. 216 cpv. 1 ZGB e in deroga alla partecipazione agli acquisti prevista dall\'art. 215 ZGB, le parti contraenti concordano che, in caso di scioglimento del matrimonio per morte di un coniuge, al superstite spetti la somma totale delle proposte di entrambi i coniugi come proprietà libera.',
    },
    section3: {
      firstParagraph:
        'In applicazione dell\'art. 206 cpv. 3 ZGB, i coniugi escludono una partecipazione reciproca all\'aumento di valore in caso di scioglimento del matrimonio per morte.',
    },
  },
  jointProvisions: {
    title: 'Disposizioni Comuni',
    section1: {
      firstParagraph:
        'Con la stipula di questo contratto, le parti contraenti revocano tutte le disposizioni testamentarie precedenti. Sono escluse eventuali dichiarazioni di beneficiario nei confronti di assicurazioni e istituti di previdenza.',
    },
    section2: {
      firstParagraph:
        'Ai sensi di una disposizione di divisione, si stabilisce che il coniuge superstite può assumere liberamente quei beni materiali e di capitale che gli piacciono, in compensazione delle pretese patrimoniali e successorie. Questa disposizione di divisione si riferisce espressamente anche ai terreni, che devono essere iscritti nel registro fondiario come proprietà esclusiva del coniuge superstite. Il valore di compensazione è il valore di mercato ufficiale (valore fiscale); alla data del decesso del primo coniuge.',
      secondParagraph:
        'Il coniuge superstite ha anche il diritto di compensare le pretese successorie degli altri eredi a sua scelta in contanti o assegnando altri beni patrimoniali. L\'intero arredamento della casa spetta al coniuge superstite come proprietà esclusiva senza compensazione della quota ereditaria.',
    },
    section3: {
      firstParagraph:
        'Se il coniuge superstite si risposa, deve corrispondere ai discendenti l\'importo che avrebbero ricevuto alla morte del primo coniuge in una divisione patrimoniale e successoria secondo le disposizioni legali e senza questo contratto. Si tiene conto di quanto i discendenti abbiano già ricevuto dall\'eredità del primo coniuge nonostante questo contratto e di quanto sia stato loro donato dal coniuge superstite. Determinante per l\'esistenza del patrimonio ereditario è l\'inventario ereditario fiscale, che è stato redatto dalle autorità cantonali o dagli eredi stessi alla morte del primo genitore/coniuge. Le pretese dei discendenti diventano esigibili con il giorno dell\'avveramento della condizione e fino a quel giorno non sono né da interessare né da garantire. È lasciato al genitore/coniuge superstite decidere in quale forma avvenga il pagamento.',
    },
    section4: {
      firstParagraph:
        'Questo contratto matrimoniale è soggetto al diritto svizzero, salvo disposizioni legali imperative contrarie. Nella misura consentita, le autorità svizzere sono competenti anche per i rapporti patrimoniali e successori delle parti contraenti.',
    },
    section5: {
      firstParagraph:
        'Questo contratto matrimoniale perde la sua validità se, alla morte del primo coniuge, il matrimonio delle parti contraenti è stato legalmente sciolto, ai sensi dell\'art. 117 ZGB, è stato separato giudizialmente o di fatto, o è pendente una domanda di divorzio o di protezione del matrimonio.',
    },
  },
  contractualAgreements: {
    title: 'Accordi Contrattuali Successori',
    section1: {
      firstParagraph:
        'Alla morte del primo coniuge, il superstite mantiene i propri beni e riceve, in base all\'accordo contrattuale matrimoniale sopra indicato, le proposte di entrambi i coniugi come proprietà.',
    },
    section2: {
      firstParagraph:
        'Inoltre, le parti contraenti si favoriscono reciprocamente anche successoriamente, nella misura in cui ciò sia legalmente consentito.',
      nonCommonChildren:
        'Il coniuge superstite può quindi scegliere tra il patrimonio residuo:',
      pointA:
        'a.) la massima quota ereditaria possibile (attualmente 5/8) come proprietà e assegnazione della quota legittima ai discendenti',
      pointB:
        'b.) la quota libera (attualmente 1/4) come proprietà e dal resto del patrimonio spettante ai discendenti, l\'usufrutto gratuito ai sensi dell\'art. 473 ZGB, senza dover fornire garanzie.',
    },
    section4: {
      firstParagraph:
        'I coniugi si concedono reciprocamente il diritto di disporre liberamente, mediante disposizione testamentaria, dell\'importo maggiore tra CHF {{disposal_fixed_amount}} o {{disposal_percentage}}% del loro patrimonio netto.',
    },
  },
  signatures: {
    title: 'Firma {{gender}}',
    female: 'Moglie',
    male: 'Marito',
  },
  notarization: {
    title: 'Autenticazione',
    firstParagraph: 'Il notaio sottoscritto certifica con la presente che',
    firstCondition:
      'ha letto il presente documento alle parti contraenti,',
    secondCondition:
      'il documento corrisponde alla volontà comunicata dalle parti contraenti,',
    thirdCondition:
      'il documento è stato firmato a mano dalle parti contraenti in sua presenza,',
    noterTitle: 'Firma Notaio',
  },
};

export default outputDoc;