const partnerWithUs = {
  mainBanner: {
    title: 'Offrez un accès à des solutions de prévoyance numérique',
    subtitle:
      'Les fonds de pension, les banques, les compagnies d\'assurance, les associations et les partenaires médiatiques collaborent avec gut geregelt pour offrir aux gens un accès facile à une prévoyance complète.',
    buttonLabel: 'Parlez avec nous',
  },
  youtubeTutorial: 'Pourquoi collaborer avec nous ?',
  aboutCards: {
    differentiate: {
      title: 'Atteindre la différenciation',
      description:
        'Générez plus de trafic pour votre plateforme avec des services supplémentaires et un contenu pertinent.',
    },
    efficiency: {
      title: 'Plus d\'efficacité',
      description:
        'Avec des services supplémentaires, vous pouvez facilement générer plus de revenus tout en réduisant les coûts.',
    },
    simplyIntegrated: {
      title: 'Intégration simple',
      description:
        'Prêt à l\'emploi avec la solution cloud autonome simple dans votre design.',
    },
  },
  subBanner: {
    title: 'En tant que solution en libre-service ou de conseil professionnel',
    subtitle:
      'gut geregelt peut être utilisé comme solution en libre-service en marque blanche pour les clients finaux ou comme solution de conseil pour soutenir votre processus de conseil. Une intégration API dans une solution existante est également possible.',
  },
  talkWithUs: {
    title: 'Aidez les gens à mener une vie bien organisée',
    description:
      'Élargissez votre offre de services et fournissez des services de prévoyance significatifs aux personnes qui souhaitent simplifier leur vie et avoir un impact positif sur leur famille.',
    talkAboutPossibilities: 'Parlons des possibilités',
    iam: 'Je suis',
    partnerOccupations: {
      bank: 'Banque',
      insurance: 'Assurance',
      pensionFund: 'Fonds de pension',
      association: 'Association',
      media: 'Partenaire médiatique',
      trust_foundation: 'Fondation',
      other: 'Autre',
    },
  },
  solutions: {
    pensionFund: {
      title: 'Fonds de pension',
      content:
        'Nous essayons depuis un certain temps d\'augmenter le nombre de visiteurs sur notre portail d\'assurance. Mais au-delà des relevés d\'assurance annuels, il y a peu de bonnes raisons. Avec gut geregelt, nous élargissons les possibilités de prévoyance et augmentons en même temps le trafic avec une offre innovante.',
    },
    bank: {
      title: 'Banque',
      content:
        'Bien que les banques se soient concentrées par le passé sur la fourniture financière aux clients, nous pensons qu\'en tant que banque, nous devrions également explorer de nouvelles voies pour aider les clients à mener une vie bien organisée. Avec un outil comme gut geregelt, cela devient possible.\n',
    },
    fintechPlatform: {
      title: 'Plateforme FinTech',
      content:
        'En tant que solution, nous sommes déjà très proches des clients en matière de prévoyance financière. L\'ajout de notre offre par des solutions de prévoyance élargies et juridiquement sûres est une étape logique pour permettre aux clients une prévoyance complète.',
    },
  },
  faq: {
    questions: {
      question1: 'Quels avantages gut geregelt offre-t-il à mes clients ?',
      question2: 'Où sont situées les données ?',
      question3: 'gut geregelt est-il conforme au DSG et au RGPD de l\'UE ?',
      question4: 'Puis-je utiliser gut geregelt comme solution de conseil ?',
      question5: 'gut geregelt est-il disponible en tant que solution en marque blanche ?',
      question6: 'Comment puis-je intégrer gut geregelt dans mon entreprise ?',
      question7: 'Quelles langues sont prises en charge ?',
      question8:
        'Puis-je également impliquer mes organisations partenaires ?',
      question9: 'Quels avantages gut geregelt offre-t-il en tant que membre du conseil de fondation ?',
      question10: 'Puis-je demander des fonctionnalités supplémentaires ?',
      question11: 'Quel est le modèle commercial ?',
    },
    answers: {
      answer1:
        'Avec gut geregelt, les gens ont pour la première fois un accès facile à des services de prévoyance numérique avec sécurité juridique. Nous vous aidons à faire les premiers pas vers une vie bien organisée. Cela leur permet, ainsi qu\'à leur famille, d\'avoir plus de contrôle et moins de stress en cas d\'urgence.',
      answer2:
        'Les données sont stockées en Suisse. Pour les organisations partenaires en Allemagne et en Autriche, nous prévoyons une zone de conformité UE.',
      answer3:
        'Oui. Les exigences du DSG et du RGPD de l\'UE ont servi de critères de conception lors de la mise en œuvre de gut geregelt en tant que logiciel et constituent ainsi la base de l\'architecture du produit.',
      answer4:
        'Oui, gut geregelt propose, en plus de la solution en libre-service pour les clients finaux, un cockpit de conseil. Cela permet à vos conseillers de développer des solutions avec les clients, le client ayant la possibilité de faire des ajustements ou des ajouts de manière autonome à un moment ultérieur.',
      answer5:
        'Oui. gut geregelt est une solution en marque blanche dédiée pour des organisations telles que les banques, les assurances, les fonds de pension et d\'autres institutions de prévoyance ainsi que les institutions financières et les associations. La solution peut être intégrée de différentes manières.',
      answer6:
        'Différents scénarios sont possibles. L\'intégration la plus simple se fait via un simple lien vers gut geregelt. Cependant, nous proposons également des intégrations via SSO et API. L\'intégration en tant qu\'iFrame est également envisageable.',
      answer7:
        'gut geregelt prend en charge l\'allemand, l\'anglais, le français et l\'italien.',
      answer8:
        'gut geregelt propose différents modèles de partenariat et vous offre par exemple la possibilité de répertorier des notaires et des avocats ainsi que des conseillers financiers et patrimoniaux en tant qu\'experts.',
      answer9:
        'Avec gut geregelt, en tant que membre du conseil de fondation, je peux activement minimiser mon risque de responsabilité en incitant les assurés à la prévoyance, en fournissant un outil pour augmenter le trafic sur le portail de l\'institution de prévoyance, et en créant de plus en plus de transparence et d\'efficacité.',
      answer10:
        'Oui. Chez gut geregelt, nous écoutons très attentivement ce que disent nos clients et partenaires. Nous examinons les souhaits et les exigences en tenant compte de tous et priorisons les fonctionnalités qui offrent une valeur ajoutée à la majorité de nos clients et partenaires.',
      answer11:
        'Les partenaires paient une redevance annuelle pour la solution gut geregelt. Pour chaque conclusion par les clients finaux, le partenaire reçoit un retour. Cependant, il existe également des partenaires qui prennent en charge partiellement ou totalement les coûts pour leurs clients finaux et mettent le service à disposition à un tarif réduit.',
    },
  },
};

export default partnerWithUs;