import { Stack, Typography } from '@mui/material';
import React from 'react';
import Image from '../Image';
import useLocales from '#/hooks/useLocales';

type Props = {
  isLanding?: boolean;
  isRegisterPortal?: boolean;
};
const Footer = ({ isLanding, isRegisterPortal }: Props) => {
  const { translate } = useLocales();

  const isFromSmart = localStorage.getItem('gg_partner_key') === 'finfinder';

  return (
    <footer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isLanding ? '#F8F8F8' : '#fff',
        paddingBottom: 20,
        paddingTop: 20,

        // ...(!isLanding && {
        //   bottom: 0,
        //   position: 'fixed',
        //   width: '100%',
        // }),
        // ...(isRegisterPortal && {
        //   position: 'absolute',
        //   bottom: -300,
        //   left: 0,
        //   right: 0,
        // }),
      }}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Image
          src={
            isFromSmart
              ? '/assets/logos/finfinder_logo.svg'
              : '/assets/logos/gutgeregeltLogo.svg'
          }
          style={{
            width: '150px',
          }}
          alt="Gut geregelt"
        />
        <Typography
          sx={{
            color: '#000',
            fontSize: '11px',
            fontWeight: 400,
          }}
        >
          © {translate('global.footer.allRightsReserved')}
        </Typography>
      </Stack>
    </footer>
  );
};

export default Footer;
