import { IQuestion } from '#/types/globalTypes';

export const treatmentQuestions: IQuestion[] = [
  {
    question:
      'Perché stai creando questa direttiva anticipata? Quale descrive meglio la tua situazione attuale?',
    options: [
      {
        label: 'Sono in buona salute, ma voglio prendere precauzioni',
      },
      {
        label: 'Ho disturbi e voglio prendere precauzioni',
      },
      {
        label:
          'Ho raggiunto una certa età e voglio prendere precauzioni per il futuro',
      },
    ],
  },
  {
    question:
      'Cosa è più importante per te nel trattamento del dolore e dei sintomi? Per favore, seleziona l\'affermazione che meglio ti rappresenta.',
    options: [
      {
        label:
          'Voglio che tutte le possibilità mediche per mantenere la mia vita siano esaurite, anche se ciò comporta oneri (sofferenza)',
      },
      {
        label:
          'Voglio che il trattamento medico serva principalmente ad alleviare la sofferenza, anche se ciò può accorciare la mia vita',
      },
    ],
  },
  {
    question:
      'Vuoi rinunciare a tutte le misure che prolungano la vita e la sofferenza, se diventi incapace di intendere e di volere a causa di un evento acuto e imprevisto e se, dopo una valutazione medica approfondita, è impossibile o improbabile che recuperi mai la tua capacità di intendere e di volere?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Vuoi essere rianimato?',
    options: [
      {
        label: 'Sì',
      },
      {
        label: 'No',
      },
    ],
  },
];

export const detailedQuestions: IQuestion[] = [
  {
    question:
      'In uno scenario in cui diventi improvvisamente incapace di intendere e di volere, ad esempio in un\'emergenza, quale sarebbe il tuo obiettivo di trattamento per prolungare la vita?',
    options: [
      {
        label: 'Voglio ricevere rianimazione e trattamento',
      },
      {
        label:
          'Non voglio essere rianimato, ma voglio essere trattato in terapia intensiva',
      },
    ],
  },
  {
    question:
      'Quale sarebbe il tuo obiettivo di trattamento per alleviare la sofferenza in caso di improvvisa incapacità lavorativa?',
    options: [
      {
        label:
          'Voglio ricevere farmaci per alleviare la sofferenza (morfina, ecc.) e terapie di trattamento',
      },
      {
        label:
          'Non voglio ricevere farmaci per alleviare la sofferenza (morfina, ecc.) e terapie di trattamento',
      },
    ],
  },
  {
    question:
      'In uno scenario in cui sei permanentemente incapace di lavorare, ad esempio a causa di demenza, quali sarebbero i tuoi obiettivi di trattamento per prolungare la vita?',
    options: [
      {
        label:
          'Voglio essere rianimato e trattato in terapia intensiva',
      },
      {
        label:
          'Non voglio essere rianimato, ma voglio essere trattato in terapia intensiva',
      },
    ],
  },
  {
    question:
      'Quali obiettivi perseguiresti in caso di improvvisa incapacità lavorativa permanente?',
    options: [
      {
        label:
          'Voglio ricevere farmaci per alleviare la sofferenza (morfina, ecc.) e terapie di trattamento',
      },
      {
        label:
          'Non voglio ricevere farmaci per alleviare la sofferenza (morfina, ecc.) e terapie di trattamento',
      },
    ],
  },
  {
    question:
      'Quali sarebbero i tuoi obiettivi di trattamento per prolungare la vita in uno scenario in cui sei permanentemente incapace di lavorare, ad esempio a causa di demenza?',
    options: [
      {
        label:
          'Voglio ricevere rianimazione e trattamento in terapia intensiva',
      },
      {
        label:
          'Non voglio essere rianimato, ma voglio essere trattato in terapia intensiva',
      },
    ],
  },
  {
    question:
      'Quali sarebbero i tuoi obiettivi per alleviare la sofferenza in caso di improvvisa incapacità lavorativa permanente?',
    options: [
      {
        label:
          'Voglio ricevere farmaci per alleviare la sofferenza (morfina, ecc.) e terapie di trattamento',
      },
      {
        label:
          'Non voglio ricevere farmaci per alleviare la sofferenza (morfina, ecc.) e terapie di trattamento',
      },
    ],
  },
];