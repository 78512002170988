import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: 'Votre conjoint',
  },
  {
    question: 'Vous',
  },
];

export const marriageQuestions: IQuestion[] = [
  {
    question: 'Quand vous êtes-vous marié ?',
  },
  {
    question: 'Avez-vous des enfants ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Combien ?',
  // },
];

export const estateQuestions: IQuestion[] = [
  {
    question:
      'Quels biens possédiez-vous et votre conjoint avant le mariage (par exemple, héritages, objets de grande valeur émotionnelle, etc.) ?',
  },
  {
    question:
      'Est-il correct que vous ayez acquis tous les autres biens (non listés) après le mariage ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Avez-vous acquis des biens immobiliers après le mariage ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Acceptez-vous de maintenir le régime ordinaire de la participation aux acquêts ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Les époux confirment que le conjoint survivant reçoit ses biens propres (par exemple, une voiture acquise avant le mariage ou un don d\'une tante) et a le choix légalement le plus avantageux concernant le reste de la succession.',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Je souhaite que mon conjoint ait le droit de disposer librement d\'une partie spécifique de la succession.',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question:
  //     'Qui doit être l\'exécuteur testamentaire en cas de décès simultané des deux conjoints ou du décès du dernier conjoint survivant ?',
  // },
  // {
  //   question:
  //     'Si la personne que vous avez choisie ne peut pas agir en tant qu\'exécuteur testamentaire, la personne suivante sera nommée :',
  // },
];