const digitalFootprint = {
  title: 'Digital footprint',
  description: 'Share relevant information and access with your relatives.',
  dashboardServiceCards: {
    importantInformation: {
      title: 'Important information',
      description:
        'Make a note for your relatives on where to find official documents, bank records, or insurance papers to reduce effort and stress during a difficult time.',
    },
    digitalAssets: {
      title: 'Digital assets & subscriptions',
      description:
        'Keep track of all your subscriptions, social media profiles, or other accounts and ensure you can pass on digital artifacts and assets to your heirs.',
    },
    picturesAndVideos: {
      title: 'Pictures & videos',
      description:
        'Ensure that your heirs have access to photos and videos you wish to preserve for posterity.',
    },
  },
};

export default digitalFootprint;
