import React, { ReactNode } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { Box, useTheme } from '@mui/material';

interface DroppableFolderProps {
  folder: { id: number | string; name: string };
  children?: ReactNode;
  style?: React.CSSProperties;
  component?: React.ElementType;
}

export const DroppableContainer: React.FC<DroppableFolderProps> = ({
  folder,
  children,
  style,
  component: Component = Box,
}) => {
  const { setNodeRef, isOver } = useDroppable({ id: folder.id.toString() });
  const theme = useTheme();

  const defaultStyle: React.CSSProperties = {
    borderRadius: 16,
    padding: 2,
    backgroundColor: isOver ? theme.palette.success.lighter : '#fff',
    ...style,
  };

  return (
    <Box component={Component} ref={setNodeRef} style={defaultStyle}>
      {children}
    </Box>
  );
};
