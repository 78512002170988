const lastWillLanding = {
  mainBanner: {
    intro: 'Créer un testament en ligne :',
    title: 'Légalement sûr, en 10 minutes',
    subtitle:
      'La détermination proactive de ce qui adviendra de votre patrimoine aide à garantir vos dernières volontés et à rendre le processus plus confortable pour votre famille.',
    buttonLabel: 'Créer un testament',
  },
  youtubeTutorial: 'Pourquoi un testament est-il important ?',
  aboutCards: {
    whoGetsWhat: {
      title: 'Qui reçoit quoi',
      description:
        'Le testament détermine qui reçoit combien, ce qui peut déroger à la succession légale.',
    },
    enforceWishes: {
      title: 'Faire respecter vos souhaits',
      description:
        'Assurez-vous de distribuer votre patrimoine selon vos souhaits et préférences personnels.',
    },
    clarificationForFamily: {
      title: 'Clarté pour la famille',
      description:
        'Évitez les discussions inutiles et assurez la clarté pour vos proches.',
    },
  },
  subBanner: {
    title: 'Bien organisé avec un testament conforme à la loi',
    subtitle:
      'Avec gut geregelt, vous créez rapidement votre testament, pouvez obtenir des conseils juridiques sur des sujets sensibles ou des questions ouvertes et faites une faveur à vos proches.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre testament',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: 'À quoi sert un testament ?',
      question2: 'Existe-t-il différentes manières de créer mon testament ?',
      question3: "Qu'est-ce qui relève du droit successoral légal ?",
      question4:
        "Qu'a changé avec la révision du droit successoral au 1er janvier 2023 ?",
      question5:
        'Sur quelles parties de mon patrimoine puis-je disposer librement dans le testament ?',
      question6:
        "Que se passe-t-il si je n'ai pas de testament et que je décède ?",
      question7: 'Puis-je rédiger mon testament avec mon conjoint ?',
      question8:
        'Puis-je faire hériter des personnes en dehors de ma famille ?',
      question9: "Puis-je déshériter quelqu'un ?",
      question10: 'Dois-je nommer un exécuteur testamentaire ?',
      question11: 'Puis-je modifier mon testament à tout moment ?',
      question12:
        "Y a-t-il d'autres documents que je devrais créer en plus du testament ?",
      question13: "Comment gut geregelt m'aide-t-il avec mon testament ?",
    },
    answers: {
      answer1:
        "Avec un testament, vous réglez votre succession. Vous déterminez ce qu'il adviendra de votre patrimoine après votre décès. De plus, si vous vivez en concubinage et souhaitez que votre partenaire hérite, vous devez absolument le mentionner dans le testament.",
      answer2:
        'Oui, il existe le testament olographe, le testament public et le testament oral.',
      answer3:
        'Il existe une part réservataire, le droit successoral légal, qui garantit que le conjoint ou les enfants ne peuvent pas être complètement exclus de votre héritage.',
      answer4:
        'Le plus grand changement est que la part réservataire des parents disparaît complètement et que la part réservataire des enfants pour les testateurs non mariés est réduite de 75% à 50%.',
      answer5:
        'En dehors des parts réservataires, vous pouvez décider librement de votre succession. Vous pouvez par exemple favoriser davantage votre conjoint ou partenaire enregistré.',
      answer6:
        'Dans ce cas, la succession légale entre automatiquement en vigueur et votre succession est répartie en fonction de votre situation familiale au moment du décès.',
      answer7:
        'Non, vous ne pouvez pas rédiger votre testament conjointement avec votre conjoint.',
      answer8:
        'Oui, en dehors de la part réservataire légale, vous pouvez décider vous-même de qui vous souhaitez faire hériter, y compris des associations ou des institutions.',
      answer9:
        "Tant qu'il ne s'agit pas d'héritiers ayant droit à la part réservataire, vous pouvez déshériter.",
      answer10:
        "Non, la nomination d'un exécuteur testamentaire est facultative, mais peut protéger contre les litiges successoraux.",
      answer11:
        'Oui, vous pouvez apporter des modifications à tout moment, il est important de les dater et de les signer.',
      answer12:
        'Oui, la {{powerOfAttorney}} et la {{livingWill}} sont deux documents que vous devriez créer dès que possible.',
      answer13:
        'Chez gut geregelt, nous vous aidons à créer votre testament de manière fluide et légale.',
    },
    hyperlinks: {
      lastWill: 'Testament',
      livingWill: 'Directives anticipées',
      powerOfAttorney: 'Mandat de protection future',
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Testament',
          description:
            "Avec un testament, vous déterminez ce qu'il advient de vos biens en cas de décès. Vous pouvez vous assurer que votre conjoint et votre famille sont protégés et que votre volonté est respectée.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Un testament vous donne la certitude que vos biens seront répartis selon vos souhaits et que votre conjoint est légalement protégé.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Protection du conjoint',
              'Règles de succession claires',
              'Éviter les litiges',
            ],
          },
        ],
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Testament',
          description:
            'Avec un testament, vous déterminez comment vos biens sont répartis en cas de décès. Vous pouvez vous assurer que votre enfant est financièrement protégé et que vos souhaits sont respectés.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Un testament vous donne la certitude que votre enfant est bien pris en charge en cas de décès et que vos biens sont répartis selon vos souhaits.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Sécurité financière pour le partenaire et la famille',
              'Clarté juridique sur les successions',
              'Protection des biens familiaux',
            ],
          },
        ],
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Avec un testament, vous déterminez comment vos biens sont répartis après votre décès. Vous veillez à ce que vos proches soient protégés selon vos souhaits.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "À l'âge de la retraite, il est important de prendre en main la planification successorale afin d'éviter les conflits successoraux et de protéger votre famille.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Clarté sur la répartition des biens',
              'Protection de la famille',
              'Éviter les litiges',
            ],
          },
        ],
      },
    },
    krankheit: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Avec un testament, vous déterminez comment vos biens sont répartis après votre décès. Vous vous assurez ainsi que vos proches sont protégés et que votre dernière volonté est respectée.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Un diagnostic grave montre à quel point la vie peut changer rapidement. Un testament garantit que votre famille est financièrement protégée et que les conflits sont évités.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Protection de votre famille',
              'Clarté sur la répartition successorale',
              'Protection des biens',
            ],
          },
        ],
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Testament',
          description:
            "Avec un testament, vous réglez ce qu'il advient de votre entreprise et de vos biens en cas de décès. Vous vous assurez ainsi que votre héritage est géré selon vos souhaits et que votre entreprise est poursuivie.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "En tant que fondateur d'entreprise, il est particulièrement important de prendre des dispositions à un stade précoce. Avec un testament, vous assurez la continuité de votre entreprise et vous évitez les litiges juridiques.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Règles de succession claires pour l'entreprise",
              'Éviter les litiges',
              "Protection du patrimoine familial et de la valeur de l'entreprise",
            ],
          },
        ],
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Testament',
          description:
            'Avec un testament, vous déterminez comment vos biens sont répartis en cas de décès. Surtout après un divorce, vous devriez vous assurer que vos règles de succession reflètent votre nouvelle situation de vie.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Un divorce modifie votre situation familiale et patrimoniale. Un testament (mis à jour) garantit que vos souhaits sont respectés même après la séparation.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Clarté sur la répartition de votre patrimoine',
              'Protection de vos proches',
              'Adaptation à la situation familiale modifiée',
            ],
          },
        ],
      },
    },
  },
};

export default lastWillLanding;
