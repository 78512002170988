import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Stack } from '@mui/material';
import { PATH_AUTH } from '#/routes/paths';
import Iconify from '#/components/shared/ui/Iconify';
import Page from '#/components/shared/ui/Page';
import Image from '#/components/shared/ui/Image';
import ResetPasswordForm from '../../components/pages/ResetPassword/ResetPasswordForm';
import useLocales from '#/hooks/useLocales';

export default function ResetPassword() {
  const { translate } = useLocales();

  return (
    <Page title="Reset Password">
      <Stack
        sx={{
          py: 12,
          maxWidth: 400,
          textAlign: 'center',
          m: 'auto',
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            sx={{ my: { mb: 5, sm: 10, height: 100, maxWidth: 100 } }}
            src="/assets/icons/resetPassword.svg"
          />
        </div>

        <Typography variant="h3" paragraph>
          {translate('global.loginInfo.forgotPassword')}
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          {translate('global.loginInfo.resetPasswordInfo')}
        </Typography>

        <ResetPasswordForm />

        <Link
          component={RouterLink}
          to={PATH_AUTH.login}
          color="inherit"
          variant="subtitle2"
          sx={{
            mt: 3,
            mx: 'auto',
            alignItems: 'center',
            display: 'inline-flex',
          }}
        >
          <Iconify icon="eva:chevron-left-fill" width={16} />
          {translate('global.loginInfo.returnToLogin')}
        </Link>
      </Stack>
    </Page>
  );
}
