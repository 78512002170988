import { Box } from '@mui/material';
import { newsLetters } from '#/components/pages/Landing/landingData';
import NewsletterCard from '#/components/pages/Landing/Cards/NewsletterCard';

type Props = {
  service: string | undefined;
};

const Newsletter = ({ service }: Props) => (
  <Box>
    <Box
      sx={{
        maxWidth: '1264px',
        margin: '0 auto',
      }}
    >
      {newsLetters
        .filter((item) => item.service === service)
        .map((item, i) => (
          <NewsletterCard
            title={item.title}
            buttonLabel={item.buttonLabel}
            key={i}
            url={item.url}
            service={item.service}
          />
        ))}
    </Box>
  </Box>
);

export default Newsletter;
