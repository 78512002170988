const outputDoc = {
  title: 'Contratto di Convivenza',
  from: 'da',
  born: 'nato/a il',
  civilStatus: {
    single: 'Celibe/Nubile',
    married: 'Sposato/a',
    widowed: 'Vedovo/a',
    divorced: 'Divorziato/a',
    registered_partnership: 'Unione registrata',
  },
  civil_status: 'Stato civile: {{civil_status}}',
  address: 'Indirizzo: {{address}}',
  male: 'Marito',
  female: 'Moglie',
  and: 'e',
  between: 'Tra',
  contractPartiesAgree: 'Le parti contraenti concordano quanto segue:',
  spousePresentation:
    '{{partnerSurname}} {{partnerName}}, nato/a il {{partnerBirthday}}, cittadinanza {{partnerCitizenship}}, {{partnerCivilStatus}}, residente a {{partnerAdress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, nato/a il {{birthday}}, cittadinanza {{citizenship}}, {{civilStatus}}, residente a {{adress}}.',
  preliminaryRemark: {
    title: 'Premessa',
    section1: {
      firstDefault:
        'Ci siamo conosciuti nel {{meet_year}} e conviviamo dal {{date_of_moving_in}} presso il seguente indirizzo: {{adress}}.',
      secondDefault:
        'Intendiamo mantenere il nostro rapporto di convivenza a tempo indeterminato.',
      bothEmployedNoChildren:
        'Entrambi svolgiamo un lavoro dipendente a tempo pieno.',
      oneWorksFullTime:
        '{{who_works_full_time}} lavora a tempo pieno, mentre {{who_works_part_time}} lavora part-time ({{workload_distribution}}%).',
      oneFullTimeOneHousehold:
        '{{who_works_full_time}} lavora a tempo pieno, mentre {{householder}} si occupa della gestione domestica e di {{childrenNo}}.',
      singularChild: 'il bambino',
      plurarChildren: 'i bambini',
    },
  },
  ownership: {
    title: 'Proprietà',
    section1: {
      text: 'Verrà redatto un inventario degli oggetti e beni di valore, che sarà aggiornato regolarmente. Questo inventario, firmato da entrambe le parti, costituisce parte integrante del presente accordo. Chiunque affermi che un determinato oggetto non incluso nell’inventario sia di proprietà di uno dei partner deve fornirne la prova. In assenza di prova, si presume la comproprietà di entrambi i partner.',
    },
  },
  costOfLiving: {
    title: 'Costi della Vita',
    section1: {
      firstText:
        'Ognuno di noi contribuirà, nei limiti delle proprie possibilità economiche, al mantenimento della vita comune. Per questo scopo apriremo un conto bancario congiunto presso {{joint_household_account_bank}}.',
      bothEmployedNoChildren: {
        firstText:
          'Entro il primo giorno del mese, {{firstParty}} e {{secondParty}} verseranno {{equal_payment_frequency}} CHF {{equal_payment_amount}} ciascuno.',
        secondText: 'Il primo versamento avverrà il {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} verrà controllato il saldo del conto. Se il saldo risultasse insufficiente, sarà integrato da entrambi i partner in parti uguali.',
      },
      oneWorksFullTime: {
        firstText:
          'Entro il primo giorno del mese, {{firstParty}} verserà CHF {{amount_paid_by_me}} e {{secondParty}} CHF {{amount_paid_by_partner}} {{equal_payment_frequency}}',
        secondText:
          '(= proporzione {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}).',
        thirdText: 'Il primo versamento avverrà il {{date_of_first_payment}}.',
        fourthText:
          '{{compensation_payment_cadence}} verrà controllato il saldo del conto. Eventuali mancanze saranno coperte nella proporzione {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}.',
      },
      oneFullTimeOneHousehold: {
        firstText:
          'Entro il primo giorno del mese, {{who_works_full_time}} verserà CHF {{equal_payment_amount}} {{equal_payment_frequency}}.',
        secondText: 'Il primo versamento avverrà il {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} verrà controllato il saldo del conto. Eventuali mancanze saranno coperte da {{who_works_full_time}}.',
      },
    },
    section2: {
      title: 'Da questo conto saranno coperti i seguenti costi:',
      expensesOptions: {
        rent: 'Affitto',
        additional:
          'Spese aggiuntive per la casa come elettricità, riscaldamento, acqua, tasse sui rifiuti, ecc.',
        radioTV: 'Canone radio e TV',
        groceries: 'Generi alimentari',
        cleaning: 'Spese per le pulizie',
      },
      bothEmployedNoChildren:
        'La gestione della casa sarà condivisa. Ogni partner amministrerà e utilizzerà autonomamente il proprio reddito e patrimonio.',
      oneWorksFullTime:
        'La gestione della casa sarà prevalentemente a carico di {{who_works_part_time}}. La compensazione di {{who_works_full_time}} è inclusa nel contributo al budget familiare.',
    },
  },
  dissolution: {
    title: 'Scioglimento del Rapporto di Convivenza',
    firstText:
      'In caso di scioglimento del rapporto di convivenza, ciascun partner riprende i propri beni che si trovano in possesso dell’altro. I beni in comproprietà devono essere suddivisi equamente.',
    secondText:
      'Questo accordo entra in vigore alla firma ed è disciplinato dalla legge svizzera. La giurisdizione esclusiva per eventuali controversie è {{city}}.',
  },
  signature: {
    place: 'Luogo',
    date: 'Data',
  },
};
export default outputDoc;
