import { Navigate, useRoutes } from 'react-router-dom';
// import Cookies from 'js-cookie';
import AuthGuard from '../guards/AuthGuard';
import DashboardLayout from '../layout/dashboard';
import { PATH_REGISTER } from '#/config';
import {
  Login,
  Home,
  AboutUs,
  PreventiveCheckUp,
  PrivacyPolicy,
  Dashboard,
  GuestDashboard,
  AcceptInvitation,
  Page403,
  Page404,
  Page500,
  Maintenance,
  ComingSoon,
  VerifyCode,
  ResetPassword,
  NewPassword,
  LSLanding,
  OnboardingStepOne,
  OnboardingStepTwo,
  OnboardingStepThree,
  ThankyouCard,
  SituationCheck,
  ContactUs,
  Contact,
  LivingWill,
  PowerAttorney,
  LastWill,
  MarriageContract,
  AssetsRegister,
  DigitalFootprint,
  ImportantInformation,
  DigitalAssets,
  CohabitationAgreement,
  Imprint,
  AGB,
  CreateUser,
  Consulting,
  Consultant,
  ProfileSettings,
  SDocumentHistory,
  PensionProvision,
  DFPhotosVideos,
  TaxFolderClient,
  WelcomeCard,
  Chat,
} from './elements';
import CompactLayout from '../layout/compact/CompactLayout';
import AuthLayout from '../layout/auth/AuthLayout';
import GuestGuard from '../guards/GuestGuard';
import MainLayout from '../layout/main/MainLayout';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function Router() {
  const urlParams = new URLSearchParams(window.location.search);
  const [ggPartnerKey] = useLocalStorage('gg_partner_key', null);

  const redirectMap = {
    register: PATH_REGISTER,
    pension: '/vorsorge',
    default: '/',
  };

  // eslint-disable-next-line no-nested-ternary
  const key = urlParams.has('register')
    ? 'register'
    : ggPartnerKey
    ? 'pension'
    : 'default';
  const redirectTo = redirectMap[key];

  return useRoutes([
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'verifizieren',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          ),
        },

        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />,
        //     </GuestGuard>
        //   ),
        // },
      ],
    },

    {
      path: 'auth',
      element: <CompactLayout />,
      children: [
        { path: 'reset-passwort', element: <ResetPassword /> },
        {
          path: 'passwort-reset-bestaetigen/:uidb64/:token',
          element: <NewPassword />,
        },
        {
          path: 'partner-onboarding/:uidb64/:token',
          element: (
            <GuestGuard>
              <NewPassword />
            </GuestGuard>
          ),
        },
        { path: 'verifizieren', element: <VerifyCode /> },
      ],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={redirectTo} replace />,
          index: true,
        },
        {
          path: 'home',
          element: <Dashboard />,
        },
        {
          path: 'gast-bei/:guestId',
          element: <GuestDashboard />,
        },
        {
          path: 'gast-bei/:guestId/dokumentverlauf',
          element: <SDocumentHistory />,
        },
        {
          path: 'kontakt',
          element: <ContactUs />,
        },
        {
          path: 'patientenverfuegung',
          element: <LivingWill />,
        },
        {
          path: 'vorsorgeauftrag',
          element: <PowerAttorney />,
        },
        {
          path: 'testament',
          element: <LastWill />,
        },
        {
          path: 'ehevertrag',
          element: <MarriageContract />,
        },
        {
          path: 'vermoegens-und-sachregister',
          element: <AssetsRegister />,
        },
        {
          path: 'konkubinatsvertrag',
          element: <CohabitationAgreement />,
        },
        {
          path: 'digitaler-nachlass',
          children: [
            {
              element: <DigitalFootprint />,
              index: true,
            },
            {
              path: 'wichtige-informationen',
              element: <ImportantInformation />,
            },
            {
              path: 'digital-vermoegen',
              element: <DigitalAssets />,
            },
            {
              path: 'fotos-videos',
              element: <DFPhotosVideos />,
            },
          ],
        },
        {
          path: 'steuerordner',
          element: <TaxFolderClient />,
        },
        {
          path: 'chat',
          element: <Chat />,
        },
        {
          path: 'vorsorge-final',
          element: <ThankyouCard />,
        },
        {
          path: 'berater',
          element: <Consultant />,
        },
        {
          path: 'beratung',
          element: <Consulting />,
        },
        {
          path: 'beratung/user-erstellen/:service',
          element: <CreateUser />,
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            {
              path: 'account',
              element: <ProfileSettings />,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        {
          path: '403',
          element: <Page403 />,
        },
        {
          path: '404',
          element: <Page404 />,
        },
        {
          path: '500',
          element: <Page500 />,
        },
        {
          path: 'maintenance',
          element: <Maintenance />,
        },
        {
          path: 'coming-soon',
          element: <ComingSoon />,
        },
        {
          path: 'kontakt',
          element: <Contact />,
        },
        {
          path: 'vorsorge',
          element: (
            <GuestGuard>
              <OnboardingStepOne />
            </GuestGuard>
          ),
        },
        {
          path: 'registrieren',
          element: <PensionProvision />,
        },
        {
          path: 'vorsorge/schritt=1',
          element: (
            <AuthGuard>
              <WelcomeCard />
            </AuthGuard>
          ),
        },
        {
          path: 'vorsorge/schritt=2',
          element: (
            <AuthGuard>
              <OnboardingStepTwo />
            </AuthGuard>
          ),
        },
        {
          path: 'vorsorge/schritt=3',
          element: (
            <AuthGuard>
              <OnboardingStepThree />
            </AuthGuard>
          ),
        },
        {
          path: 'vertrauensperson/einladung/:token',
          element: <AcceptInvitation />,
        },
        {
          path: 'familie/einladung/:token',
          element: <AcceptInvitation />,
        },
        {
          path: 'partner/einladung/:token',
          element: <AcceptInvitation />,
        },
      ],
    },
    {
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: 'lebenssituations-check',
          element: <SituationCheck />,
        },
        {
          path: '/deals/grosseltern-magazin',
          element: <Home serviceProp="grosseltern-magazin" />,
        },
        {
          path: '/:serviceLanding',
          element: <Home />,
        },
        {
          path: '/vorsorge/:situation',
          element: <LSLanding />,
        },
        {
          path: '/ueber-uns',
          element: <AboutUs />,
        },
        {
          path: '/vorsorgecheck',
          element: <PreventiveCheckUp />,
        },
        {
          path: '/impressum',
          element: <Imprint />,
        },
        // {
        //   path: '/terms-conditions',
        //   element: <TermConditions />,
        // },
        {
          path: '/datenschutzbestimmungen',
          element: <PrivacyPolicy />,
        },
        {
          path: '/agb',
          element: <AGB />,
        },
      ],
    },
    // {
    //   path: '/',
    //   element: <Navigate to={redirectTo} replace />,
    //   index: true,
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
