import { IQuestion } from '#/types/globalTypes';

export const inheritorsMarriedVersion: IQuestion[] = [
  {
    question:
      'Vous avez indiqué que vous êtes marié. Pouvez-vous nous fournir les informations concernant votre conjoint ?',
  },
  {
    question:
      'Avez-vous conclu un contrat de mariage ou un contrat immobilier ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Avez-vous un bien immobilier/appartement que vous souhaitez léguer à votre partenaire ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Êtes-vous en instance de divorce ?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
  {
    question: 'Avez-vous des enfants issus d\'un mariage précédent ou actuel ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Souhaitez-vous favoriser d\'autres personnes ou institutions ?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const inheritorsSingleVersion: IQuestion[] = [
  {
    question: 'Vivez-vous actuellement dans une relation stable ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Avez-vous conclu un contrat immobilier (partenariat enregistré) ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Avez-vous un bien immobilier/appartement que vous souhaitez léguer à votre partenaire ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Avez-vous des enfants ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Souhaitez-vous favoriser d\'autres personnes ou institutions ?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const inheritorsDivorcedVersion: IQuestion[] = [
  {
    question: 'Vivez-vous actuellement dans une relation stable ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question:
      'Vous avez indiqué que vous êtes divorcé. Avez-vous des enfants ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question: 'Souhaitez-vous favoriser d\'autres personnes ou institutions ?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const distributionQuestions: IQuestion[] = [
  {
    question:
      'Dans la mesure permise par la loi, je souhaite que le plus possible de ma succession soit distribué à :',
    options: [
      {
        label: 'Conjoint/partenaire enregistré/partenaire de vie',
      },
      {
        label: 'Enfants',
      },
      {
        label: 'Une institution de mon choix',
      },
    ],
  },
  {
    question:
      'Souhaitez-vous offrir des objets spécifiques ou une somme d\'argent à un membre de la famille, une personne spécifique, même en dehors de votre famille, ou à une institution ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  // {
  //   question:
  //     'Avez-vous différents héritages que vous souhaitez offrir à différentes personnes ?',
  //   options: [
  //     {
  //       label: 'Oui',
  //     },
  //     {
  //       label: 'Non',
  //     },
  //   ],
  // },
];

export const governanceQuestions: IQuestion[] = [
  {
    question: 'Qui doit être l\'exécuteur testamentaire ?',
  },
];
