const agb = {
  title: 'Conditions Générales',
  scopeOfApp: {
    title: "Champ d'application",
    content: {
      p1: "L'objet de ces Conditions Générales (CG) est l'utilisation des services de gut geregelt AG (ci-après dénommée gut geregelt). gut geregelt met à disposition du client les services dans leur version actuelle pour la durée du contrat, moyennant paiement.",
      p2: "La relation contractuelle entre gut geregelt et le client est établie par l'acceptation de l'offre. En acceptant l'offre, le client reconnaît expressément les présentes CG, même si elles sont en contradiction totale ou partielle avec ses propres conditions générales.",
      p3: "Le client est tenu de fournir à tout moment des informations exactes, complètes et à jour. Le non-respect de cette obligation constitue une violation des conditions d'utilisation, pouvant entraîner la résiliation immédiate du contrat.",
    },
  },
  complience: {
    title: 'Respect des CG',
    content: {
      p1: "En utilisant la plateforme et les services de gut geregelt, l'utilisateur accepte expressément ces CG ainsi que leurs parties intégrantes, notamment la politique de confidentialité. L'utilisateur s'engage à respecter les présentes CG et leurs parties intégrantes dans le cadre de l'utilisation des services.",
    },
  },
  yourAccount: {
    title: 'Votre compte',
    content: {
      p1: "Les utilisateurs de gut geregelt sont des titulaires de compte. Ils acceptent ce qui suit : (1) Ils choisissent un mot de passe sécurisé, le gardent secret et le traitent de manière confidentielle ; (2) Ils ne transfèrent pas leur propre compte à d'autres personnes (par exemple, à des membres de la famille ou à d'autres contacts). Ils sont responsables de toutes les actions effectuées via leur compte utilisateur, sauf s'ils ont fermé le compte ou signalé un abus.",
    },
  },
  payment: {
    title: 'Paiement',
    content: {
      p1: 'Lorsque vous achetez nos services payants, vous acceptez les conditions supplémentaires pour ces services payants et vous vous engagez à payer les frais et taxes correspondants. En cas de non-paiement de ces frais, vos services payants seront interrompus. De plus, vous acceptez ce qui suit :',
      subP1:
        "Nous pouvons enregistrer et débiter votre méthode de paiement (par exemple, carte de crédit), même après son expiration, pour éviter une interruption de nos services et pour l'utiliser pour le paiement d'autres services que vous pourriez acquérir.",
      subP2:
        "Si vous achetez un abonnement, votre méthode de paiement sera automatiquement débitée des frais et taxes applicables au début de chaque période d'adhésion. Veuillez annuler avant la date de renouvellement pour éviter des frais futurs. Informez-vous sur la façon d'annuler ou de suspendre vos services d'abonnement.",
      subP3:
        "Nous calculons les taxes que vous devez payer sur la base des informations de facturation que vous nous fournissez au moment de l'achat.",
    },
  },
  termination: {
    title: 'Résiliation',
    content: {
      p1: "gut geregelt est en droit de résilier le contrat d'utilisation à tout moment sans indication de motifs dans certains cas. Un tel cas et motif de résiliation immédiate du contrat d'utilisation est notamment une violation par l'utilisateur de ces CG, en cas de suspicion d'abus ou si la sécurité du système n'est plus garantie. Pour les comptes gratuits, gut geregelt se réserve le droit de résilier et de dissoudre le contrat d'utilisation si l'utilisateur n'y accède pas pendant plus de 12 mois consécutifs. gut geregelt en informera l'utilisateur par e-mail à l'adresse enregistrée dans son compte. La résiliation sera annoncée à l'utilisateur 30 jours avant la suspension de son compte (\"délai de préavis\"), afin qu'il puisse exporter les données qu'il a enregistrées sur la plateforme. Après l'expiration du délai de préavis, gut geregelt est en droit de supprimer définitivement le compte de l'utilisateur et les données qu'il contient.",
    },
  },
  rightOfUse: {
    title: "Droits d'utilisation",
    content: {
      p1: "gut geregelt accorde au client le droit non exclusif et non transférable d'utiliser les services de gut geregelt conformément à leur destination pendant la durée du contrat. Cela inclut la création et la gestion de documents pour l'utilisation personnelle du client.",
      p2: "Le client n'est pas autorisé à utiliser le service pour générer des documents pour des utilisateurs non enregistrés ou à rendre le service accessible à des tiers, que ce soit gratuitement ou contre rémunération.",
      p3: "Le client s'engage à limiter l'utilisation du service aux utilisateurs enregistrés.",
    },
  },
  dataUsePartner: {
    title: 'Utilisation des données par les organisations partenaires',
    content: {
      p1: "Les organisations partenaires de gut geregelt peuvent accéder à certaines données de leurs clients dans le cadre de leurs services. Le type de données partagées est listé dans les paramètres sous l'onglet 'Données'.",
      p2: 'Le client a la possibilité à tout moment de contrôler le transfert des données vers les organisations partenaires dans les paramètres et de le désactiver si nécessaire.',
      p3: "gut geregelt s'engage à limiter l'utilisation des données par les organisations partenaires au strict nécessaire et à protéger les droits des clients en matière de protection des données.",
    },
  },
  dataProtection: {
    title: 'Protection et sécurité des données',
    content: {
      p1: "gut geregelt traitera les données du client avec le plus grand soin et les protégera contre tout abus ou perte. Le client est informé que certaines données peuvent être partagées avec des organisations partenaires dans le cadre de l'utilisation du service. Les détails concernant le type de données partagées et les options de contrôle se trouvent dans les paramètres du compte utilisateur. gut geregelt prend des mesures techniques et organisationnelles conformes aux exigences applicables du RGPD. Dans la mesure des possibilités techniques, des accords spécifiques sur le stockage des données peuvent être conclus dans certains pays. Le client est responsable de la légalité du transfert ou de l'utilisation des données. Toutes les données des clients stockées et traitées par gut geregelt sont la propriété exclusive du client et sont utilisées par gut geregelt uniquement dans le but d'exécuter le contrat.",
    },
  },
  support: {
    title: 'Support',
    content: {
      p1: 'Le support de gut geregelt est disponible pour le client du lundi au vendredi de 8h00 à 12h00 et de 13h00 à 17h00 par e-mail à support@gutgeregelt.ch.',
    },
  },
  intellectualPropertyRights: {
    title: 'Droits de propriété intellectuelle',
    content: {
      p1: "Le client reconnaît les droits de propriété intellectuelle, notamment le droit d'auteur, de gut geregelt sur les services logiciels et les documentations. Le client n'est pas autorisé à mettre ce logiciel à la disposition de tiers, à le louer ou à l'utiliser en dehors du cadre de la relation contractuelle avec gut geregelt, ni à contester gut geregelt de quelque manière que ce soit.",
    },
  },
  confidentiality: {
    title: 'Confidentialité',
    content: {
      p1: 'gut geregelt ne peut divulguer ou rendre accessibles à des tiers des informations et/ou des matériaux confidentiels sans le consentement écrit exprès du client.',
      p2: "gut geregelt s'engage à ne pas utiliser et/ou mettre à disposition de tiers les informations/matériaux confidentiels dont il a connaissance sans le consentement écrit exprès du client.",
      p3: "Les informations confidentielles doivent être utilisées par gut geregelt exclusivement en relation avec et pour la fourniture des services. gut geregelt ne peut divulguer des informations confidentielles à ses employés que si cela est nécessaire pour l'exécution du projet susmentionné. Les employés doivent être tenus à la confidentialité conformément à cet accord. L'obligation de confidentialité s'applique également aux entreprises affiliées à gut geregelt. gut geregelt les obligera également à respecter la confidentialité.",
      p4: "gut geregelt peut divulguer des informations confidentielles si cela est requis par la loi ou d'autres réglementations gouvernementales. Dans ces cas, gut geregelt informera immédiatement le client et prendra, à la demande du client, les mesures de protection nécessaires, dans la mesure où elles sont raisonnables. gut geregelt prend des mesures appropriées pour protéger les informations et matériaux confidentiels contre tout accès non autorisé par des tiers.",
    },
  },
  dataProtectionAndSecurity: {
    title: 'Protection et sécurité des données',
    content: {
      p1: 'gut geregelt traitera les données du client avec le plus grand soin et les protégera contre les abus et les pertes. gut geregelt prend des mesures techniques et organisationnelles qui répondent aux exigences en vigueur du RGPD. Des accords spéciaux concernant le stockage des données dans certains pays peuvent être conclus dans le cadre des possibilités techniques.',
      p2: "Le client est responsable de la légalité du transfert de données ou de leur utilisation. Toutes les données du client stockées et traitées par gut geregelt sont la propriété exclusive du client et sont utilisées par gut geregelt exclusivement à des fins d'exécution du contrat.",
    },
  },
  disclaimer: {
    title: 'Clause de non-responsabilité',
    content: {
      p1: 'gut geregelt ne fournit pas de conseils juridiques, mais met à disposition des modèles adaptés. Dans les affaires complexes, il est indispensable de faire appel à un avocat. gut geregelt ne remplace pas un conseil juridique.',
      p2: "La responsabilité de gut geregelt est, dans la mesure permise par la loi, entièrement exclue, notamment la responsabilité pour faute légère et moyenne, les dommages indirects ou consécutifs, notamment le manque à gagner, les économies non réalisées, l'interruption d'activité, les réclamations de tiers, les dommages consécutifs à des défauts ou les dommages résultant de la perte de données, ainsi que la responsabilité pour les auxiliaires et les tiers engagés. Une éventuelle responsabilité de gut geregelt est limitée au montant des frais d'utilisation facturés à l'utilisateur par gut geregelt pour l'utilisation de la plateforme et des services pendant une période de 12 mois.",
    },
  },
  applicableLaw: {
    title: 'Droit applicable',
    content: {
      p1: "Tous les contrats entre gut geregelt et l'utilisateur sont exclusivement soumis au droit suisse, à l'exclusion du droit international privé, de la Convention des Nations Unies sur les contrats de vente internationale de marchandises (CISG) et du droit international privé.",
    },
  },
  jurisdiction: {
    title: 'Juridiction',
    content: {
      p1: "Le for juridique pour tous les litiges, divergences d'opinion ou réclamations découlant de ou en relation avec la relation contractuelle entre gut geregelt et l'utilisateur, y compris sa validité, son invalidité, sa violation ou sa dissolution, est le siège de gut geregelt.",
    },
  },
};

export default agb;
