const lsLandingMains = {
  heirat: {
    bannerTitle:
      'Il matrimonio significa assumersi responsabilità. E pianificare.',
    bannerDescription:
      'Sposarsi significa assumersi responsabilità. Per te stesso, il tuo coniuge e la tua famiglia. Ti aiutiamo a pianificare i momenti difficili durante i bei momenti.',
    sectionHeadline: 'Precauzioni raccomandate per il matrimonio',
  },
  'geburt-eines-kindes': {
    bannerTitle: 'Un bambino significa amore. E responsabilità per il futuro.',
    bannerDescription:
      'La nascita di un bambino è un evento meraviglioso che stravolge la vita. Ora è il momento perfetto per proteggere i bei momenti e pensare a lungo termine. Insieme, ci assicuriamo che il tuo bambino/i tuoi bambini e i tuoi cari siano sempre ben assistiti, qualunque cosa la vita riservi.',
    sectionHeadline: 'Precauzioni raccomandate per la nascita di un bambino',
  },
  immobilienkauf: {
    bannerTitle: 'Una casa crea sicurezza. Assicurati che rimanga così.',
    bannerDescription:
      'Comprare una casa è un grande passo verso il futuro. Affinché questa sicurezza rimanga anche nei momenti difficili, è importante pianificare in anticipo. Ti aiutiamo ad adattare i tuoi piani precauzionali alla nuova situazione di vita in modo che tu possa concentrarti completamente sulla tua nuova casa.',
    sectionHeadline: "Precauzioni raccomandate per l'acquisto immobiliare",
  },
  pensionierung: {
    bannerTitle:
      'Rilassati in pensione. Pianifica per goderti la tranquillità.',
    bannerDescription:
      "L'ingresso in pensione segna l'inizio di una nuova fase della vita. Ora è particolarmente importante pensare al futuro. Ti aiutiamo a mettere tutto sulla giusta strada in modo che tu possa goderti la pensione senza preoccupazioni.",
    sectionHeadline: "Precauzioni raccomandate per l'ingresso in pensione",
  },
  'tod-verwandter': {
    bannerTitle:
      'La pianificazione crea pace e spazio per il lutto in caso di morte.',
    bannerDescription:
      "La morte di un parente è un momento difficile in cui molte cose devono essere sistemate. Oltre al dolore, ci sono spesso domande sull'eredità digitale e sui valori materiali del defunto. Con un registro chiaro dei beni e delle proprietà e regolamenti per l'eredità digitale, crei ordine e aiuti i tuoi superstiti a gestire tutto secondo i tuoi desideri.",
    sectionHeadline: 'Precauzioni raccomandate in caso di morte',
  },
  unternehmensgruendung: {
    bannerTitle: 'Avvia la tua attività. E rimani ben protetto.',
    bannerDescription:
      "Fondando un'azienda, metti in azione la tua visione e crei qualcosa di duraturo. Ma soprattutto durante questo periodo emozionante, è importante proteggerti - sia personalmente che professionalmente. Con un mandato precauzionale e un testamento, ti assicuri che la tua azienda e la tua famiglia siano protette anche quando la vita prende svolte inaspettate.",
    sectionHeadline: "Precauzioni raccomandate per la fondazione di un'azienda",
  },
  scheidung: {
    bannerTitle:
      'Il divorzio porta cambiamenti. Assicurati che il tuo futuro rimanga sicuro.',
    bannerDescription:
      'Un divorzio può essere un periodo difficile. È importante che tu sia ben informato durante questa fase e prenda le decisioni giuste. Ti aiutiamo a prendere tutte le disposizioni necessarie per garantire che tu e la tua famiglia siate ben protetti e possiate iniziare una nuova vita.',
    sectionHeadline: 'Precauzioni raccomandate per il divorzio',
  },
  krankheit: {
    bannerTitle:
      'Una diagnosi grave porta incertezza. Ti aiutiamo a pianificare.',
    bannerDescription:
      'Una malattia grave cambia tutto. Durante questo periodo difficile, è ancora più importante concentrarsi su ciò che conta - la tua salute e le persone a cui tieni. Con un testamento biologico e un testamento, ti assicuri che i tuoi desideri siano rispettati e i tuoi cari siano protetti quando non puoi più gestire le cose da solo.',
    sectionHeadline: 'Precauzioni raccomandate per la diagnosi di malattia',
  },
};

export default lsLandingMains;
