import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import { PROVISION_TOPICS } from '#/components/pages/PensionProvision/onboarding/data';
import RecommendationCard from '#/components/pages/PensionProvision/onboarding/steps/profiling/recommendations/card';
import InformativeCard from '#/components/shared/informative-card';
import useAuth from '#/lib/hooks/useAuth';

export default function RedLightRecommendations() {
  const { user } = useAuth();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleLandingRedirect = (landingURL: string) => {
    navigate(landingURL);
  };

  const getTopicsForCivilStatus = () => {
    const baseTopics = PROVISION_TOPICS.filter((topic) =>
      [
        'vorsorgeauftrag',
        'patientenverfuegung',
        'testament',
        'digitaler-nachlass',
        'vermoegens-und-sachregister',
      ].includes(topic.value)
    );

    switch (user?.civil_status) {
      case 'married':
        return [
          ...baseTopics,
          PROVISION_TOPICS.find((topic) => topic.value === 'ehevertrag')!,
        ];
      default:
        return [
          ...baseTopics,
          PROVISION_TOPICS.find(
            (topic) => topic.value === 'konkubinatsvertrag'
          )!,
        ];
    }
  };

  const recommendedTopics = getTopicsForCivilStatus();

  return (
    <RecommendationCard
      title={String(
        translate(
          'pensionProvision.onboarding.stepThree.redLightRecommendation.title'
        )
      )}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            md: 'repeat(3, 1fr)',
          },
          gap: 2,
        }}
      >
        {recommendedTopics.map((topic) => (
          <InformativeCard
            cardTitle={String(translate(topic.title))}
            key={topic.value}
            onServiceSelect={() =>
              handleLandingRedirect(topic.panelURL as string)
            }
          />
        ))}
      </Box>
    </RecommendationCard>
  );
}
