import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Card, Link, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { PATH_AUTH } from '#/routes/paths';
import Iconify from '#/components/shared/ui/Iconify';
import Page from '#/components/shared/ui/Page';
import VerifyCodeForm from '../../components/pages/VerifyCode/VerifyCodeForm';
import useLocales from '#/hooks/useLocales';
import { useResendCode } from '#/api/userQueries';

export default function VerifyCodePage() {
  const { state } = useLocation();
  const { translate } = useLocales();
  const { mutateAsync: resendCode } = useResendCode();

  const [countdown, setCountdown] = useState(300);
  const [isWaitingForResend, setIsWaitingForResend] = useState(true);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
    setIsWaitingForResend(false);
  }, [countdown]);

  const handleResendCode = async () => {
    try {
      await resendCode({ email: state.email });
      setCountdown(300);
      setIsWaitingForResend(true);
      toast.error(translate('toast_notifications.success.otpCode'));
    } catch (error) {
      toast.error(translate('toast_notifications.error.otpCode'));
      console.error(error);
    }
  };

  // Format the countdown to mm:ss
  const formatCountdown = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  return (
    <Page title={translate('global.otp.pageTitle')}>
      <Card
        sx={{
          padding: 5,
          borderRadius: '8px',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
        }}
      >
        <Typography variant="h3" paragraph textAlign="center">
          {translate('global.otp.title')}
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          {translate('global.otp.subtitle', {
            email: state?.email,
          })}
        </Typography>

        <VerifyCodeForm />

        {isWaitingForResend && countdown > 0 && (
          <Typography sx={{ marginTop: 3 }}>
            {translate('global.otp.waitforResend')} {formatCountdown()}
          </Typography>
        )}

        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ my: 3 }}>
          <Typography
            variant="body2"
            color="primary.main"
            sx={{
              cursor: 'pointer',
              pointerEvents: countdown === 0 ? 'auto' : 'none',
              color: countdown === 0 ? 'primary.main' : 'text.disabled',
            }}
            onClick={countdown === 0 ? handleResendCode : undefined}
          >
            {translate('global.otp.resendCode')}
          </Typography>
        </Stack>

        <Link
          component={RouterLink}
          to={PATH_AUTH.login}
          color="inherit"
          underline="none"
          sx={{
            mx: 'auto',
            alignItems: 'center',
            display: 'inline-flex',
          }}
        >
          <Iconify icon="eva:chevron-left-fill" width={16} />
          {translate('global.otp.returnToLogin')}
        </Link>
      </Card>
    </Page>
  );
}
