import { IQuestion } from '#/types/globalTypes';

export const treatmentQuestions: IQuestion[] = [
  {
    question:
      'Pourquoi rédigez-vous cette directive anticipée ? Quelle est la situation qui vous décrit le mieux actuellement ?',
    options: [
      {
        label: 'Je suis en bonne santé, mais je veux prendre des précautions',
      },
      {
        label: 'J\'ai des problèmes de santé et je veux prendre des précautions',
      },
      {
        label:
          'J\'ai atteint un certain âge et je veux prendre des précautions pour l\'avenir',
      },
    ],
  },
  {
    question:
      'Qu\'est-ce qui est plus important pour vous dans le traitement de la douleur et des symptômes ? Veuillez cocher l\'affirmation qui vous correspond.',
    options: [
      {
        label:
          'Je souhaite que toutes les possibilités médicales pour maintenir ma vie soient épuisées, même si cela implique des contraintes (souffrances)',
      },
      {
        label:
          'Je souhaite que le traitement médical serve principalement à soulager les souffrances, même si cela peut raccourcir ma vie',
      },
    ],
  },
  {
    question:
      'Souhaitez-vous renoncer à toutes les mesures de prolongation de la vie et des souffrances si vous devenez incapable de discernement en raison d\'un événement aigu inattendu et s\'il est impossible ou improbable, après une évaluation médicale approfondie, que vous retrouviez votre capacité de discernement ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
  {
    question: 'Souhaitez-vous être réanimé ?',
    options: [
      {
        label: 'Oui',
      },
      {
        label: 'Non',
      },
    ],
  },
];

export const detailedQuestions: IQuestion[] = [
  {
    question:
      'Dans un scénario où vous devenez soudainement incapable de discernement, par exemple en cas d\'urgence, quel serait votre objectif de traitement pour prolonger la vie ?',
    options: [
      {
        label: 'Je souhaite être réanimé et recevoir un traitement',
      },
      {
        label:
          'Je ne souhaite pas être réanimé, mais être traité en soins intensifs',
      },
    ],
  },
  {
    question:
      'Quel serait votre objectif de traitement pour soulager les souffrances en cas d\'incapacité soudaine ?',
    options: [
      {
        label:
          'Je souhaite recevoir des médicaments pour soulager les souffrances (morphine, etc.) et des thérapies de traitement',
      },
      {
        label:
          'Je ne souhaite pas recevoir de médicaments pour soulager les souffrances (morphine, etc.) et de thérapies de traitement',
      },
    ],
  },
  {
    question:
      'Dans un scénario où vous êtes en incapacité permanente, par exemple en cas de démence, quels seraient vos objectifs de traitement pour prolonger la vie ?',
    options: [
      {
        label:
          'Je souhaite être réanimé et traité en soins intensifs',
      },
      {
        label:
          'Je ne souhaite pas être réanimé, mais être traité en soins intensifs',
      },
    ],
  },
  {
    question:
      'Quels objectifs poursuivriez-vous en cas d\'incapacité permanente soudaine ?',
    options: [
      {
        label:
          'Je souhaite recevoir des médicaments pour soulager les souffrances (morphine, etc.) et des thérapies de traitement',
      },
      {
        label:
          'Je ne souhaite pas recevoir de médicaments pour soulager les souffrances (morphine, etc.) et de thérapies de traitement',
      },
    ],
  },
  {
    question:
      'Quels seraient vos objectifs de traitement pour prolonger la vie dans un scénario où vous êtes en incapacité permanente, par exemple en raison d\'une démence ?',
    options: [
      {
        label:
          'Je souhaite recevoir une réanimation et un traitement en soins intensifs',
      },
      {
        label:
          'Je ne souhaite pas être réanimé, mais être traité en soins intensifs',
      },
    ],
  },
  {
    question:
      'Quels seraient vos objectifs pour soulager les souffrances en cas d\'incapacité permanente soudaine ?',
    options: [
      {
        label:
          'Je souhaite recevoir des médicaments pour soulager les souffrances (morphine, etc.) et des thérapies de traitement',
      },
      {
        label:
          'Je ne souhaite pas recevoir de médicaments pour soulager les souffrances (morphine, etc.) et de thérapies de traitement',
      },
    ],
  },
];