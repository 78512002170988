import { Link, MenuItem, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import MenuPopover from '#/components/shared/ui/MenuPopover';
import { useMarkAsDone } from '#/api/servicesQueries';

type Props = {
  extras: {
    toServiceURL: string;
    progress?: boolean;
  };
  serviceKey: string;
  isLoading?: boolean;
  service?: any;
  history?: boolean;
};

export default function ServiceActions({
  extras,
  isLoading,
  serviceKey,
  service,
  history,
}: Props) {
  const [moreOptions, setMoreOptions] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMoreOptions(event.currentTarget);
  };

  const handleClose = () => {
    setMoreOptions(null);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <GoToService
        url={extras.toServiceURL}
        isLoading={isLoading}
        isCompleted={service?.completed}
        percentage={service?.percentage}
        markedAsDone={service?.marked_as_done}
        history={history}
      />
      {extras?.progress && (
        <Iconify
          icon="entypo:dots-three-vertical"
          sx={{
            cursor: 'pointer',
          }}
          onClick={handleOpen}
        />
      )}
      <MenuPopover
        open={Boolean(moreOptions)}
        anchorEl={moreOptions}
        onClose={handleClose}
      >
        {extras.progress && (
          <MarkAsDone
            toServiceURL={extras.toServiceURL}
            service={serviceKey}
            disabled={service?.completed || service?.marked_as_done}
          />
        )}
        {extras.progress && <GoToHistory toServiceURL={extras.toServiceURL} />}
        {extras.progress && (
          <StartOver toServiceURL={extras.toServiceURL} service={serviceKey} />
        )}
      </MenuPopover>
    </Stack>
  );
}

const MarkAsDone = ({
  toServiceURL,
  service,
  disabled,
}: {
  toServiceURL: string;
  service: string;
  disabled?: boolean;
}) => {
  const { translate } = useLocales();

  const { mutate: markAsDone } = useMarkAsDone();

  const handleMarkAsDone = () =>
    markAsDone({
      service,
      marked_as_done: true,
    });

  return (
    <Link
      component={RouterLink}
      to={toServiceURL}
      sx={{
        textDecoration: 'none',
        color: 'text.primary',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
      state={{
        step: 'markAsDone',
      }}
      onClick={handleMarkAsDone}
    >
      <MenuItem disabled={disabled}>
        <Typography variant="subtitle1" fontWeight="light">
          {translate('global.dashCardInfos.markAsDone')}
        </Typography>
      </MenuItem>
    </Link>
  );
};

export const GoToHistory = ({
  toServiceURL,
  ownerID,
  historyAPI,
  documentType,
}: {
  toServiceURL: string;
  ownerID?: string | number;
  historyAPI?: string;
  documentType?: string;
}) => {
  const { translate } = useLocales();

  return (
    <Link
      component={RouterLink}
      to={toServiceURL}
      sx={{ textDecoration: 'none', color: 'text.primary' }}
      state={{
        step: 'history',
        ...(ownerID && { ownerID }),
        ...(historyAPI && { historyAPI }),
        ...(documentType && { documentType }),
      }}
    >
      <MenuItem>
        <Typography variant="subtitle1" fontWeight="light">
          {translate('global.dashCardInfos.history')}
        </Typography>
      </MenuItem>
    </Link>
  );
};

type GoToServiceProps = {
  url: string;
  isLoading?: boolean;
  isCompleted?: boolean;
  percentage?: number;
  markedAsDone?: boolean;
  history?: boolean;
};

const GoToService = ({
  url,
  isLoading,
  isCompleted,
  percentage,
  markedAsDone,
  history,
}: GoToServiceProps) => {
  const { translate } = useLocales();

  const notFinished =
    percentage !== null &&
    percentage !== undefined &&
    percentage > 0 &&
    percentage < 100 &&
    !markedAsDone &&
    !isCompleted;

  const hasMarkedAsDoneWithDoc = markedAsDone && history;
  const hasMarkedAsDoneWithoutDoc = markedAsDone && !history;
  const hasCompleted = isCompleted && !markedAsDone;
  const yetToStart = !isCompleted && !markedAsDone && !notFinished && !!url;

  const commingSoon = !url;

  return (
    <Link
      component={RouterLink}
      to={url}
      state={{
        step: markedAsDone ? 'uploadDocument' : undefined,
      }}
    >
      <LoadingButton
        variant="contained"
        sx={{
          textDecoration: 'none',
        }}
        loading={isLoading}
        disabled={commingSoon}
      >
        <Typography variant="body1">
          {hasMarkedAsDoneWithDoc &&
            translate('global.dashCardInfos.summaryAndReview')}
          {hasMarkedAsDoneWithoutDoc &&
            translate('global.dashCardInfos.uploadDocument')}
          {hasCompleted && translate('global.dashCardInfos.summaryAndReview')}
          {notFinished && translate('global.dashCardInfos.continue')}
          {yetToStart && translate('global.dashCardInfos.getStarted')}
          {commingSoon && translate('global.dashCardInfos.comingSoon.title')}
        </Typography>
      </LoadingButton>
    </Link>
  );
};

const StartOver = ({
  toServiceURL,
  disabled,
  service,
}: {
  toServiceURL: string;
  service: string;
  disabled?: boolean;
}) => {
  const { translate } = useLocales();

  const { mutate: markAsDone } = useMarkAsDone();

  const handleStartOver = () =>
    markAsDone({
      service,
      marked_as_done: false,
    });

  return (
    <Link
      component={RouterLink}
      to={toServiceURL}
      state={{
        startOver: true,
      }}
      sx={{
        textDecoration: 'none',
        color: 'text.primary',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
      onClick={handleStartOver}
    >
      <MenuItem disabled={disabled}>
        <Typography variant="subtitle1" fontWeight="light">
          {translate('global.dashCardInfos.startOver')}
        </Typography>
      </MenuItem>
    </Link>
  );
};
