import {
  contractParties,
  lifeSituation,
} from '#/locales/it/cohabitationAgreement/questions';
import outputDoc from '#/locales/it/cohabitationAgreement/outputDoc';

const cohabitationAgreement = {
  mainHeader: {
    regulateTogether: 'Regolate la vostra vita insieme.',
    effectiveDocs: 'Il vostro contratto di convivenza e inventario efficace',
  },
  stepperInfo: [
    {
      title: 'Partner',
      label: 'Parti contrattuali',
    },
    {
      title: 'Situazione di vita',
      label: 'Situazione di vita',
    },
    {
      title: 'Inventario',
      label: 'Inventario',
    },
    {
      title: 'Depositate il vostro contratto di convivenza e inventario',
      label: 'Il vostro contratto',
    },
  ],

  stepOne: {
    questions: contractParties,
    malePartner: 'Il vostro partner',
    femalePartner: 'La vostra partner',
  },
  stepTwo: {
    questions: lifeSituation,
    periodOptions: {
      monthly: 'Mensile',
      bi_monthly: 'Ogni due mesi',
      quarterly: 'Trimestrale',
      weekly: 'Settimanale',
      bi_weekly: 'Ogni due settimane',
    },
    jointQuestions: {
      joint_household_account_bank:
        'Presso quale banca avete il vostro conto corrente comune?',
      equal_payment:
        "Qual è l'importo dei pagamenti uguali e con quale frequenza vengono effettuati?",
      compensation_payment_cadence:
        'Con quale frequenza controllate il saldo del conto per eventuali pagamenti di compensazione?',
      expenses_to_be_covered: 'Quali costi verranno coperti?',
    },
    oneFullTimeOnePartTime: {
      who_works_full_time: 'Chi lavora a tempo pieno?',
      your_partner: '{{partnersGender}}',
      you: 'Voi',
      yourWorkload: 'Qual è il vostro carico di lavoro?',
      partnersWorkload:
        'Qual è il carico di lavoro rispetto a {{partnersGender}}',
      malePartner: 'del vostro partner',
      femalePartner: 'della vostra partner?',
      equal_payment:
        "Qual è l'importo, la proporzione per parte e la frequenza dei pagamenti?",
      account_balance_in_case_of_separation:
        'Come dovrebbe essere distribuito il saldo del conto in caso di scioglimento della convivenza?',
    },
    oneFullTimeOtherNotWorking: {
      household_and_children_responsibility:
        'Chi si occupa della casa e dei bambini?',
      monthly_transfer_for_free_disposal:
        'Trasferite mensilmente al vostro partner un importo per uso libero/Ricevete mensilmente dal vostro partner un trasferimento per uso libero?',
      yes: 'Sì',
      no: 'No',
      free_disposal_amount: 'Quanto?',
    },
  },
  stepThree: {
    soleOwnershipList:
      'Elencate tutti gli oggetti che sono di proprietà esclusiva vostra o del vostro partner.',
    commonlyPurchasedList:
      'Elencate tutti gli articoli che avete acquistato insieme',
    confirmDeleteInventory: {
      title: 'Conferma eliminazione',
      description: 'Sei sicuro di voler eliminare questo articolo?',
      cancel: 'Annulla',
      delete: 'Elimina',
    },
  },
  stepFour: {
    document: outputDoc,
    nextStepsTitle: 'I prossimi passi',
    nextStep1:
      "Stampate il contratto di convivenza e l'inventario e leggeteli attentamente.",
    nextStep2: 'Firmate entrambi i documenti.',
    nextStep3: 'Caricate i due file qui sotto.',
    nextStep4: "Aggiornate regolarmente l'inventario",
  },
  expensesOptions: {
    rent: 'Affitto',
    additional:
      "Costi accessori dell'abitazione come elettricità, riscaldamento, acqua, tasse sui rifiuti, ecc.",
    radioTV: 'Connessione radio e TV',
    groceries: 'Generi alimentari',
    cleaning: 'Spese di pulizia',
  },
};

export default cohabitationAgreement;
