const guest = {
  title: 'Prévoyance de {{first_name}} {{last_name}}',
  description:
    'Tant que votre hôte est en vie, vous avez un accès limité aux données et informations.',
  seeWhatsIncluded: 'voir ce qui est inclus',
  includedTooltip:
    'Avec l\'accès invité, ces personnes ont uniquement accès aux directives anticipées et au mandat pour cause d\'inaptitude.',
  reportDeath: 'Signaler un décès',
  reportDeathDescription:
    'Pour que vous ayez un accès complet à tous les documents et informations de {{first_name}}, nous devons malheureusement d\'abord confirmer le décès et avons besoin de l\'acte de décès officiel ainsi que du certificat d\'exécuteur testamentaire (les deux sont disponibles auprès de la commune).',
  alreadyReported: 'Déjà signalé',
};

export default guest;