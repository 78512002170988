import {
  distributionQuestions,
  governanceQuestions,
  inheritorsDivorcedVersion,
  inheritorsMarriedVersion,
  inheritorsSingleVersion,
} from './questions';
import outputDoc from './outputDoc';

const lastWill = {
  mainHeaders: {
    shortPainFree: 'Your simple and effective will',
    madeIt: 'Your effective will',
  },

  yes: 'Yes',
  no: 'No',

  addAnotherChildren: 'Add more children',
  areYouSureYouWantToDeleteChild:
    "Are you sure you want to delete the child's entry?",
  deleteInformerChild:
    'If you delete a child from the list, all their data will be deleted',
  cancelBtn: 'Cancel',
  deleteBtn: 'Delete',
  childBirthDate: 'Date of birth',

  noChildrenModal: {
    title: 'Are you sure you want to reset the number of children?',
    description:
      'If you reset the number of children, all information about your children will be deleted.',
    resetNumberBtn: 'Reset number',
    cancelBtn: 'Cancel',
  },

  stepperInfo: [
    {
      label: 'Beneficiaries',
      title: 'What is it about and why is it so important?',
    },

    {
      label: 'Distribution',
      title: 'Define the distribution',
    },

    {
      label: 'Governance',
      title: 'Define the governance',
    },

    {
      label: 'Your last will',
      title: 'Deposit your will',
    },
  ],

  stepOne: {
    introParagraph:
      'A will gives you the opportunity to provide your descendants with clear instructions about your wishes during a difficult time. It also allows you to ensure, within the legal framework, that your wishes regarding the handling of your estate are considered. Furthermore, the will gives you the opportunity to avoid difficult and emotional discussions within the family system by appointing an executor.',
    helperParagraph:
      'At the same time, drafting a will is not an easy task for you personally, so please take your time and do not feel stressed. With our reminder service, you can pause at any time and continue later. Just click the "I am done" button.',
    questions: {
      inheritorsMarriedVersion,
      inheritorsSingleVersion,
      inheritorsDivorcedVersion,
    },
    addAnotherBenefiter: 'Add beneficiary',
    isChildFromCommonPreviousMarriage:
      'This child is from the previous marriage',
    isChildFromCommonMarriage: 'This child is from the common marriage',
    isChildFromCommonRelationship: 'This child is from the common partnership',
  },

  stepTwo: {
    introParagraph: 'What do you mainly want to achieve with this will?',
    mainly: 'mainly',
    yesIHaveProperty:
      'Yes, I have property or apartment(s) that I want to give away to:',
    followingDistribution:
      'Yes, I want the following estate items / the above-mentioned sum of money to be transferred to the above-mentioned person:',
    questions: distributionQuestions,
  },
  stepThree: {
    questions: governanceQuestions,
    orAddSomeoneNew: 'or add someone new',
    addAnotherExecutor: 'Executor',
  },
  stepFour: {
    document: outputDoc,
    introParagraph: "Congratulations! You've made it!",
    helperParagraph:
      'In Switzerland, the requirement of written form applies, which means that to make your will legally valid, you have the choice:',
    helperParagraph2:
      'It is also advisable to deposit the will with a lawyer and have it registered with the official Swiss Will Register (https://www.ztr.ch/notariatsinfo/).',
    contactForm: 'Contact form',
    helperParagraph3:
      'If you want us to help you, please use our contact form.',
    obligation1:
      'A handwritten copy of the will with place, date, and your signature',
    obligation2:
      'A public will (self-reading procedure, Art. 500 ff. ZGB or reading procedure, Art. 502 ZGB), for which you need a notary and two witnesses',
    nextStepTitle: 'The next steps:',
    nextStep1:
      'Either write the will completely by hand, date and sign it, or have it notarized with a lawyer and two witnesses (according to the self-reading procedure, Art. 500 ff. ZGB or reading procedure, Art. 502 ZGB) and finally upload it here',
    nextStep2:
      'Ensure that trusted persons have a guest access to "gut-geregelt" so that they can access the will in an emergency (click here)',
    highlightText: 'click here',
    nextStep3: 'Discuss the will with the family if necessary',
    highlightedText: {
      noterizeWithLawyer: 'notarized with a lawyer and two witnesses',
      linkToSettings: 'https://gutgeregelt.ch/user/account',
    },
    stopProcess: {
      title:
        'If you have concluded a marriage contract, we cannot continue this process.',
      title1: 'If you choose "No" here, we cannot continue this process.',
      description:
        'We recommend that you contact a lawyer for a will that takes a marriage contract into account. Here you can find a lawyer for support',
      description1:
        'If you want to choose "No", we recommend setting up a will that takes the marriage contract into account. \n' +
        'For this, you should contact a lawyer directly.\n' +
        'We are happy to refer you to a specialist.',
      changeAnswerBtn: 'Change answer',
      continueBtn: 'End process and continue with a lawyer',
    },
  },
  updateCivilStatusModal: {
    title: 'Do you want to update your marital status?',
    description:
      'Changing your answer will update your marital status and reset your document. Are you sure you want to proceed?',
    updateButton: 'Update',
    cancelButton: 'Cancel',
  },

  checkChildrenRelationship: {
    title:
      'Since you have changed your current relationship status, the associated children will be removed',
    description:
      'If you have children from a previous relationship, please add them again',
    updateButton: 'Update children information',
    cancelButton: 'Cancel',
  },

  alternativeGoverner:
    'If the person you chose cannot act as executor, the following person will be appointed:',
};

export default lastWill;
