import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  CardProps,
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { fData } from '#/utils/formatNumber';
import { FolderAPI } from '#/types/digitalAssets';
import Iconify from '#/components/shared/ui/Iconify';
import usePopover from '#/lib/hooks/usePopover';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useBoolean from '#/lib/hooks/useBoolean';
import useLocales from '#/lib/hooks/useLocales';
import FileManagerNewFolderDialog from '#/components/shared/file-manager/file-manager-new-folder-dialog';
import {
  invalidateDigitalAssetsQueries,
  useRenameFolder,
  useToggleFavoriteFolder,
} from '#/lib/api/digitalAssets';
import ConfirmDialog from '#/components/shared/confirm-dialog';
import IconButtonAnimate from '#/components/shared/ui/IconButtonAnimate';

interface Props extends CardProps {
  folder: FolderAPI;
  selected?: boolean;
  onSelect?: VoidFunction;
  onDelete: VoidFunction;
  onOpen: VoidFunction;
  entityType: 'folder' | 'file';
}

export default function GridFolderItem({
  folder,
  sx,
  onDelete,
  onOpen,
  entityType,
  ...other
}: Props) {
  const [folderName, setFolderName] = useState(folder.name);

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: folder.id.toString(),
      data: { type: entityType },
    });

  const { translate } = useLocales();
  const popover = usePopover();
  const editFolder = useBoolean();
  const confirm = useBoolean();
  const favorite = useBoolean(folder.starred);

  const { mutateAsync: updateFolder } = useRenameFolder(folder.id);
  const { mutateAsync: toggleFavorite } = useToggleFavoriteFolder(folder.id);

  const handleChangeFolderName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFolderName(event.target.value);
    },
    []
  );

  const handleFavoriteToggle = async () => {
    try {
      await toggleFavorite({ id: folder.id, starred: !favorite.value });
      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error toggling favorite: ');
    }
  };

  const handleFolderRename = async (name: string) => {
    try {
      await updateFolder({ name });
      invalidateDigitalAssetsQueries.getFiles();
      editFolder.onFalse();
    } catch (error) {
      console.error('Error updating folder: ');
    }
  };

  const renderDragIcon = (
    <IconButtonAnimate
      sx={{
        cursor: 'grab',
      }}
      {...listeners}
      {...attributes}
    >
      <Iconify icon="mdi:drag" />
    </IconButtonAnimate>
  );

  const renderIcon = (
    <Box
      onClick={onOpen}
      component="img"
      src="/assets/icons/ic_folder.svg"
      alt="folder"
      sx={{ width: 36, height: 36, cursor: 'pointer' }}
    />
  );

  const renderAction = (
    <Stack
      direction="row"
      alignItems="center"
      // sx={{
      //   top: 8,
      //   right: 8,
      //   position: 'absolute',
      // }}
    >
      <Checkbox
        color="warning"
        icon={<Iconify icon="eva:star-outline" />}
        checkedIcon={<Iconify icon="eva:star-fill" />}
        checked={folder.starred || favorite.value}
        onChange={() => {
          handleFavoriteToggle();
          favorite.onToggle();
        }}
      />
      <IconButton
        color={popover.open ? 'inherit' : 'default'}
        onClick={popover.onOpen}
      >
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </Stack>
  );

  const renderText = (
    <ListItemText
      onClick={onOpen}
      primary={folder.name}
      secondary={
        <>
          {fData(folder.size)}
          <Box
            component="span"
            sx={{
              mx: 0.75,
              width: 2,
              height: 2,
              borderRadius: '50%',
              bgcolor: 'currentColor',
            }}
          />
          {folder.file_count} {translate('global.files')} |{' '}
          {folder.folder_count} {translate('global.folders')}
        </>
      }
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle1',
        sx: { cursor: 'pointer' },
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'caption',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );

  const defaultStyle: React.CSSProperties = {
    transition: 'transform 200ms ease',
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    userSelect: 'none',
    padding: '8px',
    // border: '1px solid #ccc',
    borderRadius: '8px',
    // backgroundColor: '#f9f9f9',
  };

  return (
    <>
      <Stack
        ref={setNodeRef}
        component={Paper}
        variant="outlined"
        spacing={1}
        alignItems="flex-start"
        sx={{
          ...defaultStyle,
          p: 2,
          maxWidth: 222,
          borderRadius: 2,
          position: 'relative',
          ...sx,
        }}
        {...other}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          {renderDragIcon}
          {renderAction}
        </Stack>
        <Box>{renderIcon}</Box>

        {renderText}
      </Stack>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            editFolder.onTrue();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {String(translate('global.edit'))}
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {String(translate('global.delete'))}
        </MenuItem>
      </CustomPopover>
      <FileManagerNewFolderDialog
        open={editFolder.value}
        onClose={editFolder.onFalse}
        title={String(translate('global.editFolder'))}
        onUpdate={() => handleFolderRename(folderName)}
        onChangeFolderName={handleChangeFolderName}
        folderName={folderName}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={String(translate('global.deleteConfirmation.title'))}
        content={String(
          translate('global.deleteConfirmation.question', {
            item: folder.name,
          })
        )}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDelete();
              confirm.onFalse();
            }}
          >
            <Typography>{String(translate('global.delete'))}</Typography>
          </Button>
        }
      />
    </>
  );
}
