const digitalFootprint = {
  title: 'Empreinte numérique',
  description:
    'Partagez des informations et des accès pertinents avec vos proches.',
  dashboardServiceCards: {
    importantInformation: {
      title: 'Informations importantes',
      description:
        'Faites une note pour vos proches sur l\'emplacement des documents officiels, des documents bancaires ou des assurances afin de réduire les efforts et le stress en période difficile.',
    },
    digitalAssets: {
      title: 'Actifs numériques & Abonnements',
      description:
        'Gardez une vue d\'ensemble de tous vos abonnements, profils de réseaux sociaux ou autres comptes et assurez-vous de pouvoir transmettre des artefacts et actifs numériques à vos descendants.',
    },
    picturesAndVideos: {
      title: 'Photos & Vidéos',
      description:
        'Assurez-vous que vos descendants ont accès aux photos et vidéos que vous souhaitez préserver pour la postérité.',
    },
  },
};

export default digitalFootprint;