const grosseItern = {
  mainBanner: {
    intro: 'Simply peace of mind:',
    title: 'Retirement provision, well-organized',
    subtitle:
      'A difficult topic made easy a living will, power of attorney, and last will comfortably from home – in just 20 minutes. Start now with the Swiss provider "gut geregelt."',
    buttonLabel: 'Get started now',
    sale: {
      blueText: 'Save 50%',
      blackText: 'until 31.12.2024',
    },
  },
  aboutCards: {
    justDoIt: {
      title: 'Get it done before anything happens',
      description:
        "The hope is to never need a living will or power of attorney. But since unforeseen events can always happen, it's wise to prepare for them early on.",
    },
    security: {
      title: 'Security for your spouse and family',
      description:
        'By preparing these documents in advance, you retain control over your wishes while protecting your entire family and avoiding unnecessary stressful situations.',
    },
    done: {
      title: 'Completed in 20 Minutes – from home',
      description:
        'With "gut geregelt," you create legally sound documents and contracts in clear, understandable language, all from the comfort of home. And rest assured, legal experts are available to help with more complex cases.',
    },
    swissProvider: {
      title: 'Swiss provider',
      description:
        '"gut geregelt" is your Swiss digital suite for planning ahead. Do yourself and your loved ones a favor by organizing your future well today.',
    },
  },
  angebot: {
    phrase:
      'Featured in the media and in "Grosseltern Magazin" (Issue 30/2024)',
    highlightedPhrase: 'Grosseltern Magazin',
  },
};

export default grosseItern;
