import { useDropzone } from 'react-dropzone';
import {
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  StackProps,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';
import Iconify from '../ui/Iconify';
import useLocales from '#/hooks/useLocales';

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  file,
  onDelete,
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  placeholderHeading,
  ...other
}: UploadProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const { translate } = useLocales();

  const isCustomFile = typeof file !== 'string';

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;
  const mutableFileRjections = [...fileRejections];

  const isError = isDragReject || !!error;
  const isImageFile = isCustomFile ? file?.type.includes('image') : false;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isCustomFile && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),

          ...(hasFile &&
            isImageFile && {
              padding: '12% 0',
            }),
        }}
      >
        <input {...getInputProps()} />

        <Placeholder
          heading={placeholderHeading}
          sx={{
            ...(hasFile && {
              opacity: 0,
              display: 'none',
            }),
          }}
        />

        {hasFile && (
          <SingleFilePreview
            file={file}
            helperText={translate('global.signedDoc.reUpload')}
          />
        )}
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles fileRejections={mutableFileRjections} />

      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview
              files={files}
              thumbnail={thumbnail}
              onRemove={onRemove}
            />
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={onRemoveAll}
              >
                Remove all
              </Button>
            )}

            {onUpload && (
              <Button size="small" variant="contained" onClick={onUpload}>
                Upload files
              </Button>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}

interface PlaceholderProps extends StackProps {
  heading?: string;
}

function Placeholder({ sx, heading, ...other }: PlaceholderProps) {
  const { translate } = useLocales();

  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        ...sx,
      }}
      {...other}
    >
      <div>
        <Typography
          gutterBottom
          variant="h5"
          textAlign="center"
          sx={{ color: '#EB5757' }}
        >
          {heading ?? translate('global.formLabels.dropOrSelectFile')}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {translate('global.signedDoc.placeholder.helper.dropFilesHere')}
          {/* <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            {translate('global.signedDoc.placeholder.helper.dropFilesHere')}
          </Typography> */}
          {/* {String( */}
          {/*  translate('global.signedDoc.placeholder.helper.thoroughYourDevice') */}
          {/* )} */}
        </Typography>
      </div>
    </Stack>
  );
}
