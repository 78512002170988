const cohabitationAgreementLanding = {
  mainBanner: {
    intro: '',
    title: 'Protect yourself and your partner with a Cohabitation Agreement',
    subtitle:
      'Many aspects of social and legal security in unmarried partnerships are not regulated by law. With a cohabitation agreement, you can benefit from tax advantages and clarify many unresolved questions.',
    buttonLabel: 'Create for free now',
  },
  youtubeTutorial: 'What is a cohabitation agreement?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Proactive measure',
      description:
        'Protect yourself and your partner from unresolved issues and topics in an unmarried partnership.',
    },
    defineWishes: {
      title: 'Define your wishes',
      description:
        'Define suitable and legal arrangements in case of separation or death.',
    },
    benefitMaximally: {
      title: 'Maximize benefits',
      description:
        'Benefit from the advantages of a secure cohabitation—personally, financially, and in terms of taxes.',
    },
  },
  subBanner: {
    title: 'Protected with a legally compliant cohabitation agreement',
    subtitle:
      'With "gut geregelt," you can quickly create your cohabitation agreement, adapt it and the associated inventory at any time, and thus legally clarify sensitive issues or questions to protect each other and maximize benefits from cohabitation.',
    buttonLabel: 'Create for free now',
  },
  newsletter: {
    title: 'Regulate your affairs with a cohabitation agreement',
    buttonLabel: 'Create for free now',
  },
  faq: {
    questions: {
      question1: 'What is a cohabitation agreement?',
      question2: 'What are the benefits of cohabitation compared to marriage?',
      question3:
        'Where does cohabitation not provide the same protection as marriage?',
      question4: 'How does cohabitation protect inheritance or my estate?',
      question5: "Am I entitled to my partner's social benefits?",
      question6: 'Does a cohabitation agreement require notarization?',
      question7: 'Which aspects does the law cover in cohabitation?',
      question8:
        'Can a cohabitation agreement be changed or dissolved at any time?',
      question9: 'What happens to the cohabitation agreement if you marry?',
      question10: 'How is maintenance arranged in the event of separation?',
    },
    answers: {
      answer1:
        'A cohabitation agreement is a private contract between two individuals who live in a marriage-like relationship but are not legally married. It defines the rights and obligations of the partners during the shared cohabitation and in case of separation.',
      answer2: '',
      answer3: '',
      answer4:
        'Cohabitation offers no legal inheritance protection for the partner. The estate must be arranged through a will or inheritance contract to secure the partner; otherwise, the cohabitation partner will have no inheritance rights.',
      answer5:
        "No, in cohabitation there is generally no entitlement to a partner's social benefits, such as widow's or widower's pension. Benefits like health insurance or pension claims are only secured within marriage, unless specific contractual arrangements are made. Some pension plans, however, offer the option to register the partner and claim benefits in the event of death.",
      answer6:
        'A cohabitation agreement does not necessarily require notarization. For more complex regulations, particularly regarding property or inheritance, notarization may be advisable. For which aspects does the law provide protection in cohabitation?',
      answer7:
        'Yes, a cohabitation agreement can be changed or dissolved by mutual agreement at any time. This should be documented in writing to avoid later disputes.',
      answer8:
        'With marriage, the cohabitation agreement becomes void, as marriage is comprehensively regulated by the Civil Code (ZGB). All terms in the Cohabitation Agreement are replaced by marital law.',
      answer9:
        'Since there is no statutory maintenance obligation in cohabitation, partners must arrange this themselves. It is advisable to document such agreements in the Cohabitation Agreement. Without a defined agreement, separation could lead to legal disputes, where general contract practices would apply.',
    },
    lists: {
      answer6: {
        title: 'Swiss law provides legal protection for the following areas:',
        items: [
          { description: 'Guardianship' },
          { description: 'Maintenance' },
          { description: 'Representation towards third parties' },
          { description: 'Assets' },
          { description: 'Relations with children' },
        ],
      },
    },
  },
};

export default cohabitationAgreementLanding;
