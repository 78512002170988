import { Box, MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';

const countries = [{ code: 'CH', label: 'global.switzerland' }];

export function PartnersInfo() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldNames = {
    'partner.address.street': 'route',
    'partner.address.house_number': 'street_number',
    'partner.address.zip_code': 'postal_code',
    'partner.address.city': 'locality',
  };

  // @ts-ignore
  const isStreetError = Boolean(errors?.partner?.address?.street);

  const { translate } = useLocales();

  return (
    <Box
      display="block"
      sx={{
        '& > *': {
          marginBottom: '1rem',
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="partner.first_name"
          label={translate('global.formLabels.firstName')}
        />
        <RHFTextField
          name="partner.last_name"
          label={translate('global.formLabels.lastName')}
        />
      </Stack>
      <RHFTextField
        name="partner.email"
        label={translate('global.formLabels.emailAddress')}
      />
      <Stack
        direction="row"
        spacing={2}
        sx={{
          marginTop: '1rem',
        }}
      >
        <Box
          sx={{
            width: 1,
          }}
        >
          <RHFDatePicker
            name="partner.birthday"
            label={translate('global.formLabels.date_of_birth')}
            disableFuture
          />
        </Box>
        <RHFTextField
          name="partner.place_of_citizenship"
          label={translate('global.formLabels.place_of_citinzenship')}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: '100%' }}>
          <AutoAddressPicker
            fieldNames={fieldNames}
            streetError={isStreetError}
            name="partner.address.street"
          />
        </Stack>
        <RHFTextField
          name="partner.address.house_number"
          label={translate('global.formLabels.houseNumber')}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="partner.address.zip_code"
          label={translate('global.formLabels.postalCode')}
        />
        <RHFTextField
          name="partner.address.city"
          label={translate('global.formLabels.city')}
        />
      </Stack>
      <RHFSelect
        name="partner.address.country"
        label={translate('global.formLabels.country')}
        value={countries.find((country) => country.code === 'CH')?.code}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {translate(country.label)}
          </MenuItem>
        ))}
      </RHFSelect>
    </Box>
  );
}

export function PersonalInfo() {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldNames = {
    'self.address.street': 'route',
    'self.address.house_number': 'street_number',
    'self.address.zip_code': 'postal_code',
    'self.address.city': 'locality',
  };

  // @ts-ignore
  const isStreetError = Boolean(errors?.self?.address?.street);

  const { translate } = useLocales();

  return (
    <Box
      display="block"
      sx={{
        '& > *': {
          marginBottom: '1rem',
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="self.first_name"
          label={translate('global.formLabels.firstName')}
        />
        <RHFTextField
          name="self.last_name"
          label={translate('global.formLabels.lastName')}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            width: 1,
          }}
        >
          <RHFDatePicker
            name="self.birthday"
            label={translate('global.formLabels.date_of_birth')}
            disableFuture
          />
        </Box>
        <RHFTextField
          name="self.place_of_citizenship"
          label={translate('global.formLabels.place_of_citinzenship')}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: '100%' }}>
          <AutoAddressPicker
            fieldNames={fieldNames}
            streetError={isStreetError}
            name="self.address.street"
          />
        </Stack>
        <RHFTextField
          name="self.address.house_number"
          label={translate('global.formLabels.houseNumber')}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <RHFTextField
          name="self.address.zip_code"
          label={translate('global.formLabels.postalCode')}
        />
        <RHFTextField
          name="self.address.city"
          label={translate('global.formLabels.city')}
        />
      </Stack>
      <RHFSelect
        name="self.address.country"
        label={translate('global.formLabels.country')}
        value={countries.find((country) => country.code === 'CH')?.code}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            {translate(country.label)}
          </MenuItem>
        ))}
      </RHFSelect>
    </Box>
  );
}
