const privacyPolicy = {
  title: 'Informativa sulla privacy',

  childrenUnderAge: {
    title: 'Bambini sotto i 18 anni',
    content: {
      p1: `Il nostro sito web non è destinato a bambini sotto i 18 anni. Le persone sotto i 18 anni non devono fornire informazioni sul sito o attraverso di esso. Non raccogliamo consapevolmente informazioni personali da bambini sotto i 18 anni. Se hai meno di 18 anni, non utilizzare questo sito web e non fornire informazioni su questo sito o attraverso le sue funzionalità. Se scopriamo di aver raccolto o ricevuto informazioni personali da un bambino sotto i 18 anni senza la verifica del consenso dei genitori, elimineremo tali informazioni. Se ritieni che potremmo avere informazioni da o su un bambino sotto i 18 anni, contattaci all'indirizzo support@gutgeregelt.ch.`,
    },
  },
  informationWeCollect: {
    title: 'Informazioni che raccogliamo e come le raccogliamo',
    content: {
      p1: 'Raccogliamo diversi tipi di informazioni dagli utenti del nostro sito web, incluse informazioni:',
      p2: '• Che possono identificarti personalmente, come il tuo nome o indirizzo e-mail o un altro identificatore attraverso il quale puoi essere contattato online o offline ("dati personali");',
      p3: '• Che ti riguardano ma non ti identificano individualmente; e/o',
      p4: '• Sulla tua connessione Internet, i dispositivi che utilizzi per accedere al nostro sito web e i dettagli del tuo utilizzo.',
      p5: 'Raccogliamo queste informazioni:',
      p6: '• Direttamente da te quando ci fornisci tali informazioni.',
      p7: '• Automaticamente mentre navighi nel sito. Le informazioni raccolte automaticamente possono includere dettagli di utilizzo, indirizzi IP e informazioni raccolte tramite cookie.',
      p8: '• Da terze parti, ad esempio dai nostri partner commerciali.',
    },
  },
  informationYouProvide: {
    title: 'Informazioni fornite da te',
    content: {
      p1: 'Le informazioni che raccogliamo su o attraverso il nostro sito web possono includere:',
      p2: '• Informazioni che fornisci compilando moduli sul nostro sito web. Ciò include informazioni fornite al momento della registrazione per utilizzare il nostro sito web, sottoscrivere il nostro servizio, pubblicare materiale o richiedere ulteriori servizi. Potremmo anche chiederti informazioni quando partecipi a un concorso o a una promozione sponsorizzata da noi e quando segnali un problema con il nostro sito web.',
      p3: '• Registrazioni e copie della tua corrispondenza (inclusi indirizzi e-mail), se ci contatti.',
      p4: '• Le tue risposte a sondaggi che potremmo condurre a scopo di ricerca.',
      p5: '• Le tue ricerche sul sito web.',
      p6: `Puoi anche fornire informazioni da pubblicare o visualizzare in aree pubbliche del sito web (di seguito "pubblicate") e trasmesse ad altri utenti del sito web o a terze parti (collettivamente "contributi degli utenti"). I tuoi contributi degli utenti sono pubblicati sul sito web e trasmessi ad altri a tuo rischio. Sebbene possiamo limitare l'accesso a determinate pagine, dovresti essere consapevole che nessuna misura di sicurezza è perfetta o impenetrabile. Inoltre, non possiamo controllare le azioni di altri utenti del sito web con cui potresti condividere i tuoi contributi degli utenti. Pertanto, non possiamo garantire che i tuoi contributi degli utenti non saranno visualizzati da persone non autorizzate.`,
    },
  },
  informationWeCollectThroughAutomaticData: {
    title:
      'Informazioni che raccogliamo tramite tecnologie di raccolta dati automatiche',
    content: {
      p1: 'Mentre navighi e interagisci con il nostro sito web, possiamo utilizzare tecnologie di raccolta dati automatiche per raccogliere determinate informazioni sui tuoi dispositivi, attività di navigazione e modelli, inclusi:',
      p2: '• Dettagli delle tue visite al nostro sito web, inclusi dati sul traffico, dati sulla posizione, registri e altri dati di comunicazione e le risorse a cui accedi e che utilizzi sul sito web.',
      p3: '• Informazioni sul tuo computer e sulla tua connessione Internet, inclusi il tuo indirizzo IP, il sistema operativo e il tipo di browser.',
      p4: 'Le informazioni che raccogliamo automaticamente possono includere dati personali. Raccogliamo queste informazioni per migliorare il nostro sito web e offrire un servizio migliore e più personalizzato, ad esempio:',
      p5: '• Stimare la dimensione del nostro pubblico e i modelli di utilizzo.',
      p6: '• Memorizzare informazioni sulle tue preferenze, permettendoci di personalizzare il nostro sito web in base ai tuoi interessi individuali.',
      p7: '• Accelerare le tue ricerche.',
      p8: '• Riconoscerti quando torni sul nostro sito web.',
      p9: 'Le tecnologie che utilizziamo per questa raccolta automatica di dati possono includere:',
      p10: "• Cookie (o cookie del browser). Un cookie è un piccolo file posizionato sul disco rigido del tuo computer. Puoi rifiutare i cookie del browser attivando l'impostazione appropriata sul tuo browser. Tuttavia, se selezioni questa impostazione, potresti non essere in grado di accedere a determinate parti del nostro sito web. A meno che tu non abbia regolato le impostazioni del tuo browser in modo che rifiuti i cookie, il nostro sistema emetterà cookie quando indirizzi il tuo browser al nostro sito web.",
      p11: '• Flash Cookies. Alcune funzionalità del nostro sito web possono utilizzare oggetti di archiviazione locale (o Flash Cookies) per raccogliere e memorizzare informazioni sulle tue preferenze e navigazione da, su e sul nostro sito web. I Flash Cookies non sono gestiti dalle stesse impostazioni del browser utilizzate per i cookie del browser.',
    },
  },

  informationVoluntarily: {
    title:
      "Informazioni fornite volontariamente da te per l'utilizzo dei servizi",
    content: {
      p1: "Raccogliamo informazioni che ci fornisci volontariamente per soddisfare una richiesta, ad esempio quando ti registri per utilizzare i nostri servizi e il sito web, richiedi supporto dal nostro team di supporto, ci contatti via e-mail, compili un modulo di contatto, desideri utilizzare i nostri servizi o comunichi con noi in altro modo. Queste informazioni includono il tuo nome e cognome, il nome della tua azienda, l'indirizzo fisico, l'indirizzo e-mail e determinati tipi di informazioni aziendali della tua azienda, come informazioni sui pagamenti dei clienti, entrate, informazioni sull'analisi del sito web, abbandono dei clienti, valore del cliente durante la vita e altre metriche e dati, nonché tutte le altre informazioni che ci fornisci riguardo alla tua azienda.",
      p2: "In particolare, dopo la tua registrazione per utilizzare i nostri servizi come start-up, se desideri visualizzare i dati dai tuoi account con strumenti e applicazioni di terze parti come Stripe, Google Analytics, ChartMogul, Baremetrics e ProfitWell sulla tua pagina informativa per potenziali acquirenti, accedendo alla pagina di autorizzazione nel tuo account con tali strumenti e applicazioni di terze parti o fornendoci le tue chiavi API o ID di visualizzazione su tali strumenti e applicazioni di terze parti, ci autorizzi e ci concedi l'accesso in sola lettura a informazioni specifiche nei tuoi account con tali terze parti. Di seguito sono elencate le informazioni che possiamo raccogliere con la tua autorizzazione da tali terze parti:",
      p3: '• Stripe – Accesso in sola lettura ai dati sui ricavi dei clienti per gli ultimi 12 mesi',
      p4: '• Google Analytics – Accesso in sola lettura ai dati sul traffico del sito web per gli ultimi 12 mesi',
    },
  },
  useOfCookies: {
    title: 'Uso dei cookie da parte di terzi',
    content: {
      p1: 'Alcuni contenuti o applicazioni sul sito web sono forniti da terze parti, come fornitori di contenuti e fornitori di applicazioni come Google Analytics e Hubspot, come sopra menzionato. Queste terze parti utilizzano cookie per raccogliere informazioni su di te quando utilizzi il nostro sito web. Le informazioni che raccolgono possono essere associate alle tue informazioni personali o possono raccogliere informazioni, inclusi dati personali, sulle tue attività online nel tempo e su diversi siti web e altri servizi online.',
    },
  },
  howWeUseYourInformation: {
    title: 'Come utilizziamo le tue informazioni',
    content: {
      p1: 'Utilizziamo le informazioni che raccogliamo su di te o che ci fornisci, incluse tutte le informazioni personali:',
      p2: '• Per presentarti il nostro sito web e i suoi contenuti.',
      p3: "• Per facilitare l'elenco del tuo profilo di acquirente, venditore o consulente sul sito web.",
      p4: '• Per fornirti informazioni, prodotti o servizi che ci richiedi, inclusi ma non limitati a (per gli utenti start-up) la visualizzazione di informazioni aziendali autorizzate di start-up per determinati potenziali acquirenti.',
      p5: '• Per soddisfare qualsiasi altro scopo per cui le fornisci.',
      p6: '• Per inviarti avvisi sul tuo account, inclusi avvisi di scadenza e rinnovo.',
      p7: '• Per eseguire i nostri obblighi derivanti da contratti stipulati tra te e noi e per far valere i nostri diritti, inclusi la fatturazione e la riscossione.',
      p8: '• Per informarti su modifiche al nostro sito web o ai prodotti o servizi che offriamo.',
      p9: '• Per consentirti di partecipare a funzionalità interattive sul nostro sito web.',
      p10: '• In qualsiasi altro modo che possiamo descrivere quando fornisci le informazioni.',
      p11: '• Per qualsiasi altro scopo con il tuo consenso.',
      p12: 'Con il tuo consenso, utilizzeremo le tue informazioni per informarti sui nostri beni e servizi, nonché su beni e servizi di terzi che potrebbero interessarti. Se non desideri che utilizziamo le tue informazioni in questo modo, modifica le tue impostazioni utente nel tuo profilo account. Per ulteriori informazioni, consulta la sezione "I tuoi diritti e controlli sulle informazioni" di seguito.',
    },
  },
  saleOfPersonalInformation: {
    title: 'Vendita di informazioni personali',
    content: {
      p1: 'Si prega di notare che non vendiamo le tue informazioni personali a terzi.',
    },
  },

  disclosureOfYourInformation: {
    title: 'Divulgazione delle tue informazioni',
    content: {
      p1: 'Possiamo divulgare informazioni aggregate sui nostri utenti e informazioni che non identificano alcun individuo, senza restrizioni.',
      p2: 'Possiamo divulgare informazioni personali che raccogliamo o che fornisci come descritto in questa informativa sulla privacy:',
      p3: '• Alle nostre sussidiarie e affiliate per consentirci di fornire i nostri servizi.',
      p4: '• A contraenti, fornitori di servizi e altre terze parti che utilizziamo per supportare la nostra attività e che sono contrattualmente obbligati a mantenere riservate le informazioni personali e a utilizzarle solo per gli scopi per cui le divulghiamo.',
      p5: "• A un acquirente o altro successore in caso di fusione, dismissione, ristrutturazione, riorganizzazione, scioglimento o altra vendita o trasferimento di parte o di tutto il patrimonio aziendale, sia come attività in corso che come parte di un fallimento, liquidazione o procedura simile, in cui le informazioni personali detenute dall'azienda sui nostri utenti del sito web sono tra i beni trasferiti.",
      p6: '• Per soddisfare lo scopo per cui le fornisci.',
      p7: '• Per qualsiasi altro scopo che indichiamo quando fornisci le informazioni.',
      p8: '• Con il tuo consenso.',
      p9: 'Possiamo anche divulgare le tue informazioni personali:',
      p10: '• Per conformarsi a un ordine del tribunale, una legge o un procedimento legale, inclusa la risposta a richieste di enti governativi o regolatori.',
      p11: '• Per far rispettare o applicare i nostri termini di utilizzo e altri accordi, inclusi scopi di fatturazione e riscossione.',
      p12: "• Se riteniamo che la divulgazione sia necessaria o appropriata per proteggere i diritti, la proprietà o la sicurezza dell'azienda, dei nostri clienti o di altri. Ciò include lo scambio di informazioni con altre aziende e organizzazioni per la protezione contro le frodi e la riduzione del rischio di credito.",
    },
  },
  informationRights: {
    title: 'I tuoi diritti e controlli sulle informazioni',
    content: {
      p1: 'Il Regolamento generale sulla protezione dei dati ("GDPR") conferisce ai singoli diritti sulle loro informazioni personali. Sebbene i seguenti diritti siano specifici per i membri dello Spazio economico europeo (SEE), ci sforziamo di garantire gli stessi diritti a tutti i nostri utenti come ai membri del SEE ai sensi del GDPR, ove possibile.',
      p2: 'Si prega di familiarizzare con i tuoi diritti e le loro descrizioni nella tabella seguente.',
      p3: 'È tuo diritto:',
      p4: "Accesso: Informarti sui dati personali che trattiamo su di te e richiedere l'accesso a tali dati.",
      p5: 'Rettifica: Chiederci di aggiornare o correggere dati inesatti o incompleti su di te.',
      p6: 'Cancellazione: Chiederci di cancellare i tuoi dati.',
      p7: 'Limitazione del trattamento: Chiederci di limitare il trattamento dei tuoi dati personali.',
      p8: 'Portabilità dei dati: Chiederci di fornire a te o a una terza parte una copia dei tuoi dati personali in un formato strutturato, di uso comune e leggibile da una macchina.',
      p9: 'Opposizione: Opporsi al trattamento dei tuoi dati personali.',
      p10: 'Opposizione al marketing: Opporsi al trattamento dei tuoi dati personali per scopi di marketing.',
      p11: 'Nessuna decisione automatizzata: Non essere soggetto a una decisione basata esclusivamente su un trattamento automatizzato (decisioni senza coinvolgimento umano), inclusa la profilazione, se la decisione ha effetti legali su di te o un impatto significativo simile su di te.',
    },
  },
  exerciseRights: {
    title: 'Come esercitare i tuoi diritti con noi',
    content: {
      p1: "Accesso: Per presentare una richiesta verificabile di informazioni sui dati personali che abbiamo raccolto su di te, invia un'e-mail a support@gutgeregelt.ch. Si prega di notare che quasi tutte le informazioni che raccogliamo da te sono contenute nel tuo profilo account sulla piattaforma.",
      p2: 'Rettifica: Puoi modificare le tue informazioni personali nel tuo profilo account sulla nostra piattaforma.',
      p3: "Cancellazione: Puoi esercitare questo diritto inviando un'e-mail a support@gutgeregelt.ch. Si prega di notare che cancelleremo i tuoi dati personali:",
      p4: "• Possiamo conservare i tuoi dati personali per il tempo necessario ai nostri legittimi interessi commerciali, come la prevenzione del riciclaggio di denaro, il rilevamento e la prevenzione delle frodi e l'aumento della sicurezza. Ad esempio, se sospendiamo un account gut geregelt per motivi di frode o sicurezza, possiamo conservare le informazioni di tale account gut geregelt per impedire alla persona di aprire un nuovo account gut geregelt in futuro.",
      p5: '• Possiamo conservare e utilizzare i tuoi dati personali nella misura necessaria per adempiere ai nostri obblighi legali. Gut geregelt può conservare le tue informazioni, ad esempio, per obblighi fiscali, legali di rendicontazione e audit o come registrazione di una controversia con te.',
      p6: '• Alcune copie delle tue informazioni (ad esempio, e-mail) possono rimanere nel nostro database, ma saranno separate dagli identificatori personali e conservate solo a scopo di documentazione.',
      p7: "Limitazione: Puoi esercitare questo diritto inviando un'e-mail a support@gutgeregelt.ch o interrompendo l'utilizzo della piattaforma.",
      p8: "Opposizione: Puoi esercitare questo diritto inviando un'e-mail a support@gutgeregelt.ch.",
      p9: "Portabilità dei dati: Puoi esercitare questo diritto inviando un'e-mail a support@gutgeregelt.ch.",
      p10: "Decisioni automatizzate: Puoi esercitare questo diritto inviando un'e-mail a support@gutgeregelt.ch. Si prega di notare, tuttavia, che non effettuiamo decisioni automatizzate. Tutte le decisioni relative al tuo profilo sono prese internamente dal nostro personale di supporto.",
      p11: 'Hai anche il diritto di contattare la tua autorità locale per la protezione dei dati con domande o preoccupazioni.',
    },
  },
  dataStorage: {
    title: 'Conservazione e cancellazione dei dati',
    content: {
      p1: 'In conformità con il GDPR e altre normative pertinenti, conserviamo i tuoi dati personali solo per il tempo necessario a fornire i servizi disponibili sulla piattaforma gut geregelt e per i nostri legittimi e essenziali scopi aziendali.',
    },
  },
  deletion: {
    title: 'Cancellazione',
    content: {
      p1: 'Se chiudi il tuo account o ci chiedi di chiudere il tuo account, cancelleremo, anonimizzeremo o modificheremo i tuoi dati personali in modo che non ti identifichino più. A meno che non siamo legalmente obbligati a conservare qualcosa o dobbiamo continuare a utilizzarlo per motivi giustificati legalmente.',
      p2: 'Ecco alcuni esempi di situazioni in cui siamo legalmente autorizzati o obbligati a conservare alcuni dei tuoi dati personali:',
      p3: "• Se c'è un problema irrisolto relativo al tuo account, come un pagamento in sospeso o un reclamo o una controversia irrisolta.",
      p4: '• Per i nostri obblighi legali, fiscali, di audit e contabili.',
      p5: '• Per i nostri legittimi interessi commerciali, come la prevenzione delle frodi o il mantenimento della sicurezza.',
      p6: 'Per ulteriori informazioni, consulta la sezione sulla cancellazione sopra.',
      p7: 'In alcuni casi, possiamo anonimizzare o modificare i tuoi dati personali in modo che non siano più identificabili per prevenire frodi o determinare se sei un utente precedente quando ti registri nuovamente sulla piattaforma gut geregelt per prevenire frodi.',
      p8: 'Abbiamo adottato misure per proteggere i tuoi dati personali da perdita accidentale e accesso, uso, modifica e divulgazione non autorizzati.',
      p9: 'La sicurezza delle tue informazioni dipende anche da te. Se ti abbiamo fornito (o se hai scelto) una password per accedere a determinate parti del nostro sito web, sei responsabile di mantenere riservata questa password. Ti chiediamo di non condividere la tua password con nessuno. Si prega di prestare attenzione quando si condividono informazioni in aree pubbliche del sito web, come i forum. Le informazioni che condividi in aree pubbliche possono essere visualizzate da qualsiasi utente del sito web.',
      p10: "Purtroppo, la trasmissione di informazioni via Internet non è completamente sicura. Sebbene facciamo del nostro meglio per proteggere i tuoi dati personali, non possiamo garantire la sicurezza dei tuoi dati personali trasmessi al nostro sito web. Qualsiasi trasmissione di dati personali è a tuo rischio. Non siamo responsabili per l'elusione delle impostazioni sulla privacy o delle misure di sicurezza sul sito web.",
    },
  },
  changes: {
    title: 'Modifiche alla nostra informativa sulla privacy',
    content: {
      p1: "È nostra politica pubblicare tutte le modifiche che apportiamo alla nostra informativa sulla privacy su questa pagina. Se apportiamo modifiche sostanziali al modo in cui trattiamo i dati personali dei nostri utenti, ti informeremo tramite una notifica sulla home page del sito web. La data dell'ultima revisione dell'informativa sulla privacy è indicata in alto nella pagina. Sei responsabile di garantire che abbiamo un indirizzo e-mail attuale, attivo e consegnabile per te e di visitare regolarmente il nostro sito web e questa informativa sulla privacy per verificare eventuali modifiche.",
    },
  },
  contantInformation: {
    title: 'Informazioni di contatto',
    content: {
      p1: "Se hai domande o commenti su questa informativa sulla privacy e sulle nostre pratiche sulla privacy, contattaci e il nostro responsabile della protezione dei dati all'indirizzo support@gutgeregelt.ch.",
      p2: "Per registrare un reclamo o una preoccupazione, invia un'e-mail a support@gutgeregelt.ch.",
    },
  },
};

export default privacyPolicy;
