import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import {
  useGetPartnerSettings,
  useGetPublicPartnerSettings,
} from '#/lib/api/partnerQueries';
import useAuth from '#/lib/hooks/useAuth';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import { getSubdomain } from '#/utils/helpers';

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  description?: string;
  keywords?: string;
}

const Page = forwardRef<HTMLDivElement, Props>(
  (
    { children, title = '', meta, keywords = '', description = '', ...other },
    ref
  ) => {
    const { user } = useAuth();
    const [ggPartnerKey] = useLocalStorage('gg_partner_key', null);

    const isAdminLoggedIn =
      user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

    const { data: byAdmin } = useGetPartnerSettings(isAdminLoggedIn);
    const { data: partnerTheme } = useGetPublicPartnerSettings(
      ggPartnerKey,
      !!ggPartnerKey
    );

    const partnerFavIcon = byAdmin?.favicon || partnerTheme?.favicon;

    return (
      <>
        <Helmet prioritizeSeoTags>
          <title>{`${title} | gut geregelt`}</title>
          <link
            rel="shortcut icon"
            href={partnerFavIcon || '/favicon/favicon.ico'}
          />
          <link
            rel="apple-touch-icon"
            type="image/x-icon"
            href={partnerFavIcon || '/favicon/favicon.ico'}
          />

          <meta name="description" content={`${description}`} />
          <meta property="og:title" content={`${title} | gut geregelt`} />
          <meta name="keywords" content={`${keywords}`} />
          <meta property="og:description" content={`${description}`} />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="de_CH" />
          <meta property="og:site_name" content="Werden Sie gut geregelt" />
          <meta
            property="og:image"
            content="https://storage.gutgeregelt.ch/img/feature-img-gg.png"
          />
          <meta property="og:url" content="https://gutgeregelt.ch/" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@gutgeregelt" />
          <meta name="twitter:description" content={`${description}`} />
          <meta
            name="twitter:title"
            content={`${title} | Werden Sie gut geregelt`}
          />
          <meta
            name="twitter:image"
            content="https://storage.gutgeregelt.ch/img/feature-img-gg.png"
          />
        </Helmet>

        <Box ref={ref} {...other} className="myBox">
          {children}
        </Box>
      </>
    );
  }
);

export default Page;
