const marriageContractLanding = {
  mainBanner: {
    intro: 'Ehevertrag online erstellen:',
    title: 'Rechtssicher, in 10 Minuten erledigt',
    subtitle:
      'Der Ehevertrag hilft Ihnen als Ehegatten, Ihr Vermögen zu schützen und finanzielle Streitigkeiten während oder nach der Ehe zu vermeiden.',
    buttonLabel: 'Ehevertrag erstellen',
  },
  youtubeTutorial: 'Warum ein Ehevertrag wichtig ist?',
  aboutCards: {
    protectSpouse: {
      title: 'Ehegatten schützen',
      description:
        'Der Ehevertrag schützt Sie, so dass Sie im Todesfall nicht die Immobilie wegen dem Erbfall der Kinder verkaufen müssen.',
    },
    winClarity: {
      title: 'Klarheit gewinnen',
      description:
        'Definieren Sie im Vorhinein ganz klar, wie im Todesfall das Vermögen aufgeteilt und Finanzen gehandhabt werden.',
    },
    saveDiscussions: {
      title: 'Streitigkeiten vermeiden',
      description:
        'Schaffen Sie Klarheit durch die Formalisierung der finanziellen Handhabung im erbrechtlichen Fall, um Streitigkeiten zu vermeiden.',
    },
  },
  subBanner: {
    title: 'Gut geregelt mit einem rechtlich konformen Ehevertrag',
    subtitle:
      'Mit gut geregelt erstellen Sie schnell Ihren Ehevertrag, erhalten bei Bedarf Rechtsberatung zu heiklen Themen oder offenen Fragen und schützen sich und Ihren Ehepartner. Jetzt erstellen und dann gleich noch notariell geblauigen lassen.',
    buttonLabel: 'Jetzt kostenlos erstellen',
  },
  testimonials: {
    testimonial1: {
      name: 'Daniel Schreiber',
      text: 'Meine Frau und ich haben zwei Kinder und vor einem Jahr eine Immobilie gekauft. Mit dem Ehevertrag sorgen wir dafür, dass meine Frau im Falle meines Todes das Haus nicht verkaufen muss, um unsere Kinder auszuzahlen. Das war ganz einfach.',
    },
    testimonial2: {
      name: 'Greta Beck',
      text: 'Mein Mann und ich haben 2-3 Mal versucht, uns einen Ruck zu geben und den Ehevertrag anzugehen. gut geregelt hat uns den Weg zum Anwalt zwar nicht ganz erspart, doch den Einstieg in das Thema wesentlich einfacher gemacht.',
    },
    testimonial3: {
      name: 'Sarah Barmettler',
      text: 'Einfacher und verständlicher Prozess. Wir haben uns im Vorfeld auf dem Internet schlau gemacht und wussten, was wir wollten. Das Angebot von gut geregelt hat wunderbar gepasst. Für die Beglaubigung gingen wir dann zum Notar im Dorf.',
    },
  },
  newsletter: {
    title: 'Werden Sie gut geregelt mit Ihrem Ehevertrag',
    buttonLabel: 'Jetzt erstellen',
  },
  faq: {
    questions: {
      question1: 'Was ist ein Ehevertrag?',
      question2: 'Warum sollte man einen Ehevertrag abschliessen?',
      question3: 'Wann sollte ein Ehevertrag abgeschlossen werden?',
      question4: 'Wie wird ein Ehevertrag in der Schweiz abgeschlossen?',
      question5: 'Kann ein Ehevertrag nachträglich geändert werden?',
      question6: 'Was passiert, wenn kein Ehevertrag abgeschlossen wird?',
    },
    answers: {
      answer1:
        'Ein Ehevertrag ist ein rechtlich bindendes Dokument, das von Ehepartnern vor oder während der Ehe abgeschlossen wird. Er regelt die vermögensrechtlichen Verhältnisse der Ehepartner und kann individuelle Abmachungen über die Vermögensaufteilung, Schuldenhaftung und den Unterhalt im Falle einer Scheidung oder eines Todesfalls enthalten.',
      answer2:
        'Ein Ehevertrag bietet die Möglichkeit, die finanziellen Angelegenheiten der Ehepartner klar und individuell zu regeln. Dies kann helfen, Missverständnisse und Streitigkeiten zu vermeiden und im Falle einer Scheidung oder eines Todesfalls klare Regelungen zu haben. Besonders bei unterschiedlichem Vermögen oder Einkommen der Ehepartner, bei Unternehmensbeteiligungen oder bei Patchwork-Familien kann ein Ehevertrag sinnvoll sein.',
      answer3:
        'Ein Ehevertrag kann jederzeit vor oder während der Ehe abgeschlossen werden. Es ist jedoch ratsam, den Vertrag vor der Eheschliessung abzuschliessen, um von Anfang an klare Verhältnisse zu schaffen.',
      answer4:
        'Ein Ehevertrag muss in der Schweiz zwingend in öffentlich beurkundeter Form abgeschlossen werden. Das bedeutet, er muss vor einem Notar geprüft und beurkundet werden. Beide Ehepartner müssen persönlich anwesend sein und den Vertrag unterschreiben.',
      answer5:
        'Ja, ein Ehevertrag kann jederzeit einvernehmlich von beiden Ehepartnern geändert oder aufgehoben werden. Auch hierfür ist eine öffentliche Beurkundung durch einen Notar erforderlich.',
      answer6:
        'Ohne Ehevertrag gilt das ordentliche Güterrecht der Schweiz, das die Errungenschaftsbeteiligung vorsieht. Dies bedeutet, dass das während der Ehe erworbene Vermögen im Falle einer Scheidung hälftig zwischen den Ehepartnern aufgeteilt wird, während das voreheliche Vermögen und Erbschaften als Eigengut gelten und bei der Aufteilung unberücksichtigt bleiben.',
    },
  },

  situations: {
    heirat: {
      content: {
        main: {
          title: 'Ehevertrag',
          description:
            'Ein Ehevertrag regelt, wie Ihr Vermögen während der Ehe und im Falle einer Trennung oder Scheidung aufgeteilt wird. So schaffen Sie klare Verhältnisse und vermeiden Unsicherheiten.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Der beste Zeitpunkt für einen Ehevertrag ist direkt nach der Heirat. So stellen Sie sicher, dass beide Partner fair behandelt werden, falls sich Ihre Lebensumstände ändern.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klare Vermögensaufteilung',
              'Schutz beider Partner',
              'Vorbeugung gegen Konflikte',
            ],
          },
        ],
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Ehevertrag',
          description:
            'Ein Ehevertrag regelt die Vermögensaufteilung, insbesondere wenn Sie gemeinsam eine Immobilie erwerben. So schützen Sie beide Partner und sorgen dafür, dass alles klar geregelt ist.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Beim Kauf einer Immobilie stellt sich die Frage der Vermögensaufteilung. Ein Ehevertrag schafft Klarheit und stellt sicher, dass Sie und Ihr Partner im Falle einer Trennung oder eines unerwarteten Ereignisses abgesichert sind.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klare Vermögensregelungen für gemeinsame Immobilien',
              'Schutz beider Partner bei Trennung oder Tod',
              'Vermeidung von späteren Streitigkeiten',
            ],
          },
        ],
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Ehevertrag',
          description:
            'Mit einem Ehevertrag schaffen Sie klare Regelungen in Bezug auf das Vermögen und die Absicherung Ihres Kindes. So sorgen Sie vor, falls sich Ihre Lebensumstände ändern.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Jetzt, da ein Kind Teil Ihrer Familie ist, bietet ein Ehevertrag zusätzlichen Schutz für das Vermögen Ihres Kindes. Es schafft klare Verhältnisse für die Vermögensaufteilung und die Versorgung Ihres Kindes.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Schutz des Kindesvermögens',
              'Klare Regelungen im Falle einer Trennung',
              'Vermeidung von Streitigkeiten',
            ],
          },
        ],
      },
    },
  },
};

export default marriageContractLanding;
