import powerOfTrustQuestions from './questions';
import outputDoc from './outputDoc';

const powerOfAttorney = {
  mainHeaders: {
    shortPainFree: "Mandat pour cause d'inaptitude : simple et rapide",
    madeIt: "Votre mandat pour cause d'inaptitude efficace",
  },

  stepperInfo: [
    {
      label: 'Mandat de confiance',
      title: "De quoi s'agit-il ?",
    },
    {
      label: "Votre mandat pour cause d'inaptitude",
      title: "Déposez votre mandat pour cause d'inaptitude",
    },
  ],

  stepOne: {
    introParagraph:
      'Malheureusement, il existe des situations qui peuvent conduire à notre incapacité de discernement. Des exemples incluent des accidents graves ou des maladies comme la démence.',
    helperParagraph:
      "Nous vous recommandons donc de régler à l'avance, par une directive anticipée, qui doit s'occuper de vous et vous représenter légalement dans une telle situation. Cela soulage vos proches dans une situation déjà difficile, car vos souhaits sont clairement formulés.",
    helperParagraph2:
      "Commençons : Cette section ne prend qu'environ 5 minutes. Si vous avez besoin d'une pause, vous pouvez vous arrêter et sauvegarder à tout moment.",
    questions: powerOfTrustQuestions,
  },

  stepTwo: {
    introParagraph: 'Félicitations ! Vous avez réussi.\n',
    helperParagraph:
      "L'étape la plus difficile est maintenant terminée. Téléchargez le document, imprimez-le et lisez-le attentivement pour voir s'il correspond vraiment à vos souhaits. Si vous êtes satisfait, nous vous recommandons de discuter du mandat pour cause d'inaptitude avec une personne de confiance et votre famille.",
    helperParagraph2:
      "Il est conseillé de déposer le mandat pour cause d'inaptitude chez un avocat ou un notaire, qui peut enregistrer le lieu de dépôt à votre demande auprès du Registre suisse des testaments (https://www.ztr.ch).",
    contactForm: 'Formulaire de contact',
    helperParagraph3:
      'Si vous souhaitez que nous vous aidions, veuillez utiliser notre formulaire de contact.',
    obligation1:
      "Le mandat pour cause d'inaptitude doit être rédigé de manière manuscrite ou authentifié publiquement. Le mandat manuscrit doit être écrit, daté et signé de la main de la personne mandante du début à la fin.",
    obligation2:
      'Si vous avez des doutes, un avocat ou un notaire peut vous aider.',
    document: outputDoc,
    nextStepsTitle: 'Les prochaines étapes :',
    nextStep1:
      'Soit recopier entièrement le mandat à la main, le dater et le signer, soit le faire authentifier par un avocat et le télécharger ici',
    nextStep2:
      "Assurez-vous que les personnes de confiance ont un accès invité à gut geregelt, afin qu'elles puissent accéder au mandat en cas d'urgence (cliquez ici)",
    highlightText: 'cliquez ici',
    nextStep3: "Discutez du mandat pour cause d'inaptitude avec la famille",
    highlightedText: {
      noterizeWithLawyer: 'Faire authentifier par un avocat',
    },
  },
};

export default powerOfAttorney;
