import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  invalidatePartnerQueries,
  useInviteTeamMember,
} from '#/api/partnerQueries';

const userRoles = [
  {
    value: 'CONSULTANT',
    label: 'global.team.consultant',
    description: 'global.team.consultantDescription',
  },
  {
    value: 'ADMIN',
    label: 'global.team.admin',
    description: 'global.team.adminDescription',
  },
];

interface IFormInput {
  email: string;
  role: string;
}

export default function InviteMember() {
  const { translate } = useLocales();

  const InviteMemberSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('validations.user.invalidEmail'))
      .required(translate('validations.user.email')),
    role: Yup.string().required(translate('validations.role')),
  });

  const { mutateAsync: inviteTeamMember, isLoading } = useInviteTeamMember();

  const defaultValues = {
    email: '',
    role: '',
  };

  const methods = useForm<IFormInput>({
    defaultValues,
    resolver: yupResolver(InviteMemberSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: IFormInput) => {
    try {
      await inviteTeamMember(data).then(() => {
        reset(defaultValues);
        toast.success(translate('toast_notifications.success.teamInvite'));
        invalidatePartnerQueries.teamList();
      });
    } catch (error) {
      toast.error(translate('toast_notifications.error.teamInvite'));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{
            p: 2.5,
          }}
        >
          <RHFTextField
            name="email"
            label={translate('global.formLabels.emailAddress')}
            size="small"
            sx={{
              width: '310px',
            }}
          />
          <RHFSelect
            label={translate('global.formLabels.role')}
            name="role"
            size="small"
            renderValue={(selectedValue) => {
              const selectedLabel = userRoles.find(
                (role) => role.value === selectedValue
              )?.label;
              return <Typography>{translate(selectedLabel || '')}</Typography>;
            }}
          >
            {userRoles.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                <Stack
                  sx={{
                    textTransform: 'none',
                  }}
                >
                  <Typography>{translate(role.label)}</Typography>
                  <Typography variant="caption">
                    {translate(role.description)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect>
          <Stack>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              <Typography>{translate('global.formLabels.invite')}</Typography>
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}
