const preventiveCheckUp = {
  mainBanner: {
    intro: 'Check-up previdenziale per fondi pensione',
    title: 'Creare trasparenza, minimizzare il rischio di responsabilità',
    subtitle:
      'I fondi pensione hanno il mandato di fornire una previdenza ottimale per i loro clienti. Il check-up previdenziale crea trasparenza e minimizza il rischio di responsabilità per i fondi pensione, le fondazioni e il consiglio di fondazione. Assicura un futuro sicuro per i tuoi beneficiari previdenziali.',
    buttonLabel: 'Fissa un appuntamento',
  },
  allInfos: 'Tutte le informazioni in 15min di videochiamata',
  theChallenges: {
    title: 'Le sfide della previdenza moderna',
    description:
      'Le sfide nella previdenza sono diverse e complesse. I fondi pensione e le fondazioni devono informare e guidare i loro clienti per garantire la migliore previdenza possibile in tempi incerti.',
    cards: {
      increasedLifeExpectancy: {
        title: 'Aspettativa di vita aumentata',
        description:
          "Nuove opzioni diagnostiche e terapeutiche potrebbero aumentare ulteriormente l'aspettativa di vita. I tuoi beneficiari previdenziali sono preparati a questo?",
      },
      inflation: {
        title: 'Inflazione',
        description:
          "Gli sviluppi dell'inflazione a lungo termine possono influenzare fortemente la previdenza pensionistica. Come assicuri il potere d'acquisto dei tuoi clienti?",
      },
      ahv: {
        title: 'AVS',
        description:
          "Quali cambiamenti sono previsti nell'AVS e come influenzeranno i tuoi beneficiari previdenziali?",
      },
    },
  },
  benefits: {
    title: 'I vantaggi del check-up previdenziale di gut geregelt',
    description:
      'Il check-up previdenziale di gut geregelt offre una soluzione completa e trasparente per preparare in modo ottimale i beneficiari previdenziali alle sfide e minimizzare il rischio di responsabilità.',
    cards: {
      transparency: {
        title: 'Trasparenza e formazione',
        description:
          "Informa in dettaglio i tuoi clienti dai 40 anni in su sugli effetti dell'aumento dell'aspettativa di vita e altri rischi.",
      },
      liability: {
        title: 'Mitigare il rischio di responsabilità',
        description:
          'Attraverso una formazione completa e una consulenza documentata, puoi ridurre significativamente il rischio di richieste di responsabilità e aumentare la sicurezza per la tua istituzione e il consiglio di fondazione.',
      },
      documentation: {
        title: 'Documentazione',
        description:
          'Una documentazione tracciabile di tutte le fasi di informazione e consulenza ti protegge dai rischi di responsabilità a lungo termine.',
      },
    },
  },
  liabilityRisk: 'Rischio di responsabilità del consiglio di fondazione',
  liabilityRiskSubtitle:
    'La comunicazione attiva e la creazione di trasparenza sono importanti per prevenire danni ai beneficiari previdenziali e minimizzare il rischio di responsabilità per i fondi pensione e il consiglio di fondazione.',
  contactForm: {
    title: 'Scopri come il check-up previdenziale supporta i fondi pensione',
    description:
      'Vuoi saperne di più sul check-up previdenziale e come può aiutarti a supportare in modo ottimale i tuoi clienti?',
    cta: 'Fissa ora un appuntamento senza impegno per una presentazione tramite info@gutgeregelt.ch o il modulo.',
  },
};

export default preventiveCheckUp;
