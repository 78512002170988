import { Box, Link, Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  title: string | undefined;
  menuItems: Array<{ title: string; url: string; auth?: boolean }> | undefined;
};

export default function MegaMenuItem({ title, menuItems }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { translate } = useLocales();

  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <Stack
        component="nav"
        direction="row"
        alignItems="center"
        sx={{
          color: 'text.primary',
          opacity: 0.5,
        }}
      >
        <Typography noWrap>{translate(title as string)}</Typography>
        {open ? (
          <Iconify icon="eva:arrow-ios-downward-fill" />
        ) : (
          <Iconify icon="eva:arrow-ios-upward-fill" />
        )}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <Stack spacing={0.75}>
          {menuItems?.map((option) => (
            <Link
              component={RouterLink}
              to={option.url}
              underline="none"
              onClick={handleClose}
              key={option.title}
              sx={{
                color: 'text.primary',
                textDecoration: 'none !important',
              }}
            >
              <MenuItem>
                <Typography>{String(translate(option.title))}</Typography>
              </MenuItem>
            </Link>
          ))}
        </Stack>
      </Menu>
    </Box>
  );
}
