const metadata = {
  main: {
    title: 'Legal security with gut geregelt - your Swiss provision portal',
    description:
      'Finally complete all important provision documents online! Legally secure ✓ Done in 10 min ✓ Convenient from home ✓ Swiss provision platform ✓',
    keywords: {
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
      lastWill: 'Last will',
      personalPreferences: 'Personal provisions',
      retirementPlanning: 'Pension planning',
      retirementProvision: 'Retirement provision',
    },
  },
  living_will: {
    title: 'Create Swiss living will online in 5 min',
    description:
      'Generate a Swiss Last Will online in 5 mins with gut geregelt. Legally secure ✓ Convenient from home ✓ Swiss provider ✓',
    keywords: {
      livingWill: 'Living will',
      printLivingWill: 'Print living will',
      directive: 'Living will directive',
      printDirective: 'Printable living wills',
    },
  },
  power_of_attorney: {
    title: 'Create a Swiss power of attorney online in 5 min',
    description:
      'Generate a Swiss power of attorney in just 5 mins. Protection for the family ✓ Legally secure ✓ Convenient from home ✓ Swiss provider ✓',
    keywords: {
      powerOfAttorney: 'Power of attorney',
      powerOfAttorneyTemplate: 'Power of attorney template',
      powerOfAttorneyTemplate2: 'Template power of attorney',
      powerOfAttorneyTemplate3: 'Template for power of attorney',
      powerOfAttorney2: 'Power of attorney',
    },
  },
  last_will: {
    title: 'Create a Swiss last will online in 10 min',
    description:
      'Generate a legally compliant Swiss last will securely online. Clarity for relatives ✓ Simple ✓ Convenient from home ✓ Swiss provider ✓',
    keywords: {
      lastWill: 'Last will',
      share: 'Mandatory share inheritance',
      share2: 'Inheritance mandatory share',
      estate: 'Estate',
      succession: 'Legal succession',
    },
  },
  marriage_contract: {
    title: 'Create Swiss marriage contract online in 5 min',
    description:
      'Generate a legally compliant Swiss Marriage Contract online with gut geregelt. Legally secure ✓ Convenient from home ✓ Swiss provider ✓',
    keywords: {
      lastWill: 'Marriage contract',
      share: 'Mandatory share inheritance',
      share2: 'Marriage contract real estate purchase',
      estate: 'Estate',
      succession: 'Legal succession',
    },
  },
  partner_with_us: {
    title: 'Expand your retirement offering with gut geregelt',
    description:
      'Use the white-label solution from gut geregelt to make comprehensive retirement-related processes accessible to your clients. Integrates into your advisory process.',
    keywords: {
      pensionFoundation: 'Pension foundation',
      solution: 'Provision solution',
      trustees: 'Board of trustees',
      advice: 'Provision advice',
      advisor: 'Provision advisor',
    },
  },
  digital_footprint: {
    title: 'Create digital legacy in Switzerland online',
    description:
      'Secure your digital legacy in 10 min with gut geregelt. Document assets ✓ Map digital values ✓ Preserve data ✓ Convenient from home ✓ Swiss provider ✓',
  },
  register_assets: {
    title: 'Create Swiss register of assets online',
    description:
      'A Swiss register of assets in just 10 mins. Record assets ✓ Create clarity ✓ Convenient from home ✓ Swiss provider ✓',
  },
  grosseItern: {
    title: 'Grandparents magazine special offer',
  },
  preventiveCheckUp: {
    title: 'Retirement check',
    description:
      'With the Retirement check, you can review and adjust your retirement planning if necessary.',
    keywords: {
      pensionFoundation: 'Retirement check',
      solution: 'Liability risk',
      trustees: 'Board of trustees',
      advice: 'Provision advice',
      advisor: 'Pension fund',
    },
  },
  cohabitation_agreement: {
    title: 'Create Swiss cohabitation agreement online',
    description:
      'Generate a Swiss cohabitation agreement online in just 10 min with gut geregelt. Legally compliant ✓ Convenient from home ✓ Swiss provider ✓',
  },
  heirat: {
    title: 'Marriage: This preparation is now important',
    description:
      'Getting married means taking responsibility. For yourself, for your spouse, for your family. We help you with the necessary preparation.',
  },
  'geburt-eines-kindes': {
    title: 'Birth of a Child: This preparation is now important',
    description:
      'The birth of a child is a wonderful event. Now is the perfect time to secure the beautiful moments and think long-term.',
  },
  immobilienkauf: {
    title: 'Buying a Property: This preparation is now important',
    description:
      'Buying a home is a big step. To maintain this security even in difficult times, it is important to prepare in advance.',
  },
  pensionierung: {
    title: 'Retirement: This preparation is now important',
    description:
      'With retirement comes a new chapter in life. Now is the time to think about the future.',
  },
  krankheit: {
    title: 'Diagnosis of an Illness: This preparation is now important',
    description:
      'A serious illness changes everything. An advance directive and a will ensure that your wishes are respected and your loved ones are protected.',
  },
  'tod-verwandter': {
    title: 'Death of a Relative: This preparation is now important',
    description:
      'The death of a loved one is a difficult time. Create order and help your relatives manage everything according to your wishes.',
  },
  unternehmensgruendung: {
    title: 'Starting a Business: This preparation is now important',
    description:
      'Starting a business turns your vision into reality and creates lasting value. During this exciting time, it is important to ensure protection.',
  },
  scheidung: {
    title: 'Divorce: This preparation is now important',
    description:
      'Divorce can be a challenging time. We help you make all the necessary arrangements to ensure that you and your family are well-protected.',
  },
};

export default metadata;
