const pensionProvision = {
  title: 'gut geregelt : Nous vous aidons à protéger vos proches et vous-même',
  alreadyHaveAcc: 'Vous avez déjà un compte ?',
  signIn: 'Se connecter',
  p1: "L'équipe de gut geregelt est à votre disposition pour des sujets importants que chacun devrait aborder. Nous commençons par trois sujets clés pour la planification de la retraite et de la prévoyance et nous élargirons continuellement notre offre pour répondre à vos besoins.",
  p2: 'Nous vous accompagnons comme un coach personnel dans les processus importants que vous devez aborder, afin que vous puissiez éviter des problèmes pour vous-même et vos proches dans des situations difficiles.',
  p3: "Discutons des détails. Nous recommandons que chacun ait finalisé les documents juridiques suivants au plus tard à l'âge de 50 ans :",
  p3a: 'Si vous commencez maintenant et prenez 30 minutes, nous vous garantissons que vous serez bien organisé.',
  p4: 'En fonction de vos réponses, il y a un faible risque que, comme la plupart de nos clients, vous ne puissiez pas entreprendre les étapes nécessaires du premier coup pour bien organiser votre vie.',
  p4a: "La plupart de nos clients n'y parviennent pas du premier coup pour entreprendre les étapes nécessaires pour bien organiser leur vie.",
  p5a: 'Pour vous aider, nous proposons un service de rappel.',
  p5b: 'Pour cela, nous avons besoin de quelques informations de votre part afin de pouvoir vous rappeler les étapes en suspens.',
  p6a: "C'est pourquoi nous souhaitons vous proposer notre service de rappel en tant que support.",
  p6b: 'Veuillez nous indiquer un canal de communication par lequel nous pourrons vous rappeler les étapes en suspens.',

  onboarding: {
    stepOne: {
      title: 'Vérification de la prévoyance',
      subtitle: "Tout d'abord, nous aimerions mieux vous connaître",
      questions: [
        {
          question: "Tout d'abord : Pour qui souhaitez-vous prévoir ?",
          options: [
            {
              label: 'Moi-même',
            },
            {
              label: 'Membre de la famille',
            },
          ],
        },
        {
          question: '{{referringTo}}',
          options: [
            {
              label: 'Oui',
            },
            {
              label: 'Non',
            },
          ],
        },
      ],
      theirRealEstate:
        'Votre membre de la famille possède-t-il des biens immobiliers ?',
      myRealEstate: 'Possédez-vous des biens immobiliers ?',
    },
    welcomeCard: {
      title: 'Bienvenue chez gut geregelt',
      successMessage: 'Votre compte a été créé avec succès',
      emailMessage: "Un e-mail avec votre lien d'accès est en route",
      nextStep: 'Étape suivante',
      stepOne: "Ouvrez le lien dans l'e-mail",
      stepTwo: 'Créez votre mot de passe personnel',
      stepThree: 'Commencez votre planification de prévoyance',
    },
    stepTwo: {
      title: 'Vérification de la prévoyance',
      description:
        'Ce que possèdent les personnes comme vous qui ont bien prévu.',
      subtitlePersonal:
        "Merci, {{genderFormal}} {{first_name}} {{last_name}} ! Nous recommandons aux personnes comme vous de s'assurer qu'elles possèdent ou envisagent les artefacts ou services suivants.",
      femaleGenderBased: 'chère',
      maleGenderBased: 'cher',
      subtitleOther:
        "Merci ! Pour des personnes comme {{first_name}} {{last_name}}, nous recommandons de s'assurer qu'elles envisagent les artefacts ou services suivants (en fonction du sexe)",
      selectOnlyCompleted: 'Cliquez sur ceux que vous avez déjà.',
      seeResults: 'Voir les résultats',
      noCompletedService: "Je n'ai complété aucune des options",
    },
    stepThree: {
      title: 'Comment pouvons-nous aider ?',
      stepByStep:
        'Étape par étape vers une meilleure organisation de la prévoyance.',
      redLightLabel: 'Vous avez encore un long chemin à parcourir.',
      yellowLightLabel: 'Il y a du travail devant vous !',
      greenLightLabel: 'Vous êtes bien organisé',
      redLightRecommendation: {
        title:
          "Mais ne vous inquiétez pas, si vous avancez pas à pas, vous atteindrez rapidement votre objectif. Croyez-nous sur parole. Commencez simplement aujourd'hui par la première étape.",
      },
      yellowLightRecommendation: {
        title:
          'Bien, vous avez déjà pris soin de {{list}}. Nous vous recommandons donc de continuer avec les éléments suivants :',
      },
      greenLightRecommendation: {
        title:
          "Wow ! Vous avez déjà fait tout ce que des personnes comme vous devraient au moins faire. Regardez aussi d'autres sujets qui pourraient vous apporter de la valeur.",
      },
      subtitle:
        "L'expérience montre que les questions de prévoyance sont souvent déclenchées par des événements de la vie tels que le mariage, les enfants ou l'âge.",
      provisionTopics: 'Sujets de prévoyance',
      provisionTopicsOptions: {
        financialAdvise: 'Conseiller financier',
        legalAdvise: 'Conseil juridique',
      },
      lifeSituations: 'Situations de vie',
      lifeSituationsOptions: {
        marriage: 'Mariage',
        birthOfChild: "Naissance d'un enfant",
        purchaseOfRealEstate: "Achat d'un bien immobilier",
        enteringRetirement: 'Entrée en retraite',
        illnes: 'Maladie',
        deathOfARelative: "Décès d'un proche",
        foundingACompany: "Création d'une entreprise",
        divorce: 'Divorce',
      },
    },
  },

  confirmCheckbox:
    "En cochant cette case, je reconnais et confirme que j'accepte d'être enregistré sur gut geregelt.",
  processingAgreement: 'Voir notre accord de traitement des commandes.',

  c1: {
    title: 'Directive anticipée',
    description:
      "vous permet de définir comment les professionnels de santé doivent procéder en cas d'urgence.",
  },
  c2: {
    title: "Mandat pour cause d'inaptitude",
    description:
      'évite les problèmes avec le KESB et règle la tutelle de vos enfants.',
  },
  c3: {
    title: 'Testament',
    description: 'pour laisser votre héritage tel que vous le souhaitez.',
  },

  questions: [
    {
      question:
        "Si vous avez le choix de faire quelque chose aujourd'hui ou de le remettre à demain... Que faites-vous ?",
      options: [
        {
          label: 'Je le fais tout de suite.',
        },
        {
          label: 'Je ne sais pas.',
        },
        {
          label: 'Je le remets à demain.',
        },
      ],
    },
    {
      question:
        "Si quelqu'un essaie de vous motiver à faire quelque chose que vous n'aimez pas... Qu'est-ce qui fonctionne le mieux ?",
      options: [
        {
          label: 'Les incitations me motivent.',
        },
        {
          label: 'Je ne sais pas.',
        },
        {
          label: 'Cela me fait me sentir mal.',
        },
      ],
    },
    {
      question: 'Comment abordez-vous généralement les choses ?',
      options: [
        {
          label: 'Je ferme les yeux et je le fais simplement.',
        },
        {
          label: 'Je ne sais pas.',
        },
        {
          label: 'Plutôt plus tard.',
        },
      ],
    },
    {
      question:
        'Dites-nous à quelle fréquence vous souhaitez être rappelé si vous mettez le processus en pause temporairement :',
      options: [
        {
          label: 'Hebdomadairement',
        },
        {
          label: 'Mensuellement',
        },
        {
          label: 'Trimestriellement',
        },
      ],
    },
  ],
  thankyouCard: {
    title: 'Prévoyance simple et sans complication',
    thankYou: 'Merci pour votre intérêt pour gut geregelt',
    gentlyReminder:
      "Nous vous rappellerons doucement jusqu'à ce que vous ayez tout bien organisé.",
  },

  lifesituationCheck: {
    title: 'Vérification de la situation de vie',
    description:
      'Chaque phase de la vie a ses propres sujets uniques. Découvrez ce qui est pertinent pour la phase dans laquelle vous vous trouvez.',
  },
};

export default pensionProvision;
