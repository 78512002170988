import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

export const StyledSituationCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    maxWidth: '1024px',
    height: '490px',
  },
}));

export const SituationCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const SituationCardContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const SituationCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const SituationCardContentColumns = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const SituationCardMedia = styled(Box)(({ theme }) => ({
  '& img': {
    minWidth: '293px',
  },
  [theme.breakpoints.up('md')]: {
    '& img': {
      height: '490px',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '16 / 9',
    },
  },
}));

export const SituationCardActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  width: '100%',
}));
