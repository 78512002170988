import { Box, Stack, styled } from '@mui/material';

export const S = {
  RowWrapper: styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 20px;
  `,
  RowCell: styled(Stack)<{ width: string }>`
    width: ${({ width }) => width};
  `,
};
