import {
  Autocomplete,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback } from 'react';
import useLocales from '#/lib/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  filters: {
    advisor_name: string;
    location: string[];
    search: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
  locationsOptions: string[];
};

export default function ListToolbar({
  filters,
  onFilters,
  locationsOptions,
}: Props) {
  const { translate } = useLocales();

  const handleFilterSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search', event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        py: 2.5,
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <Autocomplete
          size="small"
          multiple
          id="location"
          value={filters.location}
          onChange={(_event, newValue) => {
            onFilters('location', newValue);
          }}
          options={locationsOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={String(translate('global.formLabels.location'))}
            />
          )}
        />
      </FormControl>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        flexGrow={1}
        sx={{ width: 1 }}
      >
        <TextField
          size="small"
          fullWidth
          value={filters.search}
          onChange={handleFilterSearch}
          placeholder={String(translate('global.formLabels.search'))}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
}
