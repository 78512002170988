import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type AssetsRegisterSituationKeys = 'pensionierung' | 'tod-verwandter';

export const ASSETS_REGISTER_COMMONS = {
  service: 'vermoegensregister',
  actions: defaultActions,
  learnMoreLink: '/vermoegens-und-sachregister',
  startProcessLink: '/dashboard/vermoegens-und-sachregister',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-assets.webp',
  },
};

export const ASSETS_REGISTER_SITUATION: Record<
  AssetsRegisterSituationKeys,
  any
> = {
  pensionierung: {
    content: {
      main: {
        title: 'registerAssets.situations.pensionierung.content.main.title',
        description:
          'registerAssets.situations.pensionierung.content.main.description',
      },
      columns: [
        {
          title:
            'registerAssets.situations.pensionierung.content.columns.0.title',
          description: [
            'registerAssets.situations.pensionierung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'registerAssets.situations.pensionierung.content.columns.1.title',
          description: [
            'registerAssets.situations.pensionierung.content.columns.1.description.0',
            'registerAssets.situations.pensionierung.content.columns.1.description.1',
            'registerAssets.situations.pensionierung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  'tod-verwandter': {
    content: {
      main: {
        title: 'registerAssets.situations.tod-verwandter.content.main.title',
        description:
          'registerAssets.situations.tod-verwandter.content.main.description',
      },
      columns: [
        {
          title:
            'registerAssets.situations.tod-verwandter.content.columns.0.title',
          description: [
            'registerAssets.situations.tod-verwandter.content.columns.0.description.0',
          ],
        },
        {
          title:
            'registerAssets.situations.tod-verwandter.content.columns.1.title',
          description: [
            'registerAssets.situations.tod-verwandter.content.columns.1.description.0',
            'registerAssets.situations.tod-verwandter.content.columns.1.description.1',
            'registerAssets.situations.tod-verwandter.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getAssetsRegisterSituation = (
  situation: AssetsRegisterSituationKeys
) => ({
  ...ASSETS_REGISTER_SITUATION[situation],
  ...ASSETS_REGISTER_COMMONS,
});
