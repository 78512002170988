import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type MarriageContractSituationKeys =
  | 'heirat'
  | 'immobilienkauf'
  | 'geburt-eines-kindes';

export const MARRIAGE_CONTRACT_COMMONS = {
  service: 'ehevertrag',
  actions: defaultActions,
  learnMoreLink: '/ehevertrag',
  startProcessLink: '/dashboard/ehevertrag',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-mc.webp',
  },
};

export const MARRIAGE_CONTRACT_SITUATION: Record<
  MarriageContractSituationKeys,
  any
> = {
  heirat: {
    content: {
      main: {
        title: 'marriageContractLanding.situations.heirat.content.main.title',
        description:
          'marriageContractLanding.situations.heirat.content.main.description',
      },
      columns: [
        {
          title:
            'marriageContractLanding.situations.heirat.content.columns.0.title',
          description: [
            'marriageContractLanding.situations.heirat.content.columns.0.description.0',
          ],
        },
        {
          title:
            'marriageContractLanding.situations.heirat.content.columns.1.title',
          description: [
            'marriageContractLanding.situations.heirat.content.columns.1.description.0',
            'marriageContractLanding.situations.heirat.content.columns.1.description.1',
            'marriageContractLanding.situations.heirat.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  immobilienkauf: {
    content: {
      main: {
        title: 'Ehevertrag',
        description:
          'Ein Ehevertrag regelt die Vermögensaufteilung, insbesondere wenn Sie gemeinsam eine Immobilie erwerben. So schützen Sie beide Partner und sorgen dafür, dass alles klar geregelt ist.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Beim Kauf einer Immobilie stellt sich die Frage der Vermögensaufteilung. Ein Ehevertrag schafft Klarheit und stellt sicher, dass Sie und Ihr Partner im Falle einer Trennung oder eines unerwarteten Ereignisses abgesichert sind.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Klare Vermögensregelungen für gemeinsame Immobilien',
            'Schutz beider Partner bei Trennung oder Tod',
            'Vermeidung von späteren Streitigkeiten',
          ],
        },
      ],
    },
  },
  'geburt-eines-kindes': {
    content: {
      main: {
        title: 'Ehevertrag',
        description:
          'Mit einem Ehevertrag schaffen Sie klare Regelungen in Bezug auf das Vermögen und die Absicherung Ihres Kindes. So sorgen Sie vor, falls sich Ihre Lebensumstände ändern.',
      },
      columns: [
        {
          title: 'Warum jetzt',
          description: [
            'Jetzt, da ein Kind Teil Ihrer Familie ist, bietet ein Ehevertrag zusätzlichen Schutz für das Vermögen Ihres Kindes. Es schafft klare Verhältnisse für die Vermögensaufteilung und die Versorgung Ihres Kindes.',
          ],
        },
        {
          title: 'Ihre Vorteile',
          description: [
            'Schutz des Kindesvermögens',
            'Klare Regelungen im Falle einer Trennung',
            'Vermeidung von Streitigkeiten',
          ],
        },
      ],
    },
  },
} as const;

export const getMarriageContractSituation = (
  situation: MarriageContractSituationKeys
) => ({
  ...MARRIAGE_CONTRACT_SITUATION[situation],
  ...MARRIAGE_CONTRACT_COMMONS,
});
