import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: '{{partnersGender}}',
  },
  {
    question: 'Voi',
  },
];

export const lifeSituation: IQuestion[] = [
  {
    question: 'In quale anno vi siete conosciuti?',
  },
  {
    question: 'Da quando vivete insieme?',
  },
  {
    question: 'Quale descrive meglio la vostra situazione?',
    options: [
      {
        label: 'Entrambi lavorano, senza figli',
      },
      {
        label: "Uno lavora a tempo pieno, l'altro part-time; senza figli",
      },
      {
        label:
          "Uno lavora a tempo pieno, l'altro si occupa della casa; con figli",
      },
    ],
  },
  {
    question: "A chi è intestato l'attuale contratto di locazione?",
    options: [
      {
        label: 'Voi',
      },
      {
        label: 'Partner',
      },
      {
        label: 'Entrambi i nomi',
      },
    ],
  },
  {
    question: 'Ci sono piani per una modifica del contratto?',
    options: [
      {
        label: 'Sì, intendiamo stipulare un contratto di subaffitto',
      },
      {
        label: 'Sì, pianifichiamo di creare un contratto a entrambi i nomi',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Presso quale banca avete il vostro conto corrente comune?',
  // },
];
