import {
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { IChatParticipant } from '#/types/chat';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  participant: IChatParticipant;
};

export default function ChatRoomParticipantDialog({
  open,
  onClose,
  participant,
}: Props) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <Iconify icon="mingcute:close-line" />
      </IconButton>
      <DialogContent sx={{ py: 5, px: 3, display: 'flex' }}>
        <Avatar
          alt={participant.first_name}
          src={participant.avatar_url}
          sx={{ width: 96, height: 96, mr: 3 }}
        />

        <Stack spacing={1}>
          <Typography variant="caption" sx={{ color: 'primary.main' }}>
            {participant.role}
          </Typography>
          <Typography variant="subtitle1">
            {`${participant.first_name} ${participant.last_name}`}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
