const preventiveCheckUp = {
  mainBanner: {
    intro: 'Preventive check-up for pension funds',
    title: 'Create transparency, minimize liability risk',
    subtitle:
      'Pension funds are tasked with providing optimal retirement information for their clients. The preventive check-up brings transparency and minimizes liability risk for pension funds, foundations, and trustees. Ensure a secure future for your beneficiaries.',
    buttonLabel: 'Schedule appointment',
  },
  allInfos: 'All information in a 15-minute video call',
  theChallenges: {
    title: 'The challenges of modern retirement planning',
    description:
      'Retirement planning challenges are diverse and complex. Pension funds and foundations must inform and guide their clients to ensure optimal retirement preparation in uncertain times.',
    cards: {
      increasedLifeExpectancy: {
        title: 'Increased life expectancy',
        description:
          'New diagnostic and treatment options could further increase life expectancy. Are your pension beneficiaries prepared for this?',
      },
      inflation: {
        title: 'Inflation',
        description:
          'Long-term inflation developments can strongly influence retirement provision. How do you secure the purchasing power of your customers?',
      },
      ahv: {
        title: 'AHV',
        description:
          'What changes are expected in the AHV and how will they affect your pension beneficiaries?',
      },
    },
  },
  benefits: {
    title: 'Benefits of the preventive check-up by gut geregelt',
    description:
      'The preventive check-up by gut geregelt offers a comprehensive and transparent solution to prepare beneficiaries optimally for future challenges and minimize liability risk.',
    cards: {
      transparency: {
        title: 'Transparency and education',
        description:
          'Inform your customers aged 40 and above in detail about the effects of increasing life expectancy and other risks.',
      },
      liability: {
        title: 'Mitigate liability risk',
        description:
          'Through comprehensive education and documented consultation, you can significantly reduce the risk of liability claims and increase security for your institution and the foundation board.',
      },
      documentation: {
        title: 'Documentation',
        description:
          'Traceable documentation of all information and consultation steps protects you from long-term liability risks.',
      },
    },
  },
  liabilityRisk: 'Trustee liability risk',
  liabilityRiskSubtitle:
    'Active communication and creating transparency are essential to protect beneficiaries from harm and to minimize liability risk for pension funds and trustees.',
  contactForm: {
    title: 'Discover how the preventive check-up supports pension funds',
    description:
      'Would you like to learn more about the preventive check-up and how it can help you optimally support your clients?',
    cta: 'Arrange a non-binding presentation appointment via info@gutgeregelt.ch or by filling out the contact form.',
  },
};

export default preventiveCheckUp;
