import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import GridView from './grid/grid-view';
import TableView from './table/table-view';
import useTable from '#/lib/hooks/useTable';
import {
  invalidateDigitalAssetsQueries,
  useBulkDeleteFiles,
  useBulkDeleteFolders,
  useGetFiles,
  useMoveFile,
  useMoveFolder,
} from '#/lib/api/digitalAssets';
import { IFileFilters, IFileFilterValue } from '#/types/file';
import Iconify from '#/components/shared/ui/Iconify';
import Filters from './filters';
import { isAfter } from '#/utils/formatTime';
import useBoolean from '#/lib/hooks/useBoolean';
import CustomBreadcrumbs from '#/components/shared/custom-breadcrumbs/custom-breadcrumbs';
import useLocalStorage from '#/lib/hooks/useLocalStorage';
import { DndProvider } from '#/components/shared/dnd/dnd-provider';
import ConfirmDialog from '#/components/shared/confirm-dialog';
import useLocales from '#/lib/hooks/useLocales';
import useAuth from '#/lib/hooks/useAuth';

const defaultFilters: IFileFilters = {
  name: '',
  type: [],
  startDate: null,
  endDate: null,
};

export const FILE_TYPE_OPTIONS = [
  // 'folder',
  // 'txt',
  // 'zip',
  'audio',
  'image',
  'video',
  // 'word',
  // 'excel',
  // 'powerpoint',
  // 'pdf',
  // 'photoshop',
  // 'illustrator',
  'document',
  'archive',
];

const PARENT_LIST_KEY = 'parentList';

export default function FileContent() {
  const [parentList, setParentList] = useState<(string | number | any)[]>([]);
  const [view, setView] = useState<string>('list');
  const [fileView, setFileView] = useLocalStorage('fileView', 'list');
  const [filters, setFilters] = useState(defaultFilters);
  const [parentListKey, setParentListKey] = useLocalStorage(
    PARENT_LIST_KEY,
    []
  );
  const { translate } = useLocales();
  const { isAuthenticated } = useAuth();
  const confirm = useBoolean();
  const openDateRange = useBoolean();
  const table = useTable({ defaultRowsPerPage: 10 });

  const { mutateAsync: moveFile } = useMoveFile();
  const { mutateAsync: moveFolder } = useMoveFolder();
  const { mutateAsync: onBulkFolderDelete } = useBulkDeleteFolders();
  const { mutateAsync: onBulkFileDelete } = useBulkDeleteFiles();

  const { data: files } = useGetFiles(
    isAuthenticated,
    filters?.name,
    parentList.map((item) => item.id),
    filters?.startDate &&
      format(new Date(filters?.startDate as Date), 'yyyy-MM-dd'),
    filters?.endDate &&
      format(new Date(filters?.endDate as Date), 'yyyy-MM-dd'),
    filters?.type
  );

  const dateError = isAfter(filters.startDate, filters.endDate);
  const notFound = !files?.folders?.length && !files?.files?.length;

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
        setFileView(newView);
      }
    },
    []
  );

  const handleFilters = useCallback(
    (name: string, value: IFileFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleParentList = useCallback((id: string | number, name: string) => {
    setParentList((prevState) => {
      const existingIndex = prevState.findIndex((item) => item.id === id);
      if (existingIndex > -1) {
        return prevState.filter((_, index) => index !== existingIndex);
      }
      return [...prevState, { id, name }];
    });
  }, []);

  const handleOpenFile = useCallback((filepath: string) => {
    console.log('filepath', filepath);
    window.open(filepath, '_blank');
  }, []);

  const handleParentNavigation = useCallback((id?: string | number) => {
    setParentList((prevState) => {
      if (!id) {
        return [];
      }

      const index = prevState.findIndex((item) => item.id === id);

      return index > -1 ? prevState.slice(0, index + 1) : prevState;
    });
  }, []);

  const handleBulkDelte = async (ids: string[]) => {
    try {
      const hasFolders = files?.folders.filter((folder) =>
        ids.includes(String(folder.id))
      );
      const hasFiles = files?.files.filter((file) =>
        ids.includes(String(file.id))
      );

      if (hasFolders?.length && hasFiles?.length) {
        const folderIds = hasFolders?.map((folder) => folder.id);
        const fileIds = hasFiles?.map((file) => file.id);
        await onBulkFolderDelete({ folder_ids: folderIds });
        await onBulkFileDelete({ file_ids: fileIds });
      } else if (hasFolders?.length) {
        const folderIds = hasFolders?.map((folder) => folder.id);
        await onBulkFolderDelete({ folder_ids: folderIds });
      } else {
        await onBulkFileDelete({ file_ids: ids });
      }

      invalidateDigitalAssetsQueries.getFiles();
    } catch (error) {
      console.error('Error deleting folder: ');
    }
  };

  useEffect(() => {
    if (parentListKey) {
      setParentList(parentListKey);
    }
    if (fileView) {
      setView(fileView);
    }
  }, []);

  useEffect(() => {
    setParentListKey(parentList);
  }, [parentList]);

  return (
    <>
      <Stack
        spacing={2.5}
        sx={{
          my: { xs: 3, md: 5 },
        }}
      >
        <Stack
          spacing={2}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
        >
          <Filters
            openDateRange={openDateRange.value}
            onCloseDateRange={openDateRange.onFalse}
            onOpenDateRange={openDateRange.onTrue}
            filters={filters}
            onFilters={handleFilters}
            typeOptions={FILE_TYPE_OPTIONS}
            dateError={dateError}
          />
          <ToggleButtonGroup
            size="small"
            value={view}
            exclusive
            onChange={handleChangeView}
          >
            <ToggleButton value="list">
              <Iconify icon="solar:list-bold" />
            </ToggleButton>

            <ToggleButton value="grid">
              <Iconify icon="mingcute:dot-grid-fill" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      <DndProvider
        moveFile={moveFile}
        moveFolder={moveFolder}
        onDragEndRefresh={invalidateDigitalAssetsQueries.getFiles}
      >
        <Stack
          sx={{
            my: 3,
          }}
        >
          {parentList.length > 0 && (
            <CustomBreadcrumbs
              links={[
                {
                  name: 'Foto & Video',
                  action: () => handleParentNavigation(),
                  id: null,
                },
                ...(parentList &&
                  parentList.map((item) => ({
                    name: item.name,
                    action: () => handleParentNavigation(item.id),
                    id: item.id,
                  }))),
              ]}
            />
          )}
        </Stack>

        {view === 'list' ? (
          <TableView
            table={table}
            dataFiltered={files}
            openFolder={handleParentList}
            openFile={handleOpenFile}
            parentList={parentList}
            notFound={notFound}
            onOpenConfirm={confirm.onTrue}
          />
        ) : (
          <GridView
            table={table}
            dataFiltered={files}
            openFolder={handleParentList}
            openFile={handleOpenFile}
            parentList={parentList}
            notFound={notFound}
          />
        )}
      </DndProvider>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={translate('global.deleteConfirmation.title')}
        content={
          <>
            {translate(
              `global.deleteConfirmation.${
                table.selected.length > 1
                  ? 'multipleItemsQuestion'
                  : 'oneItemQuestion'
              }`,
              {
                items: table.selected.length,
                itemNr: table.selected.length,
              }
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleBulkDelte(table.selected);
              confirm.onFalse();
              table.setSelected([]);
            }}
          >
            <Typography color="inherit">
              {translate('global.delete')}
            </Typography>
          </Button>
        }
      />
    </>
  );
}
