const livingWillLanding = {
  mainBanner: {
    intro: 'Créer une directive anticipée en ligne :',
    title: 'Légalement sûr, en 5 minutes',
    subtitle:
      'Dans la directive anticipée, vous consignez par écrit à quels traitements médicaux vous consentez ou refusez, au cas où vous ne seriez plus en mesure de prendre des décisions pour vous-même.',
    buttonLabel: 'Créer une directive anticipée',
  },
  youtubeTutorial: 'Pourquoi une directive anticipée est-elle importante ?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Mesures de précaution',
      description:
        "Si vous êtes incapable d'agir, la directive anticipée garantit que vous êtes traité selon vos souhaits.",
    },
    wishesYouCanAdapt: {
      title: 'Souhaits adaptables',
      description:
        'Toute personne ayant la capacité de décision peut créer une directive anticipée et la modifier ou la révoquer à tout moment.',
    },
    legalSecurity: {
      title: 'Soulager les proches',
      description:
        "Votre prévoyance offre à vos proches du calme en cas d'urgence et les soulage de décisions difficiles.",
    },
  },
  subBanner: {
    title: 'Bien organisé avec une directive anticipée conforme à la loi',
    subtitle:
      'Avec gut geregelt, vous créez rapidement votre directive anticipée, pouvez obtenir des conseils juridiques sur des sujets sensibles ou des questions ouvertes et faites une faveur à vos proches.',
    buttonLabel: 'Créer gratuitement maintenant',
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            "Avec une directive anticipée, vous vous assurez que vos souhaits médicaux sont respectés en cas d'urgence. Vous décidez quels traitements vous souhaitez recevoir et lesquels vous ne souhaitez pas.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Les incertitudes de santé augmentent avec l'âge. Une directive anticipée vous donne la sécurité que vos décisions seront respectées même lorsque vous ne pourrez plus les prendre vous-même.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Autodétermination en cas d'urgence",
              'Soulagement pour les proches',
              'Sécurité juridique',
            ],
          },
        ],
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            "Une directive anticipée détermine quelles décisions médicales doivent être prises en votre nom lorsque vous n'êtes plus en mesure de le faire. Cela assure votre autodétermination et soulage votre famille.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Avec la naissance d'un enfant vient une plus grande responsabilité. Une directive anticipée garantit que vos souhaits médicaux sont respectés et que votre famille a des instructions claires dans les situations difficiles.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Autodétermination en matière médicale',
              'Soulagement pour vos proches',
              'Protection et règles claires pour la famille',
            ],
          },
        ],
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            "Avec une directive anticipée, vous spécifiez quelles mesures médicales doivent être prises ou omises en cas d'urgence. Ainsi, vous gardez le contrôle sur vos soins médicaux, même lorsque vous ne pouvez plus décider par vous-même.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Particulièrement avec des engagements à long terme comme la propriété immobilière, il est important de garder à l'esprit la prévoyance personnelle. Une directive anticipée vous donne la sécurité que vos souhaits médicaux sont respectés et que votre maison reste protégée.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Garantie de vos souhaits médicaux',
              'Soulagement pour vos proches',
              'Complément à la protection de vos valeurs personnelles et financières',
            ],
          },
        ],
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            "Avec une directive anticipée, vous vous assurez que vos souhaits médicaux sont respectés en cas d'urgence. Vous décidez quels traitements vous souhaitez recevoir et lesquels vous ne souhaitez pas.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Les incertitudes de santé augmentent avec l'âge. Une directive anticipée vous donne la sécurité que vos décisions seront respectées même lorsque vous ne pourrez plus les prendre vous-même.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              "Autodétermination en cas d'urgence",
              'Soulagement pour les proches',
              'Sécurité juridique',
            ],
          },
        ],
      },
    },
    krankheit: {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            'Une directive anticipée spécifie quelles mesures médicales doivent être prises ou omises lorsque vous ne pouvez plus décider par vous-même.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Une maladie grave peut prendre des tournures inattendues. Une directive anticipée vous donne la sécurité que vos souhaits de traitement sont respectés et soulage vos proches.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Autodétermination en matière médicale',
              'Soulagement pour les proches',
              'Clarté dans les situations difficiles',
            ],
          },
        ],
      },
    },
    'tod-verwandter': {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            "Une directive anticipée spécifie quelles mesures médicales doivent être prises ou omises lorsque vous n'êtes plus en mesure de décider par vous-même. Vous créez de la clarté et soulagez votre famille dans les moments difficiles.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "La perte d'un proche montre l'importance de régler clairement ses propres souhaits. Avec une directive anticipée, vous vous assurez que vos décisions médicales sont respectées et que votre famille n'est pas surchargée dans les situations d'urgence.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Garantie de vos souhaits médicaux',
              'Soulagement pour les proches endeuillés',
              'Instructions claires pour les urgences',
            ],
          },
        ],
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            'Une directive anticipée spécifie quelles mesures médicales doivent être prises ou omises lorsque vous ne pouvez plus décider par vous-même.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Une maladie grave peut prendre des tournures inattendues. Une directive anticipée vous donne la sécurité que vos souhaits de traitement sont respectés et soulage vos proches.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Autodétermination en matière médicale',
              'Soulagement pour les proches',
              'Clarté dans les situations difficiles',
            ],
          },
        ],
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Directive anticipée',
          description:
            "Avec une directive anticipée, vous déterminez quelles décisions médicales doivent être prises lorsque vous n'êtes plus en mesure de le faire. Après un divorce, elle vous offre l'indépendance et l'autodétermination nécessaires.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Un divorce change non seulement votre situation juridique mais aussi personnelle. Une directive anticipée garantit que vos souhaits médicaux sont respectés indépendamment des liens familiaux antérieurs.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Indépendance dans les décisions médicales',
              'Protection de votre autodétermination',
              'Soulagement pour votre nouveau partenaire ou vos proches',
            ],
          },
        ],
      },
    },
  },
  newsletter: {
    title: 'Soyez bien organisé avec votre directive anticipée',
    buttonLabel: 'Créer maintenant',
  },
  faq: {
    questions: {
      question1: "Qu'est-ce qu'une directive anticipée et à quoi sert-elle ?",
      question2:
        'Quelles sont les conditions pour créer une directive anticipée ?',
      question3: 'Quand une directive anticipée entre-t-elle en vigueur ?',
      question4: 'Que peut empêcher la directive anticipée ?',
      question5:
        'Comment fonctionnent les personnes de confiance définies dans la directive anticipée ?',
      question6:
        "Une copie de la directive anticipée est-elle valable en cas d'utilisation ?",
      question7:
        'Dois-je faire certifier ma directive anticipée par un notaire ?',
      question8:
        'Une tierce personne peut-elle remplir la directive anticipée pour moi ?',
      question9:
        'Que faire si je ne peux pas signer moi-même (cécité, SEP ou Parkinson) ?',
      question10:
        "Ma directive anticipée est-elle également valable à l'étranger ?",
      question11:
        "Puis-je également stipuler que je ne veux pas être réanimé en cas d'urgence ?",
      question12:
        'Dois-je désigner une personne de confiance ? Que se passe-t-il si je ne peux pas/voudrais pas désigner une personne de confiance ?',
      question13: "Dois-je informer ma personne de confiance à l'avance ?",
      question14:
        'Mon conjoint est-il automatiquement ma personne de confiance ?',
      question15:
        'Puis-je désigner mon enfant mineur comme personne de confiance ?',
      question16:
        'Si je déménage ou me marie, dois-je adapter ma directive anticipée ?',
      question17:
        'Dois-je remettre une copie de la directive anticipée à mon médecin traitant ?',
      question18:
        'Ma directive anticipée en ligne est-elle accessible au public ?',
      question19: "Que faire si ma directive anticipée n'est pas respectée ?",
      question20: 'Comment puis-je garantir la validité ?',
      question21:
        "Y a-t-il d'autres documents que je devrais créer en plus de la directive anticipée ?",
      question22:
        "Comment gut geregelt m'aide-t-il avec ma directive anticipée ?",
    },
    answers: {
      answer1:
        "Une directive anticipée est une expression unilatérale et écrite de la volonté en état de capacité de discernement. Vous y donnez aux médecins et soignants des instructions médicales pour le cas où vous ne seriez plus capable de discernement ou d'expression à un moment ultérieur.",
      answer2: 'Capacité de discernement en matière de décisions médicales.',
      answer3:
        "Une directive anticipée entre en vigueur lorsque deux facteurs se rencontrent : incapacité de discernement et pronostic défavorable. Une telle situation peut par exemple se présenter en cas d'AVC sévère, de démence avancée, de tumeur cérébrale à un stade avancé, de coma ou de coma végétatif après un accident, une opération ou une réanimation.",
      answer4:
        "que par des mesures médicales la mort naturelle soit retardée, que des tentatives de réanimation soient entreprises, que des machines maintiennent la vie sans perspective d'amélioration, alimentation artificielle.",
      answer5:
        "Toutes les personnes de confiance mentionnées dans la directive anticipée deviennent mandataires dans l'ordre de leur mention. Elles sont autorisées à prendre des décisions médicales au nom de la personne disposante lorsqu'une situation non prévue dans la directive anticipée se présente. En cas de conflit, la loi prévoit que toute personne proche de la personne incapable de discernement peut s'adresser à l'autorité de protection de l'adulte.",
      answer6:
        "Oui, une copie peut être utilisée en cas d'application. Nous recommandons néanmoins qu'au moins une personne de confiance connaisse le lieu de conservation des documents originaux.",
      answer7:
        "Non. Depuis l'entrée en vigueur du nouveau droit de protection de l'adulte en 2013, une directive anticipée rédigée en état de capacité de discernement est juridiquement contraignante.",
      answer8:
        'Oui, à condition que vous compreniez le contenu et ajoutiez à la fin de manière autonome la date et la signature manuscrite ou que vous puissiez signer électroniquement la directive anticipée de manière qualifiée.',
      answer9: 'Faites certifier votre directive anticipée par un notaire.',
      answer10:
        "gut geregelt ne peut intervenir que dans le cadre de la Suisse pour le respect d'une directive anticipée. Dans quelle mesure une directive anticipée est contraignante à l'étranger doit être vérifiée dans le pays concerné.",
      answer11:
        "En principe, il s'agit en cas d'urgence de sauver et de stabiliser la vie, de sorte qu'il n'y a souvent pas de temps pour chercher une directive anticipée éventuellement existante. Le personnel de secours est cependant également tenu de déterminer la volonté présumée d'une personne non réactive et d'agir en conséquence.",
      answer12:
        "Une directive anticipée est également juridiquement valable si aucune personne de confiance n'est nommée, cependant, il est dans ce cas avantageux que la directive anticipée soit mise à jour chaque année.",
      answer13:
        'Nous recommandons de les informer pour des raisons pratiques. Ainsi, votre représentant sait ce qui est attendu de lui.',
      answer14:
        'Non. Le conjoint doit être explicitement mentionné dans la directive anticipée si cela est souhaité.',
      answer15:
        'Oui, légalement, une personne de confiance doit être capable de discernement, mais pas nécessairement majeure. Nous recommandons de faire appel à une personne adulte pour le soutien.',
      answer16:
        'Une directive anticipée doit être adaptée autant que possible aux circonstances de vie actuelles. Nous recommandons donc de prendre en compte chaque changement.',
      answer17: 'Nous recommandons de remettre une copie au médecin traitant.',
      answer18: "Non, l'accès est protégé par mot de passe.",
      answer19:
        "Vous ou des personnes proches de vous pouvez contacter par écrit l'autorité de protection de l'adulte ou appeler et faire valoir que la directive anticipée n'est pas respectée ou que les intérêts de la personne incapable de discernement sont menacés ou ne sont plus préservés.",
      answer20:
        'gut geregelt vous aide à garantir la validité juridique. Chaque directive anticipée doit contenir la date de création et la signature personnelle de la personne disposante.',
      answer21:
        'Oui, le {{powerOfAttorney}} et le {{lastWill}} sont deux documents que vous devriez créer dès que possible.',
      answer22:
        "Chez gut geregelt, nous vous aidons à créer votre directive anticipée de manière fluide et légale. Pour que vous puissiez envisager l'avenir en toute sérénité.",
    },
  },
};

export default livingWillLanding;
