const grosseItern = {
  mainBanner: {
    intro: 'Semplicemente una buona sensazione:',
    title: 'Previdenza ben regolata',
    subtitle:
      'Un argomento difficile diventa finalmente semplice: crea una direttiva del paziente, una procura e un testamento comodamente da casa - in soli 20 minuti. Inizia ora con il fornitore svizzero gut geregelt.',
    buttonLabel: 'Inizia ora',
    sale: {
      blueText: 'Risparmia il 50%',
      blackText: 'fino al 31.12.2024',
    },
  },
  aboutCards: {
    justDoIt: {
      title: 'Fallo semplicemente, prima che succeda qualcosa',
      description:
        'La speranza è di non dover mai utilizzare la direttiva del paziente e la procura. Tuttavia, gli imprevisti accadono, quindi vale la pena crearli in anticipo.',
    },
    security: {
      title: 'Sicurezza per coniugi e famiglia',
      description:
        'Creando questi documenti in tempo, mantieni il controllo sui tuoi desideri. Allo stesso tempo, proteggi tutta la tua famiglia ed eviti situazioni di stress inutili.',
    },
    done: {
      title: 'Fatto in 20 minuti - da casa',
      description:
        'Con gut geregelt crei documenti e contratti legalmente sicuri in un linguaggio facilmente comprensibile, comodamente da casa. E non preoccuparti: in casi più complessi, gli esperti legali ti aiuteranno.',
    },
    swissProvider: {
      title: 'Fornitore svizzero: regola bene la previdenza',
      description:
        'gut geregelt è la tua suite digitale svizzera per la previdenza. Fai un favore a te stesso e ai tuoi cari e lasciati regolare bene già oggi.',
    },
  },
  angebot: {
    phrase:
      'Conosciuto dai media e dalla rivista Grosseltern (Edizione 30/2024)',
    highlightedPhrase: 'Rivista Grosseltern',
  },
};

export default grosseItern;