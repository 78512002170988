const metadata = {
  heirat: {
    title: 'metadata.heirat.title',
    description: 'metadata.heirat.description',
  },
  'geburt-eines-kindes': {
    title: 'metadata.geburt-eines-kindes.title',
    description: 'metadata.geburt-eines-kindes.description',
  },
  immobilienkauf: {
    title: 'metadata.immobilienkauf.title',
    description: 'metadata.immobilienkauf.description',
  },
  pensionierung: {
    title: 'metadata.pensionierung.title',
    description: 'metadata.pensionierung.description',
  },
  krankheit: {
    title: 'metadata.krankheit.title',
    description: 'metadata.krankheit.description',
  },
  'tod-verwandter': {
    title: 'metadata.tod-verwandter.title',
    description: 'metadata.tod-verwandter.description',
  },
  unternehmensgruendung: {
    title: 'metadata.unternehmensgruendung.title',
    description: 'metadata.unternehmensgruendung.description',
  },
  scheidung: {
    title: 'metadata.scheidung.title',
    description: 'metadata.scheidung.description',
  },
};

export default metadata;
