const registerAssets = {
  mainBanner: {
    title: 'Klarheit und Überblick mit dem Vermögens- und Sachregister',
    subtitle:
      'Ein umfassender Überblick über Ihr gesamtes Vermögen gibt Ihnen nicht nur Seelenfrieden, sondern erleichtert auch das Leben Ihrer Familienmitglieder.',
    buttonLabel: 'Klarheit gewinnen',
  },
  youtubeTutorial: 'Wie Sie Ihren Nachlass vorbereiten?',
  aboutCards: {
    logPhysicalAssets: {
      title: 'Physische Vermögenswerte erfassen',
      description:
        'Erfassen Sie physische Vermögenswerte, wo Ihre Angehörigen sie finden können und wem Sie sie vermachen möchten.',
    },
    outlineRelationships: {
      title: 'Beziehungen darlegen',
      description:
        'Vermerken Sie Ihre Bankdetails und Co., in denen Sie Vermögen investiert haben, um es Ihrer Familie zu erleichtern, Nachforschungen anzustellen.',
    },
    simplifyCleanup: {
      title: 'Die Aufräumarbeiten vereinfachen',
      description:
        'Bewahren Sie alles zentralisiert und gut geschützt an einem Ort auf, damit vertrauenswürdige Personen schnell Zugang zu relevanten Informationen erhalten.',
    },
  },
  subBanner: {
    title: 'Das Gesamtbild, das Ihrer Familie Dutzende von Stunden erspart',
    subtitle:
      'Viele unserer Kunden kennen das aus eigener Erfahrung - wenn wenig dokumentiert und aufgezeichnet ist, werden Stunden damit verschwendet, einen klaren Überblick über das Eigentum und die Vermögenswerte des Verstorbenen zu bekommen. Wir helfen Ihnen, Ihren Lieben diese Erfahrung zu ersparen.',
    buttonLabel: 'Überblick gewinnen',
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'Meine Tochter hat mir geholfen, das Vermögen meines Mannes und mein Vermögen in das Inventar des Vermögensverzeichnisses einzutragen. Es war für uns alle eine Erleichterung, zu wissen, wo wir was haben.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: 'Ein Testament hatte ich bereits. Aber mit gut geregelt habe ich jetzt auch einen Überblick über mein ganzes Hab und Gut, den ich laufend aktualisieren und im Falle meines Todes den entsprechenden Personen direkt zugänglich machen kann.',
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: 'Eine einfache Lösung, die eine gute Pflichtübung ist, um alles aufzuschreiben, was von Wert ist. Ich war überrascht, wie viel zusammenkam, woran ich nicht direkt gedacht habe.',
    },
  },
  newsletter: {
    title: 'Ihr Nachlass, gut geregelt',
    buttonLabel: 'Jetzt organisieren',
  },
  faq: {
    questions: {
      question1:
        'Was ist ein Vermögens- und Sachregister und warum ist es wichtig?',
      question2:
        'Welche Arten von Vermögenswerten können im Vermögens- und Sachregister enthalten sein?',
      question3: 'Wie funktioniert der Vermögensregisterdienst?',
      question4:
        'Wie kann ich sicherstellen, dass meine Lieben Zugang zu meinem Vermögensregister haben?',
      question5: 'Was passiert mit meinen Vermögenswerten nach meinem Tod?',
      question6:
        'Kann ich mein Vermögens- und Sachregister aktualisieren oder ändern?',
      question7: 'Wie sicher sind meine persönlichen Informationen?',
      question8:
        'Welche rechtlichen Aspekte sollten beim Verwalten eines Vermögens- und Sachregisters berücksichtigt werden?',
      question9:
        'Kann der Dienst den Erben dabei helfen, finanzielle Angelegenheiten zu klären?',
    },
    answers: {
      answer1:
        'Ein Vermögens- und Sachregister ist eine umfassende Aufzeichnung aller physischen Vermögenswerte und Beziehungen zu Finanzinstitutionen einer Person. Es ist wichtig, ein solches Register zu führen, um einen klaren Überblick über die eigenen Vermögenswerte zu haben und die Abwicklung für die Angehörigen nach dem Tod zu erleichtern. Dies kann den Lieben Dutzende von Stunden ersparen.',
      answer2:
        'Das Register kann eine Vielzahl von Vermögenswerten umfassen, einschliesslich Immobilien, Fahrzeugen, Schmuck, Kunstwerken, Bankkonten, Anlagekonten und mehr',
      answer3:
        'Unser Dienst bietet eine benutzerfreundliche Plattform, auf der Kunden ihre Vermögenswerte eingeben und verwalten können. Sie können den Standort, den Eigentumsstatus und die Begünstigten für jedes Vermögen definieren.',
      answer4:
        'Sie definieren vertrauenswürdige Personen, die nach Ihrem Ableben uneingeschränkten Zugang zu Ihrem Konto erhalten. Diesen Erhalten sie mit einer Sterbeurkunde und einem Vollstreckerzertifikat.',
      answer5:
        'Das Vermögens- und Sachregister, zusammen mit dem Testament, erleichtert die Abwicklung für die Angehörigen, indem es klare Anweisungen gibt, wie die Vermögenswerte verteilt oder verwaltet werden sollen und wo was zu finden ist.',
      answer6:
        'Ja, Kunden können ihr Vermögens- und Sachregister jederzeit aktualisieren oder ändern, um Änderungen in ihren Vermögenswerten oder Begünstigten widerzuspiegeln.',
      answer7:
        'Wir implementieren strenge Sicherheitsmassnahmen, um die höchste Vertraulichkeit und Sicherheit der Kundeninformationen zu gewährleisten, einschliesslich branchenüblicher Verschlüsselungen und Zugangskontrollen.',
      answer8:
        'Kunden sollten rechtliche Themen wie die Erstellung eines Testaments und Erbschaftsangelegenheiten berücksichtigen. Es ist ratsam, rechtlichen Rat einzuholen, um sicherzustellen, dass das Register den geltenden Gesetzen entspricht.',
      answer9:
        'Ja, indem es einen klaren Überblick über die Vermögenswerte des Verstorbenen bietet und die Identifizierung und Verwaltung von Vermögenswerten erleichtert, unterstützt der Dienst die Erben bei der Klärung finanzieller Angelegenheiten.',
    },
  },

  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Vermögens- und Sachregister',
          description:
            'Ein Vermögens- und Sachregister dokumentiert Ihre Besitztümer und Wertgegenstände. So schaffen Sie Transparenz und stellen sicher, dass alles, was Ihnen wichtig ist, in guten Händen bleibt.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Nach dem Tod wird die Aufteilung von Vermögen und Besitztümern oft zur Belastung. Mit einem Vermögensregister beugen Sie Unklarheiten vor und sorgen dafür, dass alles nach Ihren Wünschen geregelt wird.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klarheit über Vermögenswerte und Besitztümer',
              'Schutz vor Streitigkeiten',
              'Ordnung und Transparenz für Ihre Familie',
            ],
          },
        ],
      },
    },

    'tod-verwandter': {
      content: {
        main: {
          title: 'Vermögens- und Sachregister',
          description:
            'Ein Vermögens- und Sachregister dokumentiert Ihre Besitztümer und Wertgegenstände. So schaffen Sie Transparenz und stellen sicher, dass alles, was Ihnen wichtig ist, in guten Händen bleibt.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Nach dem Tod eines Verwandten wird die Aufteilung von Vermögen und Besitztümern oft zur Belastung. Mit einem Vermögensregister beugen Sie Unklarheiten vor und sorgen dafür, dass alles nach Ihren Wünschen geregelt wird.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Klarheit über Vermögenswerte und Besitztümer',
              'Schutz vor Streitigkeiten',
              'Ordnung und Transparenz für Ihre Familie',
            ],
          },
        ],
      },
    },
  },
};

export default registerAssets;
