const outputDoc = {
  title: 'Contrat de Concubinage',
  from: 'de',
  born: 'né(e) le',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié(e)',
    widowed: 'Veuf/Veuve',
    divorced: 'Divorcé(e)',
    registered_partnership: 'Partenariat enregistré',
  },
  civil_status: 'État civil: {{civil_status}}',
  address: 'Adresse: {{address}}',
  male: 'Mari',
  female: 'Épouse',
  and: 'et',
  between: 'Entre',
  contractPartiesAgree: 'Les parties contractantes conviennent de ce qui suit:',
  spousePresentation:
    '{{partnerSurname}} {{partnerName}}, né(e) le {{partnerBirthday}}, citoyenneté {{partnerCitizenship}}, {{partnerCivilStatus}}, domicilié(e) à {{partnerAdress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, né(e) le {{birthday}}, citoyenneté {{citizenship}}, {{civilStatus}}, domicilié(e) à {{adress}}.',
  preliminaryRemark: {
    title: 'Remarque Préliminaire',
    section1: {
      firstDefault:
        'Nous nous sommes rencontrés en {{meet_year}} et vivons ensemble depuis le {{date_of_moving_in}} à l’adresse suivante: {{adress}}.',
      secondDefault:
        'Nous avons l’intention de maintenir notre relation de concubinage pour une durée indéterminée.',
      bothEmployedNoChildren: 'Nous travaillons tous les deux à temps plein.',
      oneWorksFullTime:
        '{{who_works_full_time}} travaille à temps plein, tandis que {{who_works_part_time}} travaille à temps partiel ({{workload_distribution}}%).',
      oneFullTimeOneHousehold:
        '{{who_works_full_time}} travaille à temps plein, tandis que {{householder}} s’occupe du ménage et de {{childrenNo}}.',
      singularChild: 'l’enfant',
      plurarChildren: 'les enfants',
    },
  },
  ownership: {
    title: 'Propriété',
    section1: {
      text: 'Un inventaire des biens mobiliers sera établi et régulièrement mis à jour. Cet inventaire, signé par les deux parties, fait partie intégrante du présent accord.',
    },
  },
  costOfLiving: {
    title: 'Frais de Subsistance',
    section1: {
      firstText:
        'Chacun de nous contribue, dans les limites de ses possibilités économiques, aux frais de vie communs. À cet effet, nous ouvrirons un compte bancaire commun chez {{joint_household_account_bank}}.',
      bothEmployedNoChildren: {
        firstText:
          'Avant le premier du mois, {{firstParty}} et {{secondParty}} verseront {{equal_payment_frequency}} CHF {{equal_payment_amount}} chacun.',
        secondText: 'Premier paiement le {{date_of_first_payment}}.',
        thirdText:
          '{{compensation_payment_cadence}} le solde du compte sera vérifié. Un éventuel déficit sera couvert par les deux parties à parts égales.',
      },
      oneWorksFullTime: {
        firstText:
          'Avant le premier du mois, {{firstParty}} versera CHF {{amount_paid_by_me}} et {{secondParty}} CHF {{amount_paid_by_partner}} {{equal_payment_frequency}}.',
        secondText:
          '(= proportion {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}).',
        thirdText: 'Premier paiement le {{date_of_first_payment}}.',
        fourthText:
          '{{compensation_payment_cadence}} le solde du compte sera vérifié. Tout déficit sera couvert selon la proportion {{percentage_paid_by_me}}/{{percentage_paid_by_partner}}.',
      },
    },
    section2: {
      title: 'Les dépenses couvertes par ce compte incluent :',
      expensesOptions: {
        rent: 'Loyer',
        additional:
          'Charges locatives telles que électricité, chauffage, eau, taxes sur les déchets, etc.',
        radioTV: 'Abonnement radio et télévision',
        groceries: 'Aliments',
        cleaning: 'Frais de ménage',
      },
      bothEmployedNoChildren:
        'La gestion du ménage sera assurée par les deux parties.',
    },
  },
  dissolution: {
    title: 'Dissolution du Contrat de Concubinage',
    firstText:
      'En cas de dissolution, chaque partenaire récupère ses biens propres. Les biens en copropriété seront répartis équitablement.',
    secondText:
      'Cet accord prend effet à la signature et est soumis au droit suisse. Le tribunal compétent est {{city}}.',
  },
  signature: {
    place: 'Lieu',
    date: 'Date',
  },
};
export default outputDoc;
