const tt = {
  title: 'Terms and conditions',
  scope: {
    title: 'Scope',
    content:
      'These General Terms and Conditions (GTC) govern the use of the services of betascale AG (hereinafter referred to as "gut geregelt"). Gut geregelt provides the services to the customer for the duration of the contract in the current version against payment. The contractual relationship between gut geregelt and the customer is established upon acceptance of the offer. By accepting the offer, the customer expressly acknowledges these GTC, even if they contradict the customer\'s own terms and conditions in whole or in part. The customer is obliged to provide correct, complete, and up-to-date information at all times. Failure to comply with this obligation constitutes a breach of the terms of use and may lead to immediate termination of the contract.',
  },
  userRights: {
    title: 'User rights',
    content:
      'Gut geregelt grants the customer a non-exclusive and non-transferable right to use the services as intended for the duration of the contract. The customer is not entitled to make the services available to third parties for a fee or free of charge. Any form of providing the service to third parties is expressly prohibited. The customer must ensure that all contractual relationships with third parties exclude the free use of the service.',
  },
  customerResponsibilities: {
    title: 'Customer responsibilities',
    content:
      'Beyond the services provided by gut geregelt, the customer bears full responsibility for the operation, security, and condition of their electronic and digital media on which the services of gut geregelt are used (hardware, software, operation, security, etc.). The customer bears all costs associated with fulfilling these obligations.',
  },
  systemAvailability: {
    title: 'System availability',
    content:
      'The services of gut geregelt are based on the "best-effort" principle. Gut geregelt takes appropriate measures to ensure the most uninterrupted use of the services possible. However, the customer is aware that the service and other components from third-party providers, whose functionality is beyond the control of gut geregelt, constitute a technically complex system. Therefore, gut geregelt cannot guarantee constant and complete availability of the components of more than 99.5%. Interruptions due to system maintenance, updates, etc., will be announced in advance with a notice period of two working days for planned maintenance work. Urgent maintenance work that leads to an interruption of availability can be carried out without prior notice to promptly resolve issues or avert potential threats to the system. If gut geregelt identifies a threat to the proper operation due to negligent or intentional actions by third parties, it is authorized to take all necessary measures immediately to protect its infrastructure and software from damage.',
  },
  support: {
    title: 'Support',
    content:
      'The support of gut geregelt is available to the customer via email at support@gutgeregelt.ch from Monday to Friday, 8:00 AM – 12:00 PM and 1:00 PM – 5:00 PM, with an average response time of 1 hour.',
  },
  renumerations: {
    title: 'Remunerations',
    content:
      'The amount of remuneration for the agreed services is specified separately in the respective price lists. These are usually recurring fees. Recurring fees can be adjusted after prior notice. They are due within 20 days or can be collected by credit card. Additionally agreed services will be invoiced separately.',
  },
  intellectualPropertyRights: {
    title: 'Intellectual property rights',
    content:
      'The customer acknowledges the intellectual property rights, in particular the copyright, of gut geregelt on software, services, and documentation. The customer is not permitted to make this software available to third parties for a fee or free of charge, to sublet and/or use it outside the contractual relationship with gut geregelt, or to challenge the rights of gut geregelt in any way.',
  },
  dataProtection: {
    title: 'Data protection and data security',
    content:
      "gut geregelt will handle the customer's data with the utmost care and protect it from misuse and loss. The customer is informed that certain data may be shared with partner organizations as part of the use of the service. Details on the type of data shared and control options can be found in the user account settings. Gut geregelt does not store any personal data of its customers. Nevertheless, gut geregelt takes technical and organizational measures that comply with the applicable requirements of the GDPR. Within the scope of technical possibilities, special agreements on data storage can be made in certain countries. The customer is responsible for the legality of data transfer or its use. All customer data stored and processed by gut geregelt is the exclusive property of the customer and is used by gut geregelt solely for the purpose of contract execution.",
  },
  disclaimerLiability: {
    title: 'Disclaimer of liability',
    content:
      'Gut geregelt ensures that the services are functional and operational in accordance with the legal provisions of this contract. The customer undertakes to indemnify gut geregelt from all claims by third parties due to the stored data and to reimburse gut geregelt for any costs arising from any legal violations. Gut geregelt is entitled to immediately block the storage space if there is a justified suspicion that the stored data is illegal and/or infringes the rights of third parties. A justified suspicion of illegality and/or infringement exists if courts, authorities, and/or third parties inform gut geregelt about it. Gut geregelt must inform the customer immediately about the removal and the reasons for it. The prohibition must be lifted as soon as the suspicion is completely cleared. Gut geregelt excludes any liability towards the customer (or third parties) within the framework of the legal provisions, in particular for the fulfillment of its contractual and non-contractual obligations, as well as for data loss and lost profits (even in the case of gross negligence). This disclaimer also applies to damages that arise directly or indirectly from the use of our services. The mutual liability of the parties, regardless of the liability reason, is in any case limited to the amount of the monthly service fees of the last twelve months before the occurrence of the damage.',
  },
  communication: {
    title: 'Communication',
    content:
      'Gut geregelt is entitled to name the customer as a reference and to use general information about the agreed contract for marketing and sales purposes appropriately.',
  },
  amendments: {
    title: 'Amendments',
    content:
      'Gut geregelt reserves the right to change or replace these General Terms and Conditions at any time. In the event of a change, gut geregelt will attempt to inform the customer at least 30 days before the new terms take effect.',
  },
  severabilityClause: {
    title: 'Severability clause',
    content:
      'In the event of the complete or partial ineffectiveness of individual provisions of this contract, ineffective or unenforceable provisions shall be implemented or supplemented in such a way that the economic purpose pursued with the ineffective provision is achieved. The same applies in the case of gaps in this contract.',
  },
  jurisdiction: {
    title: 'Jurisdiction',
    content:
      'The parties agree that Swiss law applies to all legal relationships arising from this contract. The exclusive place of jurisdiction for all disputes arising from the execution of this contractual relationship is Liestal BL (Switzerland).',
  },
};

export default tt;
