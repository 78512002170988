import { IQuestion } from '#/types/globalTypes';

export const contractParties: IQuestion[] = [
  {
    question: '{{partnersGender}}',
  },
  {
    question: 'You',
  },
];

export const lifeSituation: IQuestion[] = [
  {
    question: 'In which year did you meet?',
  },
  {
    question: 'Since when have you been living together?',
  },
  {
    question: 'What best describes your situation?',
    options: [
      {
        label: 'Both are employed, no children',
      },
      {
        label: 'One works full-time, the other part-time; no children',
      },
      {
        label: 'One works full-time, the other manages the household; with children',
      },
    ],
  },
  {
    question: 'Whose name is on the current lease?',
    options: [
      {
        label: 'Yours',
      },
      {
        label: 'Partner',
      },
      {
        label: 'Both names',
      },
    ],
  },
  {
    question: 'Are there plans to change the contract?',
    options: [
      {
        label: 'Yes, we intend to sign a sublease agreement',
      },
      {
        label: 'Yes, we plan to create a contract in both names',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'At which bank is your joint household account?',
  // },
];
