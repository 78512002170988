import {
  alpha,
  Box,
  Button,
  CardActionArea,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { IFileFilters, IFileFilterValue } from '#/types/file';
import Iconify from '#/components/shared/ui/Iconify';
import usePopover from '#/lib/hooks/usePopover';
import Label from '#/components/shared/Label';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import FileThumbnail from '#/components/shared/file-thumbnail';
import { CustomDateRangePicker } from '#/components/shared/custom-date-range-picker';
import { shortDateLabel } from '#/utils/formatTime';
import useLocales from '#/lib/hooks/useLocales';

type Props = {
  openDateRange: boolean;
  filters: IFileFilters;
  onCloseDateRange: VoidFunction;
  onOpenDateRange: VoidFunction;
  onFilters: (name: string, value: IFileFilterValue) => void;
  typeOptions: string[];
  dateError: boolean;
};

export default function Filters({
  openDateRange,
  filters,
  onFilters,
  typeOptions,
  onOpenDateRange,
  onCloseDateRange,
  dateError,
}: Props) {
  const popover = usePopover();
  const { translate } = useLocales();

  const renderLabel = filters.type.length
    ? translate(`global.fileTypeOptions.${filters.type[0]}`) ||
      (translate('global.allType') as string)
    : (translate('global.allType') as string);

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (newValue: string) => {
      const checked = filters.type.includes(newValue)
        ? filters.type.filter((value) => value !== newValue)
        : [...filters.type, newValue];
      onFilters('type', checked);
    },
    [filters.type, onFilters]
  );

  const handleResetType = useCallback(() => {
    popover.onClose();
    onFilters('type', []);
  }, [onFilters, popover]);

  const handleFilterStartDate = useCallback(
    (newValue: Date | null) => {
      onFilters('startDate', newValue);
    },
    [onFilters]
  );

  const handleFilterEndDate = useCallback(
    (newValue: Date | null) => {
      onFilters('endDate', newValue);
    },
    [onFilters]
  );

  const renderFilterName = (
    <TextField
      value={filters.name}
      onChange={handleFilterName}
      placeholder={translate('global.search') as string}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
      sx={{
        width: { xs: 1, md: 260 },
      }}
    />
  );

  const renderFilterType = (
    <>
      <Button
        color="inherit"
        onClick={popover.onOpen}
        endIcon={
          <Iconify
            icon={
              popover.open
                ? 'eva:arrow-ios-upward-fill'
                : 'eva:arrow-ios-downward-fill'
            }
            sx={{ ml: -0.5 }}
          />
        }
      >
        <Typography>{renderLabel}</Typography>
        {filters.type.length > 2 && (
          <Label color="info" sx={{ ml: 1 }}>
            +{filters.type.length - 2}
          </Label>
        )}
      </Button>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ p: 2.5 }}
      >
        <Stack spacing={2.5}>
          <Box
            gap={1}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(4, 1fr)',
            }}
          >
            {typeOptions.map((type) => {
              const selected = filters.type.includes(type);

              return (
                <CardActionArea
                  key={type}
                  onClick={() => handleFilterType(type)}
                  sx={{
                    p: 1,
                    borderRadius: 1,
                    cursor: 'pointer',
                    border: (theme) =>
                      `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                    ...(selected && {
                      bgcolor: 'action.selected',
                    }),
                  }}
                >
                  <Stack spacing={1} direction="row" alignItems="center">
                    <FileThumbnail file={type} />
                    <Typography variant={selected ? 'subtitle2' : 'body2'}>
                      {translate(`global.fileTypeOptions.${type}`)}
                    </Typography>
                  </Stack>
                </CardActionArea>
              );
            })}
          </Box>
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleResetType}
            >
              <Typography>{translate('global.cancel') as string}</Typography>
            </Button>

            <Button variant="contained" onClick={popover.onClose}>
              <Typography>{translate('global.apply') as string}</Typography>
            </Button>
          </Stack>
        </Stack>
      </CustomPopover>
    </>
  );

  const renderFilterDate = (
    <>
      <Button
        color="inherit"
        onClick={onOpenDateRange}
        endIcon={
          <Iconify
            icon={
              openDateRange
                ? 'eva:arrow-ios-upward-fill'
                : 'eva:arrow-ios-downward-fill'
            }
            sx={{ ml: -0.5 }}
          />
        }
      >
        <Typography>
          {!!filters.startDate && !!filters.endDate
            ? shortDateLabel(filters.startDate, filters.endDate)
            : (translate('global.selectDate') as string)}
        </Typography>
      </Button>

      <CustomDateRangePicker
        title={translate('global.selectDateRange') as string}
        variant="calendar"
        startDate={filters.startDate}
        endDate={filters.endDate}
        onChangeStartDate={handleFilterStartDate}
        onChangeEndDate={handleFilterEndDate}
        open={openDateRange}
        onClose={onCloseDateRange}
        selected={!!filters.startDate && !!filters.endDate}
        error={dateError}
      />
    </>
  );

  return (
    <Stack
      spacing={1}
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      sx={{ width: 1 }}
    >
      {renderFilterName}
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        flexGrow={1}
      >
        {renderFilterDate}
        {renderFilterType}
      </Stack>
    </Stack>
  );
}
