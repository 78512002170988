import { deDE, itIT, enUS, frFR } from '@mui/material/locale';
import { PATH_DASHBOARD, PATH_PAGE } from './routes/paths';

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.home;
export const PATH_REGISTER = PATH_PAGE.pensionProvision;

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
};

export type Language = {
  value: string;
  label: string;
  systemValue: any;
  flag: string;
};

export const allLangs: Language[] = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    flag: '/assets/icons/ic_flag_en.svg',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    flag: '/assets/icons/ic_flag_fr.svg',
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    flag: '/assets/icons/ic_flag_de.svg',
  },
  {
    label: 'Italiano',
    value: 'it',
    systemValue: itIT,
    flag: '/assets/icons/ic_flag_it.svg',
  },
];

export const defaultLang = allLangs[2];
