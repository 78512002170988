const agb = {
  title: 'Condizioni Generali di Contratto',
  scopeOfApp: {
    title: 'Ambito di Applicazione',
    content: {
      p1: "Oggetto di queste Condizioni Generali di Contratto (CGC) è l'utilizzo dei servizi di gut geregelt AG (di seguito denominata gut geregelt). gut geregelt mette a disposizione del cliente i servizi a pagamento per la durata del contratto nella versione attuale.",
      p2: "Il rapporto contrattuale tra gut geregelt e il cliente si instaura con l'accettazione dell'offerta. Con l'accettazione dell'offerta, il cliente riconosce espressamente le presenti CGC, anche se sono in tutto o in parte in contrasto con le sue condizioni generali.",
      p3: "Il cliente è tenuto a fornire in ogni momento informazioni esatte, complete e aggiornate. Il mancato rispetto di tale obbligo costituisce una violazione delle condizioni d'uso, che può portare alla risoluzione immediata del contratto.",
    },
  },
  complience: {
    title: 'Conformità alle CGC',
    content: {
      p1: "Utilizzando la piattaforma e i servizi di gut geregelt, l'utente accetta espressamente queste CGC e i loro componenti integranti, come in particolare l'informativa sulla privacy. L'utente si impegna a rispettare le presenti CGC e i loro componenti integranti nell'ambito dell'utilizzo dei servizi.",
    },
  },
  yourAccount: {
    title: 'Il tuo Account',
    content: {
      p1: "Gli utenti di gut geregelt sono titolari di account. Accettano quanto segue: (1) scelgono una password sicura, la mantengono segreta e la trattano in modo confidenziale; (2) non trasferiscono il proprio account ad altri (ad esempio a familiari o altri contatti). Sono responsabili di tutte le azioni effettuate tramite il proprio account, a meno che non abbiano chiuso l'account o segnalato un abuso.",
    },
  },
  payment: {
    title: 'Pagamento',
    content: {
      p1: 'Quando si acquistano i nostri servizi a pagamento, si accettano le condizioni aggiuntive per questi servizi a pagamento e si accetta di pagare le relative tariffe e imposte. In caso di mancato pagamento di queste tariffe, i servizi a pagamento verranno interrotti. Inoltre, si accetta quanto segue:',
      subP1:
        'Possiamo memorizzare e addebitare il metodo di pagamento (ad es. carta di credito), anche dopo la sua scadenza, per evitare interruzioni dei nostri servizi e per utilizzarlo per il pagamento di altri servizi che si potrebbero acquistare.',
      subP2:
        "Se si acquista un abbonamento, il metodo di pagamento verrà automaticamente addebitato all'inizio di ogni periodo di abbonamento con le tariffe e le imposte applicabili per quel periodo. Si prega di annullare prima della data di rinnovo per evitare costi futuri. Informarsi su come annullare o sospendere i servizi di abbonamento.",
      subP3:
        "Calcoliamo le imposte da pagare in base ai dati di fatturazione forniti al momento dell'acquisto.",
    },
  },
  termination: {
    title: 'Risoluzione',
    content: {
      p1: "gut geregelt ha il diritto di risolvere il contratto di utilizzo in qualsiasi momento senza fornire motivazioni in determinati casi. Un tale caso e motivo per una risoluzione immediata del contratto di utilizzo è in particolare una violazione da parte dell'utente di queste CGC, in caso di sospetto di abuso o se la sicurezza del sistema non è più garantita. Per gli account gratuiti, gut geregelt si riserva il diritto di risolvere e sciogliere il contratto di utilizzo se l'utente non vi accede per più di 12 mesi consecutivi. gut geregelt informerà l'utente in anticipo via e-mail all'indirizzo registrato nel suo account. La risoluzione verrà comunicata all'utente 30 giorni prima del blocco del suo account (\"periodo di preavviso\"), in modo che possa esportare i dati memorizzati sulla piattaforma nel frattempo. Dopo la scadenza del periodo di preavviso, gut geregelt ha il diritto di cancellare irrevocabilmente l'account dell'utente e i dati in esso contenuti.",
    },
  },
  rightOfUse: {
    title: 'Diritti di utilizzo',
    content: {
      p1: "gut geregelt concede al cliente il diritto non esclusivo e non trasferibile di utilizzare i servizi di gut geregelt secondo le modalità previste per la durata del contratto. Questo include la creazione e la gestione di documenti per l'uso personale del cliente.",
      p2: 'Il cliente non è autorizzato a utilizzare il servizio per generare documenti per utenti non registrati o a rendere il servizio disponibile a terzi, gratuitamente o a pagamento.',
      p3: "Il cliente si impegna a limitare l'uso del servizio agli utenti registrati.",
    },
  },
  dataUsePartner: {
    title: 'Uso dei dati da parte delle organizzazioni partner',
    content: {
      p1: "Le organizzazioni partner di gut geregelt possono accedere a determinati dati dei loro clienti nell'ambito dei loro servizi. Il tipo di dati condivisi è indicato nelle impostazioni sotto la scheda 'Dati'.",
      p2: 'Il cliente ha la possibilità in qualsiasi momento di controllare il trasferimento dei dati alle organizzazioni partner nelle impostazioni e di disattivarlo, se necessario.',
      p3: "gut geregelt si impegna a limitare l'uso dei dati da parte delle organizzazioni partner allo stretto necessario e a proteggere i diritti dei clienti in materia di protezione dei dati.",
    },
  },
  dataProtection: {
    title: 'Protezione e sicurezza dei dati',
    content: {
      p1: "gut geregelt tratterà i dati del cliente con la massima cura e li proteggerà da abusi e perdite. Il cliente è informato che alcuni dati possono essere condivisi con organizzazioni partner nell'ambito dell'utilizzo del servizio. I dettagli sul tipo di dati condivisi e sulle opzioni di controllo si trovano nelle impostazioni dell'account utente. gut geregelt adotta misure tecniche e organizzative conformi ai requisiti applicabili del GDPR. Nell'ambito delle possibilità tecniche, è possibile stipulare accordi specifici per l'archiviazione dei dati in determinati paesi. Il cliente è responsabile della legalità del trasferimento o dell'uso dei dati. Tutti i dati dei clienti archiviati e trattati da gut geregelt sono di esclusiva proprietà del cliente e vengono utilizzati da gut geregelt esclusivamente per l'esecuzione del contratto.",
    },
  },
  support: {
    title: 'Supporto',
    content: {
      p1: 'Il supporto di gut geregelt è disponibile per il cliente dal lunedì al venerdì dalle 8:00 alle 12:00 e dalle 13:00 alle 17:00 via e-mail a support@gutgeregelt.ch.',
    },
  },
  intellectualPropertyRights: {
    title: 'Diritti di Proprietà Intellettuale',
    content: {
      p1: "Il cliente riconosce i diritti di proprietà intellettuale, in particolare il diritto d'autore, di gut geregelt sui servizi software e sulla documentazione. Il cliente non è autorizzato a mettere a disposizione di terzi questo software a pagamento o gratuitamente, né a noleggiarlo e/o utilizzarlo al di fuori del contesto del rapporto contrattuale con gut geregelt o contestare gut geregelt in qualsiasi forma.",
    },
  },
  confidentiality: {
    title: 'Riservatezza',
    content: {
      p1: 'gut geregelt non può divulgare o rendere accessibili a terzi informazioni e/o materiali riservati senza il consenso scritto esplicito del cliente.',
      p2: 'gut geregelt si impegna a non utilizzare e/o mettere a disposizione di terzi le informazioni/materiali riservati di cui è venuta a conoscenza senza il consenso scritto esplicito del cliente.',
      p3: "Le informazioni riservate devono essere utilizzate da gut geregelt esclusivamente in relazione e per la fornitura del servizio. gut geregelt può divulgare informazioni riservate ai propri dipendenti solo se necessario per l'esecuzione del progetto sopra menzionato. I dipendenti devono essere vincolati alla riservatezza in conformità con questo accordo. L'obbligo di riservatezza si applica anche alle società affiliate a gut geregelt. gut geregelt le vincolerà anche alla riservatezza.",
      p4: 'gut geregelt può divulgare informazioni riservate se richiesto dalla legge o da altre normative statali. In questi casi, gut geregelt informerà immediatamente il cliente e, su richiesta del cliente, adotterà le misure di protezione necessarie, nella misura in cui siano ragionevoli. gut geregelt adotterà misure adeguate per proteggere le informazioni e i materiali riservati da accessi non autorizzati da parte di terzi.',
    },
  },
  dataProtectionAndSecurity: {
    title: 'Protezione dei Dati e Sicurezza',
    content: {
      p1: "gut geregelt tratterà i dati del cliente con la massima cura e li proteggerà da abuso e perdita. A tal fine, gut geregelt adotterà misure tecniche e organizzative che soddisfano i requisiti della GDPR. Accordi speciali per la memorizzazione dei dati in determinati paesi possono essere presi nell'ambito delle possibilità tecniche.",
      p2: 'Il cliente è responsabile della legalità della trasmissione dei dati e del loro utilizzo. Tutti i dati del cliente memorizzati e trattati da gut geregelt sono di esclusiva proprietà del cliente e vengono utilizzati da gut geregelt esclusivamente per scopi di adempimento contrattuale.',
    },
  },
  disclaimer: {
    title: 'Esclusione di Responsabilità',
    content: {
      p1: 'gut geregelt non offre consulenza legale, ma fornisce modelli adattati. In questioni complesse, è essenziale consultare un avvocato. gut geregelt non sostituisce la consulenza legale.',
      p2: "La responsabilità di gut geregelt è esclusa nella misura consentita dalla legge, in particolare la responsabilità per colpa lieve e media, danni indiretti o consequenziali, in particolare perdita di profitto, risparmi non realizzati, interruzione dell'attività, richieste di terzi, danni consequenziali o danni da perdita di dati, nonché la responsabilità per ausiliari e terzi coinvolti. Un'eventuale responsabilità di gut geregelt è limitata al massimo all'importo della tariffa di utilizzo addebitata all'utente da gut geregelt per l'utilizzo della piattaforma e dei servizi per un periodo di 12 mesi.",
    },
  },
  applicableLaw: {
    title: 'Legge Applicabile',
    content: {
      p1: "Tutti i contratti tra gut geregelt e l'utente sono soggetti esclusivamente al diritto svizzero, con esclusione del diritto internazionale privato, della Convenzione delle Nazioni Unite sui contratti di vendita internazionale di merci (CISG) e del diritto internazionale privato.",
    },
  },
  jurisdiction: {
    title: 'Foro Competente',
    content: {
      p1: "Il foro competente per tutte le controversie, divergenze o reclami derivanti o in relazione al rapporto contrattuale tra gut geregelt e l'utente, inclusa la sua validità, invalidità, violazione o risoluzione, è la sede di gut geregelt.",
    },
  },
};

export default agb;
