const lastWillLanding = {
  mainBanner: {
    intro: 'Create your last will online:',
    title: 'Legally secure, done in 10 minutes',
    subtitle:
      'Taking proactive steps to decide what will happen to your estate helps ensure your last wishes are met and makes the process easier for your family.',
    buttonLabel: 'Create last will',
  },
  youtubeTutorial: 'Why is a last will important?',
  aboutCards: {
    whoGetsWhat: {
      title: 'Who gets what',
      description:
        'A last will specifies who receives what, allowing you to deviate from the default legal succession rules.',
    },
    enforceWishes: {
      title: 'Enforce your wishes',
      description:
        'Ensure that your assets are distributed according to your personal wishes and preferences.',
    },
    clarificationForFamily: {
      title: 'Clarity for the family',
      description:
        'Avoid unnecessary disputes and provide clarity for your loved ones.',
    },
  },
  subBanner: {
    title: 'Well prepared with a legally compliant last will',
    subtitle:
      'With "gut geregelt," you can quickly create your last will, receive legal advice on sensitive issues or questions, and protect for your loved ones.',
    buttonLabel: 'Create for free now',
  },
  newsletter: {
    title: 'Get organized with your last will',
    buttonLabel: 'Create now',
  },
  faq: {
    questions: {
      question1: 'Why should I have a last will?',
      question2: 'Are there different ways to create a last will?',
      question3: 'What does the legal inheritance include?',
      question4:
        'What changed with the inheritance law reform on January 1, 2023?',
      question5:
        'Which parts of my estate can I freely distribute in my last will?',
      question6: 'What happens if I die without a last will?',
      question7: 'Can I draft a joint last will with my spouse?',
      question8: 'Can I leave assets to people outside my family?',
      question9: 'Can I disinherit someone?',
      question10: 'Do I need to appoint an executor?',
      question11: 'Can I change my last will at any time?',
      question12:
        'Are there other documents I should prepare besides my last will?',
      question13: 'How does "gut geregelt" help with my last will?',
    },
    answers: {
      answer1:
        'With a last will, you control your estate by specifying how your assets are distributed after your death. For instance, if you live in a common-law relationship and want your partner to inherit, you must include this in your last will.',
      answer2:
        'Yes, there is a handwritten last will, a notarized last will, and an oral last will.',
      answer3:
        'The legal inheritance includes a reserved portion to ensure that certain heirs, such as spouses or children, cannot be completely excluded from the inheritance.',
      answer4:
        'The most significant change is that the reserved portion for parents has been removed, and for children of unmarried testators, the reserved portion has been reduced from 75% to 50%.',
      answer5:
        'Besides the reserved portions, you can decide freely on the distribution of the rest of your estate. This allows, for example, more of your estate to go to a spouse or registered partner.',
      answer6:
        'If you pass away without a last will, the default legal succession applies, and your estate will be divided according to your family situation at the time of death.',
      answer7: 'No, you cannot draft a joint last will with your spouse.',
      answer8:
        'Yes, beyond the reserved portions, you are free to designate beneficiaries, including charities or organizations.',
      answer9:
        'As long as the heir is not entitled to a reserved portion, you may disinherit them.',
      answer10:
        'Appointing an executor is optional but can help prevent inheritance disputes.',
      answer11:
        'Yes, you may make changes at any time, but remember to date and sign each update.',
      answer12:
        "Yes, it's advisable to also prepare a {{powerOfAttorney}} and {{livingWill}} as soon as possible.",
      answer13:
        'At "gut geregelt," we assist you in smoothly and legally creating your last will.',
    },
    hyperlinks: {
      lastWill: 'Last will',
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Last Will',
          description:
            'With a last will, you determine what happens to your assets in the event of your death. You can ensure that your spouse and family are protected and that your will is respected.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A last will gives you the security that your assets will be distributed according to your wishes and that your spouse is legally protected.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Protection of the spouse',
              'Clear inheritance regulations',
              'Avoidance of disputes',
            ],
          },
        ],
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Last Will',
          description:
            'With a last will, you determine how your assets are divided in the event of your death. You can ensure that your child is financially secure and that your wishes are respected.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A last will gives you the security that your child is well cared for in the event of your death and that your assets are distributed according to your wishes.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Financial security for partner and family',
              'Legal clarity about inheritance',
              'Protection of family assets',
            ],
          },
        ],
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Last Will',
          description:
            'With a last will, you determine how your assets are divided after your death. You ensure that your loved ones are protected according to your wishes.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'In retirement, it is important to take care of estate planning to avoid inheritance disputes and protect your family.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Clarity about the distribution of assets',
              'Protection of the family',
              'Avoidance of disputes',
            ],
          },
        ],
      },
    },
    krankheit: {
      content: {
        main: {
          title: 'Last Will',
          description:
            'With a last will, you determine how your assets are divided after your death. This ensures that your loved ones are protected and that your last will is respected.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A serious diagnosis makes it clear how quickly life can change. A last will ensures that your family is financially secure and that disputes are avoided.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Protection of your family',
              'Clarity about inheritance distribution',
              'Protection of assets',
            ],
          },
        ],
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Last Will',
          description:
            'With a last will, you regulate what happens to your company and assets in the event of your death. This ensures that your inheritance is managed according to your wishes and your company is continued.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'Especially as a company founder, it is important to take precautions early on. With a last will, you secure the continued existence of your company and prevent legal disputes.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Clear inheritance regulations for the company',
              'Avoidance of disputes',
              'Protection of family assets and company value',
            ],
          },
        ],
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Last Will',
          description:
            'With a last will, you determine how your assets are divided in the event of your death. Especially after a divorce, you should ensure that your inheritance regulations reflect your new living circumstances.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A divorce changes your family and financial circumstances. A (updated) last will ensures that your wishes are respected even after the separation.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Clarity about the distribution of your assets',
              'Protection of your loved ones',
              'Adaptation to the changed family situation',
            ],
          },
        ],
      },
    },
  },
};

export default lastWillLanding;
