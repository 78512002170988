const agb = {
  title: 'General terms and conditions',
  scopeOfApp: {
    title: 'Scope of application',
    content: {
      p1: 'The subject of these General Terms and Conditions (GTC) is the use of the services of gut geregelt AG (hereinafter referred to as gut geregelt). gut geregelt provides the customer with the services for use for the duration of the contract in the current version for a fee.',
      p2: `The contractual relationship between gut geregelt and the customer is established upon acceptance of the offer. By accepting the offer, the customer expressly acknowledges these GTC, even if they contradict the customer's own terms and conditions in whole or in part.`,
      p3: 'The customer is obliged to provide accurate, complete, and up-to-date information at all times. Failure to comply with this requirement constitutes a breach of the terms of use, which may result in immediate termination of the contract.',
    },
  },
  complience: {
    title: 'Compliance with the GTC',
    content: {
      p1: 'By using the gut geregelt platform and services, the user expressly agrees to these GTC and their integral components, such as the privacy policy. The user undertakes to comply with these GTC and their integral components when using the services.',
    },
  },
  yourAccount: {
    title: 'Your account',
    content: {
      p1: 'gut geregelt users are account holders. They agree to the following: (1) You choose a secure password, keep it secret, and treat it confidentially; (2) You do not transfer your own account to others (e.g., family members or other contacts). You are responsible for all actions taken through your user account unless you have closed the account or reported misuse.',
    },
  },
  payment: {
    title: 'Payment',
    content: {
      p1: 'When you purchase our paid services, you agree to the additional terms for these paid services and agree to pay the applicable fees and taxes to us. If these fees are not paid, your paid services will be terminated. Furthermore, you agree to the following:',
      subP1:
        'We may store and charge your payment method (e.g., credit card), even after it has expired, to avoid interruption of our services and to use it for payment of other services you may purchase.',
      subP2:
        'If you purchase a subscription, your payment method will be automatically charged at the beginning of each membership period with the fees and taxes due for that period. Please cancel before the renewal date to avoid future charges. Learn how to cancel or suspend your subscription services.',
      subP3:
        'We calculate the taxes you owe based on the billing information you provide to us at the time of purchase.',
    },
  },
  termination: {
    title: 'Termination',
    content: {
      p1: `gut geregelt is entitled to terminate the user contract at any time without giving reasons in certain cases. Such a case and reason for immediate termination of the user contract is in particular a breach of these GTC by the user, suspicion of misuse, or if the security of the system is no longer guaranteed. For free accounts, gut geregelt reserves the right to terminate and dissolve the user contract if the user does not access it for more than 12 consecutive months. gut geregelt will inform the user in advance by email to the address stored in their account. The termination will be announced to the user 30 days before their account is blocked ("notice period"), so that they can export the data they have stored on the platform in the meantime. After the notice period has expired, gut geregelt is entitled to irrevocably delete the user's account and the data stored therein.`,
    },
  },
  rightOfUse: {
    title: 'Rights of use',
    content: {
      p1: "gut geregelt grants the customer the non-exclusive and non-transferable right to use the services of gut geregelt as intended for the duration of the contract. This includes the creation and management of documents for the customer's own use.",
      p2: 'The customer is not entitled to use the service to generate documents for non-registered users or to make the service available to third parties for a fee or free of charge.',
      p3: 'The customer undertakes to restrict the use of the service to registered users.',
    },
  },
  support: {
    title: 'Support',
    content: {
      p1: 'The gut geregelt support is available to the customer from Monday to Friday from 8:00 am to 12:00 pm and 1:00 pm to 5:00 pm via email at support@gutgeregelt.ch.',
    },
  },
  intellectualPropertyRights: {
    title: 'Intellectual property rights',
    content: {
      p1: 'The customer acknowledges the intellectual property rights, in particular the copyright of gut geregelt on software services and documentation. The customer is not entitled to make this software available to third parties for a fee or free of charge, to sublet it, and/or to use it outside the framework of the contractual relationship with gut geregelt or to dispute gut geregelt in any form.',
    },
  },
  confidentiality: {
    title: 'Confidentiality',
    content: {
      p1: 'gut geregelt may not disclose confidential information and/or materials to third parties or make them accessible to third parties directly or indirectly without the express written consent of the customer.',
      p2: 'gut geregelt undertakes not to use the confidential information/materials known to it without the express written consent of the customer and/or to make them available to third parties.',
      p3: 'gut geregelt may use confidential information exclusively in connection with and for the provision of the contractually agreed services. Disclosure of this information to employees, representatives and consultants of gut geregelt is only permitted if this is necessary for the performance of the agreed services. All persons who receive access to this information must be bound to confidentiality accordingly.',
      p4: "gut geregelt may disclose confidential information if required by law or other government regulations. In these cases, gut geregelt will inform the customer immediately and, at the customer's request, take the necessary protective measures, as far as these are reasonable. gut geregelt takes appropriate precautions to protect confidential information and materials from unauthorized access by third parties.",
    },
  },
  dataProtectionAndSecurity: {
    title: 'Data protection and security',
    content: {
      p1: `gut geregelt will treat the customer's data with the utmost care and protect it from misuse and loss. To this end, gut geregelt takes technical and organizational measures that meet the current requirements of the GDPR. Special agreements on the storage of data in certain countries can be made within the framework of technical possibilities.`,
      p2: 'The customer is responsible for the legality of the data transfer or its use. All data of the customer stored and processed by gut geregelt are the exclusive property of the customer and are used by gut geregelt exclusively for the purpose of fulfilling the contract.',
    },
  },
  dataUsePartner: {
    title: 'Data use by partner organizations',
    content: {
      p1: "Partner organizations of well regulated can access certain data of their customers as part of their services. The type of data shared is listed in the settings under the 'Data' tab.",
      p2: 'The customer has the option at any time to control the transfer of data to partner organizations in the settings and to deactivate it if necessary.',
      p3: 'gut geregelt undertakes to limit the use of data by partner organizations to the extent necessary and to protect the data protection rights of customers.',
    },
  },
  disclaimer: {
    title: 'Disclaimer',
    content: {
      p1: 'gut geregelt does not provide legal advice but provides legal templates. In complex matters, the involvement of a lawyer is essential. gut geregelt does not replace legal advice.',
      p2: 'The liability of gut geregelt is, as far as legally permissible, fully excluded, in particular the liability for slight and medium negligence, indirect or consequential damages, in particular lost profits, unrealized savings, business interruption, claims of third parties, consequential damages or damages from data loss as well as the liability for auxiliary persons and third parties involved. Any liability of gut geregelt is limited to the amount of the usage fee charged to the user by gut geregelt for the use of the platform and services for a period of 12 months.',
    },
  },
  applicableLaw: {
    title: 'Applicable law',
    content: {
      p1: 'All contracts between gut geregelt and the user are subject exclusively to Swiss law, excluding conflict of laws, the United Nations Convention on Contracts for the International Sale of Goods (CISG), and international private law.',
    },
  },
  jurisdiction: {
    title: 'Jurisdiction',
    content: {
      p1: 'The place of jurisdiction for all disputes, disagreements, or claims arising from or in connection with the contractual relationship between gut geregelt and the user, including its validity, invalidity, breach, or dissolution, is the seat of gut geregelt.',
    },
  },
};

export default agb;
