const guest = {
  title: 'Previdenza di {{first_name}} {{last_name}}',
  description:
    'Finché il tuo ospite è in vita, hai accesso limitato ai dati e alle informazioni.',
  seeWhatsIncluded: 'vedi cosa è incluso',
  includedTooltip:
    'Con l\'accesso ospite, queste persone hanno accesso solo al testamento biologico e al mandato precauzionale.',
  reportDeath: 'Segnala decesso',
  reportDeathDescription:
    'Per avere pieno accesso a tutti i documenti e le informazioni di {{first_name}}, dobbiamo purtroppo prima confermare il decesso e abbiamo bisogno del certificato di morte ufficiale e del certificato di esecutore testamentario (entrambi ottenibili presso il comune).',
  alreadyReported: 'Già segnalato',
};

export default guest;