const outputDoc = {
  title: 'Direttiva Anticipata',
  from: 'da ',
  born: 'nato il ',
  civilStatus: {
    single: 'Celibe/Nubile',
    married: 'Sposato/a',
    widowed: 'Vedovo/a',
    divorced: 'Divorziato/a',
    registered_partnership: 'Unione registrata',
  },
  civil_status: 'Stato civile: {{civil_status}}',
  address: 'Indirizzo: {{address}}',
  declare: 'Io, {{first_name}} {{last_name}}, dichiaro quanto segue:  ',
  paragraph1:
    'Nel caso in cui diventi incapace di intendere e di volere a causa di malattia o incidente, con questa direttiva anticipata stabilisco la mia volontà giuridicamente vincolante e i miei desideri riguardo al trattamento medico, assistenza, morte e decesso. Come persona/e autorizzata/e a rappresentarmi, incarico la/le seguente/i persona/e: ',
  paragraph2:
    'La persona autorizzata a rappresentarmi, in caso di mia incapacità di intendere e di volere, attua la mia volontà stabilita e prende decisioni mediche e assistenziali per me. Deve essere informata sul mio stato di salute e sull\'evoluzione prevista della malattia. Esento espressamente il team di cura dal segreto professionale, nella misura in cui ciò sia necessario per una decisione imminente. La persona autorizzata deve essere coinvolta, se possibile, prima che vengano prese decisioni mediche. Se questa direttiva anticipata non regola una questione, la persona autorizzata decide secondo la mia presunta volontà.',
  paragraph3:
    'La persona indicata ha anche il diritto di visionare la documentazione del paziente e un eventuale referto autoptico dopo la mia morte. ',
  repInformParagraph:
    'Ho discusso l\'incarico con la persona autorizzata a rappresentarmi il {{representative_inform_date}}. ',
  secondaryRepParagraph:
    'Nel caso in cui la persona incaricata secondo il punto 1 non possa assumere il suo compito, nomino come persona sostituta:  ',
  motivation: {
    title: 'Motivazione e atteggiamento di base',
    paragraph1: 'Emetto questa direttiva anticipata ',
    takePrecautions: 'in buona salute, ma con pianificazione lungimirante.',
    makeProvisions:
      'con un certo quadro clinico che mi porta a prendere precauzioni.',
    makeProvisionsForFuture:
      'dopo aver raggiunto una certa età, dove devono essere prese anche precauzioni a breve termine.',
    paragraph2:
      'Con questa direttiva anticipata voglio soprattutto raggiungere che ',
    alleviateSuffering:
      'le possibilità mediche siano utilizzate principalmente per alleviare la mia sofferenza. L\'estensione della mia vita a tutti i costi non è una priorità per me. Accetto che la rinuncia al trattamento medico possa accorciare la mia vita.',
    exhaustedMedicalPossibilities:
      'tutte le possibilità mediche per mantenere la mia vita siano esaurite. La mia sofferenza deve essere alleviata il più possibile. Accetto che ciò possa comportare oneri (ad es. coscienza offuscata).',
  },
  medical: {
    title: 'Disposizioni mediche / Obiettivo del trattamento ',
    paragraph1:
      'Se mi trovo in una delle seguenti situazioni, mi aspetto dal team di cura il seguente comportamento:',
    exhaustedMedicalPossibilities:
      'Desidero soprattutto un trattamento efficace del dolore e di altri sintomi come l\'ansia. Accetto che ciò possa comportare oneri (ad es. coscienza offuscata).',
    alleviateSuffering:
      'Desidero soprattutto mantenere la coscienza e la mia capacità di comunicare. Accetto che ciò possa compromettere l\'ottimale alleviamento del dolore e di altri sintomi come l\'ansia.',
  },
  forego_measures: {
    yes: 'Rinuncio a tutte le misure che comportano solo un prolungamento della vita e della sofferenza, se divento incapace di intendere e di volere a causa di un evento acuto e imprevisto e se, dopo una valutazione medica approfondita, è impossibile o improbabile che recuperi mai la mia capacità di intendere e di volere. ',
    no: 'Voglio che tutte le misure possibili siano attuate, se divento incapace di intendere e di volere a causa di un evento acuto e imprevisto e anche se, dopo una valutazione medica approfondita, è impossibile o improbabile che recuperi mai la mia capacità di intendere e di volere. ',
  },
  revive_preference: {
    paragraph: 'In caso di arresto cardiaco o respiratorio, deve',
    yes: 'essere effettuata',
    no: 'non essere effettuata',
    paragraph1: 'la rianimazione.',
  },
  moreDetails: {
    treatment_goal_sudden_incapacity: {
      paragraph: 'In caso di incapacità improvvisa (emergenza) ',
      resuscitationAndTreatment:
        'desidero una rianimazione e un trattamento in terapia intensiva (obiettivo del trattamento: prolungamento della vita). ',
      treatedInIntensiveCareUnit:
        'non desidero una rianimazione, ma un trattamento in terapia intensiva. ',
    },
    suffering_relief_sudden_incapacity: {},
    treatment_goal_prolonged_incapacity: {
      paragraph: 'In caso di incapacità prolungata ',
      resuscitationAndTreatment:
        'desidero una rianimazione e un trattamento in terapia intensiva (obiettivo del trattamento: prolungamento della vita). ',
      treatedInIntensiveCareUnit:
        'non desidero una rianimazione, ma un trattamento in terapia intensiva.',
    },
    treatment_goal_permanent_incapacity: {
      paragraph: 'In caso di incapacità permanente ',
      resuscitationAndTreatment:
        'desidero una rianimazione e un trattamento in terapia intensiva (obiettivo del trattamento: prolungamento della vita). ',
      treatedInIntensiveCareUnit:
        'non desidero una rianimazione, ma un trattamento in terapia intensiva. ',
    },
  },
  revocation: {
    title: 'Revoca',
    paragraph1:
      'Revoco con la presente tutte le direttive anticipate di data precedente.',
  },
  abilityToConsent: {
    title: 'Capacità di agire ',
    paragraph1:
      'Rilascio questa dichiarazione dopo attenta considerazione e nella piena responsabilità di me stesso, nonché nella consapevolezza che, per quanto riguarda le misure mediche, i miei medici, tutori o rappresentanti sono vincolati alla decisione della persona incaricata.',
    informedRepresentative:
      'Ho discusso questa direttiva con le persone menzionate all\'inizio della prima pagina, che possono confermare che, a loro avviso, ero capace di intendere e di volere in quel momento e che il contenuto corrisponde alla mia volontà. ',
  },
};

export default outputDoc;