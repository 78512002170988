const digitalAssets = {
  introHead: 'Ensure a clean digital footprint',
  introDescription:
    'Make sure your heirs have access to important data and define who inherits what.',
  passwordWarning: 'Never share passwords directly with gut geregelt!',
  passwordWarningTooltip:
    'Please never give passwords directly to gut geregelt. Only provide information about where relatives can find an overview of your passwords.',
  downloadTemplate: 'Download template for secure password storage',
  requestOtherPasswordManager: 'Request other password manager',
  addItems: 'Add items manually',
  downloadList: 'Download list',
  addItem: 'Add item',
  yourPhoneNumber: 'Your phone number',

  afterDeathOptions: {
    delete: 'Delete',
    transfer: 'Transfer',
    inherit: 'Inherit',
  },
  assetTypes: {
    email: 'Email',
    social_media_account: 'Social media',
    membership: 'Membership',
    subscription: 'Subscription',
    domain: 'Domain',
    royalty: 'Royalties',
    crypto_wallet: 'Crypto wallet',
    device: 'Device',
    password_manager: 'Password manager',
    phone_provider: 'Phone provider',
    other: 'Other',
  },

  assetsTable: {
    tableHeads: {
      name: 'Name',
      access: 'Access',
      type: 'Type',
      after_death: 'After death',
      beneficiary: 'Beneficiary',
    },
  },

  confirmDeleteModal: {
    title:
      'Are you sure you want to delete {{asset}} from your digital assets?',
    description: 'You can add it again at any time later.',
    onClose: 'Close',
    onConfirm: 'Delete',
  },
};

export default digitalAssets;
