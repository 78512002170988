const livingWillLanding = {
  mainBanner: {
    intro: 'Crea una direttiva del paziente online:',
    title: 'Legalmente valido, completato in 5 minuti',
    subtitle:
      'Nella direttiva del paziente, annoti per iscritto a quali trattamenti medici acconsenti o rifiuti, nel caso in cui non fossi più in grado di prendere decisioni per te stesso.',
    buttonLabel: 'Crea direttiva del paziente',
  },
  youtubeTutorial: 'Perché è importante una direttiva del paziente?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Misure preventive',
      description:
        'Se sei incapace di agire, la direttiva del paziente assicura che tu venga trattato secondo i tuoi desideri.',
    },
    wishesYouCanAdapt: {
      title: 'Desideri adattabili',
      description:
        'Ogni persona con capacità decisionale può creare una direttiva del paziente e modificarla o revocarla in qualsiasi momento.',
    },
    legalSecurity: {
      title: 'Alleviare i familiari',
      description:
        'La tua previdenza offre tranquillità ai tuoi familiari in caso di emergenza e li solleva da decisioni difficili.',
    },
  },
  subBanner: {
    title: 'Ben regolato con una direttiva del paziente conforme alla legge',
    subtitle:
      'Con gut geregelt, puoi creare rapidamente la tua direttiva del paziente, ricevere consulenza legale su temi delicati o domande aperte e fare un favore ai tuoi cari.',
    buttonLabel: 'Crea ora gratuitamente',
  },
  newsletter: {
    title: 'Diventa ben regolato con la tua direttiva del paziente',
    buttonLabel: 'Crea ora',
  },
  faq: {
    questions: {
      question1: "Cos'è una direttiva del paziente e a cosa serve?",
      question2:
        'Quali sono i requisiti per la creazione di una direttiva del paziente?',
      question3: 'Quando entra in vigore una direttiva del paziente?',
      question4: 'Cosa può prevenire la direttiva del paziente?',
      question5:
        'Come funzionano le persone di rappresentanza definite nella direttiva del paziente?',
      question6:
        'Una copia della direttiva del paziente è valida in caso di applicazione?',
      question7:
        'Devo far autenticare la mia direttiva del paziente da un notaio?',
      question8:
        'Una terza persona può compilare la direttiva del paziente per me?',
      question9:
        'Cosa faccio se non posso firmare da solo (cecità, SM o Parkinson)?',
      question10: "La mia direttiva del paziente è valida anche all'estero?",
      question11:
        'Posso anche disporre di non essere rianimato in caso di emergenza?',
      question12:
        'Devo indicare una persona di rappresentanza? Cosa succede se non posso/voglio indicare una persona di rappresentanza?',
      question13:
        'Devo informare in anticipo la mia persona di rappresentanza?',
      question14:
        'Il mio coniuge è automaticamente la mia persona di rappresentanza?',
      question15:
        'Posso nominare il mio figlio minorenne come persona di rappresentanza?',
      question16:
        'Se mi trasferisco o mi sposo, devo adattare la mia direttiva del paziente?',
      question17:
        'Dovrei consegnare una copia della direttiva del paziente al mio medico di base?',
      question18:
        'La mia direttiva del paziente online è pubblicamente accessibile?',
      question19:
        'Cosa succede se la mia direttiva del paziente non viene attuata?',
      question20: 'Come posso garantire la validità?',
      question21:
        'Ci sono altri documenti che dovrei creare oltre alla direttiva del paziente?',
      question22:
        'Come mi aiuta gut geregelt con la mia direttiva del paziente?',
    },
    answers: {
      answer1:
        "Una direttiva del paziente è un'espressione di volontà unilaterale e scritta in stato di capacità di giudizio. In essa fornisci istruzioni mediche a medici e infermieri nel caso in cui in un momento successivo non fossi più in grado di giudicare o esprimerti.",
      answer2: 'Capacità di giudizio in relazione alle decisioni mediche.',
      answer3:
        'Una direttiva del paziente entra in vigore quando si verificano due fattori: incapacità di giudizio e prognosi infausta. Una tale situazione può verificarsi, ad esempio, in caso di grave ictus, demenza avanzata, tumore cerebrale in stadio avanzato, coma o stato vegetativo dopo incidente, operazione o rianimazione.',
      answer4:
        'che le misure mediche prolunghino la morte naturale, che vengano effettuati tentativi di rianimazione, che le macchine mantengano la vita senza prospettive di miglioramento, alimentazione artificiale.',
      answer5:
        "Tutte le persone di rappresentanza nominate nella direttiva del paziente diventano procuratori nell'ordine in cui sono nominate. Sono autorizzate a prendere decisioni mediche a nome della persona che dispone, se si verifica una situazione non regolata nella direttiva del paziente. In caso di conflitto, la legge prevede che ogni persona vicina alla persona incapace di giudizio possa rivolgersi all'autorità di protezione degli adulti.",
      answer6:
        'Sì, una copia può essere utilizzata in caso di applicazione. Tuttavia, raccomandiamo che almeno una persona di rappresentanza conosca il luogo di conservazione dei documenti originali.',
      answer7:
        "No. Dall'entrata in vigore della nuova legge sulla protezione degli adulti nel 2013, una direttiva del paziente redatta in stato di capacità di giudizio è legalmente vincolante.",
      answer8:
        'Sì, a condizione che tu comprenda il contenuto e alla fine aggiunga autonomamente data e firma a mano o possa firmare elettronicamente la direttiva del paziente in modo qualificato.',
      answer9: 'Fai autenticare la tua direttiva del paziente da un notaio.',
      answer10:
        "gut geregelt può intervenire solo all'interno della Svizzera per garantire il rispetto di una direttiva del paziente. In che misura una direttiva del paziente sia vincolante all'estero deve essere verificato nel paese specifico.",
      answer11:
        "Fondamentalmente, in caso di emergenza si tratta di salvare e stabilizzare la vita, quindi spesso non c'è tempo per cercare una direttiva del paziente eventualmente esistente. Tuttavia, il personale di soccorso è anche obbligato a determinare e agire secondo la volontà presunta di una persona non responsiva.",
      answer12:
        'Una direttiva del paziente è valida anche se non viene nominata una persona di rappresentanza, tuttavia in questo caso è vantaggioso se la direttiva del paziente viene aggiornata annualmente.',
      answer13:
        'Raccomandiamo di informare la persona di rappresentanza per motivi pratici. In questo modo, il tuo rappresentante sa cosa ci si aspetta da lui.',
      answer14:
        'No. Il coniuge deve essere esplicitamente indicato nella direttiva del paziente, se desiderato.',
      answer15:
        'Sì, per legge una persona di rappresentanza deve essere capace di giudizio, ma non necessariamente maggiorenne. Raccomandiamo di coinvolgere anche una persona adulta per supporto.',
      answer16:
        'Una direttiva del paziente dovrebbe essere adattata il più possibile alle circostanze di vita attuali. Pertanto, raccomandiamo di considerare ogni cambiamento.',
      answer17: 'Raccomandiamo di fornire una copia al medico curante.',
      answer18: "No, l'accesso è protetto da password.",
      answer19:
        "Tu o le persone a te vicine potete contattare o chiamare per iscritto l'autorità di protezione degli adulti e affermare che la direttiva del paziente non viene rispettata o che gli interessi della persona incapace di giudizio sono minacciati o non più tutelati.",
      answer20:
        'gut geregelt ti aiuta a garantire la validità legale. Ogni direttiva del paziente deve contenere la data di creazione e la firma personale della persona che dispone.',
      answer21:
        'Sì, la {{powerOfAttorney}} e il {{lastWill}} sono due documenti che dovresti creare il prima possibile.',
      answer22:
        'Con gut geregelt ti aiutiamo a creare la tua direttiva del paziente in modo fluido e legalmente valido. In modo che tu possa guardare al futuro con serenità.',
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Con una direttiva del paziente, ti assicuri che i tuoi desideri medici vengano rispettati in caso di emergenza. Decidi quali trattamenti desideri ricevere e quali no.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              "Le incertezze sulla salute aumentano con l'età. Una direttiva del paziente ti dà la sicurezza che le tue decisioni saranno rispettate anche quando non potrai più prenderle tu stesso.",
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Autodeterminazione in caso di emergenza',
              'Sollievo per i familiari',
              'Sicurezza legale',
            ],
          },
        ],
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Una direttiva del paziente stabilisce quali decisioni mediche devono essere prese a tuo nome quando non sei più in grado di farlo. Questo assicura la tua autodeterminazione e solleva la tua famiglia.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Con la nascita di un figlio cresce la responsabilità. Una direttiva del paziente assicura che i tuoi desideri medici vengano rispettati e che la tua famiglia abbia istruzioni chiare in situazioni difficili.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Autodeterminazione in ambito medico',
              'Sollievo per i tuoi familiari',
              'Protezione e regole chiare per la famiglia',
            ],
          },
        ],
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Con una direttiva del paziente, specifichi quali misure mediche devono essere prese o omesse in caso di emergenza. In questo modo, mantieni il controllo sulle tue cure mediche, anche quando non puoi più decidere da solo.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Soprattutto con impegni a lungo termine come la proprietà di una casa, è importante tenere presente anche la previdenza personale. Una direttiva del paziente ti dà la sicurezza che i tuoi desideri medici vengano rispettati e che la tua casa rimanga protetta.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Garanzia dei tuoi desideri medici',
              'Sollievo per i tuoi familiari',
              'Complemento alla protezione dei tuoi valori personali e finanziari',
            ],
          },
        ],
      },
    },
    pensionierung: {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Con una direttiva del paziente, ti assicuri che i tuoi desideri medici vengano rispettati in caso di emergenza. Decidi quali trattamenti desideri ricevere e quali no.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              "Le incertezze sulla salute aumentano con l'età. Una direttiva del paziente ti dà la sicurezza che le tue decisioni saranno rispettate anche quando non potrai più prenderle tu stesso.",
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Autodeterminazione in caso di emergenza',
              'Sollievo per i familiari',
              'Sicurezza legale',
            ],
          },
        ],
      },
    },
    krankheit: {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Una direttiva del paziente specifica quali misure mediche devono essere prese o omesse quando non puoi più decidere da solo.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Una malattia grave può prendere svolte inaspettate. Una direttiva del paziente ti dà la sicurezza che i tuoi desideri di trattamento vengano rispettati e solleva i tuoi familiari.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Autodeterminazione in ambito medico',
              'Sollievo per i familiari',
              'Chiarezza in situazioni difficili',
            ],
          },
        ],
      },
    },
    'tod-verwandter': {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Una direttiva del paziente specifica quali misure mediche devono essere prese o omesse quando non sei più in grado di decidere da solo. Crei chiarezza e sollevi la tua famiglia in momenti difficili.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'La perdita di un parente stretto mostra quanto sia importante regolare chiaramente i propri desideri. Con una direttiva del paziente, ti assicuri che le tue decisioni mediche vengano rispettate e che la tua famiglia non sia sovraccaricata in situazioni di emergenza.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Garanzia dei tuoi desideri medici',
              'Sollievo per i familiari in lutto',
              'Istruzioni chiare per le emergenze',
            ],
          },
        ],
      },
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            'Una direttiva del paziente specifica quali misure mediche devono essere prese o omesse quando non puoi più decidere da solo.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Una malattia grave può prendere svolte inaspettate. Una direttiva del paziente ti dà la sicurezza che i tuoi desideri di trattamento vengano rispettati e solleva i tuoi familiari.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Autodeterminazione in ambito medico',
              'Sollievo per i familiari',
              'Chiarezza in situazioni difficili',
            ],
          },
        ],
      },
    },
    scheidung: {
      content: {
        main: {
          title: 'Direttiva del paziente',
          description:
            "Con una direttiva del paziente, determini quali decisioni mediche devono essere prese quando non sei più in grado di farlo. Dopo un divorzio, ti offre l'indipendenza e l'autodeterminazione necessarie.",
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Un divorzio cambia non solo la tua situazione legale ma anche personale. Una direttiva del paziente assicura che i tuoi desideri medici vengano rispettati indipendentemente dai precedenti legami familiari.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Indipendenza nelle decisioni mediche',
              'Protezione della tua autodeterminazione',
              'Sollievo per il tuo nuovo partner o i tuoi familiari',
            ],
          },
        ],
      },
    },
  },
};

export default livingWillLanding;
