import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useDroppable } from '@dnd-kit/core';
import { BreadcrumbsLinkProps } from './types';

type Props = {
  link: BreadcrumbsLinkProps;
  activeLast?: boolean;
  disabled: boolean;
};

export default function BreadcrumbsLink({ link, activeLast, disabled }: Props) {
  const { setNodeRef, isOver } = useDroppable({ id: link.id || '' });

  const styles = {
    // typography: 'body2',
    alignItems: 'center',
    color: 'text.primary',
    display: 'inline-flex',
    borderRadius: 4,
    padding: '2px 8px',

    backgroundColor: isOver ? 'success.lighter' : 'transparent',

    ...(disabled &&
      !activeLast && {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'text.disabled',
      }),

    ...(link.action && {
      cursor: 'pointer',
      '&:hover': {
        color: 'primary.main',
        backgroundColor: 'action.hover',
      },
    }),
  };

  const renderContent = (
    <>
      {link.icon && (
        <Box
          component="span"
          sx={{
            mr: 1,
            display: 'inherit',
            '& svg': { width: 20, height: 20 },
          }}
        >
          {link.icon}
        </Box>
      )}

      <Typography variant="subtitle1">{link.name}</Typography>
    </>
  );

  if (link.href) {
    return (
      <Link component={RouterLink} to={link.href} sx={styles}>
        {renderContent}
      </Link>
    );
  }

  return (
    <Box ref={setNodeRef} onClick={link.action} sx={styles}>
      {renderContent}
    </Box>
  );
}
