import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '#/components/shared/ui/Logo';
import LanguagePopover from '#/components/shared/ui/header/LanguagePopover';
import useLocales from '#/hooks/useLocales';

type Props = {
  isOffset?: boolean;
};

export default function Header({ isOffset = false }: Props) {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <AppBar
      sx={{
        px: { sx: 2, md: 3, lg: 5 },
        // py: 2,
        width: '100%',
        height: 70,
        boxShadow: 'none',
        zIndex: theme.zIndex.appBar + 1,
        // ...bgBlur({
        //   color: theme.palette.background.default,
        // }),
        backgroundColor: theme.palette.background.paper,

        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width={1}
          alignItems="center"
          maxWidth="1264px"
          margin="0 auto"
        >
          <Logo />
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <LanguagePopover />
            <Link
              component={RouterLink}
              underline="none"
              to="/kontakt"
              marginLeft="10px"
            >
              <Typography>{translate('global.needHelp')}</Typography>
            </Link>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
