const outputDoc = {
  title: 'Power of attorney',
  from: 'from',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  primary: 'Primary person',
  deputy: 'Deputy',
  civil_status: 'Civil status: {{civil_status}}',
  address: 'Address: {{address}}',
  declare: 'I, {{first_name}} {{last_name}}, declare the following: ',
  paragraph1:
    'In the event that I become incapable of making decisions and am unable to form or communicate my will permanently or temporarily, I appoint the following person(s) to take on the tasks as my power of attorney and asset manager and to represent me in all legal matters: ',
  firstParagraph: {
    sameTrustees: 'Personal and asset care, same person as well as',
    differentTrustees: 'Personal and asset care, different person',
  },
  assetCare: 'Asset care',
  personCare: 'Personal care',
  mainCharacter: 'Primary person',
  alternate: 'Alternate',
  compensationDeclare:
    'Receives compensation according to the definition below',
  nonCompensationDeclare: 'Receives no compensation',
  paragraph2: 'I appoint the following person as a substitute:',
  paragraph3:
    'In the event that I become incapable of making decisions and am unable to form or communicate my will permanently or temporarily, I appoint the following substitute person(s) to take on the tasks as my power of attorney and asset manager and to represent me in all legal matters: ',
  personCareDeclare1:
    'The appointed person determines which measures are to be taken regarding my care and medical treatment and ensures an orderly daily routine and appropriate participation in social life.',
  personCareDeclare2:
    'Unless expressly stated in your will, your digital assets will pass to your legal heirs in the event of your death. Therefore, you should decide during your lifetime what should be deleted or to whom it should be transferred after your death.',
  assetCare1:
    'The appointed person manages my income and assets, disposes of them, and takes all related measures. They provide the necessary instructions and applications to third parties, banks, and authorities to safeguard my interests. The appointed person ensures the payment of my bills via post or bank and receives payments on my behalf. They are authorized to receive and open all mail addressed to me.',
  assetCare2:
    'The management of bank accounts and securities should be based on existing agreements and practices. Asset management is expressly not subject to the provisions of the ordinance on asset management within the framework of guardianship or trusteeship.',
  assetCare3:
    'The appointed person may not make gifts from my assets or dispose of assets gratuitously, except for occasional gifts or contributions to fulfill a moral obligation.',
  repLegal: 'Legal representation',
  repLegal1:
    'The appointed person is authorized to initiate all legal and, if necessary, procedural actions required under this power of attorney, in particular to conclude or terminate the necessary contracts, submit tax returns, and represent me before authorities and courts or third parties. The appointed person is responsible for all administrative matters.',
  repLegal2:
    'The appointed person is authorized to sell or encumber my real estate, to establish, change, and delete annotations, pre-notations, and easements registered in the land register in my favor or burden, to have such transactions notarized, and to arrange for corresponding entries in the land register.',
  custodyOrder: 'Custody order',
  childrenTrustee:
    'If my children are not yet of legal age, I wish the following person to be appointed as their guardian and the children to be placed in their care:',
  childrenTrusteeRelationship: 'Relationship to me: {{relation}}',
  relation: {
    husband: 'Husband',
    wife: 'Wife',
    husband_wife: 'Husband/wife',
    partner: 'Partner',
    friend: 'Friend',
    'brother-sister': 'Brother, sister',
    'brother-sister-in-law': 'Brother-in-law, sister-in-law',
  },
  alternateChildrenTrustee: 'I appoint the following person as a substitute:',
  childrenTrusteeDeclare:
    'I am aware that this custody order is not legally part of the power of attorney, that it is a wish, and that the child protection authority is not bound by it and can only consider the wish if it corresponds to the child’s welfare. There is no legal claim that the child protection authority entrusts the above-mentioned person(s) with guardianship.',
  releaseFromConfidentiality: 'Release from confidentiality',
  releaseFromConfidentialityDeclare:
    'I release all persons and authorities subject to professional confidentiality from their professional and official secrecy towards the appointed person (especially banks, doctors, and officials).',
  involvementOfAidsAndAssistants: 'Involvement of aids and assistants',
  involvementOfAidsAndAssistantsDeclare:
    'To fulfill the assignment, the appointed person is authorized to acquire aids at my expense and to involve assistants and substitutes (such as household helpers, nursing services, tax advisors, lawyers, asset managers, etc.) and to conclude, amend, or terminate contracts with them.',
  expensesAndCompensation: 'Expenses and compensation',
  expenses: 'Expenses',
  expensesDeclare:
    'The necessary and proven expenses are to be reimbursed to the person appointed for personal or asset care.',
  indemnities: 'Compensation',
  indemnitiesPositive:
    'The person appointed for personal or asset care is entitled to appropriate compensation for their services, which is measured according to the usual rates of the adult protection authority for the compensation of guardians.',
  indemnitiesNegative:
    'The person(s) appointed for the care of children, personal or asset care, and who, according to the document creator’s wish, should receive compensation, will receive such compensation, which is measured according to the usual rates of the adult protection authority for guardians.',
  storageRegistration: 'Storage and Registration of the Power of Attorney',
  storage: 'Storage',
  storageRegistrationDeclare:
    'I am aware that power of attorney documents can be deposited for safekeeping with the child and adult protection authority responsible for the municipality of residence. It is my free decision whether to take advantage of this option.',
  storageRegistrationDeclare2:
    'The person entrusted with the power of attorney is authorized and empowered to contact the adult protection authority of my place of residence with this power of attorney in case of need so that the authority can issue the necessary certificate for my representation in accordance with Art. 363 ZGB.',
  registration: 'Registration',
  registrationDeclare:
    'I am aware of the possibility of informing the competent civil registry office about the creation and deposit location of the present power of attorney for registration in the central database according to Art. 361 para. 3 ZGB. It is my free decision whether to take advantage of this option.',
  applicableLaw: 'Applicable law and jurisdiction',
  applicableLawDeclare:
    'This power of attorney is subject to Swiss law, regardless of my nationality or residence, excluding conflict of law norms (IPRG). Jurisdiction is:',
  revocation: 'Revocation',
  revocationDeclare: 'I hereby revoke all powers of attorney of earlier dates.',
  abilityToAct: 'Capacity to act',
  abilityToActDeclare:
    'I make this declaration after careful consideration and in full responsibility for myself, and with the awareness that regarding medical measures, my doctors, caregivers, or authorized representatives are bound by the decision of the appointed person. I have discussed this directive with the persons mentioned at the beginning of the first page, who can confirm that, to their perception, I was capable of making decisions at that time and that the content corresponds to my will.',
};

export default outputDoc;
