const provisionLanding = {
  mainBanner: {
    intro: 'Finalmente una buona previdenza:',
    title: 'Crea direttiva del paziente, procura e testamento online',
    subtitle:
      'In caso di emergenza, i tuoi familiari sono esposti a molte emozioni. Risparmia loro ulteriore stress dovuto a formalità non risolte.',
    buttonLabel: 'Previdenza ora',
  },
  sale: '-43% / per persona',
  addMore: 'Aggiungi altri {{membersNo}}',
  youtubeTutorial: 'Perché la previdenza è così importante?',
  aboutCards: {
    remainInControl: {
      title: 'Rimanere in controllo',
      description:
        'Se non ti occupi di te stesso, lo faranno altri — non necessariamente nel tuo migliore interesse.',
    },
    protectYourFamily: {
      title: 'Proteggi la tua famiglia',
      description:
        'Agisci proattivamente per chiarire come la tua famiglia affronterà la situazione se sei incapace di agire o non sei più presente.',
    },
    relieveRelatives: {
      title: 'Allevia i familiari',
      description:
        'Pensa a coloro che hanno molto da fare quando non sei più in grado. Fornisci sollievo.',
    },
  },
  whyWithUs: {
    title: 'Buoni motivi per gut geregelt?',
    cards: {
      card1: {
        title: 'Comprensibile',
        description:
          'Ti vengono chieste cose che non capisci affatto?! Non con noi. Parliamo la tua lingua.',
      },
      card2: {
        title: 'Rapidamente completato',
        description:
          'Invece di rispondere a dozzine di domande in un processo lungo, ottieni ciò che desideri in 10 minuti - garantito.',
      },
      card3: {
        title: 'Conveniente',
        description:
          'gut geregelt ti consente di fare una previdenza completa e legalmente conforme a un prezzo imbattibile.',
      },
    },
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'Mio marito ed io discutiamo di questo argomento da anni. Grazie a nostra figlia, siamo venuti a conoscenza di gut geregelt. Siamo rimasti sorpresi di quanto fosse facile e veloce fare ciò che abbiamo rimandato per anni.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: 'Mio padre era un imprenditore della vecchia scuola. Molto impegnato, poco documentato. Quando è morto, mia sorella ed io abbiamo dovuto faticosamente creare chiarezza e ordine. Anche se non sono ancora in età avanzata, non voglio correre rischi e risparmiare alla mia famiglia un simile fardello. gut geregelt mi ha permesso di fare proprio questo. E tutto dal divano.',
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: 'Due anni fa ho creato un testamento con il mio avvocato. Grazie a gut geregelt, ora ho anche una direttiva del paziente e una procura. Il processo è stato chiaro e soprattutto il linguaggio comprensibile. Anche se spero di non averne mai bisogno, sono felice di aver superato la paura di creare questi documenti.',
    },
  },
  subBanner: {
    title: 'Previdenza legalmente conforme, semplificata',
    subtitle:
      'gut geregelt offre accesso a consulenza legale se necessario e ti aiuta a creare documenti legalmente conformi, conservarli in sicurezza e condividerli in caso di emergenza con chi ne ha bisogno.',
    buttonLabel: 'Inizia ora',
  },
  pricing: {
    title: 'Il pacchetto giusto per te',
    subtitle:
      'Offriamo qualcosa per tutti. Scegli ora il pacchetto che meglio si adatta alle tue esigenze.',
    oneTime: 'una tantum',
    perYear: 'anno',
    additionalMember: 'membro aggiuntivo',
    purchaseButton: 'Previdenza ora',
    manageButton: 'Gestisci',
    starter: {
      title: 'Starter',
      finfinderSale: 'Risparmia 200 CHF con FinFinder',
      features: [
        'Tutti i documenti di previdenza',
        'Gestione digitale dei documenti',
        'Registro dei beni e delle proprietà',
        'Eredità digitale',
        'Cartella fiscale',
        "Supporto nell'organizzazione",
        'Accesso ospite per i rappresentanti',
        'Accesso alla consulenza legale e finanziaria',
      ],
    },
    standard: {
      title: 'Standard',
      finfinderSale: 'Risparmia 400 CHF con FinFinder',
      features: [
        'Tutti i documenti di previdenza',
        'Gestione digitale dei documenti',
        'Registro dei beni e delle proprietà',
        'Eredità digitale',
        'Cartella fiscale',
        "Supporto nell'organizzazione",
        'Accesso ospite per i rappresentanti',
        'Promemoria (completamento documenti, aggiornamenti legali e altre modifiche)',
        'Accesso alla consulenza legale e finanziaria',
      ],
    },
  },
  provisionBanner: {
    title: 'Anche se è difficile... È importante pensarci ora',
    subtitle:
      'Molti di noi tendono a rimandare la previdenza per la vecchiaia. Dopotutto, nessuno ama pensare allo scenario peggiore. Tuttavia, è importante ricordare che non fai un favore né a te né ai tuoi cari se lo trascuri. Prima inizi, meglio è. Ti aiutiamo a trovare il piano previdenziale giusto per te.',
    buttonLabel: 'Previdenza ora',
  },
  newsletter: {
    title: 'Diventa ben regolato',
    buttonLabel: 'Crea i tuoi documenti',
  },
  eightReasons: {
    title: '8 motivi per gut geregelt',
    cards: {
      card1: {
        title: 'Semplice e comprensibile',
        description: {
          provisionLanding:
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
          patientenverfuegung:
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
          vorsorgeauftrag:
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
          testament:
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
          ehevertrag:
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
          'digitaler-nachlass':
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
          konkubinatsvertrag:
            'Parliamo la tua lingua e rendiamo comprensibili termini complessi.',
        },
      },
      card2: {
        title: 'Completato in 30 minuti',
        description: {
          provisionLanding:
            'Il tuo documento previdenziale completato in soli 14 minuti in media grazie al nostro processo ottimizzato.',
          patientenverfuegung:
            'La tua direttiva del paziente completata in soli 14 minuti in media grazie al nostro processo ottimizzato.',
          vorsorgeauftrag:
            'La tua procura completata in soli 14 minuti in media grazie al nostro processo ottimizzato.',
          testament:
            'Il tuo testamento completato in soli 14 minuti in media grazie al nostro processo ottimizzato.',
          ehevertrag:
            'Il tuo contratto matrimoniale completato in soli 14 minuti in media grazie al nostro processo ottimizzato.',
          'digitaler-nachlass':
            'Il tuo lascito digitale completato in soli 14 minuti in media grazie al nostro processo ottimizzato.',
          konkubinatsvertrag:
            'Il tuo contratto di convivenza completato in soli 14 minuti in media grazie al nostro processo ottimizzato.',
        },
      },
      card3: {
        title: 'Comodamente da casa',
        description: {
          provisionLanding:
            'Crea i tuoi documenti comodamente da casa, senza appuntamenti, quando vuoi.',
          patientenverfuegung:
            'Crea la tua direttiva del paziente comodamente da casa, senza appuntamenti, quando vuoi.',
          vorsorgeauftrag:
            'Crea la tua procura comodamente da casa, senza appuntamenti, quando vuoi.',
          testament:
            'Crea il tuo testamento comodamente da casa, senza appuntamenti, quando vuoi.',
          ehevertrag:
            'Crea il tuo contratto matrimoniale comodamente da casa, senza appuntamenti, quando vuoi.',
          'digitaler-nachlass':
            'Crea il tuo lascito digitale comodamente da casa, senza appuntamenti, quando vuoi.',
          konkubinatsvertrag:
            'Crea il tuo contratto di convivenza comodamente da casa, senza appuntamenti, quando vuoi.',
        },
      },
      card4: {
        title: 'Adattabile in qualsiasi momento',
        description: {
          provisionLanding:
            'Adatta i tuoi documenti in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
          patientenverfuegung:
            'Adatta la tua direttiva del paziente in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
          vorsorgeauftrag:
            'Adatta la tua procura in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
          testament:
            'Adatta il tuo testamento in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
          ehevertrag:
            'Adatta il tuo contratto matrimoniale in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
          'digitaler-nachlass':
            'Adatta il tuo lascito digitale in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
          konkubinatsvertrag:
            'Adatta il tuo contratto di convivenza in qualsiasi momento in modo semplice e senza complicazioni a nuove circostanze di vita.',
        },
      },
      card5: {
        title: 'Legalmente sicuro',
        description: {
          provisionLanding:
            'Crea documenti legalmente sicuri, verificati da esperti e sempre aggiornati.',
          patientenverfuegung:
            'Crea una direttiva del paziente legalmente sicura, verificata da esperti e sempre aggiornata.',
          vorsorgeauftrag:
            'Crea una procura legalmente sicura, verificata da esperti e sempre aggiornata.',
          testament:
            'Crea un testamento legalmente sicuro, verificato da esperti e sempre aggiornato.',
          ehevertrag:
            'Crea un contratto matrimoniale legalmente sicuro, verificato da esperti e sempre aggiornato.',
          'digitaler-nachlass':
            'Crea un lascito digitale legalmente sicuro, verificato da esperti e sempre aggiornato.',
          konkubinatsvertrag:
            'Crea un contratto di convivenza legalmente sicuro, verificato da esperti e sempre aggiornato.',
        },
      },
      card6: {
        title: 'Conservazione sicura',
        description: {
          provisionLanding: 'Conservazione sicura di tutti i documenti.',
          patientenverfuegung:
            'Conservazione sicura della direttiva del paziente.',
          vorsorgeauftrag: 'Conservazione sicura della procura.',
          testament: 'Conservazione sicura del testamento.',
          ehevertrag: 'Conservazione sicura del contratto matrimoniale.',
          'digitaler-nachlass': 'Conservazione sicura del lascito digitale.',
          konkubinatsvertrag:
            'Conservazione sicura del contratto di convivenza.',
        },
      },
      card7: {
        title: 'Accesso per persone importanti',
        description: {
          provisionLanding:
            'Comunicazione e accesso ai documenti rilevanti per familiari, medici o esecutori testamentari al momento giusto.',
          patientenverfuegung:
            'Comunicazione e accesso alla direttiva del paziente rilevante per familiari, medici o esecutori testamentari al momento giusto.',
          vorsorgeauftrag:
            'Comunicazione e accesso alla procura rilevante per familiari, medici o esecutori testamentari al momento giusto.',
          testament:
            'Comunicazione e accesso al testamento rilevante per familiari, medici o esecutori testamentari al momento giusto.',
          ehevertrag:
            'Comunicazione e accesso al contratto matrimoniale rilevante per familiari, medici o esecutori testamentari al momento giusto.',
          'digitaler-nachlass':
            'Comunicazione e accesso al lascito digitale rilevante per familiari, medici o esecutori testamentari al momento giusto.',
          konkubinatsvertrag:
            'Comunicazione e accesso al contratto di convivenza rilevante per familiari, medici o esecutori testamentari al momento giusto.',
        },
      },
      card8: {
        title: 'Conveniente',
        description: {
          provisionLanding:
            'Previdenza completa e legalmente conforme a una frazione del costo di un notaio.',
          patientenverfuegung:
            'Direttiva del paziente completa e legalmente conforme a una frazione del costo di un notaio.',
          vorsorgeauftrag:
            'Procura completa e legalmente conforme a una frazione del costo di un notaio.',
          testament:
            'Testamento completo e legalmente conforme a una frazione del costo di un notaio.',
          ehevertrag:
            'Contratto matrimoniale completo e legalmente conforme a una frazione del costo di un notaio.',
          'digitaler-nachlass':
            'Lascito digitale completo e legalmente conforme a una frazione del costo di un notaio.',
          konkubinatsvertrag:
            'Contratto di convivenza completo e legalmente conforme a una frazione del costo di un notaio.',
        },
      },
    },
  },
  betterRegulatedLife: {
    title: 'Una vita ben regolata',
    provisionCheck: {
      title: 'Check-up previdenziale',
      subtitle:
        'Comprendiamo la tua situazione di vita. Scopri in due minuti cosa ti serve per una previdenza ben regolata. Ti incontriamo dove ti trovi.',
      buttonLabel: 'Inizia il check-up previdenziale',
    },
    lifeSituationCheck: {
      title: 'Check-up della situazione di vita',
      subtitle:
        'Stai per affrontare una nuova fase della vita o hai vissuto un evento significativo e ti chiedi come questo influenzerà la tua previdenza?',
      buttonLabel: 'Inizia il check-up della situazione di vita',
    },
  },
};

export default provisionLanding;
