import * as Yup from 'yup';
import { MenuItem, Stack, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  invalidateRegisterOfAssets,
  List,
  useAddAsset,
  useCommonBeneficiariesList,
} from '#/api/assetsRegister';
import Iconify from '#/components/shared/ui/Iconify';
import Beneficiaries from '#/components/pages/LastWill/steps/content/Inheritors/childrens/Beneficiary/Beneficiaries';

type Props = {
  onClose: () => void;
};

export default function AddAssetForm({ onClose }: Props) {
  const [lastAddedBenefiter, setLastAddedBenefiter] = useState<boolean>(false);
  const [addNewBenefiter, setAddNewBenefiter] = useState(false);
  const [beneficiaryType, setBeneficiaryType] = useState('' as string);

  const { translate } = useLocales();

  const Schema = Yup.object().shape({
    description: Yup.string().required(
      translate('validations.digitalAssets.assets.description')
    ),
    storage_location: Yup.string().required(
      translate('validations.digitalAssets.assets.storage_location')
    ),
    beneficiary_id: Yup.string().required(
      translate('validations.digitalAssets.assets.beneficiary_id')
    ),
  });

  const defaultValues = useMemo(
    () => ({
      description: '',
      storage_location: '',
      beneficiary_id: '',
      beneficiary_type: beneficiaryType,
    }),
    [beneficiaryType]
  );

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = methods;

  const { data: gatheredList } = useCommonBeneficiariesList();
  const { mutateAsync: addAsset } = useAddAsset();

  const handleLastAdded = () => {
    if (!gatheredList) return;
    setLastAddedBenefiter(true);
  };

  const getGroups = gatheredList
    ? // @ts-ignore
      Object.keys(gatheredList)?.map((key) => gatheredList[key])
    : [];

  useEffect(() => {
    if (beneficiaryType) {
      // @ts-ignore
      setValue('beneficiary_type', beneficiaryType);
    }
  }, [beneficiaryType]);

  useEffect(() => {
    if (lastAddedBenefiter && gatheredList) {
      setValue(
        'beneficiary_id',
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        getGroups[1][getGroups[1]?.length - 1]?.id
      );
      // @ts-ignore
      setValue('beneficiary_type', 'beneficiary', { shouldValidate: true });
    }
  }, [gatheredList, lastAddedBenefiter]);

  const disabled = !isDirty;

  const onSubmit = async (data: any) => {
    try {
      await addAsset(data).then(() => {
        toast.success(translate('toast_notifications.success.addedAsset'));
        onClose();
        invalidateRegisterOfAssets.getAssets();
      });
    } catch (error) {
      toast.error(translate('toast_notifications.error.addedAsset'));
    }
  };

  return addNewBenefiter ? (
    <Stack marginY={2}>
      <Beneficiaries
        externalRequest={addNewBenefiter}
        setNewBeneficiary={setAddNewBenefiter as any}
        handleLastAdded={handleLastAdded}
      />
    </Stack>
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="row" sx={{ marginY: 2 }}>
        <RHFTextField
          name="description"
          label={translate('global.formLabels.asset')}
        />
        <RHFTextField
          name="storage_location"
          label={translate('global.formLabels.storageLocation')}
        />
      </Stack>
      <RHFSelect
        name="beneficiary_id"
        label={translate('global.formLabels.designatedBeneficiary')}
      >
        <MenuItem onClick={() => setAddNewBenefiter(true)}>
          <Iconify icon="mdi:account-plus" color="text.secondary" />
          <Typography color="text.secondary">
            {translate('global.addNewBeneficiary')}
          </Typography>
        </MenuItem>
        {getGroups[0]?.map((representative: List) => (
          <MenuItem
            key={representative.id}
            value={representative.id}
            onClick={() => setBeneficiaryType('representative')}
          >
            {representative.name}
          </MenuItem>
        ))}
        {getGroups[1]?.map((beneficiary: List) => (
          <MenuItem
            key={beneficiary.id}
            value={beneficiary.id}
            onClick={() => setBeneficiaryType('beneficiary')}
          >
            {beneficiary.name}
          </MenuItem>
        ))}
      </RHFSelect>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        sx={{ marginTop: 2 }}
      >
        <LoadingButton variant="contained" type="submit" disabled={disabled}>
          <Typography>{translate('global.save')}</Typography>
        </LoadingButton>
        <Button variant="text" onClick={onClose}>
          <Typography>{translate('global.cancel')}</Typography>
        </Button>
      </Stack>
    </FormProvider>
  );
}
