import { useMutation } from 'react-query';
import queryClient from '#/api/query-client';
import { useDelete, useGet, usePatch, usePost, usePut } from '#/api/reactQuery';
import { DigitalAssetsList, FilesAPI, FormValues } from '#/types/digitalAssets';
import axios from '#/utils/axios';

const BASE_URL = 'digital-footprint';

export const DigitalAssetsEndpoints = {
  getDigitalAssets: () => `${BASE_URL}/digital-assets/`,
  addDigitalAsset: () => `${BASE_URL}/digital-assets/`,
  updateDigitalAsset: (id: number | string | undefined) =>
    `${BASE_URL}/digital-assets/${id}/`,
  deleteDigitalAsset: (id: number | string | undefined) =>
    `${BASE_URL}/digital-assets/${id}/`,
  exportDigitalAssets: () => `${BASE_URL}/digital-assets/export-list/`,
  getFiles: () => `${BASE_URL}/files/`,
  uploadFile: () => `${BASE_URL}/files/`,
  createFolder: () => `${BASE_URL}/folders/`,
  renameFolder: (id: number | string | undefined) =>
    `${BASE_URL}/folders/${id}/rename/`,
  renameFile: (id: number | string | undefined) =>
    `${BASE_URL}/files/${id}/rename/`,
  deleteFolder: (id: number | string | undefined) =>
    `${BASE_URL}/folders/${id}/`,
  deleteFile: (id: number | string | undefined) => `${BASE_URL}/files/${id}/`,
  moveFolder: (id: number | string | null) => `${BASE_URL}/folders/${id}/move/`,
  moveFile: (id: number | string | null) => `${BASE_URL}/files/${id}/move/`,
  toggleFavoriteFolder: (id: number | string | undefined) =>
    `${BASE_URL}/folders/${id}/toggle-star/`,
  toggleFavoriteFile: (id: number | string | undefined) =>
    `${BASE_URL}/files/${id}/toggle-star/`,
  filesBulkDelete: () => `${BASE_URL}/files/bulk-delete/`,
  foldersBulkDelete: () => `${BASE_URL}/folders/bulk-delete/`,
};

export const invalidateDigitalAssetsQueries = {
  getDigitalAssets: () => {
    queryClient
      .invalidateQueries(DigitalAssetsEndpoints.getDigitalAssets())
      .then((r) => r);
  },
  exportDigitalAssets: () => {
    queryClient.removeQueries(DigitalAssetsEndpoints.exportDigitalAssets());
  },
  getFiles: () => {
    queryClient.invalidateQueries(DigitalAssetsEndpoints.getFiles());
  },
};

export const useGetDigitalAssets = (
  page: number,
  invitation_id?: number | string,
  search?: string | undefined,
  asset_type?: string | undefined,
  ordering?: string | undefined,
  enabled?: boolean
) =>
  useGet<DigitalAssetsList>(
    DigitalAssetsEndpoints.getDigitalAssets(),
    {
      page,
      search,
      asset_type,
      ordering,
      invitation_id,
    },
    {
      enabled,
    }
  );

export const useAddDigitalAsset = () =>
  usePost<FormValues, FormValues>(DigitalAssetsEndpoints.addDigitalAsset());

export const useUpdateDigitalAsset = (id: number | string | undefined) =>
  usePut<FormValues, FormValues>(DigitalAssetsEndpoints.updateDigitalAsset(id));

export const useDeleteDigitalAsset = (id: number | string | undefined) =>
  useDelete(DigitalAssetsEndpoints.deleteDigitalAsset(id));

export const useExportDigitalAssets = (
  enabled: boolean,
  invitation_id?: number | string
) =>
  useGet<{ file_url: string }>(
    DigitalAssetsEndpoints.exportDigitalAssets(),
    {
      invitation_id,
    },
    {
      enabled,
    }
  );

// FILES API

export const useGetFiles = (
  enabled: boolean,
  search?: string,
  folder?: string | number | (string | number)[],
  date_from?: string | Date | null,
  date_to?: string | Date | null,
  file_type?: string | undefined | (string | undefined)[]
) =>
  useGet<FilesAPI>(
    DigitalAssetsEndpoints.getFiles(),
    {
      search,
      folder,
      date_from,
      date_to,
      file_type,
    },

    { enabled, refetchOnWindowFocus: 'always' }
  );

export const useCreateFolder = () =>
  usePost<
    { name: string; parent?: null },
    {
      name: string;
      parent: number | null;
    }
  >(DigitalAssetsEndpoints.createFolder());

export const useUploadFile = () =>
  usePost<FormData, FormData>(DigitalAssetsEndpoints.uploadFile());

export const useRenameFolder = (id: number | string | undefined) =>
  usePatch<{ name: string }, { name: string }>(
    DigitalAssetsEndpoints.renameFolder(id)
  );

export const useRenameFile = (id: number | string | undefined) =>
  usePatch<{ name: string }, { name: string }>(
    DigitalAssetsEndpoints.renameFile(id)
  );

export const useDeleteFolder = () =>
  useMutation(async (vars: { id: number | string }) => {
    const endpoint = DigitalAssetsEndpoints.deleteFolder(vars.id);
    const { data } = await axios.delete(endpoint);
    return data;
  });

export const useDeleteFile = () =>
  useMutation(async (vars: { id: number | string }) => {
    const endpoint = DigitalAssetsEndpoints.deleteFile(vars.id);
    const { data } = await axios.delete(endpoint);
    return data;
  });

export const useMoveFolder = () =>
  useMutation(
    async (vars: {
      parent_id: number | string | null;
      folder_id: number | string | null;
    }) => {
      const endpoint = DigitalAssetsEndpoints.moveFolder(vars.folder_id);
      const { data } = await axios.post(endpoint, {
        parent_id: vars.parent_id,
      });
      return data;
    }
  );

export const useMoveFile = () =>
  useMutation(
    async (vars: {
      folder_id: number | string | null;
      file_id: number | string | null;
    }) => {
      const endpoint = DigitalAssetsEndpoints.moveFile(vars.file_id);
      const { data } = await axios.post(endpoint, {
        folder_id: vars.folder_id,
      });
      return data;
    }
  );

export const useToggleFavoriteFolder = (id: number | string | undefined) =>
  usePatch(DigitalAssetsEndpoints.toggleFavoriteFolder(id));

export const useToggleFavoriteFile = (id: number | string | undefined) =>
  usePatch(DigitalAssetsEndpoints.toggleFavoriteFile(id));

export const useBulkDeleteFiles = () =>
  usePost<{ file_ids: (string | number)[] }, { file_ids: (string | number)[] }>(
    DigitalAssetsEndpoints.filesBulkDelete()
  );

export const useBulkDeleteFolders = () =>
  usePost<
    { folder_ids: (string | number)[] },
    { folder_ids: (string | number)[] }
  >(DigitalAssetsEndpoints.foldersBulkDelete());
