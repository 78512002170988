const preventiveCheckUp = {
  mainBanner: {
    intro: 'Bilan de prévoyance pour caisses de pension',
    title: 'Créer de la transparence, minimiser les risques de responsabilité',
    subtitle:
      "Les caisses de pension ont pour mission d'assurer une prévoyance optimale pour leurs clients. Le bilan de prévoyance crée de la transparence et minimise les risques de responsabilité pour les caisses de pension, les fondations et le conseil de fondation. Assurez un avenir sûr pour vos bénéficiaires de prévoyance.",
    buttonLabel: 'Prendre rendez-vous',
  },
  allInfos: "Toutes les infos en 15min d'appel vidéo",
  theChallenges: {
    title: 'Les défis de la prévoyance moderne',
    description:
      'Les défis de la prévoyance sont divers et complexes. Les caisses de pension et les fondations doivent informer et guider leurs clients pour assurer la meilleure prévoyance possible en temps incertains.',
    cards: {
      increasedLifeExpectancy: {
        title: 'Espérance de vie accrue',
        description:
          "Les nouvelles options de diagnostic et de traitement pourraient encore augmenter l'espérance de vie. Vos bénéficiaires de prévoyance y sont-ils préparés ?",
      },
      inflation: {
        title: 'Inflation',
        description:
          "Les évolutions de l'inflation à long terme peuvent fortement influencer la prévoyance retraite. Comment sécurisez-vous le pouvoir d'achat de vos clients ?",
      },
      ahv: {
        title: 'AVS',
        description:
          "Quels changements sont attendus dans l'AVS et comment affecteront-ils vos bénéficiaires de prévoyance ?",
      },
    },
  },
  benefits: {
    title: 'Les avantages du bilan de prévoyance de gut geregelt',
    description:
      'Le bilan de prévoyance de gut geregelt offre une solution complète et transparente pour préparer de manière optimale les bénéficiaires de prévoyance aux défis et minimiser les risques de responsabilité.',
    cards: {
      transparency: {
        title: 'Transparence et éducation',
        description:
          "Informez en détail vos clients de 40 ans et plus sur les effets de l'augmentation de l'espérance de vie et d'autres risques.",
      },
      liability: {
        title: 'Atténuer les risques de responsabilité',
        description:
          'Grâce à une éducation complète et une consultation documentée, vous pouvez réduire considérablement le risque de réclamations en responsabilité et augmenter la sécurité pour votre institution et le conseil de fondation.',
      },
      documentation: {
        title: 'Documentation',
        description:
          "Une documentation traçable de toutes les étapes d'information et de consultation vous protège des risques de responsabilité à long terme.",
      },
    },
  },
  liabilityRisk: 'Risque de responsabilité du conseil de fondation',
  liabilityRiskSubtitle:
    'Une communication active et la création de transparence sont importantes pour prévenir les dommages aux bénéficiaires de prévoyance et minimiser les risques de responsabilité pour les caisses de pension et le conseil de fondation.',
  contactForm: {
    title:
      'Découvrez comment le bilan de prévoyance soutient les caisses de pension',
    description:
      'Souhaitez-vous en savoir plus sur le bilan de prévoyance et comment il peut vous aider à soutenir de manière optimale vos clients ?',
    cta: 'Prenez maintenant un rendez-vous sans engagement pour une présentation via info@gutgeregelt.ch ou le formulaire.',
  },
};

export default preventiveCheckUp;
