import { Card, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import ChatHeaderDetail from './chat-header-detail';
import ChatRoom from './chat-room';
import {
  IChatConversation,
  IChatConversations,
  IChatParticipant,
} from '#/types/chat';
import ChatMessageList from './chat-message-list';
import ChatMessageInput from './chat-message-input';
import useAuth from '#/lib/hooks/useAuth';
import ChatNav from './chat-nav';
import DashboardContainer from '#/components/shared/dashboard-container';
import useLocales from '#/lib/hooks/useLocales';
import ChatHeaderCompose from './chat-header-compose';

export default function ChatView() {
  const [recipients, setRecipients] = useState<IChatParticipant[]>([]);
  const [searchParams] = useSearchParams();

  const { user } = useAuth();
  const { translate } = useLocales();

  const selectedConversationId = searchParams.get('id') || '';

  const participants = [
    {
      id: 1,
      first_name: 'John',
      last_name: 'Doe',
      avatar_url:
        'https://api-dev-minimal-v630.pages.dev/assets/images/avatar/avatar-4.webp',
      last_activity: new Date(),
      status: 'online',
      role: 'Tax expert',
    },
    {
      id: 2,
      first_name: 'Gjejn',
      last_name: 'Doe',
      avatar_url:
        'https://api-dev-minimal-v630.pages.dev/assets/images/avatar/avatar-6.webp',
      last_activity: new Date(),
      status: 'offline',
      role: 'Tax expert',
    },
  ];

  const conversations: IChatConversations = {
    byId: {
      '1': {
        id: '1',
        type: 'private',
        unread_count: 0,
        messages: [
          {
            id: '1',
            body: 'Hello, how can I help you today?',
            created_at: new Date(),
            sender_id: 1,
            content_type: 'text',
            attachments: [],
          },
          {
            id: '2',
            body: 'I need help with my taxes',
            created_at: new Date(),
            sender_id: user?.id,
            content_type: 'text',
            attachments: [],
          },
        ],
        participants: [participants[0]],
      },
      '2': {
        id: '2',
        type: 'private',
        unread_count: 0,
        messages: [
          {
            id: '1',
            body: 'Hello, this is Jane',
            created_at: new Date(),
            sender_id: 2,
            content_type: 'text',
            attachments: [],
          },
          {
            id: '2',
            body: "Hey what's up Jane",
            created_at: new Date(),
            sender_id: user?.id,
            content_type: 'text',
            attachments: [],
          },
        ],
        participants: [participants[1]],
      },
    },
    allIds: ['1', '2'],
  };

  const conversation: IChatConversation =
    conversations.byId[selectedConversationId];

  const contacts = [
    {
      id: 1,
      first_name: 'John',
      last_name: 'Doe',
      avatar_url:
        'https://api-dev-minimal-v630.pages.dev/assets/images/avatar/avatar-4.webp',
      last_activity: new Date(),
      status: 'online',
      role: 'Tax expert',
    },
    {
      id: 2,
      first_name: 'Gjejn',
      last_name: 'Doe',
      avatar_url:
        'https://api-dev-minimal-v630.pages.dev/assets/images/avatar/avatar-6.webp',
      last_activity: new Date(),
      status: 'offline',
      role: 'Tax expert',
    },
  ];

  const handleAddRecipients = useCallback((selected: IChatParticipant[]) => {
    setRecipients(selected);
  }, []);

  const details = !!conversation;

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      flexShrink={0}
      sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}
    >
      {selectedConversationId ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {details && (
            <ChatHeaderDetail
              participants={conversation?.participants || participants}
            />
          )}
        </>
      ) : (
        <ChatHeaderCompose
          contacts={contacts}
          onAddRecipients={handleAddRecipients}
        />
      )}
    </Stack>
  );

  const renderNav = (
    <ChatNav
      contacts={contacts}
      conversations={conversations}
      loading={false}
      selectedConversationId={selectedConversationId}
    />
  );

  const renderMessages = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <ChatMessageList
        messages={conversation?.messages}
        participants={participants}
      />

      <ChatMessageInput
        recipients={recipients}
        onAddRecipients={handleAddRecipients}
        //
        selectedConversationId={selectedConversationId}
        disabled={!recipients.length && !selectedConversationId}
      />
    </Stack>
  );

  return (
    <DashboardContainer introTitle={translate('global.chat.customerChat')}>
      <Stack
        component={Card}
        direction="row"
        sx={{ height: '72vh', marginY: 8 }}
      >
        {renderNav}
        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
        >
          {renderHead}
          <Stack
            direction="row"
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
              borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
          >
            {renderMessages}
            {details && (
              <ChatRoom
                conversation={conversation}
                participants={conversation?.participants || participants}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </DashboardContainer>
  );
}
