import { Avatar, Checkbox, Typography } from '@mui/material';
import { S } from './styles';

export default function ListItem() {
  return (
    <S.RowWrapper>
      <S.RowCell direction="row" alignItems="center" spacing={2} width="60%">
        <Checkbox />
        <Avatar />
        <Typography>Blum & Grob Rechtsanwälte AG</Typography>
      </S.RowCell>
      <S.RowCell width="20%">
        <Typography>Zürich</Typography>
      </S.RowCell>
      <S.RowCell width="20%">
        <Typography>CHF 250</Typography>
      </S.RowCell>
    </S.RowWrapper>
  );
}
