const adminDashboard = {
  main: {
    title: 'Tableau de bord',
    description:
      'Voyez combien de vos clients visitent bien organisés, créent un compte et achètent ensuite un forfait.',
  },
  analytics: {
    cards: {
      visitors: 'Visiteurs',
      conversion_rate_login: 'Taux de conversion en connexion',
      loginsCreated: 'Connexions créées',
      conversion_rate_pay: 'Taux de conversion en clients payants',
      paying_customers: 'Clients payants',
    },
  },
  charts: {
    visitors: 'Visiteurs',
    conversionRateToLogin: 'Taux de conversion en connexion',
    loginsCreated: 'Connexions créées',
    conversionRateToPaying: 'Taux de conversion en clients payants',
    subscriptionsCreated: 'Clients payants',
  },
  historicalData: 'Données historiques',
  historicalDataSubheader:
    '({{subscription_growth_percentage}}%) par rapport à l\'année dernière',
  demographics: 'Démographie',
  women: 'Femmes',
  man: 'Hommes',
  visits: 'visites',

  widgetsOptions: {
    lifeExpectancy: 'Espérance de vie',
    ageAppropriate: 'Placement 3a adapté à l\'âge',
    ahvCalculator: 'Calculateur AVS',
  },

  clientOverview: 'Aperçu des clients',
  generalOverview: 'Tableau de bord',
  legalAdvisors: 'Conseillers juridiques',
  financialAdvisors: 'Conseillers financiers',
  platform: 'Plateforme',
  user: 'Utilisateur',
  mainInfoModal: {
    title: 'Veuillez remplir les informations manquantes',
    description:
      'Veuillez compléter votre prénom et votre nom pour terminer la connexion.',
  },

  consulting: {
    unlockWidget: {
      title: 'Déverrouiller le widget',
      description:
        'Demandez une offre et activez des widgets pour vous et vos conseillers afin de fournir une consultation de retraite simplifiée aux clients lors des entretiens de conseil.',
    },
  },
  teamTable: {
    tableHeads: {
      name: 'Nom',
      email: 'Email',
      role: 'Rôle',
      last_login: 'Dernière connexion',
    },
  },
  consultantTable: {
    defaultSwitchTooltip:
      'En passant à la liste par défaut, seuls les conseillers fournis par Bien Organisé seront affichés.',
    tableHeads: {
      name: 'Nom',
      email: 'Email',
      location: 'Emplacement',
      added: 'Ajouté',
    },
  },
  usersTable: {
    toolbarButtons: {
      columns: 'Colonnes',
      export: 'Exporter',
      filter: 'Filtrer',
      transferSelectedUsers:
        'Transférer les utilisateurs sélectionnés ({{selectedUsers}})',
      removeFromConsulting: 'Retirer de la consultation',
    },
    tableHeads: {
      category: 'Catégorie',
      name: 'Nom',
      email: 'Email',
      owned_by: 'Conseiller',
      last_login: 'Dernière connexion',
      pension_provision: 'Prévoyance',
      living_will: 'Directives anticipées',
      power_of_attorney: 'Mandat pour cause d\'inaptitude',
      last_will: 'Testament',
    },
  },
  deleteUser: 'Supprimer l\'utilisateur',
  deleteConsultant: {
    title:
      'Vous êtes sur le point de supprimer {{consultant}} de la liste. Êtes-vous sûr ?',
    description: 'Veuillez confirmer la suppression.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },
  deleteTeamMember: {
    title: 'Vous êtes sur le point de supprimer la liste. Êtes-vous sûr ?',
    description: 'Veuillez confirmer la suppression.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },
  cancelInvitation: {
    title: 'Vous êtes sur le point d\'annuler l\'invitation. Êtes-vous sûr ?',
    description:
      'Même après l\'annulation, vous pouvez réinviter le membre.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },
  deleteUserModal: {
    title:
      'Vous êtes sur le point de supprimer {{user}} de la liste. Êtes-vous sûr ?',
    description: 'Veuillez confirmer la suppression.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },

  userTransferModal: {
    title:
      'Vous avez sélectionné les utilisateurs ci-dessous pour les transférer à un autre conseiller',
    description:
      'Sélectionnez dans la liste ci-dessous le conseiller vers lequel transférer',
  },

  confirmUnassignment: {
    title:
      'Confirmez-vous que l\'affectation du conseiller pour les utilisateurs sélectionnés sera supprimée ?',
    description: 'Vous pouvez les affecter à un autre conseiller plus tard.',
    onConfirm: 'Confirmer',
    onClose: 'Annuler',
  },
};

export default adminDashboard;