import {
  contractParties,
  estateQuestions,
  marriageQuestions,
} from './questions';
import outputDoc from './outputDoc';

const marriageContract = {
  mainHeaders: {
    maximiseBenefits: 'Massimizza i vantaggi per il tuo coniuge',
    effectiveMarriageContract: 'Il tuo contratto matrimoniale efficace',
  },

  stepperInfo: [
    {
      label: 'Coniuge',
      title: 'Parti contrattuali',
    },
    {
      label: 'Matrimonio',
      title: 'Matrimonio',
    },
    {
      label: 'Patrimonio',
      title: 'Patrimonio',
    },
    {
      label: 'Il tuo contratto',
      title: 'Deposita il tuo contratto matrimoniale',
    },
  ],

  propertyTypes: {
    apartment: 'Appartamento',
    house: 'Casa',
    chalet: 'Chalet',
    rustico: 'Rustico',
    building_plot: 'Terreno edificabile',
    multi_family_house: 'Casa plurifamiliare',
    commercial_building: 'Edificio commerciale',
    residential_building: 'Edificio residenziale',
    commercial_property: 'Proprietà commerciale',
  },

  stepOne: {
    questions: contractParties,
  },
  stepTwo: {
    questions: marriageQuestions,
    confirmAssetDelete: {
      title: 'Sei sicuro di voler eliminare questo bene?',
      description: 'Puoi aggiungerlo di nuovo in qualsiasi momento.',
      cancel: 'Annulla',
      delete: 'Elimina',
    },
  },
  stepThree: {
    questions: estateQuestions,
    tooltips: {
      id2: 'Se scegli "Sì", il contratto includerà un paragrafo che conferma che il resto del tuo patrimonio è stato accumulato congiuntamente, il cosiddetto guadagno. Ciò significa che il coniuge superstite diventa automaticamente proprietario del 50% del patrimonio. Questo offre molti vantaggi per il tuo coniuge.',
      id4: 'Questa condizione deve essere soddisfatta per il nostro modulo. Se hai altre richieste, contatta un avvocato. Siamo felici di aiutarti a trovare un avvocato adatto.',
      id6: 'In questo caso, deve essere considerato il valore maggiore al momento del decesso',
      id7:
        'Se desideri autorizzare una terza persona a eseguire il tuo testamento, aggiungila qui. \n' +
        'Se non aggiungi una terza persona, il coniuge superstite è automaticamente autorizzato.\n' +
        "Si prega di notare che una terza persona ha generalmente il diritto di rifiutare l'incarico.\n" +
        "Se la persona accetta l'incarico, ha diritto a un compenso per le spese. Si applicano le disposizioni legali al momento del decesso.",
    },
  },
  stepFour: {
    document: outputDoc,
    introParagraph: 'Ben fatto!',
    nextStepsTitle: 'I prossimi passi:',
    nextStep1: 'Stampa il contratto matrimoniale e leggilo attentamente',
    nextStep2:
      'Contatta un notaio per far autenticare il tuo contratto (clicca qui per trovarne uno)',
    highlightedText: 'clicca qui',
    nextStep3: 'Carica qui sotto il contratto matrimoniale autenticato',
  },
};

export default marriageContract;
