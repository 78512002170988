const outputDoc = {
  title: 'Living will',
  from: 'from ',
  born: 'born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  civil_status: 'Civil status: {{civil_status}}',
  address: 'Address: {{address}}',
  declare: 'I, {{first_name}} {{last_name}}, declare the following: ',
  paragraph1:
    'In the event that I become incapable of making decisions due to illness or accident, I use this living will to establish my legally binding wishes regarding medical treatment, care, dying, and death. I appoint the following person(s) as my representative(s): ',
  paragraph2:
    'The appointed representative shall enforce my established will in the event of my incapacity and make medical and care decisions for me. They must be informed about my health condition and the expected course of the illness. I expressly release the treatment team from confidentiality to the extent necessary for a pending decision. The representative must be involved whenever possible before medical decisions are made. If this living will does not address a question, the representative shall decide according to my presumed will.',
  paragraph3:
    'The named person is also authorized to view the patient documentation and any autopsy report after my death.',
  repInformParagraph:
    'I have discussed the assignment with the representative on {{representative_inform_date}}.',
  secondaryRepParagraph:
    'In the event that the appointed person according to section 1 cannot fulfill their task, I appoint the following substitute: ',
  motivation: {
    title: 'Motivation and attitude',
    paragraph1: 'I issue this living will ',
    takePrecautions: 'in good health, but with foresight.',
    makeProvisions:
      'with a certain medical condition that leads me to make provisions.',
    makeProvisionsForFuture:
      'after reaching a certain age, where even short-term provisions must be made.',
    paragraph2: 'With this living will, I primarily want to ensure that ',
    alleviateSuffering:
      'medical options are primarily used to alleviate my suffering. Extending my life at any cost is not a priority for me. I accept that foregoing medical treatment may shorten my life.',
    exhaustedMedicalPossibilities:
      'all medical options to maintain my life are exhausted. My suffering should be alleviated as much as possible. I accept that this may involve burdens (e.g., impaired consciousness).',
  },
  medical: {
    title: 'Medical orders / treatment goal',
    paragraph1:
      'If I find myself in one of the following situations, I expect the treatment team to act as follows:',
    exhaustedMedicalPossibilities:
      'I primarily want effective treatment of pain and other symptoms such as anxiety. I accept that this may involve burdens (e.g., impaired consciousness).',
    alleviateSuffering:
      'I primarily want to maintain consciousness and my ability to communicate. I accept that this may impair the optimal relief of pain and other symptoms such as anxiety.',
  },
  forego_measures: {
    yes: 'I forgo all measures that merely result in prolonging life and suffering if I become incapable of making decisions due to an unexpected, acute event and if, after thorough medical assessment, it is impossible or unlikely that I will ever regain my decision-making capacity.',
    no: 'I want all possible measures to be implemented if I become incapable of making decisions due to an unexpected, acute event, even if, after thorough medical assessment, it is impossible or unlikely that I will ever regain my decision-making capacity.',
  },
  revive_preference: {
    paragraph: 'In the event of cardiac or respiratory arrest,',
    yes: 'a',
    no: 'no',
    paragraph1: 'resuscitation should be performed.',
  },
  moreDetails: {
    treatment_goal_sudden_incapacity: {
      paragraph: 'In the event of sudden incapacity (emergency)',
      resuscitationAndTreatment:
        'I wish for resuscitation and treatment in the intensive care unit (treatment goal extension).',
      treatedInIntensiveCareUnit:
        'I do not wish for resuscitation, but I do wish for treatment in the intensive care unit.',
    },
    suffering_relief_sudden_incapacity: {},
    treatment_goal_prolonged_incapacity: {
      paragraph: 'In the event of prolonged incapacity',
      resuscitationAndTreatment:
        'I wish for resuscitation and treatment in the intensive care unit (treatment goal extension).',
      treatedInIntensiveCareUnit:
        'I do not wish for resuscitation, but I do wish for treatment in the intensive care unit.',
    },
    treatment_goal_permanent_incapacity: {
      paragraph: 'In the event of permanent incapacity',
      resuscitationAndTreatment:
        'I wish for resuscitation and treatment in the intensive care unit (treatment goal extension).',
      treatedInIntensiveCareUnit:
        'I do not wish for resuscitation, but I do wish for treatment in the intensive care unit.',
    },
  },
  revocation: {
    title: 'Revocation',
    paragraph1: 'I hereby revoke all living wills of earlier dates.',
  },
  abilityToConsent: {
    title: 'Capacity to consent',
    paragraph1:
      'I make this declaration after careful consideration and in full responsibility for myself, and with the awareness that regarding medical measures, my doctors, caregivers, or authorized representatives are bound by the decision of the appointed person.',
    informedRepresentative:
      'I have discussed this directive with the persons mentioned at the beginning of the first page, who can confirm that, to their perception, I was capable of making decisions at that time and that the content corresponds to my will.',
  },
};

export default outputDoc;
