import { treatmentQuestions, detailedQuestions } from './questions';
import outputDoc from './outputDoc';

const livingWill = {
  mainHeaders: {
    shortPainFree: 'Your simple & effective living will',
    madeIt: 'Your effective living wll',
    detailedLivingWill: 'Well done, you made it',
  },

  stepperInfo: [
    {
      label: 'Basics',
      title: 'What is the topic?',
    },
    {
      label: 'Executor',
      title: 'The people who take care of me',
    },
    {
      label: 'Treatments',
      title: 'When should it become effective?',
    },
    // {
    //   label: '',
    //   title: 'Let's clarify the details',
    // },
    {
      label: 'Your living will',
      title: 'Submit your living will',
    },
  ],

  stepOne: {
    introParagraph:
      'With a living will, you determine how you want to be medically treated and cared for if you become incapable of making decisions.\n' +
      '\n' +
      'Any legally competent person can create, change, or revoke a living will. The living will also states who will enforce it in an emergency. You can appoint a representative who is authorized to make decisions for you and serves as a contact person for medical staff. Ideally, you appoint a primary person and a substitute.',
    helperParagraph: 'To get started, please fill out the following:',
  },

  stepTwo: {
    introParagraph: 'Representative',
    helperParagraph: 'My trusted person is:',
    optionalRepresentative:
      'If the person named above is unavailable, I appoint the following substitute:',

    contactConfirmation:
      'I confirm that I have informed the representative(s) about my wishes.',
  },

  stepThree: {
    introParagraph:
      'To ensure that the living will aligns with your wishes, you now need to answer some challenging and personal questions.',
    helperParagraph:
      'You need to consider how you define quality of life and in which situations you would forgo medical interventions. Take all the time you need. With our reminder service, you can pause and save at any time.',
    questions: treatmentQuestions,
  },

  stepFour: {
    introParagraph:
      'Please read the following scenarios carefully and check what best applies to you for each condition. For each health condition, we want to know which treatment you desire to extend your life and/or alleviate your suffering.',
    questions: detailedQuestions,
    highlightedText: {
      coma: 'Coma',
      dementia: 'Dementia',
      emergency: 'Emergency',
      goalOnSufferingRelief: 'Goal of suffering relief',
      prolongedIncapacity: 'Prolonged incapacity',
      permanentIncapacity: 'Permanent incapacity',
      suddenlyIncacitated: 'Suddenly incapacitated',
      sufferingRelief: 'Suffering relief',
      suddenIncapacitation: 'Sudden incapacitation',
      treatmentGoalForLifeExtension: 'Treatment goal for life extension',
      treatmentGoalsForLifeExtension: 'Treatment goals for life extension',
      treatmentGoalForSufferingRelief: 'Treatment goal for suffering relief',
      vegetativeState: 'Vegetative state',
    },
  },

  stepFive: {
    introParagraph: 'Well done, you are now organised!',
    helperParagraph:
      'The hardest step is now complete. Now you just need to print, date, and sign the living will and hand a copy to your representative(s) (preferably in person) and your family doctor. Please also discuss the living will with your family at an appropriate time.',
    here: 'here',
    eightMinutes: '8 minutes',
    helperParagraph2:
      'If you want a more detailed version of the advanced directive, you can continue here, which takes about 8 minutes.',
    document: outputDoc,
    nextStepsTitle: 'The next steps:',
    nextStep1: 'Print, date, sign the living will, and upload it here',
    nextStep2:
      'Send a copy to your family doctor and ensure that trusted persons have guest access to well-regulated so they can access the power of attorney in an emergency (click here)',
    highlightedText: 'click here',
    nextStep3: 'Discuss the living will with the family',
  },
};

export default livingWill;
