import { useLocation } from 'react-router-dom';
import Banner from '#/components/shared/ui/banner';
import { LandingContainer } from '#/components/pages/LSLandings/styles';
import SituationCard from '#/components/shared/ui/situation-card';
import landingContent from '#/components/pages/LSLandings/landing-content';
import Page from '#/components/shared/ui/Page';
import metadata from '#/components/pages/LSLandings/constants/metadata';
import IntroHead from '#/components/shared/IntroHead';
import useLocales from '#/lib/hooks/useLocales';

export default function Landing() {
  const location = useLocation();
  const { translate } = useLocales();

  const url = location.pathname.split('/').pop();

  const content = landingContent[url as keyof typeof landingContent];
  const metaTitle = metadata[url as keyof typeof metadata].title;
  const metaDescription = metadata[url as keyof typeof metadata].description;
  const { sectionHeadline, bannerDescription, bannerImg, bannerTitle, cards } =
    content;

  return (
    <Page title={translate(metaTitle)} description={translate(metaDescription)}>
      <LandingContainer>
        <Banner
          title={translate(bannerTitle)}
          description={translate(bannerDescription)}
          image={bannerImg}
        />
        <IntroHead
          variant="h2"
          component="h3"
          title={translate(sectionHeadline || '')}
          description=""
        />
        {cards.map((card, i) => (
          <SituationCard order={i + 1} key={card.service} situation={card} />
        ))}
      </LandingContainer>
    </Page>
  );
}
