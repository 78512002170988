const assetsRegister = {
  mainTitle: 'Aperçu de votre succession',
  mainDescription:
    'Créez pour vous et vos proches un aperçu central des principaux actifs financiers et non financiers et des institutions où vous les détenez (par exemple, banques ou assurances).',

  exportAssets: 'Exporter les actifs',
  exportFinancialInstitutions: 'Exporter les institutions financières',
  exportAllData: 'Exporter toutes les données',

  tabs: {
    assets: 'Actifs',
    financialInstitutions: 'Institutions financières',
  },

  deleteAsset: {
    title:
      'Vous êtes sur le point de supprimer {{asset}} de la liste. Êtes-vous sûr ?',
    description: 'Veuillez confirmer la suppression.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },

  deleteFinancialInstitution: {
    title:
      'Vous êtes sur le point de supprimer {{institution}} de la liste. Êtes-vous sûr ?',
    description: 'Veuillez confirmer la suppression.',
    onConfirm: 'Supprimer',
    onClose: 'Annuler',
  },

  assetsTable: {
    tableHeads: {
      description: 'Description',
      storageLocation: 'Lieu de stockage',
      designatedBeneficiary: 'Bénéficiaire désigné',
    },
    addAsset: 'Ajouter un actif',
  },

  institutionType: {
    secondary: 'Banque secondaire',
    primary: 'Banque principale',
  },

  financialInstitutionsTable: {
    tableHeads: {
      institution: 'Institution',
      type: 'Type',
    },
    addFinancialInstitution: 'Ajouter une institution financière',
  },
};

export default assetsRegister;