import { MenuItem, Stack, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  invalidateRegisterOfAssets,
  useAddFinancial,
} from '#/api/assetsRegister';

type Props = {
  onClose: () => void;
};

const INSTITUTION_TYPES = [
  {
    value: 'primary',
  },
  {
    value: 'secondary',
  },
];

export default function AddFinancialInstitution({ onClose }: Props) {
  const { translate } = useLocales();

  const Schema = Yup.object().shape({
    institution_name: Yup.string().required(
      translate('validations.digitalAssets.financial.institution_name')
    ),
    type: Yup.string().required(
      translate('validations.digitalAssets.financial.type')
    ),
  });

  const defaultValues = {
    institution_name: '',
    type: '',
  };

  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const { mutateAsync: addFinancial } = useAddFinancial();

  const disabled = !isDirty;

  const onSubmit = async (data: any) => {
    try {
      await addFinancial(data).then(() => {
        toast.success(
          translate('toast_notifications.success.addedFinancialInstitution')
        );
        onClose();
        invalidateRegisterOfAssets.getFinancial();
      });
    } catch (error) {
      toast.error(
        translate('toast_notifications.error.addedFinancialInstitution')
      );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} direction="row" sx={{ marginY: 2 }}>
        <RHFTextField
          name="institution_name"
          label={translate('global.formLabels.institutionName')}
        />
        <RHFSelect name="type" label={translate('global.formLabels.type')}>
          {INSTITUTION_TYPES.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {translate(`assetsRegister.institutionType.${type.value}`)}
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        sx={{ marginTop: 2 }}
      >
        <LoadingButton variant="contained" type="submit" disabled={disabled}>
          <Typography>{translate('global.save')}</Typography>
        </LoadingButton>
        <Button variant="text" onClick={onClose}>
          <Typography>{translate('global.cancel')}</Typography>
        </Button>
      </Stack>
    </FormProvider>
  );
}
