import powerOfTrustQuestions from './questions';
import outputDoc from './outputDoc';

const powerOfAttorney = {
  mainHeaders: {
    shortPainFree: 'Mandato Precauzionale: breve e indolore',
    madeIt: 'Il tuo efficace mandato precauzionale',
  },

  stepperInfo: [
    {
      label: 'Procura fiduciaria',
      title: 'Di cosa si tratta?',
    },
    {
      label: 'Il tuo mandato precauzionale',
      title: 'Deposita il tuo mandato precauzionale',
    },
  ],

  stepOne: {
    introParagraph:
      'Purtroppo ci sono situazioni che possono portare alla nostra incapacità di intendere e di volere. Esempi di ciò sono gravi incidenti o malattie come la demenza.',
    helperParagraph:
      'Ti consigliamo quindi di stabilire in anticipo, tramite una cosiddetta direttiva anticipata, chi dovrebbe prendersi cura di te in una tale situazione e rappresentarti legalmente. Questo allevia i tuoi cari in una situazione già difficile, poiché i tuoi desideri sono chiaramente formulati.',
    helperParagraph2:
      'Iniziamo: questa sezione richiede solo circa 5 minuti. Se hai bisogno di una pausa, puoi fermarti e salvare in qualsiasi momento.',
    questions: powerOfTrustQuestions,
  },

  stepTwo: {
    introParagraph: "Congratulazioni! Ce l'hai fatta.\n",
    helperParagraph:
      'Il passo più difficile è ora completato. Scarica il documento, stampalo e leggilo attentamente per vedere se corrisponde davvero ai tuoi desideri. Se sei soddisfatto, ti consigliamo di discutere il mandato precauzionale con una persona di fiducia e la tua famiglia.',
    helperParagraph2:
      'Si consiglia di depositare il mandato precauzionale presso un avvocato o un notaio, che può registrare il luogo di conservazione su tua richiesta presso il Registro Svizzero dei Testamenti (https://www.ztr.ch).',
    contactForm: 'Modulo di contatto',
    helperParagraph3:
      'Se desideri che ti aiutiamo, utilizza il nostro modulo di contatto.',
    obligation1:
      "Il mandato precauzionale deve essere redatto a mano o autenticato pubblicamente. Il mandato precauzionale scritto a mano deve essere scritto, datato e firmato dall'inizio alla fine dalla persona che lo emette.",
    obligation2: 'Se sei incerto, un avvocato o un notaio può aiutarti.',
    document: outputDoc,
    nextStepsTitle: 'I prossimi passi:',
    nextStep1:
      "Copia a mano l'intera procura, datala e firmala oppure falla autenticare da un avvocato e caricala qui",
    nextStep2:
      'Assicurati che le persone di fiducia abbiano un accesso ospite per gut geregelt, in modo che possano accedere alla procura in caso di emergenza (clicca qui)',
    highlightText: 'clicca qui',
    nextStep3: 'Discuti il mandato precauzionale con la famiglia',
    highlightedText: {
      noterizeWithLawyer: 'Falla autenticare da un avvocato',
    },
  },
};

export default powerOfAttorney;
