import { useState, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Upload } from '../upload';
import Iconify from '../ui/Iconify';
import {
  invalidateDigitalAssetsQueries,
  useUploadFile,
} from '#/lib/api/digitalAssets';
import useLocales from '#/lib/hooks/useLocales';

interface Props extends DialogProps {
  title?: string;
  //
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  //
  folderName?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //
  open: boolean;
  onClose: VoidFunction;
  parent?: string | number | null;
  isLoading?: boolean;
  inputLabel?: string;
}

export default function FileManagerNewFolderDialog({
  title = 'Upload Files',
  open,
  onClose,
  //
  onCreate,
  onUpdate,
  //
  folderName,
  onChangeFolderName,
  parent,
  inputLabel,
  isLoading,
  ...other
}: Props) {
  const [files, setFiles] = useState<(File | string)[]>([]);

  const { translate } = useLocales();
  const { mutateAsync: uploadFile, isLoading: uploadingFiles } =
    useUploadFile();

  const handleFileUpload = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file);
      if (parent) {
        formData.append('folder', parent.toString());
      }
    });

    try {
      await uploadFile(formData);
      invalidateDigitalAssetsQueries.getFiles();
      onClose();
    } catch (error) {
      onClose();
      console.error('Error uploading file: ');
    }
  };

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {' '}
        {title}{' '}
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {onCreate || onUpdate ? (
          <TextField
            fullWidth
            label={inputLabel || translate('global.folderName')}
            value={folderName}
            onChange={onChangeFolderName}
            sx={{ mb: 3 }}
          />
        ) : (
          <Upload
            multiple
            files={files}
            onDrop={handleDrop}
            onRemove={handleRemoveFile}
          />
        )}
      </DialogContent>

      <DialogActions>
        {!onCreate && !onUpdate && (
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            onClick={handleFileUpload}
          >
            <Typography>
              {uploadingFiles
                ? translate('global.uploading')
                : translate('global.upload')}
            </Typography>
          </Button>
        )}

        {!!files.length && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleRemoveAllFiles}
          >
            <Typography>{translate('global.removeAll')}</Typography>
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <LoadingButton
              variant="contained"
              loading={isLoading || uploadingFiles}
              onClick={onCreate || onUpdate}
            >
              <Typography>
                {onUpdate
                  ? translate('global.save')
                  : translate('global.create')}
              </Typography>
            </LoadingButton>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}
