import { IQuestion } from '#/types/globalTypes';

export const treatmentQuestions: IQuestion[] = [
  {
    question:
      'Why are you creating this living will? What best describes your current situation?',
    options: [
      {
        label: 'I am in good health, but I want to make provisions',
      },
      {
        label: 'I have health issues and want to make provisions',
      },
      {
        label:
          'I have reached a certain age and want to make provisions for the future',
      },
    ],
  },
  {
    question:
      'What is more important to you in pain and symptom management? Please check the appropriate statement.',
    options: [
      {
        label:
          'I want all medical options to be exhausted to preserve my life, even if it involves burdens (suffering)',
      },
      {
        label:
          'I want medical treatment to primarily serve the relief of suffering, even if it may shorten my life',
      },
    ],
  },
  {
    question:
      'Do you want to forgo all life-prolonging and suffering-prolonging measures if you become incapable of making decisions due to an unexpected, acute event and it is deemed impossible or unlikely by thorough medical assessment that you will regain your decision-making capacity?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you want to be resuscitated?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
];

export const detailedQuestions: IQuestion[] = [
  {
    question:
      'In a scenario where you suddenly become incapable of making decisions, e.g., in an emergency, what would be your treatment goal for life extension?',
    options: [
      {
        label: 'I want to receive resuscitation and treatment',
      },
      {
        label:
          'I do not want to be resuscitated, but I want to be treated in the intensive care unit',
      },
    ],
  },
  {
    question:
      'What would be your treatment goal for alleviating suffering in the event of sudden incapacity?',
    options: [
      {
        label:
          'I want to receive pain-relieving medications (morphine, etc.) and treatment therapies',
      },
      {
        label:
          'I do not want to receive pain-relieving medications (morphine, etc.) and treatment therapies',
      },
    ],
  },
  {
    question:
      'In a scenario where you are permanently incapacitated, e.g., due to dementia, what would be your treatment goals for life extension?',
    options: [
      {
        label:
          'I want to be resuscitated and treated in the intensive care unit',
      },
      {
        label:
          'I do not want to be resuscitated, but I want to be treated in the intensive care unit',
      },
    ],
  },
  {
    question:
      'What goals would you pursue in the event of sudden permanent incapacity?',
    options: [
      {
        label:
          'I want to receive pain-relieving medications (morphine, etc.) and treatment therapies',
      },
      {
        label:
          'I do not want to receive pain-relieving medications (morphine, etc.) and treatment therapies',
      },
    ],
  },
  {
    question:
      'What would be your treatment goals for life extension in a scenario where you are permanently incapacitated, for example, due to dementia?',
    options: [
      {
        label:
          'I want to receive resuscitation and treatment in the intensive care unit',
      },
      {
        label:
          'I do not want to be resuscitated, but I want to be treated in the intensive care unit',
      },
    ],
  },
  {
    question:
      'What would be your goals for alleviating suffering in the event of sudden permanent incapacity?',
    options: [
      {
        label:
          'I want to receive pain-relieving medications (morphine, etc.) and treatment therapies',
      },
      {
        label:
          'I do not want to receive pain-relieving medications (morphine, etc.) and treatment therapies',
      },
    ],
  },
];