import { Situation } from '#/components/shared/ui/situation-card/types';
import { getLivingWillSituation } from '#/components/pages/LSLandings/constants/living-will';
import { getMarriageContractSituation } from '#/components/pages/LSLandings/constants/marriage-contract';
import { getLastWillSituation } from '#/components/pages/LSLandings/constants/last-will';
import { getPOASituation } from '#/components/pages/LSLandings/constants/poa';
import { getDigitalFootprintSituation } from '#/components/pages/LSLandings/constants/digital-footprint';
import { getFinancialPlanningSituation } from '#/components/pages/LSLandings/constants/financial-planning';
import { getAssetsRegisterSituation } from '#/components/pages/LSLandings/constants/assets-register';

type LsLandingContent = {
  [key: string]: {
    bannerImg: string;
    bannerTitle: string;
    bannerDescription: string;
    sectionHeadline?: string;
    cards: Situation[];
  };
};

const landingContent: LsLandingContent = {
  heirat: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/vorsorge-marriage.webp',
    bannerTitle: 'lsLandingMains.heirat.bannerTitle',
    bannerDescription: 'lsLandingMains.heirat.bannerDescription',
    sectionHeadline: 'lsLandingMains.heirat.sectionHeadline',
    cards: [
      getMarriageContractSituation('heirat'),
      getLivingWillSituation('heirat'),
      getLastWillSituation('heirat'),
    ],
  },

  'geburt-eines-kindes': {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/birthOfChild.webp',
    bannerTitle: 'lsLandingMains.geburt-eines-kindes.bannerTitle',
    bannerDescription: 'lsLandingMains.geburt-eines-kindes.bannerDescription',
    sectionHeadline: 'lsLandingMains.geburt-eines-kindes.sectionHeadline',
    cards: [
      getPOASituation('geburt-eines-kindes'),
      getLastWillSituation('geburt-eines-kindes'),
      getMarriageContractSituation('geburt-eines-kindes'),
      getLivingWillSituation('geburt-eines-kindes'),
    ],
  },

  immobilienkauf: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/purchaseOfRealEstate.webp',
    bannerTitle: 'lsLandingMains.immobilienkauf.bannerTitle',
    bannerDescription: 'lsLandingMains.immobilienkauf.bannerDescription',
    sectionHeadline: 'lsLandingMains.immobilienkauf.sectionHeadline',
    cards: [
      getPOASituation('immobilienkauf'),
      getMarriageContractSituation('immobilienkauf'),
      getLivingWillSituation('immobilienkauf'),
    ],
  },

  pensionierung: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/enteringRetirement.webp',
    bannerTitle: 'lsLandingMains.pensionierung.bannerTitle',
    bannerDescription: 'lsLandingMains.pensionierung.bannerDescription',
    sectionHeadline: 'lsLandingMains.pensionierung.sectionHeadline',
    cards: [
      getLivingWillSituation('pensionierung'),
      getLastWillSituation('pensionierung'),
      getPOASituation('pensionierung'),
      getDigitalFootprintSituation('pensionierung'),
      getFinancialPlanningSituation('pensionierung'),
      getAssetsRegisterSituation('pensionierung'),
    ],
  },

  'tod-verwandter': {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/deathOfARelative.webp',
    bannerTitle: 'lsLandingMains.tod-verwandter.bannerTitle',
    bannerDescription: 'lsLandingMains.tod-verwandter.bannerDescription',
    sectionHeadline: 'lsLandingMains.tod-verwandter.sectionHeadline',
    cards: [
      getDigitalFootprintSituation('tod-verwandter'),
      getAssetsRegisterSituation('tod-verwandter'),
      getLivingWillSituation('tod-verwandter'),
    ],
  },

  unternehmensgruendung: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/foundingACompany.webp',
    bannerTitle: 'lsLandingMains.unternehmensgruendung.bannerTitle',
    bannerDescription: 'lsLandingMains.unternehmensgruendung.bannerDescription',
    sectionHeadline: 'lsLandingMains.unternehmensgruendung.sectionHeadline',
    cards: [
      getPOASituation('unternehmensgruendung'),
      getLastWillSituation('unternehmensgruendung'),
      getDigitalFootprintSituation('unternehmensgruendung'),
      getLivingWillSituation('unternehmensgruendung'),
    ],
  },

  scheidung: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/divorce.webp',
    bannerTitle: 'lsLandingMains.scheidung.bannerTitle',
    bannerDescription: 'lsLandingMains.scheidung.bannerDescription',
    sectionHeadline: 'lsLandingMains.scheidung.sectionHeadline',
    cards: [
      getPOASituation('scheidung'),
      getFinancialPlanningSituation('scheidung'),
      getLastWillSituation('scheidung'),
    ],
  },

  krankheit: {
    bannerImg:
      'https://storage.gutgeregelt.ch/assets/vorsorge/banners/illness.webp',
    bannerTitle: 'lsLandingMains.krankheit.bannerTitle',
    bannerDescription: 'lsLandingMains.krankheit.bannerDescription',
    sectionHeadline: 'lsLandingMains.krankheit.sectionHeadline',
    cards: [
      getLivingWillSituation('krankheit'),
      getLastWillSituation('krankheit'),
    ],
  },
};

export default landingContent;
