const outputDoc = {
  title: 'Contrat de mariage',
  from: 'de ',
  born: 'né le ',
  civilStatus: {
    single: 'Célibataire',
    married: 'Marié',
    widowed: 'Veuf',
    divorced: 'Divorcé',
    registered_partnership: 'Partenariat enregistré',
  },
  civil_status: 'État civil : {{civil_status}}',
  address: 'Adresse : {{address}}',
  male: 'Épouse',
  female: 'Époux',
  and: 'et',
  spousePresentation:
    '{{spouseLastName}} {{spouseName}}, {{relation}}, né le {{spouseBirthDate}}, de {{spousePlaceOfCitizenship}}, {{spouseCivilStatus}}, résidant à {{spouseAddress}}.',
  personalPresentation:
    '{{firstName}} {{lastName}}, {{relation}}, né le {{birthDate}}, de {{placeOfCitizenship}}, {{civilStatus}}, résidant à {{address}}.',
  determinations: {
    title: 'Dispositions',
    section1: {
      firstParagraphWithChildren:
        'Les parties contractantes se sont mariées le {{weddingDate}} à {{weddingLocation}}.',
      oneCommonChildParagraph: 'De leur mariage est né un enfant commun : ',
      multipleCommonChildrenParagraph:
        'De leur mariage sont nés {{numberOfChildren}} enfants communs : ',
      childBirthday: 'né le {{birthday}}',
      firstParagraphWithoutChildren:
        "Les parties contractantes se sont mariées le {{weddingDate}} à {{weddingLocation}}. De leur mariage, aucun enfant commun n'est né.",
      noChildrenOutsideMarriage:
        "Les parties contractantes n'ont pas d'enfants non communs.",
      oneChildOutsideMarriage:
        'Les parties contractantes ont un enfant non commun : ',
      multipleChildrenOutsideMarriage:
        'Les parties contractantes ont {{numberOfChildren}} enfants non communs : ',
    },
    section2: {
      noMarriageContract:
        "À ce jour, les parties contractantes n'ont jamais conclu de contrat de mariage. Il n'y a pas eu de séparation de biens judiciaire ou légale.",
    },
    section3: {
      participationAgreement:
        'La participation aux acquêts comprend les acquêts et les biens propres de chaque époux. Dans les limites légales, chaque époux gère et utilise ses acquêts et ses biens propres et en dispose.',
    },
    section4: {
      personalAssets:
        "Outre les objets destinés exclusivement à l'usage personnel de chaque époux, les parties contractantes possèdent les biens propres suivants :",
      pointA: 'a.) Époux : ',
      pointB: 'b.) Épouse : ',
    },
    section5: {
      otherAssets: 'Tous les autres actifs',
      evenEstates: 'y compris ',
      otherAssetsContinued:
        "ont été acquis conjointement par les parties contractantes après le mariage ; ils constituent des acquêts au sens de l'art. 197 CC.",
      apartment: "de l'appartement",
      house: 'de la maison',
      chalet: 'du chalet',
      rustico: 'du rustico',
      building_plot: 'du terrain à bâtir',
      multi_family_house: 'de la maison multifamiliale',
      commercial_building: 'du bâtiment commercial',
      residential_building: 'du bâtiment résidentiel',
      commercial_property: 'de la propriété commerciale',
      nonAcquiredAfterMarriage:
        "Les {{assetsQuantity}} suivants ont été acquis conjointement par les parties contractantes après le mariage ; ils constituent des acquêts au sens de l'art. 197 CC : ",
      plural: 'biens immobiliers',
      singular: 'bien immobilier',
    },
  },
  marriageContractAgreements: {
    title: 'Accords contractuels de mariage',
    section1: {
      firstParagraph:
        'Les parties contractantes conviennent expressément de maintenir le régime ordinaire de la participation aux acquêts.',
    },
    section2: {
      firstParagraph:
        "En vertu de l'art. 216 al. 1 CC et en dérogation à la participation aux propositions prévue à l'art. 215 CC, les parties contractantes conviennent qu'en cas de dissolution du mariage par le décès d'un époux, la totalité des propositions des deux époux revient en pleine propriété au survivant.",
    },
    section3: {
      firstParagraph:
        "En application de l'art. 206 al. 3 CC, les époux excluent toute participation réciproque à la plus-value en cas de dissolution du mariage par décès.",
    },
  },
  jointProvisions: {
    title: 'Dispositions communes',
    section1: {
      firstParagraph:
        'Les parties contractantes révoquent par la présente toutes les dispositions antérieures de dernière volonté. Sont exclues de cette révocation les éventuelles déclarations de bénéficiaires envers les assurances et les institutions de prévoyance.',
    },
    section2: {
      firstParagraph:
        "Dans le cadre d'une disposition de partage, il est stipulé que le conjoint survivant peut, à sa discrétion, reprendre les biens mobiliers et capitaux qui lui conviennent, en déduction des droits matrimoniaux et successoraux. Cette disposition de partage s'applique expressément aussi aux biens immobiliers qui doivent être inscrits en propriété exclusive du conjoint survivant dans le registre foncier correspondant. La valeur de référence est la valeur vénale officielle (valeur fiscale) ; à la date du décès du premier défunt.",
      secondParagraph:
        "Le conjoint survivant a également le droit de régler les droits successoraux des autres héritiers, à son choix, en espèces ou par l'attribution d'autres actifs. L'ensemble du ménage revient au conjoint survivant en pleine propriété sans déduction de la part successorale.",
    },
    section3: {
      firstParagraph:
        "Si le conjoint survivant se remarie, il doit verser aux descendants le montant qu'ils auraient reçu lors du décès du premier défunt dans le cadre d'un partage matrimonial et successoral conformément aux dispositions légales et sans ce contrat. Il est tenu compte de ce que les descendants ont déjà reçu de la succession du premier défunt malgré ce contrat et de ce qui leur a été attribué depuis par le conjoint survivant par donation. La base de l'actif successoral est l'inventaire successoral fiscal, établi par les autorités cantonales ou par les héritiers eux-mêmes au décès du premier parent/conjoint défunt. Les droits des descendants deviennent exigibles à la date de la condition et ne sont ni à porter intérêt ni à garantir jusqu'à cette date. Il est laissé au parent/conjoint survivant le choix de la forme de paiement.",
    },
    section4: {
      firstParagraph:
        'Ce contrat de mariage est soumis au droit suisse, sous réserve de dispositions légales impératives contraires. Dans la mesure du possible, les autorités suisses sont également compétentes pour les relations matrimoniales et successorales des parties contractantes.',
    },
    section5: {
      firstParagraph:
        "Ce contrat de mariage perd sa validité si, au décès du premier défunt, le mariage des parties contractantes est légalement dissous, au sens de l'art. 117 CC, par jugement ou de fait, ou si une demande de divorce ou de protection du mariage est en cours.",
    },
  },
  contractualAgreements: {
    title: 'Accords contractuels de succession',
    section1: {
      firstParagraph:
        "Au décès du premier défunt, le survivant conserve ses biens propres et reçoit, en vertu de l'accord matrimonial ci-dessus, les propositions des deux époux en propriété.",
    },
    section2: {
      firstParagraph:
        'En outre, les parties contractantes se favorisent également sur le plan successoral, dans la mesure où cela est légalement autorisé.',
      nonCommonChildren:
        'Le conjoint survivant peut ainsi choisir parmi le patrimoine successoral restant entre :',
      pointA:
        "a.) la part successorale maximale possible (actuellement 5/8) en propriété et l'attribution de la réserve aux descendants",
      pointB:
        "b.) la quotité libre (actuellement 1/4) en propriété ainsi que la jouissance gratuite du reste du patrimoine successoral revenant aux descendants, conformément à l'art. 473 CC, sans avoir à fournir de garantie.",
    },
    section4: {
      firstParagraph:
        'Les époux se réservent mutuellement le droit de disposer librement, par disposition testamentaire, du montant le plus élevé entre CHF {{disposal_fixed_amount}} ou {{disposal_percentage}}% de leur patrimoine net.',
    },
  },
  signatures: {
    title: 'Signature {{gender}}',
    female: 'Épouse',
    male: 'Époux',
  },
  notarization: {
    title: 'Authentification',
    firstParagraph: 'Le notaire soussigné certifie par la présente que',
    firstCondition: 'il a lu le présent acte aux parties contractantes,',
    secondCondition:
      "l'acte correspond à la volonté qui lui a été communiquée par les parties contractantes,",
    thirdCondition:
      "l'acte a été signé à la main par les parties contractantes en sa présence,",
    noterTitle: 'Signature du notaire',
  },
};

export default outputDoc;
