import { Box, TextField } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  onSearch: (search: string) => void;
};

export default function SearchBar({ onSearch }: Props) {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TextField
        name="search"
        placeholder={translate('global.search')}
        // label={translate('global.search')}
        InputProps={{
          startAdornment: <Iconify icon="eva:search-fill" />,
        }}
        onChange={(e) => onSearch(e.target.value)}
        sx={{
          width: '640px',
        }}
      />
    </Box>
  );
}
