const assetsRegister = {
  mainTitle: 'Overview of your estate',
  mainDescription:
    'Create a central overview of the most important financial and non-financial assets for yourself and your loved ones, as well as the institutions where you hold them (e.g., banks or insurance companies).',

  exportAssets: 'Export assets',
  exportFinancialInstitutions: 'Export financial institutions',
  exportAllData: 'Export all data',

  tabs: {
    assets: 'Assets',
    financialInstitutions: 'Financial institutions',
  },

  deleteAsset: {
    title:
      'You are about to remove {{asset}} from the list. Are you sure?',
    description: 'Please confirm the deletion.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },

  deleteFinancialInstitution: {
    title:
      'You are about to remove {{institution}} from the list. Are you sure?',
    description: 'Please confirm the deletion.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },

  assetsTable: {
    tableHeads: {
      description: 'Description',
      storageLocation: 'Storage location',
      designatedBeneficiary: 'Beneficiary',
    },
    addAsset: 'Add asset',
  },

  institutionType: {
    secondary: 'Non-primary bank',
    primary: 'Primary bank',
  },

  financialInstitutionsTable: {
    tableHeads: {
      institution: 'Institution',
      type: 'Type',
    },
    addFinancialInstitution: 'Add financial institution',
  },
};

export default assetsRegister;
