const marriageContractLanding = {
  mainBanner: {
    intro: 'Create your marriage contract online:',
    title: 'Legally secure, completed in 10 minutes',
    subtitle:
      'A marriage contract helps spouses protect their assets and avoid financial disputes during or after the marriage.',
    buttonLabel: 'Create marriage contract',
  },
  youtubeTutorial: 'Why is a marriage contract important?',
  aboutCards: {
    protectSpouse: {
      title: 'Protect your spouse',
      description:
        "A marriage contract protects you, so that in the event of death, for example your spouse doesn't have to sell the property due to inheritance issues with any children.",
    },
    winClarity: {
      title: 'Gain clarity',
      description:
        'Define in advance how assets will be divided and finances managed in the event of death.',
    },
    saveDiscussions: {
      title: 'Avoid disputes amongst the family',
      description:
        'Establish clarity by formalizing financial arrangements and inheritance matters, reducing potential conflicts.',
    },
  },
  subBanner: {
    title: 'Organise your affairs with a legally compliant marriage contract',
    subtitle:
      'With "gut geregelt," you can quickly create your marriage contract, access legal advice on sensitive topics or open questions, and protect yourself and your spouse. Create it now and then have it notarized.',
    buttonLabel: 'Create for free now',
  },
  testimonials: {
    testimonial1: {
      name: 'Daniel Schreiber',
      text: "My wife and I have two children and bought a property last year. With the marriage contract, we ensure that my wife won't have to sell the house to pay out the children's inheritance share in the event of my death. It was very simple.",
    },
    testimonial2: {
      name: 'Greta Beck',
      text: 'My husband and I tried a few times to get started on a marriage contract. "gut geregelt" didn\'t completely eliminate the need for a lawyer, but it made the initial steps much easier.',
    },
    testimonial3: {
      name: 'Sarah Barmettler',
      text: 'Simple and straightforward process. We researched online beforehand and knew what we wanted. The "gut geregelt" offer was perfect. We then went to the local notary for notarization.',
    },
  },
  newsletter: {
    title: 'Get organized with your marriage contract',
    buttonLabel: 'Create now',
  },
  faq: {
    questions: {
      question1: 'What is a marriage contract?',
      question2: 'Why should one enter into a marriage contract?',
      question3: 'When should a marriage contract be concluded?',
      question4: 'How is a marriage contract concluded in Switzerland?',
      question5: 'Can a marriage contract be modified later?',
      question6: 'What happens if no marriage contract is concluded?',
    },
    answers: {
      answer1:
        'A marriage contract is a legally binding document that spouses can enter into before or during marriage. It governs the financial arrangements between the partners and may include agreements on asset division, debt responsibility, and support in the event of divorce or death.',
      answer2:
        'A marriage contract allows for clear and personalized financial arrangements between spouses. It can help avoid misunderstandings and disputes, providing clear rules in the event of divorce or death. A contract can be particularly beneficial for couples with differing assets or incomes, business involvement, or patchwork families.',
      answer3:
        "A marriage contract can be signed at any time before or during the marriage. However, it's recommended to do so before marriage to establish clear terms from the start.",
      answer4:
        'In Switzerland, a marriage contract must be publicly notarized, meaning it has to be reviewed and notarized by a notary. Both spouses must be present and sign the contract.',
      answer5:
        'Yes, a marriage contract can be amended or revoked at any time by mutual agreement. This too requires notarization by a notary.',
      answer6:
        'Without a marriage contract, the default Swiss marital property regime applies, which involves sharing marital assets acquired during the marriage equally upon divorce. Assets owned prior to marriage and inheritances are considered personal property and are excluded from division.',
    },
  },

  situations: {
    heirat: {
      content: {
        main: {
          title: 'Prenuptial Agreement',
          description:
            'A prenuptial agreement regulates how your assets will be divided during the marriage and in the event of separation or divorce. This creates clear conditions and avoids uncertainties.',
        },
        columns: [
          {
            title: 'Why Now',
            description: [
              'The best time for a prenuptial agreement is right after the marriage. This ensures that both partners are treated fairly if your life circumstances change.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Clear asset division',
              'Protection of both partners',
              'Prevention of conflicts',
            ],
          },
        ],
      },
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Prenuptial Agreement',
          description:
            'A prenuptial agreement regulates the division of assets, especially if you are purchasing a property together. This protects both partners and ensures that everything is clearly regulated.',
        },
        columns: [
          {
            title: 'Why Now',
            description: [
              'When buying a property, the question of asset division arises. A prenuptial agreement creates clarity and ensures that you and your partner are protected in the event of separation or an unexpected event.',
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              'Clear asset regulations for joint properties',
              'Protection of both partners in case of separation or death',
              'Avoidance of later disputes',
            ],
          },
        ],
      },
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Prenuptial Agreement',
          description:
            'With a prenuptial agreement, you create clear regulations regarding the assets and the security of your child. This ensures protection if your life circumstances change.',
        },
        columns: [
          {
            title: 'Why Now',
            description: [
              "Now that a child is part of your family, a prenuptial agreement offers additional protection for your child's assets. It creates clear conditions for the distribution of assets and the provision for your child.",
            ],
          },
          {
            title: 'Your Benefits',
            description: [
              "Protection of the child's assets",
              'Clear regulations in the event of a separation',
              'Avoidance of disputes',
            ],
          },
        ],
      },
    },
  },
};

export default marriageContractLanding;
