const tt = {
  title: 'Condizioni Generali',

  scope: {
    title: 'Ambito',
    content:
      'Queste Condizioni Generali (CG) regolano l\'uso dei servizi di betascale AG (di seguito denominata "gut geregelt"). Gut geregelt fornisce al cliente i servizi per la durata del contratto nella versione attuale dietro pagamento. Il rapporto contrattuale tra gut geregelt e il cliente si instaura con l\'accettazione dell\'offerta. Con l\'accettazione dell\'offerta, il cliente riconosce espressamente queste CG, anche se queste contraddicono in tutto o in parte le proprie condizioni generali. Il cliente è tenuto a fornire in ogni momento informazioni corrette, complete e aggiornate. Il mancato rispetto di questo obbligo costituisce una violazione delle condizioni d\'uso e può portare alla risoluzione immediata del contratto.',
  },
  userRights: {
    title: 'Diritti dell\'utente',
    content:
      'gut geregelt concede al cliente, per la durata del contratto, un diritto non esclusivo e non trasferibile di utilizzare i servizi in conformità con le disposizioni. Il cliente non è autorizzato a mettere i servizi a disposizione di terzi a pagamento o gratuitamente. Qualsiasi forma di fornitura del servizio a terzi è espressamente vietata. Il cliente deve garantire che tutti i rapporti contrattuali con terzi escludano l\'uso gratuito del servizio.',
  },
  customerResponsibilities: {
    title: 'Responsabilità del cliente',
    content:
      'Oltre ai servizi forniti da gut geregelt, il cliente è pienamente responsabile del funzionamento, della sicurezza e dello stato dei propri media elettronici e digitali su cui vengono utilizzati i servizi di gut geregelt (hardware, software, operatività, sicurezza, ecc.). Tutti i costi associati all\'adempimento di questi obblighi sono a carico del cliente.',
  },
  systemAvailability: {
    title: 'Disponibilità del sistema',
    content:
      'I servizi di gut geregelt si basano sul principio del "Best-Effort". Gut geregelt adotta misure adeguate per garantire un utilizzo il più possibile ininterrotto dei servizi. Tuttavia, il cliente è consapevole che il servizio e altri componenti di terze parti, la cui funzionalità è al di fuori del controllo di gut geregelt, costituiscono un sistema tecnicamente complesso. Pertanto, gut geregelt non può garantire una disponibilità continua e completa dei componenti superiore al 99,5%. Le interruzioni dovute a manutenzioni di sistema, aggiornamenti, ecc. saranno comunicate in anticipo con un preavviso di due giorni lavorativi per le manutenzioni programmate. Le manutenzioni urgenti, che comportano un\'interruzione della disponibilità, possono essere effettuate senza preavviso per risolvere immediatamente i problemi o prevenire potenziali minacce al sistema. Se gut geregelt rileva un pericolo per il corretto funzionamento a causa di azioni negligenti o intenzionali di terzi, è autorizzata a prendere immediatamente tutte le misure necessarie per proteggere la propria infrastruttura e software da danni.',
  },
  support: {
    title: 'Supporto',
    content:
      'Il supporto di gut geregelt è disponibile per il cliente via e-mail all\'indirizzo support@gutgeregelt.ch dal lunedì al venerdì, dalle 8:00 alle 12:00 e dalle 13:00 alle 17:00, con un tempo di risposta medio di 1 ora.',
  },
  renumerations: {
    title: 'Compensi',
    content:
      'L\'importo del compenso per i servizi concordati è stabilito separatamente nei rispettivi listini prezzi. Si tratta generalmente di tariffe ricorrenti. Le tariffe ricorrenti possono essere adeguate previa comunicazione. Sono dovute entro 20 giorni o possono essere addebitate tramite carta di credito. I servizi aggiuntivi concordati saranno fatturati separatamente.',
  },
  intellectualPropertyRights: {
    title: 'Diritti di proprietà intellettuale',
    content:
      'Il cliente riconosce i diritti di proprietà intellettuale, in particolare il diritto d\'autore, di gut geregelt su software, servizi e documentazione. Il cliente non è autorizzato a mettere a disposizione di terzi questo software a pagamento o gratuitamente, a noleggiarlo e/o utilizzarlo al di fuori del rapporto contrattuale con gut geregelt o a contestare in alcun modo i diritti di gut geregelt.',
  },
  dataProtection: {
    title: 'Protezione dei dati e sicurezza dei dati',
    content:
      'gut geregelt tratterà i dati del cliente con la massima cura e li proteggerà da abuso e perdita. gut geregelt non memorizza dati personali dei suoi clienti. Tuttavia, gut geregelt adotta misure tecniche e organizzative che soddisfano i requisiti del GDPR. Nell\'ambito delle possibilità tecniche, possono essere stipulati accordi speciali per l\'archiviazione dei dati in determinati paesi. Il cliente è responsabile della legalità della trasmissione dei dati o del loro utilizzo. Tutti i dati dei clienti memorizzati e trattati da gut geregelt sono di esclusiva proprietà del cliente e vengono utilizzati da gut geregelt esclusivamente ai fini dell\'esecuzione del contratto.',
  },
  disclaimerLiability: {
    title: 'Esclusione di responsabilità',
    content:
      'gut geregelt garantisce che i servizi siano funzionali e operativi in conformità con le disposizioni legali di questo contratto. Il cliente si impegna a tenere indenne gut geregelt da tutte le rivendicazioni di terzi derivanti dai dati memorizzati e a rimborsare a gut geregelt eventuali costi derivanti da violazioni legali. gut geregelt è autorizzata a bloccare immediatamente lo spazio di archiviazione se sussiste il fondato sospetto che i dati memorizzati siano illegali e/o violino i diritti di terzi. Un sospetto fondato di illegalità e/o violazione sussiste quando tribunali, autorità e/o terzi informano gut geregelt. Gut geregelt deve informare immediatamente il cliente della rimozione e dei motivi. Il divieto deve essere revocato non appena il sospetto è completamente eliminato. Gut geregelt esclude, nei limiti delle disposizioni legali, qualsiasi responsabilità nei confronti del cliente (o di terzi), in particolare per l\'adempimento dei suoi obblighi contrattuali ed extracontrattuali, nonché per la perdita di dati e il mancato guadagno (anche in caso di negligenza grave). Questa esclusione di responsabilità si applica anche ai danni che derivano direttamente o indirettamente dall\'uso dei nostri servizi. La responsabilità reciproca delle parti, indipendentemente dal motivo della responsabilità, è in ogni caso limitata all\'importo delle tariffe di servizio mensili degli ultimi dodici mesi prima del verificarsi del danno.',
  },
  communication: {
    title: 'Comunicazione',
    content:
      'gut geregelt è autorizzata a nominare il cliente come riferimento e a utilizzare in modo appropriato le informazioni generali sul contratto concordato per scopi di marketing e vendita.',
  },
  amendments: {
    title: 'Modifiche',
    content:
      'gut geregelt si riserva il diritto di modificare o sostituire queste Condizioni Generali in qualsiasi momento. In caso di modifica, gut geregelt cercherà di informare il cliente almeno 30 giorni prima dell\'entrata in vigore delle nuove condizioni.',
  },
  severabilityClause: {
    title: 'Clausola di salvaguardia',
    content:
      'In caso di invalidità totale o parziale di singole disposizioni di questo contratto, le disposizioni invalide o inapplicabili saranno attuate o integrate in modo tale da raggiungere lo scopo economico perseguito con la disposizione invalida. Lo stesso vale in caso di lacune in questo contratto.',
  },
  jurisdiction: {
    title: 'Foro competente',
    content:
      'Le parti concordano che per tutti i rapporti giuridici derivanti da questo contratto si applica il diritto svizzero. Il foro esclusivo per tutte le controversie derivanti dall\'esecuzione di questo rapporto contrattuale è concordato essere Liestal BL (Svizzera).',
  },
};

export default tt;