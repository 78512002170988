import {
  Avatar,
  AvatarGroup,
  avatarGroupClasses,
  Badge,
  ListItemText,
  Stack,
} from '@mui/material';
import { IChatParticipant } from '#/types/chat';
import { fToNow } from '#/utils/formatTime';

type Props = {
  participants: IChatParticipant[];
};

export default function ChatHeaderDetail({ participants }: Props) {
  const group = participants.length > 1;

  const singleParticipant = participants[0];

  const renderGroup = (
    <AvatarGroup
      max={3}
      sx={{
        [`& .${avatarGroupClasses.avatar}`]: {
          width: 32,
          height: 32,
        },
      }}
    >
      {participants.map((participant) => (
        <Avatar
          key={participant.id}
          alt={`${participant.first_name} ${participant.last_name}`}
          src={participant.avatar_url}
        />
      ))}
    </AvatarGroup>
  );

  const renderSingle = (
    <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
      <Badge
        // variant={singleParticipant.status}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          src={singleParticipant.avatar_url}
          alt={singleParticipant.first_name}
        />
      </Badge>

      <ListItemText
        primary={`${singleParticipant.first_name} ${singleParticipant.last_name}`}
        secondary={
          singleParticipant.status === 'offline'
            ? fToNow(singleParticipant.last_activity)
            : singleParticipant.status
        }
        secondaryTypographyProps={{
          component: 'span',
          ...(singleParticipant.status !== 'offline' && {
            textTransform: 'capitalize',
          }),
        }}
      />
    </Stack>
  );

  return (
    <>
      {group ? renderGroup : renderSingle}

      <Stack flexGrow={1} />
    </>
  );
}
