import { defaultActions } from '#/components/pages/LSLandings/constants/commons';

type POASituationKeys =
  | 'geburt-eines-kindes'
  | 'immobilienkauf'
  | 'pensionierung'
  | 'unternehmensgruendung'
  | 'scheidung';

export const POA_COMMONS = {
  service: 'vorsorgeauftrag',
  actions: defaultActions,
  learnMoreLink: '/vorsorgeauftrag',
  startProcessLink: '/dashboard/vorsorgeauftrag',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-poa.webp',
  },
};

export const POA_SITUATION: Record<POASituationKeys, any> = {
  'geburt-eines-kindes': {
    content: {
      main: {
        title: 'poaLanding.situations.geburt-eines-kindes.content.main.title',
        description:
          'poaLanding.situations.geburt-eines-kindes.content.main.description',
      },
      columns: [
        {
          title:
            'poaLanding.situations.geburt-eines-kindes.content.columns.0.title',
          description: [
            'poaLanding.situations.geburt-eines-kindes.content.columns.0.description.0',
          ],
        },
        {
          title:
            'poaLanding.situations.geburt-eines-kindes.content.columns.1.title',
          description: [
            'poaLanding.situations.geburt-eines-kindes.content.columns.1.description.0',
            'poaLanding.situations.geburt-eines-kindes.content.columns.1.description.1',
            'poaLanding.situations.geburt-eines-kindes.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  immobilienkauf: {
    content: {
      main: {
        title: 'poaLanding.situations.immobilienkauf.content.main.title',
        description:
          'poaLanding.situations.immobilienkauf.content.main.description',
      },
      columns: [
        {
          title: 'poaLanding.situations.immobilienkauf.content.columns.0.title',
          description: [
            'poaLanding.situations.immobilienkauf.content.columns.0.description.0',
          ],
        },
        {
          title: 'poaLanding.situations.immobilienkauf.content.columns.1.title',
          description: [
            'poaLanding.situations.immobilienkauf.content.columns.1.description.0',
            'poaLanding.situations.immobilienkauf.content.columns.1.description.1',
            'poaLanding.situations.immobilienkauf.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  pensionierung: {
    content: {
      main: {
        title: 'poaLanding.situations.pensionierung.content.main.title',
        description:
          'poaLanding.situations.pensionierung.content.main.description',
      },
      columns: [
        {
          title: 'poaLanding.situations.pensionierung.content.columns.0.title',
          description: [
            'poaLanding.situations.pensionierung.content.columns.0.description.0',
          ],
        },
        {
          title: 'poaLanding.situations.pensionierung.content.columns.1.title',
          description: [
            'poaLanding.situations.pensionierung.content.columns.1.description.0',
            'poaLanding.situations.pensionierung.content.columns.1.description.1',
            'poaLanding.situations.pensionierung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  unternehmensgruendung: {
    content: {
      main: {
        title: 'poaLanding.situations.unternehmensgruendung.content.main.title',
        description:
          'poaLanding.situations.unternehmensgruendung.content.main.description',
      },
      columns: [
        {
          title:
            'poaLanding.situations.unternehmensgruendung.content.columns.0.title',
          description: [
            'poaLanding.situations.unternehmensgruendung.content.columns.0.description.0',
          ],
        },
        {
          title:
            'poaLanding.situations.unternehmensgruendung.content.columns.1.title',
          description: [
            'poaLanding.situations.unternehmensgruendung.content.columns.1.description.0',
            'poaLanding.situations.unternehmensgruendung.content.columns.1.description.1',
            'poaLanding.situations.unternehmensgruendung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },

  scheidung: {
    content: {
      main: {
        title: 'poaLanding.situations.scheidung.content.main.title',
        description: 'poaLanding.situations.scheidung.content.main.description',
      },
      columns: [
        {
          title: 'poaLanding.situations.scheidung.content.columns.0.title',
          description: [
            'poaLanding.situations.scheidung.content.columns.0.description.0',
          ],
        },
        {
          title: 'poaLanding.situations.scheidung.content.columns.1.title',
          description: [
            'poaLanding.situations.scheidung.content.columns.1.description.0',
            'poaLanding.situations.scheidung.content.columns.1.description.1',
            'poaLanding.situations.scheidung.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getPOASituation = (situation: POASituationKeys) => ({
  ...POA_SITUATION[situation],
  ...POA_COMMONS,
});
