const digitalAssets = {
  introHead: 'Assicurati di avere un\'impronta digitale pulita',
  introDescription:
    'Assicurati che i tuoi discendenti abbiano accesso e definisci chi eredita cosa.',
  passwordWarning: 'Non condividere mai le password direttamente con Gut geregelt!',
  passwordWarningTooltip:
    'Non fornire mai password a Gut geregelt. Fornisci solo informazioni su dove i parenti possono trovare una panoramica delle tue password.',
  downloadTemplate: 'Scarica il modello per la memorizzazione sicura delle password',
  requestOtherPasswordManager: 'Richiedi altri gestori di password',
  addItems: 'Aggiungi elementi manualmente',
  downloadList: 'Scarica elenco',
  addItem: 'Aggiungi elemento',
  yourPhoneNumber: 'Il tuo numero di telefono',

  afterDeathOptions: {
    delete: 'Elimina',
    transfer: 'Trasferisci',
    inherit: 'Eredita',
  },
  assetTypes: {
    email: 'E-mail',
    social_media_account: 'Social media',
    membership: 'Iscrizione',
    subscription: 'Abbonamento',
    domain: 'Dominio',
    royalty: 'Entrate da royalties',
    crypto_wallet: 'Portafoglio cripto',
    device: 'Dispositivo',
    password_manager: 'Gestore di password',
    phone_provider: 'Fornitore telefonico',
    other: 'Altro',
  },

  assetsTable: {
    tableHeads: {
      name: 'Nome',
      access: 'Accesso',
      type: 'Tipo',
      after_death: 'Dopo la morte',
      beneficiary: 'Beneficiario',
    },
  },

  confirmDeleteModal: {
    title:
      'Sei sicuro di voler eliminare {{asset}} dai tuoi beni digitali?',
    description: 'Puoi sempre aggiungerlo di nuovo in seguito.',
    onClose: 'Chiudi',
    onConfirm: 'Elimina',
  },
};

export default digitalAssets;