import { useForm } from 'react-hook-form';
import { Card, Grid, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useCallback, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
  RHFSwitch,
  RHFUploadBasic,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import ColorPicker from '#/components/shared/custom-input/ColorPicker';
import { fData } from '#/utils/formatNumber';
import {
  useGetPartnerSettings,
  useUpdatePartnerSettings,
} from '#/api/partnerQueries';
import useAuth from '#/hooks/useAuth';

type FontWeight = 'light' | 'regular' | 'medium' | 'semibold' | 'bold';

type FontConfig = {
  family: string;
  size: string;
  color: string;
  weight: FontWeight;
};

type FormValuesProps = {
  partner_name: string;
  primary_color: string;
  secondary_color: string;
  subdomain: string;
  image: string;
  billing_address: string;
  title_font: FontConfig;
  subtitle_font: FontConfig;
  label_font: FontConfig;
  prose_font: FontConfig;
  link_font: FontConfig;
  favicon: string;
  hide_solution_menu: boolean;
  hide_header_links: boolean;
  footer_title: string;
  footer_text: string;
};

const initialThemeValues = {
  primary_color: '#3366FF',
  secondary_color: '#291B4D',
  image: 'https://storage.gutgeregelt.ch/assets/gutgeregeltLogo.svg',
  title_font: {
    family: 'Public Sans',
    size: '24px',
    color: '#000000',
    weight: 'semibold',
  },
  subtitle_font: {
    family: 'Public Sans',
    size: '20px',
    color: '#000000',
    weight: 'medium',
  },
  label_font: {
    family: 'Public Sans',
    size: '16px',
    color: '#000000',
    weight: 'regular',
  },
  prose_font: {
    family: 'Public Sans',
    size: '16px',
    color: '#000000',
    weight: 'regular',
  },
  link_font: {
    family: 'Public Sans',
    size: '16px',
    color: '#000000',
    weight: 'regular',
  },
  favicon: '',
  hide_solution_menu: false,
  footer_title: '',
  footer_text: '',
};

const validateFontWeight = (weight: FontWeight): FontWeight => {
  const validWeights: FontWeight[] = [
    'light',
    'regular',
    'medium',
    'semibold',
    'bold',
  ];
  return validWeights.includes(weight) ? weight : 'regular';
};

export default function AccountPlatform() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const isAdminLoggedIn =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data: platform } = useGetPartnerSettings(isAdminLoggedIn);
  const { mutateAsync: updatePlatform } = useUpdatePartnerSettings();

  const defaultValues = useMemo(
    () => ({
      partner_name: platform?.partner_name ?? '',
      primary_color: platform?.primary_color ?? '',
      secondary_color: platform?.secondary_color ?? '',
      subdomain: platform?.subdomain ?? '',
      image: platform?.image ?? '',
      billing_address: platform?.billing_address ?? '',
      title_font: platform?.title_font ?? initialThemeValues.title_font,
      subtitle_font:
        platform?.subtitle_font ?? initialThemeValues.subtitle_font,
      label_font: platform?.label_font ?? initialThemeValues.label_font,
      prose_font: platform?.prose_font ?? initialThemeValues.prose_font,
      link_font: platform?.link_font ?? initialThemeValues.link_font,
      favicon: platform?.favicon ?? '',
      hide_solution_menu: platform?.hide_solution_menu ?? false,
      hide_header_links: platform?.hide_header_links ?? false,
      footer_title: platform?.footer_title ?? '',
      footer_text: platform?.footer_text ?? '',
    }),
    [platform]
  );

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
    watch,
  } = methods;

  const primaryColorChange =
    watch('primary_color') !== defaultValues.primary_color;
  const secondaryColorChange =
    watch('secondary_color') !== defaultValues.secondary_color;
  const subdomainChange = watch('subdomain') !== defaultValues.subdomain;
  const partnerNameChange =
    watch('partner_name') !== defaultValues.partner_name;
  const imageChange = watch('image') !== defaultValues.image;
  const billingEmailChange =
    watch('billing_address') !== defaultValues.billing_address;
  const faviconChange = watch('favicon') !== defaultValues.favicon;
  const hideMenuChange =
    watch('hide_solution_menu') !== defaultValues.hide_solution_menu;
  const hideHeaderLinks =
    watch('hide_header_links') !== defaultValues.hide_header_links;
  const footerTitleChange =
    watch('footer_title') !== defaultValues.footer_title;
  const footerTextChange = watch('footer_text') !== defaultValues.footer_text;

  const fontChanges = ['title', 'subtitle', 'label', 'prose', 'link'].map(
    (type) => {
      const fontKey = `${type}_font` as keyof FormValuesProps;
      const currentFont = watch(fontKey);
      const defaultFont = defaultValues[fontKey];
      return JSON.stringify(currentFont) !== JSON.stringify(defaultFont);
    }
  );

  const disableDomain =
    defaultValues.subdomain !== '' && defaultValues.subdomain !== null;

  const disabled =
    !primaryColorChange &&
    !secondaryColorChange &&
    !subdomainChange &&
    !partnerNameChange &&
    !imageChange &&
    !billingEmailChange &&
    !faviconChange &&
    !hideHeaderLinks &&
    !hideMenuChange &&
    !footerTitleChange &&
    !footerTextChange &&
    !fontChanges.some(Boolean);

  const onSubmit = async (data: FormValuesProps) => {
    ['title', 'subtitle', 'label', 'prose', 'link'].forEach((type) => {
      const fontKey = `${type}_font` as keyof FormValuesProps;
      const fontConfig = data[fontKey] as FontConfig;
      fontConfig.weight = validateFontWeight(fontConfig.weight);
    });

    if (JSON.stringify(data) === JSON.stringify(defaultValues)) {
      toast.loading('Nothing to update here...', {
        duration: 1500,
        icon: '🤔',
      });

      return;
    }

    const formData = new FormData();
    formData.append('partner_name', data.partner_name);
    formData.append('primary_color', data.primary_color);
    formData.append('secondary_color', data.secondary_color);
    formData.append('subdomain', data.subdomain);
    formData.append('image', data.image);
    formData.append('billing_address', data.billing_address);

    ['title', 'subtitle', 'label', 'prose', 'link'].forEach((type) => {
      const fontKey = `${type}_font` as keyof FormValuesProps;
      const fontConfig = data[fontKey] as FontConfig;
      formData.append(`${type}_font[family]`, fontConfig.family);
      formData.append(`${type}_font[size]`, fontConfig.size);
      formData.append(`${type}_font[color]`, fontConfig.color);
      formData.append(`${type}_font[weight]`, fontConfig.weight);
    });

    if (data.favicon && typeof data.favicon !== 'string') {
      formData.append('favicon', data.favicon);
    }
    formData.append('hide_solution_menu', String(data.hide_solution_menu));
    formData.append('hide_header_links', String(data.hide_header_links));
    formData.append('footer_title', data.footer_title);
    formData.append('footer_text', data.footer_text);

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      await updatePlatform(formData);
      toast.success(translate('toast_notifications.success.platform_update'));
    } catch (error) {
      console.error('Error updating settings:', error);
      toast.error('Failed to update settings');
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (!file) {
        toast.error('No file selected.');
        return;
      }

      const image = new Image();
      image.onload = () => {
        // const { naturalWidth, naturalHeight } = image;
        // if (naturalWidth < 630 || naturalHeight < 80) {
        //   toast.error(String(translate('validations.platform_logo')));
        // } else {
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        // @ts-ignore
        setValue('image', newFile, { shouldValidate: true });
        // }
      };
      image.onerror = () => {
        toast.error('Invalid image file.');
      };
      image.src = URL.createObjectURL(file);
    },
    [setValue]
  );

  const handleFaviconDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (!file) {
        toast.error('No file selected.');
        return;
      }

      const image = new Image();
      image.onload = () => {
        const { naturalWidth, naturalHeight } = image;
        if (naturalWidth < 16 || naturalHeight < 16) {
          toast.error('Favicon must be at least 16x16 pixels.');
        } else {
          const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
          // @ts-ignore
          setValue('favicon', newFile, { shouldValidate: true });
        }
      };
      image.onerror = () => {
        toast.error('Invalid image file.');
      };
      image.src = URL.createObjectURL(file);
    },
    [setValue]
  );

  const onReset = useCallback(() => {
    const resetValues: FormValuesProps = {
      ...defaultValues,
      ...initialThemeValues,
      // Preserve non-theme related fields
      partner_name: defaultValues.partner_name,
      subdomain: defaultValues.subdomain,
      billing_address: defaultValues.billing_address,
      title_font: {
        ...initialThemeValues.title_font,
        weight: initialThemeValues.title_font.weight as FontWeight,
      },
      subtitle_font: {
        ...initialThemeValues.subtitle_font,
        weight: initialThemeValues.subtitle_font.weight as FontWeight,
      },
      label_font: {
        ...initialThemeValues.label_font,
        weight: initialThemeValues.label_font.weight as FontWeight,
      },
      prose_font: {
        ...initialThemeValues.prose_font,
        weight: initialThemeValues.prose_font.weight as FontWeight,
      },
      link_font: {
        ...initialThemeValues.link_font,
        weight: initialThemeValues.link_font.weight as FontWeight,
      },
    };
    reset(resetValues);
    toast.success('Theme settings reset to defaults');
  }, [defaultValues, reset]);

  useEffect(() => {
    if (platform) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [platform]);

  const handleRemove = useCallback(() => {
    setValue('favicon', '', { shouldValidate: true });
  }, [setValue]);

  return (
    <>
      <Typography variant="subtitle1" sx={{ px: 3 }}>
        {translate('adminDashboard.platform')}
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              {/* <AccountPicture /> */}
              <RHFUploadAvatar
                name="image"
                maxSize={10000000}
                onDrop={handleDrop}
                sx={{
                  borderRadius: '5%',
                  width: '100%',
                }}
                styledPlaceholderSx={{
                  borderRadius: 1,
                }}
                avatarPreviewSx={{
                  borderRadius: '5%',
                  height: 'fit-content',
                }}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    {translate('global.imageHelper')}
                    <br /> {translate('global.imageMaxSize')} {fData(10000000)}
                  </Typography>
                }
              />
              <RHFUploadBasic
                name="favicon"
                placeholderHeading={
                  typeof watch('favicon') === 'string' &&
                  watch('favicon') !== ''
                    ? watch('favicon')
                    : translate('global.formLabels.favicon')
                }
                onDrop={handleFaviconDrop}
                onDelete={handleRemove}
                sx={{
                  marginTop: 2,
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <RHFTextField
                    name="partner_name"
                    label={String(
                      translate('global.formLabels.organisation_name')
                    )}
                  />
                </Stack>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <RHFTextField
                    disabled={disableDomain}
                    name="subdomain"
                    label={translate('global.formLabels.domain')}
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body1" color="text.secondary">
                          .gutgeregelt.ch
                        </Typography>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="billing_address"
                    label={translate('global.formLabels.billing_email')}
                  />
                </Stack>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <ColorPicker
                    name="primary_color"
                    label={translate('global.formLabels.primary_color')}
                  />
                  <ColorPicker
                    name="secondary_color"
                    label={translate('global.formLabels.secondary_color')}
                  />
                </Stack>
                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                  {translate('global.formLabels.font_settings')}
                </Typography>

                {['title', 'subtitle', 'label', 'prose', 'link'].map((type) => (
                  <Stack
                    key={type}
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={1}
                  >
                    <Typography sx={{ minWidth: 120, pt: 1 }}>
                      {translate(`global.formLabels.${type}_font`)}
                    </Typography>

                    <RHFTextField
                      name={`${type}_font.family`}
                      label="Font Family"
                      select
                    >
                      {[
                        'Public Sans',
                        'Arial',
                        'Helvetica',
                        'Times New Roman',
                        'Georgia',
                        'SegoeUI',
                      ].map((font) => (
                        <MenuItem key={font} value={font}>
                          {font}
                        </MenuItem>
                      ))}
                    </RHFTextField>

                    <RHFTextField
                      name={`${type}_font.size`}
                      label="Font Size"
                      fullWidth
                    />

                    <ColorPicker
                      name={`${type}_font.color`}
                      label="Font Color"
                      sx={{ width: 100 }}
                    />

                    <RHFTextField
                      name={`${type}_font.weight`}
                      label="Font Weight"
                      select
                      sx={{ width: 120 }}
                    >
                      {['light', 'regular', 'medium', 'semibold', 'bold'].map(
                        (weight) => (
                          <MenuItem key={weight} value={weight}>
                            {weight.charAt(0).toUpperCase() + weight.slice(1)}
                          </MenuItem>
                        )
                      )}
                    </RHFTextField>
                  </Stack>
                ))}

                <RHFSwitch
                  name="hide_header_links"
                  label={translate('global.formLabels.hide_header_links')}
                />
                <Typography variant="subtitle1">
                  {translate('global.footerSettings')}
                </Typography>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                >
                  <RHFSwitch
                    name="hide_solution_menu"
                    label={translate('global.formLabels.hide_solution_menu')}
                  />
                </Stack>

                <Stack spacing={2}>
                  <RHFTextField
                    name="footer_title"
                    label={translate('global.formLabels.footer_title')}
                  />
                  <RHFTextField
                    name="footer_text"
                    label={translate('global.formLabels.footer_text')}
                    multiline
                    rows={2}
                  />
                </Stack>
                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    width="100%"
                    justifyContent="flex-end"
                  >
                    <LoadingButton
                      aria-label="reset theme"
                      variant="outlined"
                      onClick={onReset}
                    >
                      <Typography>
                        {translate('global.resetToDefault')}
                      </Typography>
                    </LoadingButton>
                    <LoadingButton
                      aria-label="save changes"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={disabled}
                    >
                      <Typography>{translate('global.save')}</Typography>
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
